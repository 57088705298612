import { FastTrackComponent } from './fast-track/fast-track.component';
import { TrackSelectionComponent } from './track-selection/track-selection.component';
import { PreviewComponent } from './preview/preview.component';
import { KonfiguratorComponent } from './konfigurator/konfigurator.component';

import { Routes } from '@angular/router';

export const ROUTE_CONFIG: Routes = [
  {
    path: 'konfigurator',
    component: KonfiguratorComponent,
  },
  {
    path: 'track-select',
    component: TrackSelectionComponent,
  },
  {
    path: 'fast-track',
    component: FastTrackComponent,
  },

  {
    path: 'preview',
    component: PreviewComponent,
  },

  {
    path: '**',
    redirectTo: 'track-select',
    pathMatch: 'full',
  },
];
