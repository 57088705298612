import { Injectable, EventEmitter } from '@angular/core';
import * as WebFont from 'webfontloader';

/**
 * The FontDataService manages the available fonts and provides
 * helper methods for usage of these Fonts.
 */
@Injectable()
export class FontDataService {
  private loadedCounter;
  private resolveAllFontLoadedPromise: (
    value?: void | PromiseLike<void>
  ) => void;
  private readonly allFontLoadedPromise: Promise<void> = new Promise(
    resolve => {
      this.resolveAllFontLoadedPromise = resolve;
    }
  );
  public allFont: string[] = [
    'AlegreyaSC-Bold',
    'AlegreyaSC-BoldItalic',
    'AlegreyaSC-Italic',
    'AlegreyaSC-Regular',
    'Athiti-Bold',
    'Athiti-Regular',
    'Bungee-Regular',
    'Economica-Bold',
    'Economica-BoldItalic',
    'Economica-Italic',
    'Economica-Regular',
    'JosefinSans-Bold',
    'JosefinSans-BoldItalic',
    'JosefinSans-Italic',
    'JosefinSans-Regular',
    'Kalam-Bold',
    'Kalam-Regular',
    'KaushanScript-Regular',
    'Khand-Bold',
    'Khand-Regular',
    'MarcellusSC-Regular',
    'OpenSans-Bold',
    'OpenSans-BoldItalic',
    'OpenSans-Italic',
    'OpenSans-Regular',
    'Poppins-Bold',
    'Poppins-Regular',
    'Quicksand-Bold',
    'Quicksand-Regular',
    'SigmarOne-Regular',
  ];
  constructor() {
    const WebFontConfig = {
      custom: {
        families: this.allFont,
        events: true,
        loadAllFonts: true,
      },
      // function for that indicates taht all fonts are loaded
      loading: () => {
        this.loadedCounter = 0;
      },
      fontactive: () => {
        this.loadedCounter++;
        if (this.loadedCounter === this.allFont.length) {
          console.log(`Font Data Service: ${this.loadedCounter} Fonts loaded!`);
          this.resolveAllFontLoadedPromise();
        }
      },
    };

    WebFont.load(WebFontConfig);
  }

  public getAllFontLoadedPromise(): Promise<void> {
    return this.allFontLoadedPromise;
  }

  public getStylePossiblitiesForFont = (fontFamily: string): string[] => {
    return this.allFont
      .filter((element: string) => {
        const fontarr = element.split('-');
        if (fontarr.length !== 2) {
          console.error('unexpected Font!');
          return false;
        }
        return fontFamily === fontarr[0];
      })
      .map(a => {
        return a.replace(fontFamily + '-', '');
      });
  };

  public getAllMembersOfFontFamily = (fontFamily: string): string[] => {
    return this.allFont.filter((element: string) => {
      const fontarr = element.split('-');
      if (fontarr.length !== 2) {
        console.error('unexpected Font!');
        return false;
      }
      return fontFamily === fontarr[0];
    });
  };

  public getFontFamilyToggleBold = (
    fontFamily: string,
    isBoldEnabled: boolean
  ): string => {
    const currentFontArr = fontFamily.split('-');
    if (currentFontArr.length !== 2) {
      console.error('unexpected current Font!');
      return null;
    }
    const currentFontFamily = currentFontArr[0];
    const currentStyle = currentFontArr[1];
    if (isBoldEnabled) {
      if (currentStyle === 'Bold') {
        return currentFontFamily + '-Regular';
      } else if (currentStyle === 'Regular') {
        return currentFontFamily + '-Bold';
      } else if (currentStyle === 'BoldItalic') {
        return currentFontFamily + '-Italic';
      } else if (currentStyle === 'Italic') {
        return currentFontFamily + '-BoldItalic';
      }
    }
    return undefined;
  };

  public getFontFamilyToggleItalic = (
    fontFamily: string,
    isItalicEnabled: boolean
  ): string => {
    const currentFontArr = fontFamily.split('-');
    if (currentFontArr.length !== 2) {
      console.error('unexpected current Font!');
      return null;
    }
    const currentFontFamily = currentFontArr[0];
    const currentStyle = currentFontArr[1];
    if (isItalicEnabled) {
      if (currentStyle === 'Italic') {
        return currentFontFamily + '-Regular';
      } else if (currentStyle === 'Regular') {
        return currentFontFamily + '-Italic';
      } else if (currentStyle === 'BoldItalic') {
        return currentFontFamily + '-Bold';
      } else if (currentStyle === 'Bold') {
        return currentFontFamily + '-BoldItalic';
      }
    }
    return undefined;
  };

  public isFontChangePossible(currentFontFamily: string, currentStyle: string) {
    const arr = this.getStylePossiblitiesForFont(currentFontFamily);
    if (currentStyle === 'Bold') {
      return (
        arr.filter(a => {
          return a === 'Regular';
        }).length === 1
      );
    } else if (currentStyle === 'Regular') {
      return (
        arr.filter(a => {
          return a === 'Bold';
        }).length === 1
      );
    } else if (currentStyle === 'BoldItalic') {
      return (
        arr.filter(a => {
          return a === 'Italic';
        }).length === 1
      );
    } else if (currentStyle === 'Italic') {
      return (
        arr.filter(a => {
          return a === 'BoldItalic';
        }).length === 1
      );
    }
    return false;
  }
  public isFontStyle(fontFamily: string, style: string): boolean {
    return fontFamily.indexOf(style) >= 0;
  }
}
