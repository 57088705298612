import { CONSTANTS } from './../../models/helpers/constants';
import { NavigationService } from 'app/services/navigation.service';
import { Subscription } from 'rxjs';
import { ConfirmFinishOrderDialogComponent } from './../dialogs/confirmfinishorderdialog.component';

import { CarSideHandlerComponent } from './../car-side-handler/car-side-handler.component';
import { CarDataManagerService } from './../services/car-data-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { CanvasHelperService } from 'app/services/canvashelperservice';
import { DataService } from 'app/services/dataservice';
import { Fahrzeugseite } from './../../models/fahrzeugeseite';
import { Seitenansicht } from './../../models/seitenansicht';
import { RenderEditModeService } from 'app/services/rendereditmodeservice';
import { FinishGenerationProcessService } from 'app/services/finish-generation-process.service';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  AfterContentInit,
  AfterViewChecked,
  ViewChild,
  Input,
  Inject,
} from '@angular/core';
import * as fabric from 'fabric';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { NGXLogger } from "ngx-logger";import { UserType } from 'models/usertype';

/**
 * @fileOverview This component represents a
 * View showing a 'view-only' representatin of
 * each Seitenansicht. Data is loaded
 * from dataService.druckauftrag.
 */

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent
  implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked
{
  @Input()
  sideIndex: number;

  private allFahrzeugseiteInOrder = [
    Fahrzeugseite.BEIFAHRER,
    Fahrzeugseite.FAHRER,
    Fahrzeugseite.FRONT,
    Fahrzeugseite.HECK,
  ];

  private carLoaded = false;
  private allEditAreaLoaded = false;

  public showInitialLoadingCircle = true;
  public showLoadingCircle = true;
  public isFinishingOrder = false;
  /**
   * Disables the readonly button
   */
  public disableReadOnlyClose = false;
  // used URLs
  carUrl: SafeResourceUrl = undefined;
  carBackgroundUrl: SafeResourceUrl = undefined;
  templatePathUrl: SafeResourceUrl = undefined;

  @ViewChild('carsvg', { static: true })
  private carSvg: CarSideHandlerComponent;
  @ViewChild('canvas', { static: true })
  private canvasRef: ElementRef;

  // used Objects
  // TODO: BEKO-104 fix typings
  private fabric: any;
  private canvas: fabric.fabric.Canvas;

  /**
   * Creates an instance of PreviewComponent.
   * It get a druckauftrag from the dataService
   * and redirects to /track-selection if none is
   * present.
   *
   * @param {Router} router
   * @param {DomSanitizer} domSanitizer
   * @param {MatIconRegistry} matIconRegistry
   * @param {DataService} dataService
   * @param {LoginService} loginService
   * @param {CanvasHelperService} canvasHelperService
   * @memberof PreviewComponent
   */
  constructor(
    private readonly router: Router,
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly dataService: DataService,
    private readonly canvasHelperService: CanvasHelperService,
    private readonly renderEditModeService: RenderEditModeService,
    private readonly translateService: TranslateService,
    private readonly carDataManagerService: CarDataManagerService,
    private readonly finishGenerationProcessService: FinishGenerationProcessService,
    private readonly dialog: MatDialog,
    private readonly navigationService: NavigationService,
    private logger: NGXLogger,
    @Inject(DOCUMENT) private document: any
  ) {
    if (
      !this.dataService.druckauftrag &&
      !this.dataService.isLoadingDruckauftrag
    ) {
      this.logger.error('PreviewComponent: Druckauftrag is undefined.');
      this.navigationService.navigateTrackSelect(this.dataService.getParams());
      return;
    }
    // set Icons
    this.addIconsToRegistry();

    // TODO: BEKO-104 fix Typing
    this.fabric = <any>fabric.fabric;

    if (!this.sideIndex) {
      this.sideIndex = 0;
    }
    this.dataService.allEditAreaLoadedPromise.then(() => {
      this.allEditAreaLoaded = true;
      this.performLoadingCycleCheck();
    });
    this.dataService.druckauftragLoadedEmitter.subscribe(() => {
      this.performLoadingCycleCheck();
    });
    this.carDataManagerService.templateDataLoadedPromise.then(() => {
      // use?
    });
    this.dataService.fastTrackDataLoadedEmitter.subscribe(() => {
      this.performLoadingCycleCheck();
    });
  }

  /**
   * Hook for OnInit, redirects
   * when no druckauftrag is availible.
   *
   * @returns
   * @memberof PreviewComponent
   */
  ngOnInit() {
    this.sideIndex = 0;
    this.updateSvgPaths();
  }

  /**
   * Hook for AfterContentInit.
   *
   * @memberof PreviewComponent
   */
  ngAfterContentInit() {}

  /**
   * Hook for AfterViewInit
   *
   *
   * @memberof PreviewComponent
   */
  ngAfterViewInit() {
    if (this.canvasRef) {
      this.canvas = new this.fabric.Canvas(this.canvasRef.nativeElement);
      this.canvas.setBackgroundColor('#00ff00', () => {});

      this.canvasHelperService.fixViewPort(this.canvas);

      // add events
      window.addEventListener('resize', this.adaptOnResize, true);
    }
    this.carDataManagerService.createPresentationView(
      this.dataService.druckauftrag.carColor
    );
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.getCurrentSide()
    );
    if (carSideManager) {
      this.carSvg.setCarSideManager(carSideManager);
      this.onLoadCar();
    }

    this.adaptOnResize();
  }

  /**
   * Hook for AfterViewChecked
   *
   * @memberof PreviewComponent
   */
  ngAfterViewChecked() {}

  /**
   * Add icons so we can use them without security issues
   *
   * @private
   * @memberof PreviewComponent
   */
  private addIconsToRegistry = () => {
    this.matIconRegistry
      .addSvgIcon(
        'arrow-left',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/preview-prev-50px.svg'
        )
      )
      .addSvgIcon(
        'arrow-right',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/preview-next-50px.svg'
        )
      )
      .addSvgIcon(
        'step-actual',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/step.actual-24px.svg'
        )
      )
      .addSvgIcon(
        'step-coming',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/step.coming-18px.svg'
        )
      )
      .addSvgIcon(
        'step-done',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/step.done-18px.svg'
        )
      )
      .addSvgIcon(
        'save',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/content/Icon_save.svg'
        )
      )
      .addSvgIcon(
        'warenkorb',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/cart.svg'
        )
      )
      .addSvgIcon(
        'preview-next-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-preview-next.svg'
        )
      );
  };

  /**
   * get Seitenansicht for the set Fahrzeugseite
   *
   * @private
   * @memberof PreviewComponent
   */
  private getSeitenansicht = (): Seitenansicht => {
    if (!this.dataService.druckauftrag) {
      return undefined;
    }

    switch (this.getCurrentSide()) {
      case Fahrzeugseite.BEIFAHRER:
        return this.dataService.druckauftrag.rechts;
      case Fahrzeugseite.FAHRER:
        return this.dataService.druckauftrag.links;
      case Fahrzeugseite.FRONT:
        return this.dataService.druckauftrag.front;
      case Fahrzeugseite.HECK:
        return this.dataService.druckauftrag.heck;
      default:
        return undefined;
    }
  };

  /**
   * return the Fahrzeugseite type that is shown
   * in the preview alternatively undefined
   *
   * @returns  {Fahrzeugseite} enum or undefined
   * @memberof PreviewComponent
   */
  public getCurrentSide = (): Fahrzeugseite => {
    if (
      this.sideIndex >= 0 &&
      this.sideIndex < this.allFahrzeugseiteInOrder.length
    ) {
      return this.allFahrzeugseiteInOrder[this.sideIndex];
    }

    return this.allFahrzeugseiteInOrder[0];
  };

  /**
   * loads the svg paths for the current side.
   *
   * @private
   * @memberof PreviewComponent
   */
  private updateSvgPaths = (): void => {
    // FIXME: use this to load files correctly

    this.showLoadingCircle = true;
    this.carLoaded = false;

    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.getCurrentSide()
    );
    if (carSideManager) {
      this.carSvg.setCarSideManager(carSideManager);
      this.onLoadCar();
    }
    this.adaptOnResize();
  };

  /**
   * evaluates if the previous side can be selected,
   * used for UI logic
   *
   * @memberof PreviewComponent
   */
  public isPreviousSidePossible = (): boolean => {
    return this.sideIndex > 0;
  };
  /**
   * Previous button visibility state for readonlymode
   */
  public isInInitialStateReadOnly() {
    return this.isInReadOnlyMode() && !this.isPreviousSidePossible();
  }

  /**
   * selects the side with the previous index
   *
   *
   * @memberof PreviewComponent
   */
  public loadPreviousSide = () => {
    if (this.isAllDataLoaded()) {
      if (this.isPreviousSidePossible()) {
        this.sideIndex--;
        this.updateSvgPaths();
      }
    }
  };

  /**
   * changes to the next car side if possible
   *
   * @memberof PreviewComponent
   */
  public loadNextSide = (): void => {
    if (this.isAllDataLoaded()) {
      this.sideIndex++;

      if (this.sideIndex < this.allFahrzeugseiteInOrder.length) {
        this.updateSvgPaths();
      }
    }
  };

  /**
   * navigates to the prevous view
   *
   * @memberof PreviewComponent
   */
  public triggerBrowserBack = (): void => {
    window.history.back();
  };

  /**
   * navigates to the next view if allowed
   *
   * @memberof PreviewComponent
   */
  public navigateToNextView(
    urlParameter: 'save' | 'basket'
  ): Subscription | void {
    if (this.sideIndex === this.allFahrzeugseiteInOrder.length - 1) {
      this.showLoadingCircle = true;
      this.isFinishingOrder = true;

      // notify hybris directly if the "normal" user wants to order a predesigned configuration
      if (
        this.hasPredefinedPrice() &&
        this.dataService.userType !== UserType.DESIGNUSER
      ) {
        console.log('NotifyHybris----')
        return this.notifyHybris(
          urlParameter,
          this.dataService.druckauftrag.configID
        );
      }
      // call beko server to generate production data
      return this.finishGenerationProcessService
        .finish()
        .subscribe((azureConfigId: string) => {
          console.log(urlParameter, azureConfigId, 'AzureConfigI.....')
          this.notifyHybris(urlParameter, azureConfigId);
        });
    }
  }

  /**
   * Draws all fabric elements for a given Seitenansicht on the canvas
   *
   * @memberof PreviewComponent
   */
  public onLoadCar = (): void => {
    // new Car image ->clear canvas
    if (this.canvas) {
      this.carLoaded = true;
      this.performLoadingCycleCheck();
    }
    this.adaptOnResize();
  };

  private performLoadingCycleCheck() {
    if (this.isAllDataLoaded()) {
      this.canvas.clear();
      this.canvasHelperService.drawAllDataOnCanvas(
        this.canvas,
        this.getSeitenansicht().allDataElement,
        false
      );
      this.adaptOnResize();
      if (!this.dataService.druckauftrag.textLayout) {
        this.dataService.druckauftrag.textLayout = [];
      }
      const sideDict: string[] = this.canvasHelperService.getTextSvgDictionary(
        this.canvas
      );
      this.dataService.druckauftrag.textLayout[this.getCurrentSide()] =
        sideDict;
      this.showLoadingCircle = false;
      this.showInitialLoadingCircle = false;
      setTimeout(this.adaptOnResize);
    }
  }
  private isAllDataLoaded(): boolean {
    return (
      this.dataService.isFastTrackDataReplacedAndInitialized && this.carLoaded
    );
  }
  /**
   * Adapt the canvas and elements in it on resize, zoom, layout..
   *
   * @private
   * @memberof PreviewComponent
   */
  private adaptOnResize = (event?): void => {
    if (!this.carSvg || !this.canvas) {
      return;
    }

    // init and resize canvas;
    this.adaptCanvasSize();
    this.canvas.zoomToPoint(
      new this.fabric.Point(0, 0),
      this.carSvg.getScaleToCanvasFactor()
    );
    this.canvas.selection = false;
    this.renderEditMode();
  };

  /**
   * Initializes the canvas, sets the height and width of the canvas according to the surrounding svg
   *
   * @private
   * @memberof EditorComponent
   */
  private adaptCanvasSize = (): void => {
    this.canvas.setWidth(this.carSvg.getScaledWidth());
    this.canvas.setHeight(this.carSvg.getScaledHeight());
  };

  /**
   * adapts height and width for object elements and containing svgs
   *
   * @private
   * @param {ElementRef} elem
   * @param {number} width
   * @param {number} height
   * @memberof PreviewComponent
   */
  private setHeightAndWidthOfObjectSvg(
    elem: ElementRef,
    width: number,
    height: number
  ): void {
    const allElement =
      this.getContentDocument(elem).getElementsByTagName('svg');

    if (allElement.length > 0) {
      allElement[0].setAttribute('width', width + 'px');
      allElement[0].setAttribute('height', height + 'px');
    }

    elem.nativeElement.style.width = width + 'px';
    elem.nativeElement.style.height = height + 'px';
  }

  /**
   * change a style color of a svg-element of an included object-Element
   *
   * @private
   * @memberof PreviewComponent
   */
  private changeFillStyleOfElement = (
    element: ElementRef,
    styleName: string,
    colorstring: string
  ): void => {
    if (
      !element ||
      !element.nativeElement ||
      !element.nativeElement.contentDocument
    ) {
      return;
    }

    const styleElement =
      element.nativeElement.contentDocument.getElementsByTagName('style')[0];

    if (styleElement) {
      const htmlString = styleElement.innerHTML;
      element.nativeElement.contentDocument.getElementsByTagName(
        'style'
      )[0].innerHTML = this.replaceStyle(
        styleName,
        htmlString,
        'fill',
        colorstring
      );
    } else {
      setTimeout(function () {
        const scndStyleElement =
          element.nativeElement.contentDocument.getElementsByTagName(
            'style'
          )[0];

        if (scndStyleElement) {
          const htmlString = scndStyleElement.innerHTML;
          element.nativeElement.contentDocument.getElementsByTagName(
            'style'
          )[0].innerHTML = this.replaceStyle(
            styleName,
            htmlString,
            'fill',
            colorstring
          );
        }
      });
    }
  };

  /**
   * replace the style a style value(string or number) in a specific style(styleName) in the given htmlString
   */
  private replaceStyle = (
    styleName: string,
    htmlString: string,
    attrname: string,
    value: string | number
  ) => {
    const styleArr: string[] = htmlString.split('.' + styleName + '{', 2);
    if (!styleArr[1]) {
      return htmlString;
    }
    const arr: string[] = styleArr[1].split(attrname + ':');
    if (arr.length < 2) {
      return htmlString;
    }
    const valueMatch = styleArr[1].match(attrname + ':' + value);
    if (valueMatch !== null) {
      return htmlString;
    }
    const startIndex: number = htmlString.indexOf(attrname + ':');
    const styleLength: number = attrname.length + 1;
    const endIndex = htmlString.indexOf(';', startIndex + styleLength);

    const startStr = htmlString.substr(0, startIndex);
    const endStr = htmlString.substr(endIndex);
    return startStr + attrname + ':' + value + endStr;
  };

  /**
   * renders the clipping in case the editmode is disalbed
   *
   * @private
   * @memberof PreviewComponent
   */
  private renderEditMode = (): void => {
    // save all objects on cavas !! Copy objects!!
    const allObject: fabric.fabric.Object[] = this.canvas.getObjects().slice(0);

    // clear canvas before clipping
    this.canvas.clear();
    if (!this.getSeitenansicht()) {
      return;
    }

    this.renderEditModeService.defineClippingAreas(
      this.getSeitenansicht(),
      this.carSvg.getScaleToCanvasFactor(),
      this.canvas.getContext(),
      { x: 0, y: 0 } as fabric.fabric.Point
    );

    // clip the selected area on the canvas
    this.canvas.getContext().clip();

    // add all Objects again
    allObject.forEach(object => {
      this.canvas.add(object);
    });

    this.canvas.renderAll();
  };

  /**
   * Helpermethod to retrieve document element of elementref (browserindependent)
   *
   * @memberof PreviewComponent
   */
  public getContentDocument = (elementRef: ElementRef) => {
    if (elementRef && elementRef.nativeElement) {
      let element =
        elementRef.nativeElement.contentWindow ||
        elementRef.nativeElement.contentDocument;
      if (element && element.document) {
        element = element.document;
      }

      return element;
    }
    return undefined;
  };

  public showDivBehindCanvas = (): boolean => {
    if (this.dataService.druckauftrag) {
      const fahrzeugSeite = this.sideIndex;

      if (
        fahrzeugSeite === Fahrzeugseite.BEIFAHRER ||
        fahrzeugSeite === Fahrzeugseite.FAHRER
      ) {
        return false;
      }
    }
    return true;
  };

  public onClickLeftButton() {
    if (this.sideIndex <= 0) {
      this.triggerBrowserBack();
    }
    this.loadPreviousSide();
  }

  public onClickRightButton() {
    if (this.sideIndex === this.allFahrzeugseiteInOrder.length - 1) {
      this.handleRightButtonFinish();
    } else {
      this.loadNextSide();
    }
  }

  private handleRightButtonFinish() {
    if (this.isInReadOnlyMode()) {
      this.showLoadingCircle = true;
      this.disableReadOnlyClose = true;
      console.log(this.dataService.druckauftrag.configID, 'this.dataService.druckauftrag.configID')
      this.notifyHybris('close', this.dataService.druckauftrag.configID);
      return;
    }

    const dialogRef: MatDialogRef<ConfirmFinishOrderDialogComponent> =
      this.dialog.open(ConfirmFinishOrderDialogComponent, {
        panelClass: 'custom-finish-order-dialog-container',
        data: {
          textKey: 'PREVIEW.FINISH-ORDER.SAVE.TEXT',
          titleKey: 'PREVIEW.FINISH-ORDER.SAVE.TITLE',
          positiveText: 'PREVIEW.FINISH-ORDER.SAVE.POSITIVE-TEXT',
          showNoButton: true,
          negativeText: 'PREVIEW.FINISH-ORDER.SAVE.NEGATIVE-TEXT',
        },
      });
    dialogRef.afterClosed().subscribe(returnvalue => {
        if (returnvalue) {
          console.log(returnvalue, 'Return value------')
          this.navigateToNextView('save');
        }
      })
  }
  public onClickBackToDesign() {
    this.router.navigate(['/konfigurator']);
  }

  public getRightButtonLabel() {
    let value = undefined;
    if (this.sideIndex >= this.allFahrzeugseiteInOrder.length - 1) {
      if (this.dataService.readOnly) {
        this.translateService
          .get('PREVIEW.FINISH.LEFT-LABEL-READONLY')
          .subscribe(result => {
            value = result;
          });
      } else {
        this.translateService
          .get('PREVIEW.FINISH.LEFT-LABEL')
          .subscribe(result => {
            value = result;
          });
      }
    } else {
      this.translateService.get('PREVIEW.NEXT').subscribe(result => {
        value = result;
      });
    }
    return value;
  }

  public showSecondOptionButton(): boolean {
    if (
      this.sideIndex === this.allFahrzeugseiteInOrder.length - 1 &&
      this.dataService.configMode !== 'quote' &&
      (!this.isInReadOnlyMode() || this.hasPredefinedPrice())
    ) {
      return true;
    }
    return false;
  }
  public showSaveIcon(): boolean {
    if (
      this.sideIndex === this.allFahrzeugseiteInOrder.length - 1 &&
      !this.isInReadOnlyMode()
    ) {
      return true;
    }
    return false;
  }

  public onClickSecondOption() {
    const dialogRef: MatDialogRef<ConfirmFinishOrderDialogComponent> =
      this.dialog.open(ConfirmFinishOrderDialogComponent, {
        panelClass: 'custom-finish-order-dialog-container',
        data: {
          textKey: 'PREVIEW.FINISH-ORDER.WARENKORB.TEXT',
          titleKey: 'PREVIEW.FINISH-ORDER.WARENKORB.TITLE',
          positiveText: 'PREVIEW.FINISH-ORDER.WARENKORB.POSITIVE-TEXT',
          showNoButton: true,
          negativeText: 'PREVIEW.FINISH-ORDER.WARENKORB.NEGATIVE-TEXT',
        },
      });
    dialogRef.afterClosed().subscribe(returnvalue => {
        if (returnvalue) {
          this.navigateToNextView('basket');
        }
      })
  }

  private notifyHybris(
    routeExtension: 'save' | 'basket' | 'close',
    configId?: string
  ) {
    console.log(configId, 'ConfigId NotifyHybris----')
    this.document.location.href = this.getReferralUrl(routeExtension, configId);
  }
  private getReferralUrl(
    routeExtension: 'save' | 'basket' | 'close',
    configId?: string
  ): string {
    let referrerWithoutParams = document.referrer;
    const graphicsIndex = referrerWithoutParams.indexOf(
      'graphics-configurator'
    );
    if (graphicsIndex >= 0) {
      referrerWithoutParams = referrerWithoutParams.substring(0, graphicsIndex);
    }
    referrerWithoutParams += 'graphics-configurator/';
    const path =
      this.addSlashIfNecessary(referrerWithoutParams) + 'closeGraphics';
    let additionalParams = '';
    if (this.dataService.configMode === 'quote') {
      additionalParams = `?${CONSTANTS.HYBRIS_CONFIG_MODE_KEY}="${this.dataService.configMode}"`;
    }
    return `${path}/${routeExtension}/${configId}/${this.dataService.hybrisConfigId}${additionalParams}`;
  }
  private addSlashIfNecessary(path: string): string {
    if (path && !path.endsWith('/')) {
      path += '/';
    }
    return path;
  }

  public getSecondOptionLabel(): string {
    let res;
    this.translateService
      .get('PREVIEW.FINISH.RIGHT-LABEL')
      .subscribe(result => {
        res = result;
      });
    return res;
  }

  public isInReadOnlyMode(): boolean {
    return this.dataService.readOnly;
  }
  public hasPredefinedPrice(): boolean {
    return (
      this.dataService.druckauftrag.designUserPredefinedHqPrice !== null &&
      this.dataService.druckauftrag.designUserPredefinedHqPrice !== undefined
    );
  }
}
