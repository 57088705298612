import * as fabric from 'fabric';
import { FabricHelper } from 'models/helpers/fabric.helper';

const fabricObj = <any>fabric.fabric;

// -- fabric.custom.js --
fabricObj.CustomTriangle = fabricObj.util.createClass(fabricObj.Triangle, {
  type: 'customTriangle',

  _render: function(ctx: CanvasRenderingContext2D) {
    // this.callSuper('_render', ctx);
    const width = this.width + this.strokeWidth;
    const height = this.height + this.strokeWidth;
    const left = -width / 2;
    const top = -height / 2;

    const radian = (30 * Math.PI) / 180;
    const strokeWidth = this.strokeWidth / 2;
    const c = strokeWidth / Math.sin(radian);

    const x_diff_bottom = c * Math.cos(radian);
    const y_diff_bottom = strokeWidth;
    const y_diff_top = c;

    ctx.moveTo(0, top + y_diff_top);
    ctx.lineTo(left + x_diff_bottom, top + height - y_diff_bottom);
    ctx.lineTo(left + width - x_diff_bottom, top + height - y_diff_bottom);
    ctx.closePath();
    ctx.strokeStyle = this.strokeColor;
    ctx.lineWidth = this.strokeWidth;
    ctx.stroke();
    ctx.fillStyle = this.fill;
    ctx.fill();
    ctx.strokeStyle = '#000000';
    ctx.beginPath();
  },
  _toSVG: function() {
    const width = this.width + this.strokeWidth;
    const height = this.height + this.strokeWidth;
    const left = -width / 2;
    const top = -height / 2;

    const radian = (30 * Math.PI) / 180;
    const strokeWidth = this.strokeWidth / 2;
    const c = strokeWidth / Math.sin(radian);

    const x_diff_bottom = c * Math.cos(radian);
    const y_diff_bottom = strokeWidth;
    const y_diff_top = c;

    const points = [
      '0 ' + (top + y_diff_top),
      +(left + x_diff_bottom) + ' ' + (top + height - y_diff_bottom),
      '' +
        (left + width - x_diff_bottom) +
        ' ' +
        (top + height - y_diff_bottom),
    ].join(',');

    // old style was contained via  (fabricjs magic)
    // 'COMMON_PARTS',
    // stroke: rgb(252,172,0); stroke-width: 8; stroke-dasharray: none; stroke-linecap: butt;
    // stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;
    return [
      '\n',
      '<polygon ',
      ' style="stroke-width:',
      this.strokeWidth,
      '; fill: ',
      this.fill,
      '; stroke: ',
      this.stroke,
      '; stroke-dasharray: none;stroke-linecap: butt;stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 5; fill-rule: nonzero; opacity: 1;  paint-order: stroke;" ',
      'points="',
      points,
      '" />',
      '\n',
    ];
  },
  initialize: function(element, options) {
    options = options || {};
    this.callSuper('initialize', element, options);
  },
  toObject: function() {
    const obj = fabricObj.util.object.extend(this.callSuper('toObject'), {});
    return FabricHelper.copyProperties(this, obj);
  },
});

fabricObj.CustomTriangle.fromObject = function(object, callback) {
  const obj = new fabricObj.CustomTriangle(object);
  if (callback) {
    callback(obj);
  }
  return obj;
};

export const isCustomTriangle = (obj: any): obj is CustomTriangle => {
  return obj !== null && obj !== undefined && obj.type === 'customTriangle';
};
export type CustomTriangle = fabric.fabric.Triangle;
export const CustomTriangle = fabricObj.CustomTriangle;
