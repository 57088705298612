import { CropperPosition } from 'ngx-image-cropper';
import { CONSTANTS } from 'models/helpers/constants';
import { HttpHeaders } from '@angular/common/http';
import { Observable, from as fromPromise } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Druckauftrag } from 'models/druckauftrag';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { environment } from 'environments/environment';
import { StartInformation } from 'models/communcation/StartInformation';
import { Params } from '@angular/router';
import { CustomImage } from 'models/graphicelements/customimage';

/** types */
class FinishOrderSuccessResponseBody {
  configId: string;
  grapihcsId: string;
  hybrisConfigId: string;
  success: boolean;
}
class FinishOrderLPResponseBody {
  id: string;
  additionalInfo: string;
}

type finishOrderResponse =
  | FinishOrderSuccessResponseBody
  | FinishOrderLPResponseBody;
/**
 * The RestDataService sends requests and responds to the REST endpoints.
 */
@Injectable()
export class RestDataService {
  private static readonly httpJsonOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  private static readonly httpUploadOptions = {
    headers: new HttpHeaders(),
    responseType: 'text' as 'json',
    withCredentials: true,
  };

  public static getHttpOptionsWithParams(params: Params): {
    headers?: HttpHeaders;
    withCredentials?: boolean;
    params?: Params;
  } {
    const options = RestDataService.httpJsonOptions;
    let query;
    if (environment.local && !params.graphicsId) {
      query = { graphicsId: environment.graphicsId };
    }
    return {
      headers: options.headers,
      withCredentials: options.withCredentials,
      params: { ...params, ...query },
    };
  }

  public static getHttpOptions(): {
    headers?: HttpHeaders;
    withCredentials?: boolean;
    params: Params;
  } {
    const options = RestDataService.httpJsonOptions;
    let query;
    if (environment.local) {
      query = { graphicsId: environment.graphicsId };
    }
    return {
      headers: options.headers,
      withCredentials: options.withCredentials,
      params: query,
    };
  }

  public static getHttpUploadOptions(params: Params): {
    headers?: HttpHeaders;
    withCredentials?: boolean;
    params: Params;
    responseType: 'json';
  } {
    const options = RestDataService.httpUploadOptions;
    if (!params) {
      params = [];
    }
    if (environment.local) {
      params['graphicsId'] = environment.graphicsId;
    }
    return {
      headers: options.headers,
      withCredentials: options.withCredentials,
      params,
      responseType: options.responseType,
    };
  }

  private static get backendURL(): string {
    if (environment.local) {
      console.log(environment.backend_url, 'BackendurlRestataservice---')
      return environment.backend_url;
    }
    if (
      environment.test &&
      window.location.host.indexOf('web.core.windows.net') >= 0
    ) {
      console.log(environment.backend_url, 'BackendurlRestataservice---')
      return environment.backend_url;
    }
    let host = window.location.host;
    host =
      host.indexOf(environment.client_prefix + '-client') >= 0
        ? host.replace(environment.client_prefix + '-client', '')
        : host.replace(environment.client_prefix, '');

    if (!host.endsWith('/')) {
      host += '/';
    }
    return environment.backend_protocol + environment.backend_prefix + host;
  }

  constructor(private http: HttpClient) {}
  /**
   * Genereric error handler function
   * @param error
   */
  private handleError(error: any) {
    console.error(error);

    return new Observable(() => {
      return error;
    });
  }

  /**
   * saves the druckauftrag to configured backend
   * @param druckauftrag
   * @param {string} configId id of the selected car
   */
  public saveDruckauftrag(
    druckauftrag: Druckauftrag,
    params: Params
  ): Observable<FinishOrderSuccessResponseBody> {
    const httpOptions = RestDataService.getHttpOptionsWithParams(params);
    return this.http
        .post<finishOrderResponse>(
          RestDataService.backendURL + environment.finish_path,
          druckauftrag,
          {
            ...httpOptions,
            observe: 'response' as 'events',
          }
        )
        .pipe(
          switchMap(async (response) => {
            console.log(response);
            if (!(response instanceof HttpResponse)) {
              console.error('!(response instanceof HttpResponse)');
              throw Error('!(response instanceof HttpResponse)');
            }
            if (response.status === 200) {
              return typeof response.body == 'string'
                ? (JSON.parse(response.body) as FinishOrderSuccessResponseBody)
                : (response.body as FinishOrderSuccessResponseBody);
            }
            if (response.status === 202) {
              const lpResponse = response.body as FinishOrderLPResponseBody;
              params['finishOrderId'] = lpResponse.id;
              const nextRespone = await this.longPollSaveDruckauftrag({
                ...RestDataService.getHttpUploadOptions(params),
                observe: 'response' as 'events',
              });
              return typeof nextRespone == 'string'
                ? (JSON.parse(nextRespone) as FinishOrderSuccessResponseBody)
                : (nextRespone as FinishOrderSuccessResponseBody);
            }
            console.log(response.status);
          }),
        );
    }
       /*  .toPromise()
        .then(async response => {
          console.log(response)
          if (!(response instanceof HttpResponse)) {
            console.error('!(response instanceof HttpResponse)');
            throw Error('!(response instanceof HttpResponse)');
          }
          if (response.status === 200) {
            return typeof response.body == 'string'
              ? (JSON.parse(response.body) as FinishOrderSuccessResponseBody)
              : (response.body as FinishOrderSuccessResponseBody);
          }
          if (response.status === 202) {
            const lpResponse = response.body as FinishOrderLPResponseBody;
            params['finishOrderId'] = lpResponse.id;
            const nextRespone = await this.longPollSaveDruckauftrag({
              ...RestDataService.getHttpUploadOptions(params),
              observe: 'response' as 'events',
            });
            return typeof nextRespone == 'string'
              ? (JSON.parse(nextRespone) as FinishOrderSuccessResponseBody)
              : (nextRespone as FinishOrderSuccessResponseBody);
          }
          console.log(response.status);
        })
    );
  } */

  private async longPollSaveDruckauftrag(
    options
  ): Promise<finishOrderResponse> {
    const longPollUrl =
      RestDataService.backendURL + environment.long_poll_finish_path;
    return this.http
      .get<string>(longPollUrl, options)
      .toPromise()
      .then(event => {
        if (event instanceof HttpResponse && typeof event.body === 'string') {
          if (event.status === 200) {
            return event.body as unknown as FinishOrderSuccessResponseBody;
          } else if (event.status === 202) {
            return this.longPollSaveDruckauftrag(options);
          }
          console.log(`received Status ${event.status}`);
          throw new Error(
            `Expected status 200 or 502, but received ${event.status}`
          );
        } else {
          console.error('something went wrong during upload long polling!');
          console.error(
            'Expected HttpResponse with typeof body string, received'
          );
          console.error(event);
          throw new Error(
            `Expected HttpResponse with typeof body string, received ${event}`
          );
        }
      });
  }

  public getStartInformation(params: Params): Observable<StartInformation> {
    return this.http // FIXME: insert proper types instead of "any"
      .get<any[]>(
        RestDataService.backendURL + environment.start_path,
        RestDataService.getHttpOptionsWithParams(params)
      )
      .pipe(
        map((response: any) => {
          return StartInformation.createStartInformation(response);
        })
      )
  }

  public mirrorImage(
    img: CustomImage,
    params: Params
  ): Promise<{
    src: string;
    nativeSrc: string;
    croppedDisplayImage: string;
    cropInfo: CropperPosition;
  }> {
    // submit the file as form-data

    return this.http
      .post<any>(
        RestDataService.backendURL + environment.mirror_path,
        img,
        RestDataService.getHttpOptionsWithParams(params)
      )
      .toPromise()
      .then(o => (typeof o === 'string' ? JSON.parse(o) : o));
  }

  /**
   * will convert the given image to png on the server
   * @param img file to convert
   * @returns the converted image as data-uri
   */
  public remoteConvertImage(
    img: File,
    params: Params
  ): Promise<{ content: string; width: number; height: number }> {
    const form = new FormData();
    form.append('image', img);

    const httpOptions = RestDataService.getHttpUploadOptions(params);

    return this.http
      .post<string>(
        RestDataService.backendURL + environment.convert_path,
        form,
        { ...httpOptions, observe: 'response' as 'events' }
      )
      .toPromise()
      .then(async response => {
        if (!(response instanceof HttpResponse)) {
          console.error('!(response instanceof HttpResponse)');
        }
        response = response as HttpResponse<string>;
        if (response.status === 200) {
          const json = JSON.parse(response.body);
          return {
            content: json.content,
            width: json.metaInfo.width,
            height: json.metaInfo.height,
          };
        }
        if (response.status === 202) {
          params['convertId'] = JSON.parse(response.body).id;
          const json = JSON.parse(
            await this.longPollConvertStatus({
              ...RestDataService.getHttpUploadOptions(params),
              observe: 'response' as 'events',
            })
          );
          return {
            content: json.content,
            width: json.metaInfo.width,
            height: json.metaInfo.height,
          };
        }
        console.log(`Received unexpected response code: "${response.status}"`);
      });
  }

  private async longPollConvertStatus(options): Promise<string> {
    const longPollUrl =
      RestDataService.backendURL + environment.long_poll_convert_path;
    return this.http
      .get<string>(longPollUrl, options)
      .toPromise()
      .then(event => {
        if (event instanceof HttpResponse && typeof event.body === 'string') {
          if (event.status === 200) {
            return event.body;
          } else if (event.status === 202) {
            return this.longPollConvertStatus(options);
          }
          console.log(`received Status ${event.status}`);
          throw new Error(
            `Expected status 200 or 502, but received ${event.status}`
          );
        } else {
          console.error('something went wrong during upload long polling!');
          console.error(
            'Expected HttpResponse with typeof body string, received'
          );
          console.error(event);
          throw new Error(
            `Expected HttpResponse with typeof body string, received ${event}`
          );
        }
      });
  }

  public remoteConvertSortimoImage(
    id: string,
    params: Params
  ): Promise<{ original: string; preview: string }> {
    // submitt the file as form-data
    params[CONSTANTS.SORTIMO_IMAGE_ID] = id;

    return this.http
      .get<string>(
        RestDataService.backendURL + environment.convert_sortimo_image_path,
        RestDataService.getHttpUploadOptions(params)
      )
      .toPromise()
      .then((response: string) => {
        return JSON.parse(response) as { original: string; preview: string };
      });
  }

  /**
   * gets images for imagedatabase from server
   *
   * @returns the converted image as data-uri
   */
  // AzureImage[]
  public getAllDbImages(params: Params): Promise<any[]> {
    // submit the file as form-data

    return this.http
      .get<any>(
        RestDataService.backendURL + environment.all_image_path,
        RestDataService.getHttpOptionsWithParams(params)
      )
      .toPromise();
  }
}
