import { TranslateService } from '@ngx-translate/core';
/**
 * @fileOverview ColorPickerComponent provides a graphical mean to chose a color
 * from a given range of options. Gerneally one option will be pre-selected depending
 * on previous choices regarding the concerned object. Furthmore textual description
 * of the available colors is available by onHower.
 */

import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import { Color } from '../../../models/helpers/color';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Output() private colorChangedEvent = new EventEmitter();
  @Input() private type: string;
  @Input() private rgbColor: string;
  private initialized = false;

  @Input() public allColor: Color[];
  public selectedColorName: string;
  public headTitle = 'none';
  public pointerStyle = 'none';
  public opacityStyle = '0';
  public isVisible = false;

  constructor(
    private readonly translateService: TranslateService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry
      .addSvgIcon(
        'close-button',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-meldung-close.svg'
        )
      )
      .addSvgIcon(
        'no-border',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-noborder.svg'
        )
      );
  }

  /**
   * use async/await to handle the promise returned by this.translateService.get('COLOR-PICKER.HEADLINE-FOR-TEXT'). 
   * This approach makes the code more readable and avoids the deprecated use of .toPromise().then().
   * 
   * use await lastValueFrom(...) to await the completion of the observable returned by this.translateService.get(...).
   * This ensures that we get the final value emitted by the observable.
   */
  async ngOnInit() {
    if (this.type === 'text-fast-track') {
      const translation = await lastValueFrom(this.translateService.get('COLOR-PICKER.HEADLINE-FOR-TEXT'));
        this.setHeadTitle(translation);
      this.headTitle = 'Textfarbe auswählen';
      /* this.translateService
        .get('COLOR-PICKER.HEADLINE-FOR-TEXT')
        .toPromise()
        .then(this.setHeadTitle);*/
    }
    this.initialized = true;
  }

  /**
   * Setter method for the headTitle Attribute
   */
  private setHeadTitle = (value: string) => {
    this.headTitle = value;
  };

  public openView = (color?: string) => {
    this.rgbColor = color !== undefined ? color : undefined;
    this.selectItem();
  };

  /**
   * Get colorName string from user
   *
   * @param {string} color
   * @memberof ColorPickerComponent
   */
  public selectColor(color: string) {
    this.rgbColor = color;
    this.selectItem();
    this.colorChangedEvent.emit(color);
  }
  public mouseover(name: string) {
    this.selectedColorName = name;
  }
  public mouseleave() {
    if (this.rgbColor) {
      const res = this.allColor.filter(a => {
        return a.value === this.rgbColor;
      });
      if (res.length === 1) {
        this.selectedColorName = res[0].name;
        return;
      }
    }
    this.selectedColorName = '';
  }

  /**
   * Provide a title for the colorPicker component
   *
   * @memberof ColorPickerComponent
   */
  public getStyleClass = (): string => {
    if (this.isEditorFill) {
      return 'editor-fill-color-picker';
    }
    if (this.isEditorBorder) {
      return 'editor-border-color-picker';
    }
    if (this.isFastTrackTextColor) {
      return 'textcolorpicker-fast-track';
    }
    if(this.isEditorCarColor){
      return 'editor-car-color-picker';
    }
    if (this.initialized) {
      throw new Error('invalid Input parameters');
    } else {
      return '';
    }
  };

  public get isEditorFill() {
    return this.type === 'editor-text';
  }
  public get isEditorBorder() {
    return this.type === 'editor-border';
  }
  public get isFastTrackTextColor() {
    return this.type === 'text-fast-track';
  }
  public get isEditorCarColor(){
    return this.type === 'editor-car';
  }
  /**
   *  Provide styling for selected colors
   *
   * @memberof ColorPickerComponent
   */
  public getSelectedClass = color => {
    if (color.value === this.rgbColor) {
      return 'selectedclass';
    }
    return '';
  };

  /**
   * Manage the visibilty of the ColorPickerComponent
   *
   * @memberof ColorPickerComponent
   */
  public setVisible = (b: boolean) => {
    if (b) {
      this.opacityStyle = '1';
      this.pointerStyle = 'pointer';
      this.isVisible = true;
    } else {
      this.opacityStyle = '0';
      this.pointerStyle = 'none';
      this.isVisible = false;
    }
  };

  /**
   * Set new color property for the concerned object/template
   *
   * @memberof ColorPickerComponent
   */
  public selectItem = () => {
    // init initial text
    const filteredColor = this.allColor.filter(color => {
      if (
        color &&
        color.value &&
        typeof color.value === 'string' &&
        this.rgbColor
      ) {
        const c1 = color.value.toUpperCase().trim();
        const c2 = this.rgbColor.toUpperCase().trim();
        return 0 === c1.localeCompare(c2);
      }
      return false;
    });
    if (filteredColor && filteredColor.length === 1) {
      this.selectedColorName = filteredColor[0].name;
    }
  };

  /**
   * Retrieve Color Data on style change.
   * @param color Color Object
   * @returns Color Value
   */
  public getStyleForElement = (color: Color): string => {
    if (this.isMetallic(color)) {
      return (
        'linear-gradient(135deg, white 0%, ' + color.value + ' 50%, white 100%)'
      );
    }
    return color.value;
  };

  /**
   * boolean that specifies whether color is Metalic.
   * @param color Color Object
   * @returns Boolean Value
   */
  public isMetallic = (color: Color): boolean => {
    if (color && color.name) {
      return color.name.indexOf('Metallic') >= 0;
    }

    return false;
  };

  public getRgbColor(): string {
    return this.rgbColor;
  }
  public setRgbColor(color: string) {
    this.rgbColor = color;
  }

  public close() {
    this.setVisible(false);
  }
  public selectTransparentColor() {
    this.colorChangedEvent.emit();
  }
}
