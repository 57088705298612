import {
  forkJoin as observableForkJoin,
  of as observableOf,
  Observable,
  from,
  forkJoin,
  combineLatest,
  lastValueFrom,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SvgEditAreaParserService } from './svgeditareaparserservice';
import { Car } from 'models/car';
import { Router } from '@angular/router';
import { Seitenansicht } from 'models/seitenansicht';
import { Injectable } from '@angular/core';
import { Druckauftrag } from 'models/druckauftrag';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOMParser } from '@xmldom/xmldom';
import * as fabricNS from 'fabric';
import { environment } from 'environments/environment';

@Injectable()
/**
 * This service includes helper methods to:
 *  load template data from the backend
 *  process such data
 *  manipulate existing template data and
 *  to perform linked actions suchs as setting a default template.
 *
 */
export class DruckauftragCreatorService {
  private fabric: any;
  private domParser: DOMParser;
  constructor(
    private router: Router,
    private http: HttpClient,
    private svgEditAreaParserService: SvgEditAreaParserService,
    private translateService: TranslateService
  ) {
    this.fabric = <any>fabricNS;

    this.domParser = new DOMParser();
  }

  public initEditareas(druckauftrag: Druckauftrag): Observable<boolean[]> {
    console.log(`initEditareas in Dataservice`);

    if (!druckauftrag || !druckauftrag.car) {
      console.log('Dataservice: Car or druckauftrag undefined ');
      this.router.navigate(['/fahrzeugwahl']);
      return observableOf([]);
    }
    // TODO: load from svg
    // druckauftrag.links.fahrzeugPath
    /*const left = this.getPromiseForCarside(druckauftrag, druckauftrag.links);
    const right = this.getPromiseForCarside(druckauftrag, druckauftrag.rechts);
    const front = this.getPromiseForCarside(druckauftrag, druckauftrag.front);
    const heck = this.getPromiseForCarside(druckauftrag, druckauftrag.heck);

    return observableForkJoin(left, right, front, heck); */ 
    
    // Before Update getPromiseForCarside returns promises, so wrap them in observables using 'from'
    const left$ = from(this.getPromiseForCarside(druckauftrag, druckauftrag.links));
    const right$ = from(this.getPromiseForCarside(druckauftrag, druckauftrag.rechts));
    const front$ = from(this.getPromiseForCarside(druckauftrag, druckauftrag.front));
    const heck$ = from(this.getPromiseForCarside(druckauftrag, druckauftrag.heck));

    return combineLatest([left$, right$, front$, heck$]);
  }
  private async getPromiseForCarside(
    druckauftrag: Druckauftrag,
    side: Seitenansicht
  ): Promise<boolean> {
    let prefix = '';
    if (side.fahrzeugPath.indexOf('/assets/car-images/') === -1) {
      prefix = environment.assets_url + '/assets/car-images/';
    }

    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );
    const response = await lastValueFrom(
      this.http.get(prefix + side.fahrzeugPath, {
        headers: headers,
        responseType: 'text',
      })
    );

    const doc = this.domParser.parseFromString(response);
    return this.svgEditAreaParserService.loadEditArea(
      doc.getElementById('Edit'),
      druckauftrag,
      side
    );
   /*  return this.http
      .get(prefix + side.fahrzeugPath, {
        headers: headers,
        responseType: 'text',
      })
      .toPromise() // FIXME: insert proper type instead of "any"
      .then((response: any) => {
        const doc = this.domParser.parseFromString(response);
        return this.svgEditAreaParserService.loadEditArea(
          doc.getElementById('Edit'),
          druckauftrag,
          side
        );
      }); */
  }

  /**
   * generates an example car Object
   */
  public generateExampleCar(): Car {
    return new Car({
      id: 'vw-t6-2015-4892-normaldach-rechts-fluegeltueren-0-0',
      chosenColor: '#2F3959',
    });
  }

  public createCar(carid: string) {
    if (carid) {
      return new Car({
        id: carid,
      });
    }
    return undefined;
  }
}
