import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DataService } from './../services/dataservice';

/**
 * This component handles the Alert Messages on Editor.
 * It Generates Two Type of Alerts 'Warning' and 'Error'.
 */
@Component({
  selector: 'app-editor-alert-message',
  templateUrl: './editor-alert-message.component.html',
  styleUrls: ['./editor-alert-message.component.scss'],
})
export class EditorAlertMessageComponent implements OnInit {
  @Input()
  private readonly type: 'warning' | 'error';

  public textContent = '';
  public headline = '';

  public linkText = '';

  public get linkTarget(): string {
    return this.dataService.getSupportUrl();
  }
  @Output()
  public close = new EventEmitter<void>();

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly translateService: TranslateService,
    private readonly dataService: DataService
  ) {
    this.matIconRegistry
      .addSvgIcon(
        'icon-error-message',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-stop.svg'
        )
      )
      .addSvgIcon(
        'icon-warning-message',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-warnung-meldung.svg'
        )
      )
      .addSvgIcon(
        'icon-close',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-meldung-close.svg'
        )
      );
  }

  ngOnInit() {
    this.initializeHeadline();
    this.initializeContent();
    this.initializeLinkText();
  }

  /**
   * boolean that specifies whether Alert isWarning.
   */
  public get isWarning(): boolean {
    return this.type === 'warning';
  }
  /**
   * boolean that specifies whether Alert isErorr.
   */
  public get isError(): boolean {
    return this.type === 'error';
  }

  /**
   * Set Color According to Type of Alert.
   */
  public get color(): string {
    if (this.isWarning) {
      return '#FFDE00';
    }
    if (this.isError) {
      return 'red';
    }
    return 'black';
  }

  /**
   * Set Text style Color According to Type of Alert.
   */
  public get textColor(): string {
    if (this.isWarning) {
      return 'black';
    }
    if (this.isError) {
      return 'red';
    }
    return 'black';
  }

  /**
   * Set Icon style Color According to Type of Alert.
   */
  public get iconColor(): string {
    if (this.isWarning) {
      return 'black';
    }
    if (this.isError) {
      return 'white';
    }
    return 'black';
  }

  /**
   * Initialize Links Provided in Alert.
   */
  public initializeLinkText(): void {
    let key = '';
    if (this.isWarning) {
      key = 'EDITOR.UI.POPUP.WARNING.LINKTEXT';
    } else if (this.isError) {
      key = 'EDITOR.UI.POPUP.ERROR.LINKTEXT';
    }

    this.translateService.get(key).subscribe(translation => {
      this.linkText = translation;
    });
  }

  /**
   * Initialize Header of Alert.
   */
  public initializeHeadline(): void {
    let key = '';
    if (this.isWarning) {
      key = 'EDITOR.UI.POPUP.WARNING.HEADLINE';
    } else if (this.isError) {
      key = 'EDITOR.UI.POPUP.ERROR.HEADLINE';
    }

    this.translateService.get(key).subscribe(translation => {
      this.headline = translation;
    });
  }

  /**
   * Initialize Content of Alert.
   */
  public async initializeContent(): Promise<void> {
    let key = '';
    if (this.isWarning) {
      key = 'EDITOR.UI.POPUP.WARNING.CONTENT';
    } else if (this.isError) {
      key = 'EDITOR.UI.POPUP.ERROR.CONTENT';
    }

    this.translateService.get(key).subscribe(translation => {
      this.textContent = translation;
    });
  }

  public closeClick(): void {
    this.close.emit();
  }
}
