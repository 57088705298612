import { HttpClient } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { NGXLogger } from "ngx-logger";
import { MatDialogRef } from '@angular/material/dialog';

import { AzureImage } from './../../../models/communcation/AzureImage';
import { RestDataService } from './../../services/restdataservice';
import { DataService } from './../../services/dataservice';

@Component({
  selector: 'app-upload-dialog-sortimo-image',
  templateUrl: './upload-dialog-sortimo-image.component.html',
  styleUrls: ['./upload-dialog-sortimo-image.component.scss'],
})
export class UploadDialogSortimoImageComponent implements OnInit {
  private readonly ELEMENTS_PER_PAGE = 15;

  public selectedImage: AzureImage;

  public searchFilterContent = '';

  public selectedCategory = '';

  private _currentPage = 1;
  public fileUploadActive = false;

  constructor(
    private readonly dataService: DataService,
    private readonly dialogRef: MatDialogRef<UploadDialogSortimoImageComponent>,
    private readonly restDataService: RestDataService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private logger: NGXLogger,
    private readonly http: HttpClient
  ) {}

  ngOnInit() {
    this.matIconRegistry
      .addSvgIcon(
        'close-button',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-meldung-close.svg'
        )
      )
      .addSvgIcon(
        'ans-ende-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/upload/button-ans-ende.svg'
        )
      )
      .addSvgIcon(
        'weiter-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/upload/button-weiter.svg'
        )
      )
      .addSvgIcon(
        'search-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/upload/icon-suche.svg'
        )
      );

    if (this.dataService.allSortimoImage.length <= 0) {
      this.restDataService
        .getAllDbImages(this.dataService.getParams())
        .then((allImage: any[]) => {
          this.dataService.allSortimoImage = allImage.map(
            a => new AzureImage(a)
          );
        });
    }
  }

  public searchKeyUp(): void {
    console.log(this.searchFilterContent);
    this.jumpToStart();
  }

  public changeCategory(event: Event) {
    this.selectedCategory = (event.target as any).value;
    this.jumpToStart();
  }
  public get allFilteredImage(): AzureImage[] {
    const filterLC = this.searchFilterContent.toLowerCase();
    if (
      this.selectedCategory === '' &&
      (filterLC === undefined || filterLC.replace(' ', '') === '')
    ) {
      return this.dataService.allSortimoImage;
    }

    let arr = this.dataService.allSortimoImage.filter(
      image =>
        this.selectedCategory === '' ||
        (image.category && image.category === this.selectedCategory)
    );
    arr = arr.filter(image => this.filterTags(image, filterLC));
    return arr;
  }
  public get allCategory(): Set<string> {
    return new Set(
      this.dataService.allSortimoImage
        .map(image => image.category)
        .filter(
          category => category !== undefined && category.replace(' ', '') !== ''
        )
    );
  }

  private filterTags(image: AzureImage, filterLC: string): boolean {
    let tagArray: string[];
    if (typeof image.tags === 'string') {
      tagArray = image.tags.split(';');
    } else {
      tagArray = Array.isArray(image.tags) ? image.tags : [];
    }

    return (
      filterLC.replace(' ', '') === '' ||
      tagArray.some(
        tag => tag.toLowerCase().replace(' ', '').indexOf(filterLC) >= 0
      )
    );
  }

  public get allImageCurrentPage() {
    return [...this.allFilteredImage].splice(
      (this._currentPage - 1) * this.ELEMENTS_PER_PAGE,
      this.ELEMENTS_PER_PAGE
    );
  }
  public get numberOfPages(): number {
    const plus1 = this.allFilteredImage.length % this.ELEMENTS_PER_PAGE > 0;
    let pageNumber = Math.round(
      this.allFilteredImage.length / this.ELEMENTS_PER_PAGE
    );
    if (plus1) {
      pageNumber++;
    }
    return pageNumber;
  }
  public get currentPage(): number {
    return this._currentPage;
  }

  public isSelected(image: AzureImage) {
    return this.selectedImage === image;
  }
  public selectImage(image: AzureImage) {
    if (!this.fileUploadActive) {
      this.selectedImage = image;
    }
  }

  public nextPage() {
    if (this.nextPageEnabled) {
      this._currentPage++;
    }
  }
  public get nextPageEnabled(): boolean {
    return this._currentPage < this.numberOfPages;
  }
  public get previousPageEnabled(): boolean {
    return this._currentPage > 1;
  }
  public previousPage() {
    if (this.previousPageEnabled) {
      this._currentPage--;
    }
  }
  public jumpToEnd() {
    this._currentPage = this.numberOfPages;
  }
  public jumpToStart() {
    this._currentPage = 1;
  }

  public async addImageFromDB(): Promise<void> {
    this.fileUploadActive = true;
    this.dataService.loadingCycleEmitter.emit(true);
    this.dataService.editModeFalseEmitter.emit();

    try {
      const result = await this.dataService.convertSorimoDbImage(
        this.selectedImage.id
      );

      // get both images as data-uri
      const convertedImage = result.preview;

      const originalImageContent = result.original;
      const file: File = await this.getFileFormSelectedImage(
        originalImageContent
      );

      if (convertedImage && convertedImage !== '') {
        this.dataService.imageConvertedEmitter.emit({
          convertedImage,
          originalImage: {
            content: originalImageContent,
            originalWidth: undefined,
            originalHeight: undefined,
          },
          file,
          azureImage: this.selectedImage,
        });
        this.fileUploadActive = false;
        this.close();
      }
    } catch (err) {
      this.fileUploadActive = false;
      this.logger.error(err);
    }
  }

  private close(): void {
    this.dialogRef.close();
  }

  private async getFileFormSelectedImage(content): Promise<File> {
    try {
      const blob = new Blob([content], { type: 'image/svg+xml' });
      const tmpFile = blob as any;
      tmpFile.lastModifiedDate = new Date();
      tmpFile.name = this.selectedImage.filename;
      const file = tmpFile as File;

      return file;
    } catch (e) {
      this.fileUploadActive = false;
      this.dataService.loadingCycleEmitter.emit(false);
      throw e;
    }
  }
}
