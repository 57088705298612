import { Color } from '../../models/helpers/color';

/**
 * All Available Text Color Codes.
 */
export const TEXT_COLOR_DATA: Color[] = [
  {
    value: '#FAC400',
    name: 'Yellow',
  },
  {
    value: '#FACA00',
    name: 'Traffic yellow',
  },
  {
    value: '#FCAC00',
    name: 'Golden yellow',
  },
  {
    value: '#E9AA00',
    name: 'Signal yellow',
  },
  {
    value: '#EF5600',
    name: 'Orange',
  },
  {
    value: '#E23C00',
    name: 'Mars red',
  },
  {
    value: '#D01D00',
    name: 'Signal red',
  },
  {
    value: '#CF0000',
    name: 'Blood red',
  },
  {
    value: '#B11811',
    name: 'Red',
  },
  {
    value: '#C1061A',
    name: 'Cardinal red',
  },
  {
    value: '#8B101A',
    name: 'Dark Red',
  },
  {
    value: '#64101D',
    name: 'Purple red',
  },
  {
    value: '#4C1722',
    name: 'Chestnut',
  },
  {
    value: '#CD5180',
    name: 'Magenta',
  },
  {
    value: '#BB1661',
    name: 'Pink',
  },
  {
    value: '#5A2E68',
    name: 'Violet',
  },
  {
    value: '#592C87',
    name: 'Light Violet',
  },
  {
    value: '#051F6D',
    name: 'Cobalt Blue',
  },
  {
    value: '#132E5B',
    name: 'Dark Blue',
  },
  {
    value: '#182F7D',
    name: 'King blue',
  },
  {
    value: '#002778',
    name: 'Middle Blue',
  },
  {
    value: '#003E7D',
    name: 'Blue',
  },
  {
    value: '#004899',
    name: 'Sea Blue',
  },
  {
    value: '#0054A9',
    name: 'Signal blue',
  },
  {
    value: '#0067BB',
    name: 'Azure blue',
  },
  {
    value: '#0088CF',
    name: 'Light Blue',
  },
  {
    value: '#005A6D',
    name: 'Petrol',
  },
  {
    value: '#58CEB8',
    name: 'Mint',
  },
  {
    value: '#004627',
    name: 'Dark green',
  },
  {
    value: '#004B2F',
    name: 'Leaf Green',
  },
  {
    value: '#00602B',
    name: 'Emerald',
  },
  {
    value: '#007447',
    name: 'Green',
  },
  {
    value: '#008E3A',
    name: 'Light green',
  },
  {
    value: '#007A42',
    name: 'Grass green',
  },
  {
    value: '#63C43C',
    name: 'Lime green',
  },
  {
    value: '#E8D6B8',
    name: 'Light Cream',
  },
  {
    value: '#EFDB9B',
    name: 'Cream',
  },
  {
    value: '#e0ca95',
    name: 'Beige brown',
  },
  {
    value: '#B3581D',
    name: 'Nut brown',
  },
  {
    value: '#4F321F',
    name: 'Earth brown',
  },
  {
    value: '#44291E',
    name: 'Brown',
  },
  {
    value: '#FAFAFA',
    name: 'White',
  },
  {
    value: '#000000',
    name: 'Black',
  },
  {
    value: '#4C4E4F',
    name: 'Dark Grey',
  },
  {
    value: '#666B67',
    name: 'Grey',
  },
  {
    value: '#8A8F8C',
    name: 'Middle grey',
  },
  {
    value: '#A4A4A2',
    name: 'Ice grey',
  },
  {
    value: '#758289',
    name: 'Slate grey',
  },
  {
    value: '#C5C8C8',
    name: 'Light Grey',
  },
  {
    value: '#C8C8CA',
    name: 'Silver Metallic',
  },
  {
    value: '#676A71',
    name: 'Graphite metallic',
  },
  {
    value: '#17231F',
    name: 'Anthracite metallic',
  },
];
