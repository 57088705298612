'use strict';
export class PriceCalculationResult {
  public price: number;

  // Material
  public productionCosts: number;
  public deliveryConstant: number;
  public glueCosts: number;
  // Service
  public glueTime: number;
  public ruestTime: number;
  public hourRate: number;
  public materialCosts: number;
  public budget_extern: number;

  constructor(object?: any) {
    if (object != null) {
      this.price = object.price;
      // material
      this.productionCosts = object.productionCosts;

      this.deliveryConstant = object.deliveryConstant;

      // Service
      this.glueTime = object.glueTime;
      this.ruestTime = object.ruestTime;
      this.glueCosts = object.glueCosts;
      this.hourRate = object.hourRate;
      this.materialCosts = object.materialCosts;
      this.budget_extern = object.budget_extern;
    }
  }
}
