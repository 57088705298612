import { DOMParser, XMLSerializer } from '@xmldom/xmldom';
import { Injectable } from '@angular/core';

@Injectable()
export class SvgUploadFileAdapterService {
  // FIXME: unused Service
  constructor() {}
  public adaptSvgFileContent(fileContent: string): HTMLImageElement {
    console.log('Content:' + fileContent);
    const doc: Document = new DOMParser().parseFromString(fileContent);
    if (!doc) {
      throw new Error('File was not parsed');
    }
    const elemArray: any = doc.getElementsByTagName('svg');

    if (!elemArray || elemArray.length <= 0) {
      throw new Error('No svg-Element contained');
    }
    const svgElem: HTMLElement = <HTMLElement>elemArray[0];
    if (!svgElem) {
      throw new Error('Error finding svg-Element');
    }
    const viewbox = svgElem.attributes.getNamedItem('viewBox');
    if (
      !viewbox &&
      svgElem.getAttribute('width') &&
      svgElem.getAttribute('height')
    ) {
      // nothing wrong height and width are set
      const newElem1 = new Image(
        parseFloat(svgElem.getAttribute('width').replace('px', '')),
        parseFloat(svgElem.getAttribute('height').replace('px', ''))
      );
      newElem1.src = 'data:image/svg+xml;base64,' + btoa(fileContent);
      return newElem1;
    }

    if (
      !viewbox &&
      (!svgElem.getAttribute('width') || !svgElem.getAttribute('height'))
    ) {
      // either no height or width and no viewBox !
      throw new Error(
        'Svg contains no viewbox and no width or height information!'
      );
    }
    const widthStr = viewbox.value.split(' ')[2];
    const heightStr = viewbox.value.split(' ')[3];
    const width = parseFloat(widthStr);
    const height = parseFloat(heightStr);

    if (!svgElem.getAttribute('width')) {
      svgElem.setAttribute('width', width.toString() + 'px');
    }
    if (!svgElem.getAttribute('height')) {
      svgElem.setAttribute('height', height.toString() + 'px');
    }
    const serializer = new XMLSerializer();
    const newElem = new Image(width, height);

    newElem.src =
      'data:image/svg+xml;base64,' + btoa(serializer.serializeToString(doc));
    return newElem;
  }
}
