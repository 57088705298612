import {fabric} from "fabric";

export class FabricHelper {

  /**
   * Copies all important numerical values directly from a fabric object to another object.
   * This can be done to avoid rounding, applied by fabric. (despite high precission settings)
   * @param from fabric object to copy properties from
   * @param to any object to copy properties to
   * @returns the `to` object with the copied values
   */
  public static copyProperties(from: fabric.Object, to: any): any {
    to.width = from.width;
    to.height = from.height;
    to.scaleX = from.scaleX;
    to.scaleY = from.scaleX;
    to.angle = from.angle;
    to.left = from.left;
    to.top = from.top;
    return to;
  }
}
