import { InitializationService } from './services/initialization.service';
import { ConfirmFinishOrderDialogComponent } from './dialogs/confirmfinishorderdialog.component';
import { DruckauftragHelperService } from './services/druckauftrag-helper.service';
import { DruckauftragCreatorService } from './services/druckauftragcreator.service';
import { DimensionsHelperService } from './services/dimensionshelperservice';
import { environment } from './../environments/environment';
import { CanvasHelperService } from './services/canvashelperservice';
import { ConfirmDialogComponent } from './dialogs/confirmdialog.component';
import { PreviewComponent } from './preview/preview.component';
import { StateStorageService } from './services/statestorage';
import { CanvasSelectionService } from './services/canvas-selection.service';

import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TemplateDataParser } from './services/templatedataparser';
import { EditorComponent } from './editor/editor.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { ROUTE_CONFIG } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* Material */
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
/* Flex */
import { SvgEditAreaParserService } from './services/svgeditareaparserservice';
import { SvgUploadFileAdapterService } from './services/svg-upload-file-adapter.service';
import { DataService } from './services/dataservice';
import { RenderEditModeService } from './services/rendereditmodeservice';

import { KonfiguratorComponent } from './konfigurator/konfigurator.component';
import { RestDataService } from './services/restdataservice';
import { FontDataService } from './services/fontdataservice';
import { AktuellerPreisComponent } from './aktueller-preis/aktueller-preis.component';
import { PreviewStepComponent } from './preview-step/preview-step.component';
import { PriceCalculatorService } from './services/price-calculator.service';
import { NavigationService } from './/services/navigation.service';
import { ColorPickerComponent } from './editor/colorpicker/colorpicker.component';
import { IconDialogComponent } from './icon-dialog/icon-dialog.component';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

import { Angulartics2GoogleTagManager } from 'angulartics2';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TrackSelectionComponent } from './track-selection/track-selection.component';
import { FastTrackComponent } from './fast-track/fast-track.component';
import { TemplateInteractionService } from 'app/services/templateInteraction.service';

import { FinishGenerationProcessService } from 'app/services/finish-generation-process.service';

import { Globals } from './globals';
import { CarDataManagerService } from './services/car-data-manager.service';
import { CarSideHandlerComponent } from './car-side-handler/car-side-handler.component';
import * as fabric from 'fabric';
if (environment.production) {
  enableProdMode();
}
// Logging
/* import { LogModule, LogLevelString } from '@dagonmetric/ng-log';
import { ConsoleLoggerModule } from '@dagonmetric/ng-log/console';
 */import { EditorHeaderComponent } from './editor-header/editor-header.component';
import { ShapeColorSelectorButtonComponent } from './shape-color-selector-button/shape-color-selector-button.component';
import { ElementMeasurementComponent } from './element-measurement/element-measurement.component';
import { EditorAlertMessageComponent } from './editor-alert-message/editor-alert-message.component';
import { UploadDialogChooseComponent } from './dialogs/upload-dialog-choose/upload-dialog-choose.component';
import { UploadDialogOwnImageComponent } from './dialogs/upload-dialog-own-image/upload-dialog-own-image.component';
import { UploadDialogSortimoImageComponent } from './dialogs/upload-dialog-sortimo-image/upload-dialog-sortimo-image.component';
import { UploadDialogWrapperComponent } from './dialogs/upload-dialog-wrapper/upload-dialog-wrapper.component';
import { UploadDialogDirective } from './directives/upload-dialog.directive';
import { SortimoImageDisplayComponent } from './dialogs/sortimo-image-display/sortimo-image-display.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperComponent } from './editor/cropper/cropper.component';
import { CarColorChangeComponent } from './car-color-change/car-color-change.component';
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";

// JIT SETUP
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        EditorComponent,
        KonfiguratorComponent,
        PreviewComponent,
        ConfirmDialogComponent,
        ConfirmFinishOrderDialogComponent,
        AktuellerPreisComponent,
        PreviewStepComponent,
        ColorPickerComponent,
        IconDialogComponent,
        TrackSelectionComponent,
        FastTrackComponent,
        CarSideHandlerComponent,
        ToggleSwitchComponent,
        EditorHeaderComponent,
        ShapeColorSelectorButtonComponent,
        ElementMeasurementComponent,
        EditorAlertMessageComponent,
        CropperComponent,
        UploadDialogChooseComponent,
        UploadDialogOwnImageComponent,
        UploadDialogSortimoImageComponent,
        UploadDialogWrapperComponent,
        UploadDialogDirective,
        SortimoImageDisplayComponent,
        CarColorChangeComponent,
    ],
    imports: [
        RouterModule.forRoot(ROUTE_CONFIG, { useHash: false }),
        BrowserModule,
        BrowserAnimationsModule,
        MatGridListModule,
        HttpClientModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatListModule,
        MatTooltipModule,
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LoggerModule.forRoot({
            serverLoggingUrl: '/api/logs',
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR
        }),
        Angulartics2Module.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        /* LogModule.withConfig({ minLevel: environment.logLevel as LogLevelString }),
        ConsoleLoggerModule, */
        ImageCropperModule,
    ],
    providers: [
        SvgEditAreaParserService,
        TemplateDataParser,
        DataService,
        StateStorageService,
        RestDataService,
        FontDataService,
        CanvasHelperService,
        PriceCalculatorService,
        NavigationService,
        DimensionsHelperService,
        SvgUploadFileAdapterService,
        TemplateInteractionService,
        RenderEditModeService,
        DruckauftragCreatorService,
        DruckauftragHelperService,
        CarDataManagerService,
        FinishGenerationProcessService,
        InitializationService,
        CanvasSelectionService,
        Angulartics2GoogleAnalytics,
        Angulartics2GoogleTagManager,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        Globals,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function initApp() {
  return () => {
    return new Promise<void>(resolve => {
      // set fabric num fraction digits to maximal number of decimal digits (w3schools.com/js/js_numbers.asp)
      (fabric.fabric.Object as any).NUM_FRACTION_DIGITS = 17;
      resolve();
    });
  };
}
