import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'app/services/dataservice';

@Component({
  selector: 'app-car-color-change',
  templateUrl: './car-color-change.component.html',
  styleUrls: ['./car-color-change.component.scss']
})
export class CarColorChangeComponent implements OnInit, AfterViewInit {
  @Input()
  public activeObject;
  @Output() fillClicked = new EventEmitter<void>();
 
  constructor(private dataService:DataService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      
  }

  public fillClick(): void {
    this.fillClicked.emit();
  }

  public get fillColor(): string {
    return this.activeObject !== undefined
      ? this.activeObject
      : 'white';
  }

  public get fillColorName(): string {
    const match = this.dataService.vehicleColors.filter(a => {
      return a['value'] === this.fillColor;
    });
    if (match.length > 0) {
      return match[0]['name'];
    }
    return '';
  }

  public getOpacityForColor(): number {
    if (this.activeObject) {
      return 1;
    }
    return 0.2;
  }
  
}
