import { CustomImage } from '../../models/graphicelements/customimage';
import { GraphicElement } from '../../models/graphicelements/graphicelement';
import { fabric } from 'fabric';
import { Polygon } from '../../models/graphicelements/polygon';
import { Rectangle } from '../../models/graphicelements/rectangle';
import { Seitenansicht } from '../../models/seitenansicht';
import { Injectable } from '@angular/core';
import * as turf from '@turf/turf';

/**
 * this service contains the calculation for for identifying intersections between components
 */
@Injectable({
  providedIn: 'root',
})
export class IntersectionHelperService {
  constructor() {}

  public getAllIntersection(
    side: Seitenansicht
  ): Map<
    fabric.Object,
    [{ partial: boolean; included: boolean; intersectLR: boolean }]
  > {
    const objectIntersectionMap = new Map();
    let elementWith0IntersectionsFound = false;
    side.allDataElement.forEach(element => {
      side.allEditierbereich.forEach(editarea => {
        const editAreaPolygon = this.createTurfPolygon(editarea.graphicElement);
        const elementPolygon = this.createTurfPolygon(element);
        const intersectionObject = elementPolygon
          ? turf.intersect(editAreaPolygon, elementPolygon)
          : null;

        if (intersectionObject) {
          const areaIntersection = turf.area(intersectionObject);
          const areaElementPolygon = turf.area(elementPolygon);
          const intersectionInfo = {
            included: areaIntersection === areaElementPolygon,
            partialy:
              areaIntersection > 0 && areaIntersection !== areaElementPolygon,
            intersectLR: this.hasInterSectionLeftRight(
              editAreaPolygon,
              elementPolygon
            ),
          };
          if (objectIntersectionMap.get(element)) {
            objectIntersectionMap.get(element).push(intersectionInfo);
          } else {
            objectIntersectionMap.set(element, [intersectionInfo]);
          }
        }
      });

      if (!objectIntersectionMap.get(element)) {
        elementWith0IntersectionsFound = true;
      }
    });
    side.hasError =
      elementWith0IntersectionsFound ||
      side.allDataElement.some(o => (o as CustomImage).badQuality);
    return objectIntersectionMap;
  }

  private hasInterSectionLeftRight(editAreaPolygon, elementPolygon) {
    const union = turf.union(editAreaPolygon, elementPolygon);

    const editAreaBBox = turf.bbox(editAreaPolygon);
    const unionBBox = turf.bbox(union);

    const edit_TL_x = editAreaBBox[0];
    const edit_TL_y = editAreaBBox[1];
    const edit_BR_x = editAreaBBox[2];
    const edit_BR_y = editAreaBBox[3];
    const union_TL_x = unionBBox[0];
    const union_TL_y = unionBBox[1];
    const union_BR_x = unionBBox[2];
    const union_BR_y = unionBBox[3];
    const left_intersect = union_TL_x !== edit_TL_x;
    const right_intersect = union_BR_x !== edit_BR_x;
    const top_intersect = union_TL_y !== edit_TL_y;
    const bottom_intersect = union_BR_y !== edit_BR_y;

    return right_intersect || left_intersect;
  }

  private createTurfPolygon(
    obj: GraphicElement | Rectangle | Polygon | fabric.Object
  ): turf.Feature<turf.Polygon> {
    if (obj instanceof Rectangle) {
      return turf.polygon([
        [
          [this.r(obj.x + obj.width), this.r(obj.y + obj.height)],
          [this.r(obj.x), this.r(obj.y + obj.height)],
          [this.r(obj.x), this.r(obj.y)],
          [this.r(obj.x + obj.width), this.r(obj.y)],
          [this.r(obj.x + obj.width), this.r(obj.y + obj.height)],
        ],
      ]);
    } else if (obj instanceof Polygon) {
      const arr = obj.allPoint;
      arr.push(arr[0]);

      return turf.polygon([
        arr.map(value => [this.r(value.x), this.r(value.y)]),
      ]);
    } else if (obj instanceof fabric.Object) {
      if (!obj.aCoords) {
        return null;
      }
      return turf.polygon([
        [
          [this.r(obj.aCoords.bl.x), this.r(obj.aCoords.bl.y)],
          [this.r(obj.aCoords.br.x), this.r(obj.aCoords.br.y)],
          [this.r(obj.aCoords.tr.x), this.r(obj.aCoords.tr.y)],
          [this.r(obj.aCoords.tl.x), this.r(obj.aCoords.tl.y)],
          [this.r(obj.aCoords.bl.x), this.r(obj.aCoords.bl.y)],
        ],
      ]);
    }
    return null;
  }

  public r(value: number): number {
    return Math.round(value * 1000000) / 1000000;
  }
}
