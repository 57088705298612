export class FastTrackData {
  public firma: string;
  public telefon: string;
  public url: string;
  public strasse: string;
  public hausNr: string;
  public plz: string;
  public ort: string;
  public farbe: string;

  constructor(object?: any) {
    if (object != null) {
      this.firma = object.firma;
      this.telefon = object.telefon;
      this.url = object.url;
      this.plz = object.plz;
      this.ort = object.ort;
      this.farbe = object.farbe;
      this.hausNr = object.hausNr;
    } else {
      this.firma = '';
      this.telefon = '';
      this.url = '';
      this.plz = '';
      this.ort = '';
      this.farbe = '';
      this.hausNr = '';
    }
  }
}
