import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import 'fabric-customise-controls';
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  AfterViewChecked,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { fabric } from 'fabric';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NGXLogger } from "ngx-logger";
import { CropperPosition } from 'ngx-image-cropper';
import { debounceTime, map, reduce, takeUntil } from 'rxjs/operators';

import { CropperComponent } from './cropper/cropper.component';
import { WarningManagerService } from '../services/warning-manager.service';
import { AzureImage } from './../../models/communcation/AzureImage';
import { UploadDialogWrapperComponent } from './../dialogs/upload-dialog-wrapper/upload-dialog-wrapper.component';
import { ShapeColorSelectorButtonComponent } from './../shape-color-selector-button/shape-color-selector-button.component';
import { ElementMeasurementComponent } from './../element-measurement/element-measurement.component';
import { BasicShapeEditorService } from './../services/basic-shape-editor.service';

const uuidv4 = require('uuid/v4');

/**
 * @fileOverview This component handles the design of one side of the car.
 * It also handles the controls and the enabling and disableing of them
 *
 */
import { CarSideHandlerComponent } from './../car-side-handler/car-side-handler.component';
import { CarDataManagerService } from './../services/car-data-manager.service';
import { SvgUploadFileAdapterService } from './../services/svg-upload-file-adapter.service';
import { CONSTANTS } from './../../models/helpers/constants';
import { DimensionsHelperService } from './../services/dimensionshelperservice';
import { ColorPickerComponent } from './colorpicker/colorpicker.component';
import {
  CustomImage,
  isCustomImage,
} from './../../models/graphicelements/customimage';
import {
  CustomText,
  isCustomText,
} from './../../models/graphicelements/customtext';
import { DataService } from './../services/dataservice';
import { StateStorageService } from './../services/statestorage';
import { FontDataService } from './../services/fontdataservice';
import { Seitenansicht } from './../../models/seitenansicht';
import { Fahrzeugseite } from '../../models/fahrzeugeseite';
import { Point } from '../../models/graphicelements/point';
import { TEXT_COLOR_DATA } from '../../assets/color-data/textcolor-data';
import { TemplateInteractionService } from './../services/templateInteraction.service';
import { RenderEditModeService } from './../services/rendereditmodeservice';

import { CanvasHelperService } from './../services/canvashelperservice';
import { PriceCalculatorService } from './../services/price-calculator.service';
import { Color } from '../../models/helpers/color';
import { CanvasSelectionService } from './../services/canvas-selection.service';

import { FileReaderService } from '../services/file-reader-service';
import { UserType } from 'models/communcation/UserType';
import { MatSelect } from '@angular/material/select';
import { CarColorChangeComponent } from 'app/car-color-change/car-color-change.component';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [FileReaderService],
})
export class EditorComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  private readonly ALIGNMENT_HC = 'horizontal center';
  private readonly ALIGNMENT_HS = 'horizontal spread';
  private readonly ALIGNMENT_VS = 'vertical spread';

  public isShowAlignMenu = false;
  public isShowTextAlignMenu = false;
  public isShowFormAddMenu = false;
  public isShowCopyMenu = false;

  public isCroppingActive = false;
  private imageWasCropped = false;

  // dummyobject for fixing canvas not reacting on click bug
  private DUMMYOBJECT_BUG_BEKO_352: fabric.Rect = new (<any>fabric).Rect({
    fill: '#000000',
    width: 1,
    height: 1,
    left: 0,
    top: 0,
  });

  @Output()
  fullyInitializedEmitter: EventEmitter<any> = new EventEmitter();

  // TODO: find a way to use mulitple componets ( google ... )

  @ViewChild('textColorPicker', { static: true })
  private readonly textColorPicker: ColorPickerComponent;

  @ViewChild('carColorPicker', { static: true })
  private readonly carColorPicker: ColorPickerComponent;

  @ViewChild('borderColorPicker', { static: true })
  private readonly borderColorPicker: ColorPickerComponent;

  @ViewChild('measureComponentText', { static: false })
  private readonly measureComponentText: ElementMeasurementComponent;
  @ViewChild('measureComponentImage', { static: false })
  private readonly measureComponentImage: ElementMeasurementComponent;
  @ViewChild('measureComponentShape', { static: false })
  private readonly measureComponentShape: ElementMeasurementComponent;
  @ViewChild('shapeColorSelector', { static: false })
  private readonly shapeColorSelector: ShapeColorSelectorButtonComponent;
  @ViewChild('carColorSelector', { static: false })
  private readonly carColorSelector: CarColorChangeComponent;

  @ViewChild('topsystemTextarea', { static: false })
  private readonly topSystemTextArea: ElementRef<HTMLTextAreaElement>;

  @ViewChild('cropper', { static: false })
  private readonly cropper: CropperComponent;

  @ViewChild('letterSpacingSelector', { static: false })
  private readonly letterSpacingElementRef: ElementRef<HTMLInputElement>;

  @ViewChild('fontSizeStyle', { static: false })
  private readonly fontSizeStyleElementRef: ElementRef<HTMLInputElement>;

  @ViewChild('fontStyle', { static: false })
  private readonly fontStyleElementRef: MatSelect;

  carColor: string = undefined;
  templateColor: string;
  movingElementTimer: any = undefined;
  allCurrentlyPressedArrowKeys: string[] = undefined;
  carColorCode:string = "";
  isCustomColor: boolean;

  @ViewChild('carsvg', { static: true })
  private readonly carSvg: CarSideHandlerComponent;
  @ViewChild('canvas', { static: true })
  private readonly canvasRef: ElementRef;

  @ViewChild('editoroverlay', { static: true })
  private readonly carEditorOverlayRef: ElementRef;
  @ViewChild('editorFileUploadOverlay', { static: true })
  private readonly carEditorFileUploadOverlayRef: ElementRef;

  private readonly fabric: any;
  private canvas: fabric.Canvas;
  private context: CanvasRenderingContext2D;

  public isInEditmode = false;
  private selectionIsUnderline = false;

  private readonly topSystemTextChanged: Subject<void> = new Subject<void>();

  // TEXT EDIT COLOR
  public textColor = '#000000';
  public fontFamily: string;
  public textAlign: string;
  public fontSize: number;
  public lineHeight: number;
  public letterSpacing: number;

  public activateZoom = false;
  public disableZoom = false;
  private zoomInXValue = 0;
  private zoomInYValue = 0;
  private readonly zoomInFactor = 4;
  public isInZoomMode = false;
  private isMouseOnCanvasDown = false;
  private canvasDragPoint: fabric.Point;

  private showWarningIsInActiveMode = true;

  private allEditAreaLoaded = false;

  /**
   * contains the text of the currently selected text object
   * otherwise undefined
   */
  private activeObjectTextContent: string = undefined;
  public isSpacingIconHidden = false;

  private carSvgLoaded: boolean;

  private sideUpdatedSubscription: Subscription;
  private stateChangedSubscription: Subscription;
  private druckauftragLoadedEmitterSubscription: Subscription;
  private templateDataUpdatedSubscription: Subscription;
  private allTemplateDataLoaded = false;

  private imageConverterSubscription: Subscription;
  private drawAllWarningSubscription: Subscription;
  private drawAllWarningSubject: Subject<{
    warningManagerService: WarningManagerService;
    context: CanvasRenderingContext2D;
    isInZoomMode: boolean;
    seitenansicht: Seitenansicht;
    allObjectExceptSelected: fabric.Object[];
    allUngroupedCoordsArray: {
      bl: fabric.Point;
      br: fabric.Point;
      tl: fabric.Point;
      tr: fabric.Point;
    }[];
    isInEditmode: boolean;
    isTopSystemActive: boolean;
    zoomFactor: number;
    scaleToCanvasFactor: number;
  }>;

  private canvasZoomMoveTracker: Subject<{ xDiff: number; yDiff: number }>;

  /**
   * Variable for debugging Editareas
   */
  private readonly highlightEditarea = false;

  public maxTopSystemTextLength = 250;

  public get isTopSystemActive(): boolean {
    return this.dataService.isTopSystemActive;
  }

  public get hideTopsystemTextearea() {
    return !this.isTopSystemActive;
  }

  /**
   * Disables Delete Button until Object on Editor is Selected.
   */
  public get isDeleteDisabled(): boolean {
    const a = this.showObjectControls();
    const b = this.isActiveGroup();
    const c = !this.showObjectControls() && !this.isActiveGroup();
    const d = isCustomText(this.activeObject);
    const e = isCustomText(this.activeObject) && this.isTopSystemActive;
    return (
      (!this.showObjectControls() && !this.isActiveGroup()) ||
      (isCustomText(this.activeObject) && this.isTopSystemActive)
    );
  }

  /**
   * Show Alignment Menu For images onClick Event.
   * @param val Optional Boolean
   */
  public setShowAlignMenu(val?: boolean) {
    if (typeof val !== 'boolean' || val === null || val === undefined) {
      this.isShowAlignMenu = !this.isShowAlignMenu;
    } else {
      this.isShowAlignMenu = val;
    }
  }

  /**
   * Show Alignment Menu for Text onClick Event.
   * @param val 
   */
  public setShowTextAlignMenu(val: boolean) {
    if (typeof val !== 'boolean' || val === null || val === undefined) {
      this.isShowTextAlignMenu = !this.isShowTextAlignMenu;
    } else {
      this.isShowTextAlignMenu = val;
    }
  }

  /**
   * Show Menu for Adding New Shape.
   * @param val 
   */
  public setShowFormAddMenu(val?: boolean) {
    if (typeof val !== 'boolean' || val === null || val === undefined) {
      this.isShowFormAddMenu = !this.isShowFormAddMenu;
    } else {
      this.isShowFormAddMenu = val;
    }
  }

  /**
   * Show Menu for Copying Object or Text to Other vehicleSides.
   * @param val 
   */
  public setShowCopyMenu(val?: boolean) {
    if (typeof val !== 'boolean' || val === null || val === undefined) {
      this.isShowCopyMenu = !this.isShowCopyMenu;
    } else {
      this.isShowCopyMenu = val;
    }
  }

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly stateStorageService: StateStorageService,
    private readonly dataService: DataService,
    private readonly fontDataService: FontDataService,
    private readonly canvasHelperService: CanvasHelperService,
    private readonly priceCalculatorService: PriceCalculatorService,
    private readonly dimensionsHelper: DimensionsHelperService,
    public readonly dialog: MatDialog,
    private svgUploadFileAdapterService: SvgUploadFileAdapterService,
    private readonly translateService: TranslateService,
    private readonly templateInteractionService: TemplateInteractionService,
    private readonly renderEditModeService: RenderEditModeService,
    private readonly carDataManagerService: CarDataManagerService,
    private readonly selectionService: CanvasSelectionService,
    private readonly basicShapeEditor: BasicShapeEditorService,
    private logger: NGXLogger,
    private readonly warningManagerService: WarningManagerService,
    private fileReaderService: FileReaderService
  ) {
    // ---
    this.carColor = undefined;
    this.textAlign = 'left';
    this.fontSize = 40;
    this.lineHeight = 1;
    this.letterSpacing = 0;
    this.fontFamily = 'OpenSans-Regular';
    this.textColor = '#000000';
    // set Icons
    this.addIconsToRegistry();
    this.carDataManagerService.templateDataLoadedPromise.then(() => {
      this.allTemplateDataLoaded = true;
      this.performInitializationIfAllSvgsAreLoaded();
    });

    // used Objects
    // TODO: BEKO-104
    this.fabric = fabric;

    // BEKO-417 prevent flipping over on scale
    this.fabric.Object.prototype.set({
      lockScalingFlip: true,
    });
    // Try to initialize
    this.performInitializationIfAllSvgsAreLoaded();
  }

  /**
   * Hook for onInit, merges different approaches to deal with car Colors
   *
   * @memberof EditorComponent
   */
  ngOnInit() {
    this.fullyInitializedEmitter.subscribe(this.handleInitialization);

    this.templateDataUpdatedSubscription =
      this.carDataManagerService.templateDataUpdatedEmitter.subscribe(
        this.performInitializationIfAllSvgsAreLoaded
      );

    this.onSelectedSideChange();

    this.allCurrentlyPressedArrowKeys = [];
    if (this.dataService.druckauftrag) {
      if (this.dataService.druckauftrag.carColor) {
        this.carColor = this.dataService.druckauftrag.carColor;
      }
      if (this.dataService.druckauftrag.templateColor) {
        this.templateColor = this.dataService.druckauftrag.templateColor;
      } else if (this.templateColor) {
        this.dataService.druckauftrag.templateColor = this.templateColor;
      }
      this.sideUpdatedSubscription =
        this.dataService.sideUpdatedEmitter.subscribe(this.onSideUpdatedEvent);
      this.stateChangedSubscription =
        this.dataService.stateUpdateEmitter.subscribe((_change: any) => {
          this.carDataManagerService.selectTemplate(
            this.dataService.druckauftrag.selectedTemplate,
            this.dataService.druckauftrag
          );
          this.onSideUpdatedEvent();
        });

      this.druckauftragLoadedEmitterSubscription =
        this.dataService.druckauftragLoadedEmitter.subscribe(() =>
          this.onDruckauftragLoaded()
        );
      this.dataService.allEditAreaLoadedPromise.then(() => {
        this.allEditAreaLoaded = true;
        this.performInitializationIfAllSvgsAreLoaded();
      });
    } else {
      this.logger.error(`Editor: druckauftrag is undefined!`);
    }
    this.imageConverterSubscription =
      this.dataService.imageConvertedEmitter.subscribe(
        this.handleImageConversion
      );

    this.dataService.loadingCycleEmitter.subscribe((b: boolean) => {
      this.setLoadingCycle(b);
    });
    this.dataService.editModeFalseEmitter.subscribe(() => {
      if (this.isInEditmode) {
        this.setEditMode(false);
      }
    });
    if (this.dataService.isTopSystemActive) {
      this.setInitialValueForTopsystemTextarea();
    }
  }

  private readonly handleImageConversion = data => {
    const azureImage: AzureImage = data.azureImage;

    // 3.1 create and add fabric object from converted image

    const image = new Image();
    image.src = data.convertedImage;
    image.onload = () => {
      const fabricObject = this.addLoadedImageToCanvas(
        image,
        data.file,
        {
          width: data.originalImage.originalWidth,
          height: data.originalImage.originalHeight,
        },
        this.dataService.getSeitenansichtForSelectedSide()
      );
      fabricObject.nativeSource = data.originalImage.content; // 4. save also the original unconverted image
      fabricObject.additionalPrice = azureImage ? azureImage.priceValue : 0;
      this.warningManagerService.updateIntersections(
        this.dataService.getSeitenansichtForSelectedSide()
      );
      this.renderEditMode();
      this.setLoadingCycle(false);
    };
  };

  /**
   * Handle Intial Value for carside and TopSystem TextArea.
   */
  private readonly handleInitialization = () => {
    this.onCarSideDataFullyInitialized();
    if (this.dataService.isTopSystemActive) {
      this.setInitialValueForTopsystemTextarea();
    }
    /*
    Draft for on Wheel zoom
    this.canvas.on('mouse:wheel', opt => {
      let delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();

      this.adaptCarSvgViewportToCanvas();
    });*/

    this.drawAllWarningSubject = new Subject();
    this.drawAllWarningSubscription = this.drawAllWarningSubject
      .pipe(debounceTime(5))
      .subscribe(
        ({
          warningManagerService,
          context,
          isInZoomMode,
          seitenansicht,
          allObjectExceptSelected,
          allUngroupedCoordsArray,
          isInEditmode,
          isTopSystemActive,
          zoomFactor,
          scaleToCanvasFactor,
        }) => {
          if (!isInZoomMode) {
            warningManagerService.drawAllCanvasWarning(
              context,
              isInZoomMode ? zoomFactor : scaleToCanvasFactor,
              allObjectExceptSelected,
              allUngroupedCoordsArray,
              seitenansicht.fahrzeugseite,
              isInEditmode,
              isTopSystemActive,
              this.dataService.userType
            );
          }
        }
      );
  };

  public topSystemModelChange() {
    this.topSystemTextChanged.next();
  }

  private updateTopsystemText() {
    const topSystemTextElement: CustomText = this.getTopSystemCandidate();
    if (!topSystemTextElement) {
      return;
    }
    topSystemTextElement.styles = {};
    topSystemTextElement.text =
      this.topSystemTextArea.nativeElement.value.replace(/\n/g, ' ');

    this.canvas.renderAll();
    this.selectionService.discardAndStoreSelection(this.canvas);
    this.updatePriceAndSaveState();
    this.selectionService.restoreSelection();
    this.renderEditMode();
  }
  private setInitialValueForTopsystemTextarea() {
    const topSysElem: CustomText = this.getTopSystemCandidate();
    if (topSysElem !== null) {
      this.topSystemTextArea.nativeElement.value = topSysElem.text;
      topSysElem.hasRotatingPoint = false;
      topSysElem.lockMovementY = true;
      topSysElem.lockScalingX = true;
      topSysElem.lockScalingY = true;
      topSysElem.lockUniScaling = true;
      topSysElem.lockRotation = true;
      topSysElem.editable = false;
      this.canvas.setActiveObject(topSysElem);

      this.topSystemTextChanged
        .pipe(debounceTime(300))
        .subscribe(() => this.updateTopsystemText());
    } else if (this.topSystemTextArea) {
      this.topSystemTextArea.nativeElement.value = 'Something is wrong here ';
    }
  }
  private getTopSystemCandidate() {
    if (!this.canvas) {
      return null;
    }
    for (const elem of this.canvas.getObjects()) {
      if (isCustomText(elem)) {
        return elem;
      }
    }
    return null;
  }

  private getNewVerticalPostionPostCrop(
    deltaX: number,
    deltaY: number,
    htmlPosition: boolean,
    selectedImage: CustomImage
  ): number {
    const angleInRadians = this.fabric.util.degreesToRadians(
      selectedImage.angle
    );
    let rotatedDeltaY = 0;
    if (deltaX !== 0) {
      const beta = Math.atan(deltaY / deltaX);
      if (beta !== 0) {
        const hypo = deltaY / Math.sin(beta);
        rotatedDeltaY = Math.sin(angleInRadians + beta) * hypo;
      }
    } else {
      rotatedDeltaY = deltaY;
    }
    if (htmlPosition) {
      // on the bottom there are only tyres, so leave a bit more room
      return Math.min(
        this.getCanvasHeight(),
        rotatedDeltaY +
          selectedImage.top * this.carSvg.getScaleToCanvasFactor() -
          CropperComponent.PADDING
      );
    } else {
      return Math.min(
        this.getCanvasHeight() / this.carSvg.getScaleToCanvasFactor() -
          selectedImage.getScaledHeight(),
        deltaY / this.carSvg.getScaleToCanvasFactor() + selectedImage.top
      );
    }
  }

  private updateImageOnCanvas(customImage: CustomImage) {
    customImage.setCoords();
    this.selectionService.discardAndStoreSelection(this.canvas);
    this.updatePriceAndSaveState();
    this.canvas.renderAll();
    this.selectionService.restoreSelection();
    this.renderEditMode();
  }

  private async substituteImage(
    convertedImage: string,
    customImage: CustomImage
  ) {
    this.imageWasCropped = false;

    if (!customImage.originalDisplayImage) {
      customImage.originalDisplayImage = customImage.getSrc();
    }
    if (!customImage.initialDisplayImageWidth) {
      customImage.initialDisplayImageWidth = customImage.width;
    }
    if (!customImage.initialDisplayImageHeight) {
      customImage.initialDisplayImageHeight = customImage.height;
    }

    await EditorComponent.waitForSettingImageSrc(customImage, convertedImage);

    this.updateImageOnCanvas(customImage);
  }

  private getNewHorizontalPositionPostCrop(
    deltaX: number,
    deltaY: number,
    htmlPosition: boolean,
    selectedImage: CustomImage
  ): number {
    const angleInRadians = this.fabric.util.degreesToRadians(
      selectedImage.angle
    );
    let rotatedDeltaX = 0;
    if (deltaX !== 0) {
      const beta = Math.atan(deltaY / deltaX);
      if (beta !== 0) {
        const hypo = deltaY / Math.sin(beta);
        rotatedDeltaX = Math.cos(angleInRadians + beta) * hypo;
      } else {
        rotatedDeltaX = deltaX;
      }
    }

    if (htmlPosition) {
      return Math.min(
        this.getCanvasWidth() - CropperComponent.PADDING,
        rotatedDeltaX +
          selectedImage.left * this.carSvg.getScaleToCanvasFactor() -
          CropperComponent.PADDING
      );
    } else {
      return Math.min(
        this.getCanvasWidth() / this.carSvg.getScaleToCanvasFactor() -
          selectedImage.getScaledWidth(),
        deltaX / this.carSvg.getScaleToCanvasFactor() + selectedImage.left
      );
    }
  }

  /**
   * Exit genlty by unscribing to all eventListeners
   *
   * @memberof EditorComponent
   */
  ngOnDestroy() {
    if (this.sideUpdatedSubscription) {
      this.sideUpdatedSubscription.unsubscribe();
    }
    if (this.stateChangedSubscription) {
      this.stateChangedSubscription.unsubscribe();
    }

    if (this.druckauftragLoadedEmitterSubscription) {
      this.druckauftragLoadedEmitterSubscription.unsubscribe();
    }
    if (this.templateDataUpdatedSubscription) {
      this.templateDataUpdatedSubscription.unsubscribe();
    }
    if (this.drawAllWarningSubscription) {
      this.drawAllWarningSubscription.unsubscribe();
    }

    window.removeEventListener('keyup', this.onKeyupEventListener);
    window.removeEventListener('keydown', this.onKeyDownEventListener);
    window.removeEventListener('dblclick', this.onDoubleClickEventListener);
    window.removeEventListener('click', this.onClickEventListener);
    this.imageConverterSubscription.unsubscribe();
  }

  /**
   * boolean that specifies whether the width edit for a shape is active
   */
  private get isInputForElementActive(): boolean {
    const textFieldInputsActive =
      (this.fontStyleElementRef && this.fontStyleElementRef.focused) ||
      (this.fontSizeStyleElementRef &&
        this.fontSizeStyleElementRef.nativeElement ===
          document.activeElement) ||
      (this.letterSpacingElementRef &&
        this.letterSpacingElementRef.nativeElement === document.activeElement);

    return (
      textFieldInputsActive ||
      (this.measureComponentText
        ? this.measureComponentText.hasFocus()
        : false) ||
      (this.measureComponentImage
        ? this.measureComponentImage.hasFocus()
        : false) ||
      (this.measureComponentShape
        ? this.measureComponentShape.hasFocus()
        : false) ||
      (this.shapeColorSelector ? this.shapeColorSelector.hasFocus() : false)
    );
  }

  /**
   * Get ActiveObject(shape) Border Color
   */
  public get activeBorderColor(): string {
    if (!this.canvas) {
      return undefined;
    }
    return this.canvas.getActiveObject()
      ? this.canvas.getActiveObject().stroke
      : undefined;
  }

  /**
   * Load all font from Fontdataservice as Matselect option Value.
   */
  public get allFont() {
    return this.fontDataService.allFont;
  }

  /**
   * Actively Selected Object on Canvas.
   */
  public get activeObject() {
    return this.canvas ? this.canvas.getActiveObject() : undefined;
  }

  public get activeCarColor() {
    return this.dataService.druckauftrag ? this.dataService.druckauftrag.carColor : undefined;
  }

  /**
   * Rotation of Active Object.
   */
  public get currentRotation(): number {
    if (this.activeObject) {
      return this.activeObject.angle;
    }
    return 0;
  }

  /**
   * boolean that specifies whether the Text is selected
   */
  public get isTextSelected() {
    return (
      (this.isSingleObject() && isCustomText(this.canvas.getActiveObject())) ||
      false
    );
  }

  /**
   * boolean that specifies whether the Image is Selected
   */
  public get isImageSelected() {
    return (
      (this.isSingleObject() && isCustomImage(this.canvas.getActiveObject())) ||
      false
    );
  }

  /**
   * boolean that specifies whether the shape is Selected
   */
  public get isShapeSelected() {
    return (
      (this.isSingleObject() &&
        (this.canvas.getActiveObject().type === 'rect' ||
          this.canvas.getActiveObject().type === 'triangle' ||
          this.canvas.getActiveObject().type === 'customTriangle' ||
          this.canvas.getActiveObject().type === 'circle')) ||
      false
    );
  }

  private onDruckauftragLoaded() {
    this.priceCalculatorService.updateDruckauftragPrice(
      this.dataService.druckauftrag,
      this.dataService.countryHourRate,
      this.dataService.currencyFactor,
      this.dataService.userType,
      this.dataService.country
    );

    this.warningManagerService.updateIntersections(
      this.dataService.druckauftrag.rechts
    );
    this.warningManagerService.updateIntersections(
      this.dataService.druckauftrag.links
    );
    this.warningManagerService.updateIntersections(
      this.dataService.druckauftrag.front
    );
    this.warningManagerService.updateIntersections(
      this.dataService.druckauftrag.heck
    );

    this.stateStorageService.saveCurrentDataServiceStateAsInitialState();
  }

  /**
   * Deals with changes to the selected car color and
   * purges all recorder ArrowKey events
   *
   * @public
   * @memberof EditorComponent
   */
  public onSideUpdatedEvent = () => {
    // TODO: needs to update Car color here also.
    // reset timer used for keyboard events
    if (this.movingElementTimer) {
      clearInterval(this.movingElementTimer);
      this.movingElementTimer = undefined;
    }
    this.allCurrentlyPressedArrowKeys = [];

    this.onSelectedSideChange();
    if (this.dataService.druckauftrag.carColor) {
      this.carColor = this.dataService.druckauftrag.carColor;
    } else if (this.carColor) {
      this.dataService.druckauftrag.carColor = this.carColor;
    }

    if (this.dataService.druckauftrag.templateColor) {
      this.templateColor = this.dataService.druckauftrag.templateColor;
    } else if (this.templateColor) {
      this.dataService.druckauftrag.templateColor = this.templateColor;
    }
  };

  /**
   * Get the canvas for the view, add handling canvas events
   *
   * @memberof EditorComponent
   */
  ngAfterViewInit() {
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    if (carSideManager) {
      this.carSvg.setCarSideManager(carSideManager);
      this.onLoadCar();
    }
    if (document.getElementById('canvas')) {
      this.canvas = new this.fabric.Canvas('canvas');
      (<any>this.canvas).enableRetinaScaling = true;

      this.dataService.canvas = this.canvas;

      this.context = this.canvas.getContext();
      // bugfix missing viewport transforms on canvas
      this.canvas.viewportTransform.forEach((value, index) => {
        if (!value) {
          this.canvas.viewportTransform[index] = 0;
        }
      });
      // add canvas events
      this.addFabricJsEventListeners();

      this.disableRotationForGroups();
      this.addKeyEvents();
      this.performInitializationIfAllSvgsAreLoaded();
      this.adaptOnResize();
    }
  }

  private disableRotationForGroups() {
    this.canvas.on('selection:created', function (e) {
      const selection = e.target;
      if (selection.type === 'activeSelection') {
        selection.hasRotatingPoint = false;
      }
    });

    // fired e.g. when you select one object first,
    // then add another via shift+click
    this.canvas.on('selection:updated', function (e) {
      const selection = e.target;
      if (selection.type === 'activeSelection' && selection.hasRotatingPoint) {
        selection.hasRotatingPoint = false;
      }
    });
  }

  private addKeyEvents = (): void => {
    window.addEventListener('keyup', this.onKeyupEventListener);
    window.addEventListener('keydown', this.onKeyDownEventListener);
    window.addEventListener('dblclick', this.onDoubleClickEventListener);
    window.addEventListener('click', this.onClickEventListener);
  };
  private canvasMouseDown = (e: fabric.IEvent) => {
    if (!this.canvas.getActiveObject()) {
      // reset hidden icons (hover)
      this.isSpacingIconHidden = false;
      this.setEditMode(false);
    } else {
      this.setEditMode(true);
    }

    if (e.target === null && this.isInZoomMode) {
      this.isMouseOnCanvasDown = true;
      this.canvas.defaultCursor = 'grabbing';

      this.canvasZoomMoveTracker = new BehaviorSubject({ xDiff: 0, yDiff: 0 });

      const activateCanvasViewportMovementTracking = () => {
        return this.canvasZoomMoveTracker
          .pipe(
            takeUntil(timer(50)),
            reduce(
              (acc: any, val: any) => {
                return {
                  xDiff: acc.xDiff + val.xDiff,
                  yDiff: acc.yDiff + val.yDiff,
                };
              },
              { xDiff: 0, yDiff: 0 }
            ),

            map(({ xDiff, yDiff }) => {
              this.adaptViewportOfCarSvg(xDiff, yDiff);
              this.canvasZoomMoveTracker.next({ xDiff: 0, yDiff: 0 });
              if (this.isMouseOnCanvasDown) {
                activateCanvasViewportMovementTracking();
              }
            })
          )
          .subscribe();
      };
      activateCanvasViewportMovementTracking();
    }
    this.canvasDragPoint = e.pointer;
  };
  private canvasMouseUp = (e: fabric.IEvent) => {
    this.isMouseOnCanvasDown = false;
    this.canvasDragPoint = null;
    if (this.isInZoomMode) {
      this.canvas.defaultCursor = 'grab';
    }
  };
  private canvasMouseMove = (e: fabric.IEvent) => {
    if (this.isMouseOnCanvasDown && this.isInZoomMode) {
      if (this.canvasDragPoint) {
        const xDiff = e.pointer.x - this.canvasDragPoint.x;
        const yDiff = e.pointer.y - this.canvasDragPoint.y;

        this.canvasZoomMoveTracker.next({ xDiff, yDiff });
      }
      this.canvasDragPoint = e.pointer;
    }
  };
  private adaptViewportOfCarSvg = (
    xDifference: number,
    yDifference: number
  ) => {
    this.canvas.relativePan({
      x: xDifference,
      y: yDifference,
    } as fabric.Point);

    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    carSideManager.setViewPort(
      this.canvas.vptCoords.tl as fabric.Point,
      this.canvas.vptCoords.br as fabric.Point
    );
  };

  /**
   * Set even handling on the canvas:
   * adapt fontsize, quality checks for
   * images and positon checks for elements.
   *
   * @private
   * @memberof EditorComponent
   */
  private addFabricJsEventListeners = (): void => {
    this.canvas.on('object:modified', this.onCanvasObjectModified);

    this.canvas.on('object:scaling', (e: fabric.IEvent) => {
      if (isCustomText(e.target)) {
        this.canvasHelperService.adaptCustomTextSizeOnScaling(e.target, e);
      } else if (isCustomImage(e.target)) {
        this.canvasHelperService.adaptImageOnScale(
          e.target,
          this.isTopSystemActive
        );
      }
    });
  };

  /**
   * Handles Object that are Modified on Canvas.
   * @param e Fabric IEvent Interface
   */
  private readonly onCanvasObjectModified = (
    e: fabric.IEvent | { target: fabric.Object }
  ) => {
    // separate handling of template content
    const modifiedObject = e.target;

    // this log is useful for checking coordinates of edit areas, etc
    // this.logger.debug(`modified ${modifiedObject.type}, pos ${anyObj.aCoords.tl.x},${anyObj.aCoords.tl.y}`);

    if (isCustomText(modifiedObject)) {
      this.handleModifiedCustomText(modifiedObject);
      modifiedObject.render(this.context);
    } else if (
      isCustomImage(modifiedObject) &&
      !(<any>modifiedObject).isTemplateData
    ) {
      // ignore template data since template data is svg
      this.dimensionsHelper.performQualityCheck(modifiedObject);
    }
    // bugfix when modifing group
    // an active group that is specified when
    // statestorageservice.saveState() is called leads to
    // false postiontioning of elements of this group
    // when the saved state is loaded again
    this.selectionService.discardAndStoreSelection(this.canvas);

    this.handleModifiedGroup(
      modifiedObject,
      this.selectionService.allSelectedObject
    );
    this.renderEditMode();
    // check for boundary issues
    (<any>this.canvas).requestRenderAll();
    // save state when object was moved rotated resized
    this.updatePriceAndSaveState();
    this.selectionService.restoreSelection();
  };

  /**
   * Adapts Font of CustomText Modification On Canvas.
   * @param modifiedObject Fabric Object
   */
  private handleModifiedCustomText = (modifiedObject: CustomText) => {
    // adapt fontsize
    this.canvasHelperService.adaptCustomTextSizeOnScaling(
      modifiedObject,
      undefined
    );
    this.canvasHelperService.roundFontSizesOfCustomText(modifiedObject);
    modifiedObject.setCoords();
    modifiedObject.caching = false;

    if (modifiedObject.isTemplateData) {
      // test for change of text content
      if (
        this.activeObjectTextContent &&
        this.activeObjectTextContent !== modifiedObject.text
      ) {
        // the user changed the text content of the text element
        // however that fact doesnt reset the template data flag (which would then cause the text to be transferred
        // to a new template)
        // BEKO-307: here we might need to remember the text
        // modifiedObject.isTemplateData = false;
        const textKey = this.getTextKeyFromCustomText(modifiedObject);
        if (modifiedObject.isTemplateData && textKey) {
          this.templateInteractionService.onTextOfTemplateTextObjectChanged(
            this.dataService.druckauftrag,
            modifiedObject,
            modifiedObject.text
          );
        }
      }
      // disable caching to prevent performance issues
      modifiedObject.noScaleCache = true;
    }
  };

  private handleModifiedGroup = (
    modifiedObject: fabric.Object,
    groupedObjects: fabric.Object[]
  ) => {
    // adapt fontsize of texts in groups
    // adapting the font size while the group is active
    // has no effect
    if (groupedObjects) {
      groupedObjects.forEach(value => {
        if (isCustomText(value)) {
          this.canvasHelperService.adaptCustomTextSizeOnScaling(
            value,
            undefined
          );
          this.canvasHelperService.roundFontSizesOfCustomText(value);
          value.render(this.context);
        }
      });
    }
    // check and set Position
    const allObjForCheckValidPosition = groupedObjects
      ? groupedObjects
      : [modifiedObject];
    allObjForCheckValidPosition.forEach(element => {
      if (!element) {
        return;
      }
      const objectZoomModification = this.isInZoomMode ? this.zoomInFactor : 1;
      const width =
        this.canvas.getWidth() /
        (this.canvas.getZoom() / objectZoomModification);
      const height =
        this.canvas.getHeight() /
        (this.canvas.getZoom() / objectZoomModification);

      const elementCoords = (<any>element).aCoords;
      const tl = elementCoords.tl;
      const br = elementCoords.br;

      //  left overflow
      if (tl.x < 0 || br.x < 0) {
        element.left = Math.max(0, element.left - br.x);
      }
      // right overflow
      if (tl.x > width || br.x > width) {
        element.left = Math.min(
          element.left - (tl.x - width),
          element.left - (br.x - width)
        );
      }
      // top overflow
      if (tl.y < 0 || br.y < 0) {
        element.top = Math.max(0, element.top - br.y);
      }
      // bot overflow
      if (tl.y > height || br.y > height) {
        element.top = Math.min(
          element.top - (tl.y - height),
          element.top - (br.y - height)
        );
      }
      element.setCoords();
    });
  };

  /**
   * Load svg(s) for the selected side
   *
   * @memberof EditorComponent
   */
  public onSelectedSideChange = () => {
    const selectedSide = this.dataService.getSeitenansichtForSelectedSide();
    this.isInEditmode = false;
    this.carEditorOverlayRef.nativeElement.style.display = 'block';
    // clear canvas
    if (this.canvas) {
      this.canvas.clear();
    }
    this.carSvgLoaded = false;
    this.carEditorFileUploadOverlayRef.nativeElement.style.display = 'none';

    if (selectedSide) {
      this.carSvg.setCarSideManager(
        this.carDataManagerService.getCarSideManager(
          this.dataService.getSelectedSide()
        )
      );
      this.carSvg.updateHtml();
      this.onLoadCar();
    }
  };

  /**
   * DblClickEventListener
   * show dialog for CustomImage in BadQuality
   *
   * @private
   * @memberof EditorComponent
   */
  private onDoubleClickEventListener = (e: MouseEvent) => {
    const canvasTarget = this.canvas.findTarget(e, true);
    if (canvasTarget) {
      this.logger.debug(JSON.stringify(canvasTarget));
      this.logger.debug(
        'Current element : \n' +
          'Width: ' +
          (canvasTarget.width + canvasTarget.strokeWidth) *
            canvasTarget.scaleX *
            CONSTANTS.VIEWBOX_TO_METER_FACTOR *
            100 +
          'cm' +
          ' ' +
          ' Height: ' +
          (canvasTarget.height + canvasTarget.strokeWidth) *
            canvasTarget.scaleY *
            CONSTANTS.VIEWBOX_TO_METER_FACTOR *
            100 +
          'cm\n' +
          'countryHourRate:' +
          this.dataService.countryHourRate +
          '\n' +
          'currencyFactor: ' +
          this.dataService.currencyFactor
      );
      this.logger.info(canvasTarget.toSVG());
    }
  };

  private onClickEventListener = (e: MouseEvent) => {
    const boundingRect = this.canvasRef.nativeElement.getBoundingClientRect();
    this.zoomBugfixCheck(boundingRect, e);
    const target = this.canvas.findTarget(e, true);

    this.applyZoom(boundingRect, target, e);
    this.renderEditMode();
  };

  /**
   * Perform zoomBugfixCheck
   *
   * @private
   * @param {*} boundingRect
   * @param {MouseEvent} e
   * @memberof EditorComponent
   */

  private zoomBugfixCheck(boundingRect: any, e: MouseEvent): void {
    if (this.isInZoomMode) {
      return;
    }
    this.canvas.add(this.DUMMYOBJECT_BUG_BEKO_352);
    const bugfixEvent = _.cloneDeep(e);

    Object.defineProperty(bugfixEvent, 'clientX', {
      value: boundingRect.left + 1 + document.scrollingElement.scrollLeft,
    });
    Object.defineProperty(bugfixEvent, 'x', {
      value: boundingRect.left + 1 + document.scrollingElement.scrollLeft,
    });
    Object.defineProperty(bugfixEvent, 'clientY', {
      value: boundingRect.top + 1 + document.scrollingElement.scrollTop,
    });
    Object.defineProperty(bugfixEvent, 'y', {
      value: boundingRect.top + 1 + document.scrollingElement.scrollTop,
    });

    const target = this.canvas.findTarget(bugfixEvent, true);

    if (!target) {
      console.error(
        'Canvas reagiert nicht, ist eine Regelmäßigkeit zu Erkennen?'
      );
      // ERROR:
      this.canvas.remove(this.DUMMYOBJECT_BUG_BEKO_352);
      this.canvas.setZoom(this.carSvg.getScaleToCanvasFactor());
      this.onSelectedSideChange();
    } else {
      this.canvas.remove(this.DUMMYOBJECT_BUG_BEKO_352);
    }
  }

  /**
   * Apply zoom if requested
   *
   * @private
   * @memberof EditorComponent
   */
  private applyZoom = (
    boundingRect: any,
    target: fabric.Object,
    e: MouseEvent
  ): void => {
    // calculate new dimension relative to the scrollingElement
    const canvasTop = boundingRect.top - document.scrollingElement.scrollTop;
    const canvasLeft = boundingRect.left - document.scrollingElement.scrollLeft;
    const clickX = e.clientX - document.scrollingElement.scrollLeft;
    const clickY = e.clientY - document.scrollingElement.scrollTop;
    if (this.disableZoom && !target && (<any>e.target).nodeName === 'CANVAS') {
      // do not handle disable zoom here
    } else if (
      this.activateZoom &&
      !this.disableZoom &&
      clickX - canvasLeft > 0 &&
      clickX - canvasLeft < this.canvasRef.nativeElement.offsetWidth &&
      clickY - canvasTop > 0 &&
      clickY - canvasTop < this.canvasRef.nativeElement.offsetHeight &&
      (<any>e.target).nodeName === 'CANVAS'
    ) {
      this.canvas.defaultCursor = 'grab';
      this.canvas.hoverCursor = 'default';
      this.disableZoom = true;
      this.zoomInXValue = Math.round(e.x - boundingRect.left);
      this.zoomInYValue = Math.round(e.y - boundingRect.top);
      this.canvas.zoomToPoint(
        new this.fabric.Point(this.zoomInXValue, this.zoomInYValue),
        this.canvas.getZoom() * this.zoomInFactor
      );
    }
    this.adaptCarSvgViewportToCanvas();
  };

  public resetZoomMode() {
    this.canvas.hoverCursor = 'default';
    this.canvas.defaultCursor = 'default';
    this.canvas.zoomToPoint(
      new this.fabric.Point(this.zoomInXValue, this.zoomInYValue),
      this.canvas.getZoom() / this.zoomInFactor
    );

    this.canvas.absolutePan({
      x: 0,
      y: 0,
    } as fabric.Point);
    this.activateZoom = false;
    this.disableZoom = false;
    this.isInZoomMode = false;
    this.canvas.selection = true;
    this.isInEditmode = false;
  }

  public adaptCarSvgViewportToCanvas() {
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    carSideManager.setViewPort(
      this.canvas.vptCoords.tl as fabric.Point,
      this.canvas.vptCoords.br as fabric.Point
    );
  }

  /**
   * onKeyDownEventListener handles
   * - ctrl/meta + z/y for undo/redo
   * - arrow key movement for object
   *   displacement which is timer
   *   based.
   *
   * @private
   * @memberof EditorComponent
   */

  private onKeyDownEventListener = (keyevent: KeyboardEvent) => {
    if (keyevent) {
      if (
        keyevent.ctrlKey &&
        keyevent.code === 'KeyC' &&
        !this.isTopSystemActive
      ) {
        this.canvasHelperService.copy(
          this.canvas,
          this.dataService.getSelectedSide()
        );
        return;
      } else if (
        keyevent.ctrlKey &&
        keyevent.code === 'KeyV' &&
        !this.isTopSystemActive
      ) {
        this.executePasteOperation();
        return;
      }
      // global key events, are also intercepted when not editing
      const activeObject = this.canvas.getActiveObject();
      if (!activeObject) {
        // Reset
        this.allCurrentlyPressedArrowKeys = [];
        clearInterval(this.movingElementTimer);
        this.movingElementTimer = undefined;
      }
      if (keyevent.ctrlKey || keyevent.metaKey) {
        if (keyevent.key.toLowerCase() === 'z') {
          if (!this.isUndoDisabled()) {
            this.stateStorageUndo();
            keyevent.preventDefault();
            return;
          }
        } else if (keyevent.key.toLowerCase() === 'y') {
          if (!this.isRedoDisabled()) {
            this.stateStorageRedo();
            keyevent.preventDefault();
            return;
          }
        }
      } else if (
        !(isCustomText(activeObject) && activeObject.isEditing) &&
        !this.isInputForElementActive
      ) {
        switch (keyevent.key) {
          case 'ArrowUp':
          case 'ArrowDown':
          case 'ArrowRight':
          case 'ArrowLeft': {
            if (this.allCurrentlyPressedArrowKeys.indexOf(keyevent.key) < 0) {
              this.allCurrentlyPressedArrowKeys.push(keyevent.key);
            }

            if (!this.movingElementTimer) {
              this.movingElementTimer = setInterval(
                this.updateLastActive,
                CONSTANTS.MOVING_INTERVAL_MSECONDS
              );
              this.updateLastActive();
            }

            this.disabledEventPropagation(keyevent);
            keyevent.preventDefault();
            break;
          }
        }
      }
    }
  };

  public async executePasteOperation(parameterToSeitenansicht?: Seitenansicht) {
    const seitenansichtTo =
      parameterToSeitenansicht ||
      this.dataService.getSeitenansichtForSelectedSide();

    this.dataService.setSelectedSide(seitenansichtTo.fahrzeugseite);
    this.onSideUpdatedEvent();
    this.updatePriceAndSaveState();
    const pasteSucceeded = await this.canvasHelperService.paste(
      this.canvas,
      seitenansichtTo
    );
    if (pasteSucceeded) {
      // save state on success
      await new Promise<void>(resolveUpdate => {
        setTimeout(() => {
          this.renderEditMode();
          this.selectionService.discardAndStoreSelection(this.canvas);
          this.updatePriceAndSaveState();
          this.selectionService.restoreSelection();
          resolveUpdate();
        });
      });
      if (parameterToSeitenansicht) {
        // copy selection from this car side in case it was triggered with "to" parameter
        await this.canvasHelperService.copy(
          this.canvas,
          seitenansichtTo.fahrzeugseite
        );
      }
    }
  }

  /**
   *
   * Method be called at the end of a timer interval.
   * It passes the  object | group which was last
   * active during the interval to an object updating
   * method.
   *
   * @private
   * @memberof EditorComponent
   */
  private updateLastActive = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.updatePositionBasedOnCursorKeys(activeObject);
      (<any>this.canvas).requestRenderAll();
    }
  };

  /**
   * disables event propagation.
   *
   * @private
   * @param {Event} event
   * @memberof EditorComponent
   */
  private disabledEventPropagation(event: Event) {
    if (event) {
      if (event instanceof KeyboardEvent) {
        if (event.stopPropagation) {
          event.stopPropagation();
        }
      } else if (window.event) {
        window.event.cancelBubble = true;
      }
    }
  }

  private isCopyPasteEvent(keyevent: KeyboardEvent): boolean {
    return (
      (keyevent.ctrlKey && keyevent.code === 'KeyC') ||
      (keyevent.ctrlKey && keyevent.code === 'KeyV')
    );
  }
  /**
   * Handle different keyup events and act according to
   * a given strategy. Events support are:
   * - Delete
   * - Arrows
   * - ctrl left/right
   *
   *
   * @private
   * @memberof EditorComponent
   */
  private onKeyupEventListener = (keyevent: KeyboardEvent) => {
    if (!keyevent || this.isCopyPasteEvent(keyevent)) {
      return;
    }
    const key = keyevent.key;
    const activeObject = this.canvas.getActiveObject();
    const isEditing = activeObject ? (<any>activeObject).isEditing : false;

    // there
    if (
      (isCustomText(activeObject) && activeObject.isEditing) ||
      !activeObject
    ) {
      this.allCurrentlyPressedArrowKeys = [];
    }

    if (!activeObject) {
      return;
    }
    switch (keyevent.code) {
      case 'Delete': {
        // handle text separtely doe to character editing
        if (
          !(isCustomText(activeObject) && activeObject.isEditing) &&
          !this.isInputForElementActive &&
          !(isCustomText(activeObject) && this.dataService.isTopSystemActive)
        ) {
          this.removeObject();
        }
        break;
      }
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowRight':
      case 'ArrowLeft': {
        const index = this.allCurrentlyPressedArrowKeys.indexOf(key);
        if (index > -1) {
          this.allCurrentlyPressedArrowKeys.splice(index, 1);
        }

        if (
          !(isCustomText(activeObject) && activeObject.isEditing) &&
          !this.isInputForElementActive
        ) {
          if (this.allCurrentlyPressedArrowKeys.length <= 0) {
            this.selectionService.discardAndStoreSelection(this.canvas);

            // reset timer used for keyboard events
            if (this.movingElementTimer) {
              clearInterval(this.movingElementTimer);
              this.movingElementTimer = undefined;
            }
            this.updatePriceAndSaveState();
            this.selectionService.restoreSelection();
          }
        }
        break;
      }
      case 'F2': {
        const fontSizeInputElement = this.fontSizeStyleElementRef.nativeElement;
        if (
          isCustomText(activeObject) &&
          !activeObject.isEditing &&
          document.activeElement !== fontSizeInputElement
        ) {
          activeObject.setSelectionStart(0);
          activeObject.setSelectionEnd(activeObject.text.length);
          activeObject.enterEditing();
          activeObject.setCoords();
        }

        break;
      }
      case 'Enter': {
        if (
          isCustomText(activeObject) &&
          !isEditing &&
          !this.isInputForElementActive &&
          !(keyevent.ctrlKey || keyevent.metaKey || keyevent.shiftKey) &&
          !this.isTopSystemActive
        ) {
          const fontSizeInputElement =
            this.fontSizeStyleElementRef.nativeElement;
          if (
            key !== 'ControlLeft' &&
            key !== 'ControlRight' &&
            document.activeElement !== fontSizeInputElement
          ) {
            activeObject.setSelectionStart(activeObject.text.length);
            activeObject.setSelectionEnd(activeObject.text.length);
            activeObject.enterEditing();
            activeObject.setCoords();
          }
        } else if (this.isCroppingActive) {
          this.finishCropper();
        }
      }
    }
  };

  private finishCropper(customimage?: CustomImage) {
    // apply a confidence interval
    this.imageWasCropped = true;
    this.isCroppingActive = false;
    // insert the new image if something was cropped
    this.insertImageIfCropped(customimage);
  }

  /**
   * Hook for AfterViewChecked
   *
   * @memberof EditorComponent
   */
  ngAfterViewChecked() {
    // this.logger.debug('afterViewChecked')
  }

  /**
   * A icons to trusted resources so we can use them
   *
   * @private
   * @memberof EditorComponent
   */

  private addIconsToRegistry = () => {
    this.matIconRegistry
      .addSvgIcon(
        'template-background',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/auto.bg.color-36px.svg'
        )
      )
      .addSvgIcon(
        'move-back',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/layer-down-36px.svg'
        )
      )
      .addSvgIcon(
        'move-front',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/layer-up-36px.svg'
        )
      )
      .addSvgIcon(
        'text-box',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/textbox-36px.svg'
        )
      )
      .addSvgIcon(
        'image-box',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/picupload-36px.svg'
        )
      )
      .addSvgIcon(
        'text-color',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/colorpicker-36px.svg'
        )
      )
      .addSvgIcon(
        'shapes-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-formen.svg'
        )
      )
      .addSvgIcon(
        'shape-rect-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-rechteck.svg'
        )
      )
      .addSvgIcon(
        'shape-circle-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-kreis.svg'
        )
      )
      .addSvgIcon(
        'shape-triangle-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-dreieck.svg'
        )
      )
      .addSvgIcon(
        'icon-underline',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-24px/format.underline-24px.svg'
        )
      )
      .addSvgIcon(
        'icon-italic',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-24px/format.italic-24px.svg'
        )
      )
      .addSvgIcon(
        'icon-bold',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-24px/format.bold-24px.svg'
        )
      )
      .addSvgIcon(
        'icon-alignRight',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-24px/align.right-24px.svg'
        )
      )
      .addSvgIcon(
        'icon-alignLeft',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-24px/align.left-24px.svg'
        )
      )
      .addSvgIcon(
        'zoom-in',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-zoom-plus.svg'
        )
      )
      .addSvgIcon(
        'zoom-out',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-zoom-minus.svg'
        )
      )
      .addSvgIcon(
        'icon-alignCenter',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-24px/align.center-24px.svg'
        )
      )
      .addSvgIcon(
        'icon-spacing',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-text-laufweite.svg'
        )
      )
      .addSvgIcon(
        'redo-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/redo-36px.svg'
        )
      )
      .addSvgIcon(
        'undo-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/undo-36px.svg'
        )
      )
      .addSvgIcon(
        'trash-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/trash-36px.svg'
        )
      )
      .addSvgIcon(
        'object-align',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-align-36px.svg'
        )
      )
      .addSvgIcon(
        'object-align-bottom',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-bottomalign-36px.svg'
        )
      )
      .addSvgIcon(
        'object-dist-horizontal',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-dist-horizontally-36px.svg'
        )
      )
      .addSvgIcon(
        'object-dist-vertical',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-dist-vertically-36px.svg'
        )
      )
      .addSvgIcon(
        'object-align-horizontal',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-horizontalalign-36px.svg'
        )
      )
      .addSvgIcon(
        'object-align-left',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-leftalign-36px.svg'
        )
      )
      .addSvgIcon(
        'object-align-right',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-rightalign-36px.svg'
        )
      )
      .addSvgIcon(
        'object-align-top',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-topalign-36px.svg'
        )
      )
      .addSvgIcon(
        'object-align-vertical',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/object-verticalalign-36px.svg'
        )
      )
      .addSvgIcon(
        'copy-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-duplizieren.svg'
        )
      )
      .addSvgIcon(
        'crop-image',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-crop.svg'
        )
      )
      .addSvgIcon(
        'mirror-image',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-spiegeln.svg'
        )
      )

      .addSvgIcon(
        'icon-rotation',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-rotate.svg'
        )
      )
      .addSvgIcon(
        'image-car-heck',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-heck.svg'
        )
      )
      .addSvgIcon(
        'image-car-front',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-front.svg'
        )
      )
      .addSvgIcon(
        'image-car-beifahrer',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-beifahrerseite.svg'
        )
      )
      .addSvgIcon(
        'image-car-fahrer',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-fahrerseite.svg'
        )
      );
  };

  public async copySelectionToFahrzeugseite(to: string) {
    const selectedFahrzeugseite = this.dataService.getSelectedSide();
    const fahrzeugseite =
      typeof Fahrzeugseite[to] === 'number'
        ? Fahrzeugseite[to]
        : selectedFahrzeugseite;
    await this.canvasHelperService.copy(this.canvas, selectedFahrzeugseite);
    const seitenansicht: Seitenansicht = [
      this.dataService.druckauftrag.links,
      this.dataService.druckauftrag.rechts,
      this.dataService.druckauftrag.front,
      this.dataService.druckauftrag.heck,
    ]
      .filter(a => {
        return a.fahrzeugseite === fahrzeugseite;
      })
      .pop();
    await this.executePasteOperation(seitenansicht);
    this.isShowCopyMenu = false;
  }

  public triggerStrokeColorChange() {
    if ((<any>this.canvas).getActiveObjects().length <= 0) {
      return;
    }
    const activeObject = (<any>this.canvas).getActiveObject();
    this.borderColorPicker.openView(activeObject.stroke);
    this.borderColorPicker.setVisible(true);
    this.textColorPicker.setVisible(false);
  }

  public triggerStrokeWidthChange(value: number) {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    if (
      activeObject.type === 'rect' ||
      activeObject.type === 'triangle' ||
      activeObject.type === 'customTriangle' ||
      activeObject.type === 'circle'
    ) {
      if (activeObject.strokeWidth !== value) {
        this.basicShapeEditor.adaptElementStrokeWidth(activeObject, value);
        // this.canvas.requestRenderAll();
        this.selectionService.discardAndStoreSelection(this.canvas);
        this.updatePriceAndSaveState();
        this.selectionService.restoreSelection();
      }
    }
  }

  public triggerElementWidthUpdate(value: number) {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    // set current values for scaling
    this.canvasHelperService.setPreviousScaling(
      activeObject.scaleX,
      activeObject.scaleY
    );
    const isChange = this.canvasHelperService.calculateWidthScalingForObject(
      activeObject,
      value
    );
    if (isChange) {
      if (isCustomImage(activeObject)) {
        this.canvasHelperService.adaptImageOnScale(
          activeObject,
          this.isTopSystemActive
        );
      }
      this.onCanvasObjectModified({
        target: activeObject,
      });
    }
  }

  public triggerElementHeightUpdate(value: number) {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    // set current values for scaling
    this.canvasHelperService.setPreviousScaling(
      activeObject.scaleX,
      activeObject.scaleY
    );
    const isChange = this.canvasHelperService.calculateHeightScalingForObject(
      activeObject,
      value
    );
    if (isChange) {
      if (isCustomImage(activeObject)) {
        this.canvasHelperService.adaptImageOnScale(
          activeObject,
          this.isTopSystemActive
        );
      }
      this.onCanvasObjectModified({
        target: activeObject,
      });
    }
  }

  public triggerElementRotationUpdate(value: number) {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    if (activeObject.angle !== value) {
      activeObject.angle = value;
      activeObject.render(this.context);
      activeObject.setCoords();

      this.renderEditMode();
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
    }
  }

  /**
   * Trigger CarColor Change and Retrieve Previously selected Color.
   */
  public triggerCarColorChange = (x?: any) => {
    this.carColor = this.dataService.druckauftrag.carColor;

    this.carColorPicker.openView(this.carColor);
    this.carColorPicker.setVisible(true)
  }

  /**
   * Trigger UI-color picker for a CustomText or Shape
   * and retrieve the previously selected color
   *
   * @memberof EditorComponent
   */
  public triggerTextColorChange = (x?: any) => {
    if ((<any>this.canvas).getActiveObjects().length <= 0) {
      return;
    }
    const activeObject = (<any>this.canvas).getActiveObjects()[0];

    this.textColor = this.getCurrentFillForTextElement(activeObject) as string;

    this.textColorPicker.openView(this.textColor);
    this.textColorPicker.setVisible(true);
    this.borderColorPicker.setVisible(false);
  };

  onCustomTextColorChange() {
    if ((<any>this.canvas).getActiveObjects().length <= 0) {
      return;
    }
    
    const activeObject = (<any>this.canvas).getActiveObjects()[0];
    this.canvasHelperService.changeTextColorOfObject(activeObject, this.carColorCode)
    this.carColorCode = '';
    this.isCustomColor = false;
  }

  private getCurrentFillForTextElement(
    currentObject: fabric.Object
  ): string | fabric.Pattern | fabric.Gradient {
    const color = currentObject.fill;
    if (isCustomText(currentObject)) {
      // after that if a substring of a text object is selected
      // we deal with it style properties
      const activeIText = <fabric.IText>currentObject;
      if (activeIText.isEditing) {
        // more than one characters are selected
        if (activeIText.selectionStart !== activeIText.selectionEnd) {
          return this.getColorOfSelectedText(activeIText);
          // just one character is selected
        } else if (activeIText.selectionStart === activeIText.selectionEnd) {
          return activeIText.getSelectionStyles(
            activeIText.selectionStart - 1,
            activeIText.selectionEnd
          )[0]['fill'];
        }
      } else {
        activeIText.selectionStart = 0;
        activeIText.selectionEnd = activeIText.text.length;
        return this.getColorOfSelectedText(activeIText) || color;
      }
    }
    return color;
  }

  /**
   * Get color of selected text, being
   * the color with the highest occurence
   * among all characters in the string.
   *
   * @private
   * @memberof EditorComponent
   */
  private getColorOfSelectedText = (object: fabric.IText): string => {
    let selectedColor = '';

    const styleObjects = object.getSelectionStyles(
      object.selectionStart,
      object.selectionEnd
    );
    // get occurence number for any given 'fill'
    const fillCountArray = styleObjects
      .filter(obj => {
        return obj !== undefined;
      })
      .map(
        current =>
          styleObjects.filter(other => current['fill'] === other['fill']).length
      );
    // get 'fill' with highest occurence
    selectedColor =
      styleObjects[
        fillCountArray.indexOf(Math.max.apply(null, fillCountArray))
      ]['fill'];

    return selectedColor;
  };

  /**
   * changes the Bordercolor of the active canvas object on colorChangedEvent Emiiter.
   */
  public changeBorderColor(newColor: string) {
    const activeObject = (<any>this.canvas).getActiveObject();
    const color = this.borderColorPicker.getRgbColor();
    this.borderColorPicker.setVisible(false);
    if (!newColor && activeObject) {
      this.basicShapeEditor.adaptElementStrokeWidth(activeObject, 0);
      activeObject.stroke = undefined;
      activeObject.dirty = true;
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
      return;
    }

    if (activeObject) {
      activeObject.stroke = color;
      activeObject.dirty = true;
      if (activeObject.strokeWidth === 0) {
        this.basicShapeEditor.adaptElementStrokeWidth(activeObject, 1);
      }
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
    }
  }

  /**
   * changes the textcolor of the active canvas object on colorChangedEvent Emiiter.
   */
  public changeTextColor(newColor: string) {
    let color = undefined;
    color = this.textColorPicker.getRgbColor();
    const activeObject = (<any>this.canvas).getActiveObjects();
    if (activeObject) {
      activeObject.forEach(obj => {
        let fill = '#000000';
        if (isCustomText(obj)) {
          if (!obj.isEditing) {
            obj.selectionStart = 0;
          }
          fill = this.getStyle(obj, 'fill');
        }
        color = color || fill || obj.fill;
      });
    }
    this.textColor = color;

    this.textColorPicker.setRgbColor(this.textColor);
    // after we chose the color
    const change = this.onChangeTextColor();
    this.textColorPicker.setVisible(false);
    if (change) {
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
    }
  }

  public changeCarColor(newColor: string){
    let color = undefined;
    color = this.carColorPicker.getRgbColor();
    this.carColor = color
    this.carColorPicker.setRgbColor(this.carColor);
    this.onChangeCarColor();
    this.carColorPicker.setVisible(false);
  }
  /**
   * returns if a group is selected
   *
   * @returns {boolean}
   * @memberof EditorComponent
   */
  public isActiveGroup(): boolean {
    if (this.canvas) {
      return (
        ((<any>this.canvas).getActiveObject() &&
          (<any>this.canvas).getActiveObject().type === 'activeSelection') ||
        false
      );
    }
    return false;
  }

  /**
   * This methods is used for initialization purposes by
   * reporting back to performInitializationIfAllSvgsAreLoaded
   * when the carS svg was fully loaded.
   *
   * @memberof EditorComponent
   */
  public onLoadCar = () => {
    this.carSvgLoaded = true;
    this.performInitializationIfAllSvgsAreLoaded();
  };

  private performInitializationIfAllSvgsAreLoaded = () => {
    if (
      !this.carSvgLoaded ||
      !this.canvas ||
      !this.dataService.druckauftragAndTemplateInitialized ||
      !this.allEditAreaLoaded
    ) {
      this.logger
        .warn(`performInitializationIfAllSvgsAreLoaded: not all are true
      car ${this.carSvgLoaded}
      canvas ${this.canvas}
      templateData ${this.allTemplateDataLoaded}
      this.dataservice.druckauftragAndTemplateInitialized  ${this.dataService.druckauftragAndTemplateInitialized}
      allEditAreaLoaded:${this.allEditAreaLoaded}`);
      return;
    }

    if (!this.canvas) {
      throw new Error('no canvas available');
    }
    this.carColor = this.dataService.druckauftrag.carColor;

    this.carDataManagerService.setBackgroundColor(this.carColor);

    // read template data from template if it is not defined
    if (
      this.dataService.druckauftrag.templateColor &&
      this.dataService.druckauftrag.templateColor !== '#000000'
    ) {
      this.templateColor = this.dataService.druckauftrag.templateColor;
    }

    // new Car image ->clear canvas
    if (this.canvas) {
      this.canvas.clear();
      this.adaptOnResize();

      this.addEventlistenerIfNotdefinedYet(
        'selection:created',
        this.onCanvasSelectionCreated
      );
      this.addEventlistenerIfNotdefinedYet(
        'selection:cleared',
        this.resetWarningSelection
      );
      this.addEventlistenerIfNotdefinedYet(
        'selection:cleared',
        this.cropResetListener
      );
      this.addEventlistenerIfNotdefinedYet(
        'selection:updated',
        this.resetWarningSelectionAndUpdateWarning
      );

      this.addEventlistenerIfNotdefinedYet('mouse:down', this.canvasMouseDown);
      this.addEventlistenerIfNotdefinedYet('mouse:up', this.canvasMouseUp);
      this.addEventlistenerIfNotdefinedYet('mouse:move', this.canvasMouseMove);

      this.addEventlistenerIfNotdefinedYet('after:render', this.onAfterRender);

      this.canvas._objects.forEach(o => {
        if (isCustomImage(o)) {
          o.render(this.context);
        }
      });
    } else {
      throw new Error('canvas not ready');
    }

    const side = this.dataService.getSeitenansichtForSelectedSide();
    if (side && side.allDataElement) {
      this.canvasHelperService.drawAllDataOnCanvas(
        this.canvas,
        side.allDataElement,
        true
      );
    } else {
      throw new Error('data not yet available');
    }

    this.fullyInitializedEmitter.emit();
    this.adaptOnResize();
  };

  private readonly addEventlistenerIfNotdefinedYet = (
    eventname: string,
    funct: any
  ) => {
    if (
      !(this.canvas as any).__eventListeners[eventname] ||
      (this.canvas as any).__eventListeners[eventname].indexOf(funct) < 0
    ) {
      this.canvas.on(eventname, funct);
    }
  };

  private readonly resetWarningSelection = e => {
    this.showWarningIsInActiveMode = true;
  };

  private readonly cropResetListener = e => {
    if (this.isCroppingActive) {
      const deselectedObject = e.deselected[0];
      if (isCustomImage(deselectedObject)) {
        this.finishCropper(deselectedObject);
      }
    }
  };

  private readonly resetWarningSelectionAndUpdateWarning = e => {
    this.resetWarningSelection(e);
    this.drawAllWarningsOnCanvas();
  };
  private onCanvasSelectionCreated = (e: fabric.IEvent) => {
    this.resetWarningSelection(e);

    const activeObject = this.canvas.getActiveObject();
    if (isCustomText(activeObject)) {
      this.activeObjectTextContent = activeObject.text;
    }

    if (this.textColorPicker.isVisible) {
      this.textColorPicker.setVisible(false);
    }
    this.setEditMode(true);
  };

  private readonly onAfterRender = () => {
    if (!this.canvas.getActiveObject() && !this.isTopSystemActive) {
      this.canvasHelperService.outlineObjects(
        this.context || this.canvas.getContext(),
        this.canvas.getObjects(),
        this.dataService.druckauftrag.carColor
      );
    }
  };

  /**
   * Changes the color of the car background.
   *
   * @memberof EditorComponent
   */
  public onChangeCarColor = () => {
    this.carDataManagerService.setBackgroundColor(this.carColor);
    this.dataService.druckauftrag.carColor = this.carColor
  };

  /**
   * Helpermethod to retrieve document element of elementref (browserindependent)
   *
   * @memberof EditorComponent
   */
  public getContentDocument = (elementRef: ElementRef) => {
    if (!elementRef) {
      return undefined;
    }
    let element =
      elementRef.nativeElement.contentWindow ||
      elementRef.nativeElement.contentDocument;
    if (!element) {
      return null;
    }
    if (element.document) {
      element = element.document;
    }
    return element;
  };

  /**
   * mirror JPG Files
   */
  public mirrorObject = async () => {
    const activeObject: CustomImage = (<any>this.canvas).getActiveObject();
    this.setLoadingCycle();
    try {
      const mirrorImage = await this.dataService.mirrorImage(activeObject);
      if (mirrorImage.cropInfo) {
        activeObject.cropperPositionPercent = mirrorImage.cropInfo;
      }
      activeObject.nativeSource = mirrorImage.nativeSrc;
      activeObject.originalDisplayImage = mirrorImage.src;

      await EditorComponent.waitForSettingImageSrc(
        activeObject,
        mirrorImage.croppedDisplayImage
      );
      activeObject.setCoords();
      this.canvas.renderAll();
      this.onCanvasObjectModified({ target: activeObject });
    } catch (e) {
      this.setLoadingCycle(false);
      throw e;
    } finally {
      this.setLoadingCycle(false);
    }
  };
  private static waitForSettingImageSrc(
    target: CustomImage,
    display: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      target.setSrc(display, () => resolve());
    });
  }
  private setLoadingCycle(status: boolean = true) {
    this.carEditorFileUploadOverlayRef.nativeElement.style.display = status
      ? 'block'
      : 'none';
  }

  private addLoadedImageToCanvas = (
    imgObj: HTMLImageElement,
    file: File,
    originalFileSize: { width: number; height: number },
    currentSide: Seitenansicht
  ) => {
    const imageInfo =
      file.name +
      ' ' +
      imgObj.width +
      '×' +
      imgObj.height +
      ' ' +
      (<any>imgObj).type +
      ' ' +
      Math.round(file.size / 1024) +
      'KB';

    this.logger.debug('Image was uploaded: \n' + imageInfo);
    // add missing attributes for extraction in CustomImage constructor
    const image: CustomImage = new this.fabric.CustomImage(imgObj);
    image.filename = file.name
      .replace(/&/g, '_and_')
      .replace(/'/g, '_apostrophe_')
      .replace(/"/g, '_quote_')
      .replace(/</g, '_lessthan_')
      .replace(/>/g, '_greaterthan_');
    image.kbSize = Math.round(file.size / 1024);
    image.size = file.size;
    image.originalWidth = originalFileSize.width;
    image.originalHeight = originalFileSize.height;

    image.id = uuidv4();
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    this.canvasHelperService.adjustImageOnOversize(image, carSideManager);
    this.canvasHelperService.addElementToCanvas(
      carSideManager,
      this.canvas,
      image,
      currentSide,
      true
    );
    this.dimensionsHelper.performQualityCheck(image);
    // scale to 72 DPI if quality is bad
    if (image.badQuality) {
      this.dimensionsHelper.scaleForQuality(image);
    }
    this.dimensionsHelper.performQualityCheck(image);
    this.adaptOnResize();
    this.canvas.renderAll();
    return image;
  };

  /**
   * adapt the canvas and elements in it on resize, zoom, layout..
   *
   * @private
   * @memberof EditorComponent
   */
  private adaptOnResize = (): boolean => {
    if (!this.carSvg || !this.canvas || this.isInZoomMode) {
      return false;
    }
    if (!this.context) {
      this.context = this.canvas.getContext();
    }

    // init and resize canvas;
    this.adaptCanvasSize();
    // we need to reposition once more in order so that everything lines up perfectly
    // (should be investigated why this needs to be)

    this.canvas.zoomToPoint(
      new this.fabric.Point(0, 0),
      this.carSvg.getScaleToCanvasFactor()
    );

    this.renderEditMode();
    return true;
  };

  /**
   * renders the cliping in case the editmode is disalbed
   *
   * @private
   * @memberof EditorComponent
   */
  private renderEditMode = () => {
    if (this.isMouseOnCanvasDown && this.isInZoomMode) {
      return;
    }
    // save all objects on cavas !! Copy arrayreference!!
    if (!this.isInEditmode) {
      if (this.textColorPicker) {
        this.textColorPicker.setVisible(false);
      }

      if (
        !this.dataService.getSeitenansichtForSelectedSide() ||
        !this.dataService.getSeitenansichtForSelectedSide().allEditierbereich
      ) {
        return;
      }

      const currentside = this.carDataManagerService.getCarSideManager(
        this.dataService.getSelectedSide()
      );
      if (currentside) {
        currentside.hideEditInvert();
      }
      this.canvas.clipPath = this.renderEditModeService.getClipToPath(
        this.dataService.getSeitenansichtForSelectedSide()
      );

      this.warningManagerService.updateIntersections(
        this.dataService.getSeitenansichtForSelectedSide()
      );
    } else {
      this.canvas.clipPath = null;
      this.carDataManagerService
        .getCarSideManager(this.dataService.getSelectedSide())
        .showEditInvert();
    }

    this.drawAllWarningsOnCanvas();
  };

  private drawAllWarningsOnCanvas() {
    const allObject: fabric.Object[] = this.canvas.getObjects().slice(0);
    const allObjectExceptSelected = allObject.filter(o => {
      return (
        o !== this.canvas.getActiveObject() &&
        (!this.isActiveGroup ||
          ((<any>this.canvas).getActiveObjects() as fabric.Object[]).indexOf(
            o
          ) < 0)
      );
    });
    const allUngroupedCoordsArray = allObjectExceptSelected.map(o => o.aCoords);
    const seitenansicht = this.dataService.getSeitenansichtForSelectedSide();

    if (this.drawAllWarningSubject && this.context) {
      this.drawAllWarningSubject.next({
        warningManagerService: this.warningManagerService,
        context: this.context,
        isInZoomMode: this.isInZoomMode,
        seitenansicht,
        allObjectExceptSelected,
        allUngroupedCoordsArray,
        isInEditmode: this.isInEditmode,
        isTopSystemActive: this.isTopSystemActive,
        zoomFactor: this.canvas.getZoom(),
        scaleToCanvasFactor: this.carSvg.getScaleToCanvasFactor(),
      });
    }
  }

  /**
   * Initializes the canvas, sets the height and width of the canvas according to the surrounding svg
   *
   * @private
   * @memberof EditorComponent
   */
  private adaptCanvasSize = (): void => {
    this.canvas.setWidth(this.carSvg.getScaledWidth());
    this.canvas.setHeight(this.carSvg.getScaledHeight());
  };

  /**
   * adapts height and width for object elements and containing svgs
   * @unused
   * @deprecated
   * @param elem
   * @param width
   * @param height
   */
  private setHeightAndWidthOfObjectSvg(
    elem: ElementRef,
    width: number,
    height: number
  ): void {
    const allElement =
      this.getContentDocument(elem).getElementsByTagName('svg');
    if (allElement.length > 0) {
      allElement[0].setAttribute('width', width + 'px');
      allElement[0].setAttribute('height', height + 'px');
    }
    elem.nativeElement.style.width = width + 'px';
    elem.nativeElement.style.height = height + 'px';
  }

  /**
   * @unused
   * @deprecated
   */
  private getFillStypeOfSvgElement = (elementRef: ElementRef): string => {
    const element = this.getContentDocument(elementRef);
    if (!element) {
      return undefined;
    }
    const styleElement = element.getElementsByTagName('style')[0];
    if (styleElement) {
      const htmlString = styleElement.innerHTML;
      const arr: string[] = htmlString.split('fill:', 2);
      if (arr.length > 1) {
        // contains either ; or } ...
        let arr2 = arr[1].split(';', 2);
        if (arr2.length > 1) {
          return arr2[0];
        }
        arr2 = arr[1].split('}', 2);
        if (arr2.length > 1) {
          return arr2[0];
        }
        return undefined;
      }
    }
    return undefined;
  };

  public showObjectControls = (): boolean => {
    if (!this.canvas) {
      return false;
    }
    const activeObject: fabric.Object = this.canvas.getActiveObject();
    if (!activeObject) {
      return false;
    }
    return true;
  };
  /**
   * evaluates whether  the controls for text elements should be displayed or not
   */
  public showTextControls = (): boolean => {
    if (!this.isTextSelected) {
      return false;
    }
    this.synchronizeTextControls(this.canvas.getActiveObject() as CustomText);
    return true;
  };

  // synchronizes the textcontrols in case a textobject is selected
  private synchronizeTextControls = (txtObj: CustomText) => {
    this.synchronizeFontFamilyAndSizeControl(txtObj);

    this.selectionIsUnderline =
      this.getStyle(txtObj, 'underline') !== undefined ||
      (this.getStyle(txtObj, 0) && this.getStyle(txtObj, 0).underline)
        ? true
        : this.getFirstStyle(txtObj, 'underline');

    this.textAlign = txtObj.textAlign ? txtObj.textAlign : undefined;
    if (!this.textAlign) {
      this.textAlign = 'left';
    }

    this.letterSpacing = (<any>txtObj).charSpacing;
    this.lineHeight = txtObj.lineHeight;
  };

  private getFirstStyle(textObj: CustomText, attribute: string) {
    if (
      !textObj ||
      !textObj.styles ||
      !textObj.styles[0] ||
      !textObj.styles[0][0]
    ) {
      return false;
    }
    return textObj.styles[0][0][attribute];
  }

  private synchronizeFontFamilyAndSizeControl = (txtObj: CustomText) => {
    // handle outer object (not in editing mode but selected )
    if (!txtObj.isEditing) {
      if (txtObj.getSelectionStyles(0, 1).length > 0) {
        console.log(txtObj.fontFamily, 'Sync')
        this.fontFamily =
          txtObj.getSelectionStyles(0, 1)[0].fontFamily || txtObj.fontFamily;
        this.fontSize =
          txtObj.getSelectionStyles(0, 1)[0].fontSize || txtObj.fontSize;
      } else {
        this.fontFamily = txtObj.fontFamily;
        this.fontSize = txtObj.fontSize;
      }
    } else {
      let end = txtObj.selectionEnd;
      // handle no selection
      if (txtObj.selectionStart === txtObj.selectionEnd) {
        end += 1;
      }
      // handle normal selection
      if (txtObj.getSelectionStyles(txtObj.selectionStart, end).length > 0) {
        // handle cursor behind last char
        if (txtObj.selectionStart !== txtObj.text.length) {
          const fontFamily = txtObj.getSelectionStyles(
            txtObj.selectionStart,
            end
          )[0].fontFamily;

          this.fontFamily =
            fontFamily ||
            txtObj.getSelectionStyles(0, 1)[0].fontFamily ||
            txtObj.fontFamily ||
            this.fontFamily;
          const fontSize = txtObj.getSelectionStyles(
            txtObj.selectionStart,
            end
          )[0].fontSize;
          this.fontSize = fontSize || this.fontSize;
          // const fill = txtObj.getSelectionStyles(txtObj.selectionStart, end)[0].fill;
        } else {
          if (txtObj.selectionStart > 0 && txtObj.selectionEnd > 0) {
            const fontFamily = txtObj.getSelectionStyles(
              txtObj.selectionStart - 1,
              end - 1
            )[0].fontFamily;
            this.fontFamily = fontFamily || this.fontFamily;
            const fontSize = txtObj.getSelectionStyles(
              txtObj.selectionStart - 1,
              end - 1
            )[0].fontSize;
            this.fontSize = fontSize || this.fontSize;
            // const fill = txtObj.getSelectionStyles(txtObj.selectionStart - 1,end - 1)[0].fill;
          }
        }
      }
    }
  };
  /**
   * evaluates if the current active object is a shape (rectangle, triangle, customTriangle, circle)
   */
  public activeObjectIsAShape = (): boolean => {
    if (!this.canvas || !this.canvas.getActiveObject()) {
      return false;
    }
    return (
      this.canvas.getActiveObject().type === CONSTANTS.RECT_TYPE_STRING ||
      this.canvas.getActiveObject().type === CONSTANTS.TRIANGLE_TYPE_STRING ||
      this.canvas.getActiveObject().type === CONSTANTS.CIRCLE_TYPE_STRING
    );
  };

  /**
   * removes the selected object from the canvas
   */
  public removeObject = (): void => {
    const activeSelection: any = (<any>this.canvas).getActiveObjects();
    if (activeSelection.length > 0) {
      const allObject: fabric.Object[] = activeSelection;
      this.discardSelection();

      allObject.forEach((element: fabric.Object) => {
        this.canvas.remove(element);
        // remove object from side
        this.dataService
          .getSeitenansichtForSelectedSide()
          .allDataElement.splice(
            this.dataService
              .getSeitenansichtForSelectedSide()
              .allDataElement.indexOf(element),
            1
          );
      });
    }
    this.canvas.renderAll();
    // save state when object was removed
    this.updatePriceAndSaveState();
  };
  /**
   * updates the Text color
   */
  public onChangeTextColor = (): boolean => {
    const obj: fabric.Object = this.canvas.getActiveObject();
    const allObject: fabric.Object[] = this.canvas.getActiveObjects();
    let something_was_changed = false;
    if (isCustomText(obj)) {
      something_was_changed =
        something_was_changed ||
        this.canvasHelperService.changeTextColorOfObject(obj, this.textColor);
      //  obj.isEditing = false;
      obj.setCoords();
    } else if (obj.type === CONSTANTS.ACTIVE_SELECTION_TYPE_STRING) {
      allObject.forEach((groupedObject: fabric.Object) => {
        const changeResult = this.canvasHelperService.changeTextColorOfObject(
          groupedObject,
          this.textColor
        );
        something_was_changed = something_was_changed || changeResult;
        groupedObject.setCoords();
      });
    } else {
      // rect circle and triangle /customTriangle
      const changeResult = this.canvasHelperService.changeTextColorOfObject(
        obj,
        this.textColor
      );
      obj.setCoords();
      // circle triangle and rect need to be set "dirty" otherwise their fill style is not correctly updated
      (<any>obj).dirty = true;
      something_was_changed = something_was_changed || changeResult;
    }

    (<any>this.canvas).requestRenderAll();
    return something_was_changed;
  };

  /**
   * updates the letterspacing for a ITextelement
   */
  public onChangeLetterSpacing = (): void => {
    const newValue = this.letterSpacingElementRef.nativeElement.valueAsNumber;
    const obj: fabric.Object = this.canvas.getActiveObject();
    if (isCustomText(obj)) {
      (<any>obj).charSpacing = newValue;
      this.letterSpacing = newValue;
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
    }
  };

  /**
   * changes the fontfamily
   * does not save the state incase "notSaveState" is true
   */
  public onChangeFontFamily = (): void => {
    const txtObj: fabric.Object = this.canvas.getActiveObject();
    // adapt position for menu to  top
    if (!isCustomText(txtObj)) {
      return;
    }
    if (!txtObj.isEditing) {
      txtObj.fontFamily = this.fontFamily;
      // also change font of sub chars ...
      txtObj.selectionStart = 0;
      txtObj.selectionEnd = txtObj.text.length;
      this.canvasHelperService.adaptTextStyleofCharacters(
        txtObj,
        'fontFamily',
        this.fontFamily
      );
      txtObj.setCoords();
    } else {
      if (txtObj.selectionStart === 0) {
        txtObj.fontFamily = this.fontFamily;
      }
      this.canvasHelperService.adaptTextStyleofCharacters(
        txtObj,
        'fontFamily',
        this.fontFamily
      );
      txtObj.setCoords();
    }
    // render is necessary to detect changes in price (font render) before calculating price
    this.canvas.renderAll();
    this.selectionService.discardAndStoreSelection(this.canvas);
    this.updatePriceAndSaveState();
    this.selectionService.restoreSelection();
  };

  /**
   * Changes the Text Alignment.
   */
  public onChangeTextAlign = (): void => {
    const obj: fabric.Object = this.canvas.getActiveObject();
    if (isCustomText(obj)) {
      obj.textAlign = this.textAlign;
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
    }
  };

  /**
   * Updates the Font Size.
   * @param e Input From DomElement
   */
  public onChangeFontSize = (e: InputEvent): void => {
    const newValue = (e.target as HTMLInputElement).value;
    let fontSize = isFinite(parseFloat(newValue)) ? parseFloat(newValue) : 0;
    if (fontSize < 4) {
      fontSize = 4;
    }
    if (this.isTopSystemActive) {
      return;
    }

    const obj: fabric.Object = this.canvas.getActiveObject();

    if (isCustomText(obj)) {
      if (!obj.selectionStart && !obj.selectionEnd) {
        obj.fontSize = fontSize;
      } else {
        this.canvasHelperService.adaptTextStyleofCharacters(
          obj,
          'fontSize',
          fontSize
        );
      }
      obj.render(this.context);
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
    }
  };

  /**
   * Changes LineHeight of CustomText
   */
  public onChangeLineHeight = (): void => {
    const obj: fabric.Object = this.canvas.getActiveObject();
    if (isCustomText(obj)) {
      obj.lineHeight = this.lineHeight;
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
    }
  };

  /**
   * updatesprice and Saves State
   */
  public updatePriceAndSaveState() {
    // handle selection
    this.canvas.discardActiveObject();

    this.priceCalculatorService.updateDruckauftragPrice(
      this.dataService.druckauftrag,
      this.dataService.countryHourRate,
      this.dataService.currencyFactor,
      this.dataService.userType,
      this.dataService.country
    );
    if (!this.canvas.getActiveObject()) {
      this.isInEditmode = false;
    }
    this.stateStorageService.saveState();
    this.renderEditMode();
  }

  /**
   * underlines selected Text Element
   */
  public changeTextUnderline = (): void => {
    const obj: fabric.Object = this.canvas.getActiveObject();
    if (isCustomText(obj)) {
      const isUnderline =
        this.getStyle(obj, 'underline') !== undefined ||
        (this.getStyle(obj, 0) && this.getStyle(obj, 0).underline)
          ? true
          : this.getFirstStyle(obj, 'underline');

      if (!obj.isEditing) {
        const start = obj.selectionStart;
        const end = obj.selectionEnd;
        obj.selectionStart = 0;
        obj.selectionEnd = obj.text.length;
        this.canvasHelperService.adaptTextStyleofCharacters(
          obj,
          'underline',
          !isUnderline
        );
        obj.selectionStart = start;
        obj.selectionEnd = end;
      } else {
        this.canvasHelperService.adaptTextStyleofCharacters(
          obj,
          'underline',
          !isUnderline
        );
      }
      this.selectionService.discardAndStoreSelection(this.canvas);
      this.updatePriceAndSaveState();
      this.selectionService.restoreSelection();
    }
  };
  /**
   * Makes selected Text-Element italic
   */
  public changeTextItalic = (): void => {
    const font = this.fontDataService.getFontFamilyToggleItalic(
      this.fontFamily,
      this.isItalicEnabled()
    );
    if (font && font !== this.fontFamily) {
      this.fontFamily = font;
      this.onChangeFontFamily();
    }
  };
  /**
   * Makes selected Text-Element bold
   */
  public changeTextBold = (): void => {
    const font = this.fontDataService.getFontFamilyToggleBold(
      this.fontFamily,
      this.isBoldEnabled()
    );
    if (font && font !== this.fontFamily) {
      this.fontFamily = font;
      this.onChangeFontFamily();
    }
  };

  /**
   * Return the style of the object or selected Text
   */
  private getStyle = (object: fabric.IText, styleName) => {
    let selectionStart = object.selectionStart;
    let selectionEnd = object.selectionEnd;
    if (
      object.selectionStart === object.selectionEnd &&
      object.selectionEnd > object.text.length
    ) {
      selectionEnd += 1;
    } else {
      selectionStart -= 1;
    }
    return (<any>object).getSelectionStyles(selectionStart, selectionEnd, true)[
      styleName
    ];
  };

  /**
   * moves the selected object one Layer up
   */
  public sendObjectForeward = (): void => {
    const obj: fabric.Object = this.canvas.getActiveObject();
    obj.bringForward(true);
    // update side data (since z-index psotion is saved via position in objects array )
    this.takeCanvasSnapshotsAndUpdateSeitenansichtArray(
      this.dataService.getSeitenansichtForSelectedSide()
    );
  };
  /**
   * moves the selected object one Layer down
   */
  public sendObjectBackward = (): void => {
    const obj: fabric.Object = this.canvas.getActiveObject();
    obj.sendBackwards(true);
    // update side data (since z-index position is saved via position in objects array )
    this.takeCanvasSnapshotsAndUpdateSeitenansichtArray(
      this.dataService.getSeitenansichtForSelectedSide()
    );
  };

  private async takeCanvasSnapshotsAndUpdateSeitenansichtArray(
    seitenansicht: Seitenansicht
  ) {
    seitenansicht.allDataElement.forEach((o, i) => {
      this.logger.debug(`${i} : ${isCustomText(o) ? o.text : 'image'}`);
    });
    await this.dataService.cloneAllDataElementArrayForSeitenansicht(
      seitenansicht,
      this.canvas.getObjects()
    );

    this.canvas.renderAll();
    seitenansicht.allDataElement.forEach((o, i) => {
      this.logger.debug(`${i} : ${isCustomText(o) ? o.text : 'image'}`);
    });
    this.dataService
      .getSeitenansichtForSelectedSide()
      .allDataElement.forEach((o, i) => {
        this.logger.debug(`${i} : ${isCustomText(o) ? o.text : 'image'}`);
      });

    this.selectionService.discardAndStoreSelection(this.canvas);
    this.updatePriceAndSaveState();
    this.selectionService.restoreSelection();
  }

  /**
   * adds a Textelement to the canvas
   */
  public addTextElement = () => {
    // TODO: Find Old deleted Font. Rmeove frm Future Use
    if (!this.fontFamily) {
      this.fontFamily = 'OpenSans-Regular';
    }

    let txtContent = '';
    this.translateService.get('EDITOR.SRC.TEXTCONTENT').subscribe(result => {
      txtContent = result;
    });

    const txt: CustomText = new CustomText(txtContent);
    txt.fontFamily = this.fontFamily;
    txt.fontSize = this.fontSize;
    txt.fill = '#000000';
    txt.lineHeight = this.lineHeight;

    const currentSide = this.dataService.getSeitenansichtForSelectedSide();
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    this.canvasHelperService.addElementToCanvas(
      carSideManager,
      this.canvas,
      txt,
      currentSide
    );
    this.adaptOnResize();
    // renderAll to receive correct price directly
    this.canvas.renderAll();
    this.updatePriceAndSaveState();
  };

  /**
   * adds Rectangle to the Canvas
   */
  public addRectangle = () => {
    const obj: fabric.Rect = this.basicShapeEditor.getRectangle();
    const currentSide = this.dataService.getSeitenansichtForSelectedSide();
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    this.canvasHelperService.addElementToCanvas(
      carSideManager,
      this.canvas,
      obj,
      currentSide,
      true
    );
    this.adaptOnResize();
    this.updatePriceAndSaveState();
  };
  /**
   * adds Triangle to the Canvas
   */
  public addTriangle = () => {
    const obj = this.basicShapeEditor.getTriangle();
    const currentSide = this.dataService.getSeitenansichtForSelectedSide();
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    this.canvasHelperService.addElementToCanvas(
      carSideManager,
      this.canvas,
      obj,
      currentSide,
      true
    );
    this.adaptOnResize();
    this.updatePriceAndSaveState();
  };

  /**
   * adds Cirlce to the Canvas
   */
  public addCircle = () => {
    const obj: fabric.Circle = this.basicShapeEditor.getCircle();

    const currentSide = this.dataService.getSeitenansichtForSelectedSide();
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    this.canvasHelperService.addElementToCanvas(
      carSideManager,
      this.canvas,
      obj,
      currentSide,
      true
    );
    this.adaptOnResize();
    this.updatePriceAndSaveState();
  };

  /**
   * starts the File selection Process
   */
  public addLogoElement = () => {
    this.dialog.open(UploadDialogWrapperComponent, {
      panelClass: 'upload-workflow-container',
    });
  };

  /**
   * boolean that specifies whether FontStyle is Bold. 
   */
  private isFontStyleBold(): boolean {
    return this.fontDataService.isFontStyle(this.fontFamily, 'Bold');
  }

  /**
   * boolean that specifies whether FontStyle is Italic.
   */
  private isFontStyleItalic(): boolean {
    return this.fontDataService.isFontStyle(this.fontFamily, 'Italic');
  }

  /**
   * boolean that specifies whether FontFamily Has Bold Enabled.
   */
  public isBoldEnabled = (): boolean => {
    const currentFontArr = this.fontFamily.split('-');

    if (currentFontArr.length !== 2) {
      console.error('unexpected current Font!');
      return null;
    }
    const currentFontFamily = currentFontArr[0];
    const currentStyle = currentFontArr[1];

    return this.fontDataService.isFontChangePossible(
      currentFontFamily,
      currentStyle
    );
  };
  /**
   * boolean that specifies whether FontFamily has Italic Enabled.
   */
  public isItalicEnabled = (): boolean => {
    // TODO: Possible Duplication.
    const currentFontArr = this.fontFamily.split('-'); // i.e Array [ "OpenSans", "Bold" ]
    if (currentFontArr.length !== 2) {
      console.error('unexpected current Font!');
      return null;
    }
    const currentFontFamily = currentFontArr[0]; // OpenSans
    const currentStyle = currentFontArr[1]; // Bold
    const arr =
      this.fontDataService.getStylePossiblitiesForFont(currentFontFamily);
    if (currentStyle === 'Italic') {
      return (
        arr.filter(a => {
          return a === 'Regular';
        }).length === 1
      );
    } else if (currentStyle === 'Regular') {
      return (
        arr.filter(a => {
          return a === 'Italic';
        }).length === 1
      );
    } else if (currentStyle === 'BoldItalic') {
      return (
        arr.filter(a => {
          return a === 'Bold';
        }).length === 1
      );
    } else if (currentStyle === 'Bold') {
      return (
        arr.filter(a => {
          return a === 'BoldItalic';
        }).length === 1
      );
    }
    return false;
  };

  /**
   * Changes the style of button, states get
   * styled in this order:
   * - active
   * - idle
   * - disabled
   *
   * @memberof EditorComponent
   */

  public getClassForButton = (txtAttrname: string): string => {
    if (
      (txtAttrname === 'Bold' && this.isFontStyleBold()) ||
      (txtAttrname === 'Italic' && this.isFontStyleItalic()) ||
      (txtAttrname === 'Underline' && this.selectionIsUnderline)
    ) {
      return 'not-active-text-control';
    }
    return 'active-text-control';
  };

  /**
   * Changes the Editmode
   */
  public setEditMode = (value: boolean) => {
    const activeObjects = (<any>this.canvas).getActiveObjects();

    if (this.isInEditmode === value) {
      return;
    }
    this.isInEditmode = value;
    if (activeObjects) {
      activeObjects.forEach(element => {
        element.setCoords();
      });
    }
    if (!this.isInEditmode) {
      // this is part of the bugfix bug when selecting group of objects
      this.discardSelection();

      this.priceCalculatorService.updateDruckauftragPrice(
        this.dataService.druckauftrag,
        this.dataService.countryHourRate,
        this.dataService.currencyFactor,
        this.dataService.userType,
        this.dataService.country
      );
    }
    this.adaptOnResize() || this.renderEditMode();
  };

  // -------------------------------------------------------------------------------------------------------------------------------------
  // textAlign
  // -------------------------------------------------------------------------------------------------------------------------------------

  public clickTextAlign = (value: string): void => {
    this.textAlign = value;
    this.onChangeTextAlign();
  };
  public isEnabledTextAlignButton = (value: string): boolean => {
    return this.showTextControls() && this.textAlign === value;
  };

  // -------------------------------------------------------------------------------------------------------------------------------------
  // UNDO REDO METHODS
  // -------------------------------------------------------------------------------------------------------------------------------------

  public isRedoDisabled = (): boolean => {
    return !this.stateStorageService.isRedoPossible() || this.isInZoomMode;
  };
  public isUndoDisabled = (): boolean => {
    return !this.stateStorageService.isUndoPossible() || this.isInZoomMode;
  };
  public stateStorageUndo = (): void => {
    if (!this.isUndoDisabled()) {
      this.canvas.clear();
      this.stateStorageService.undo();
      this.adaptOnResize();
    }
  };
  public stateStorageRedo = (): void => {
    if (!this.isRedoDisabled()) {
      this.canvas.clear();
      this.stateStorageService.redo();
      this.adaptOnResize();
    }
  };

  public hideSpacing = () => {
    if (this.isInEditmode) {
      this.isSpacingIconHidden = true;
    }
  };
  public showSpacing = () => {
    this.isSpacingIconHidden = false;
  };

  /**
   * returns the Viewbox width
   */
  public getViewBoxWidth = (): number => {
    const carSideManager = this.carDataManagerService.getCarSideManager(
      this.dataService.getSelectedSide()
    );
    const viewBoxWidth = carSideManager.getViewboxWidth();
    return viewBoxWidth;
  };

  public discardSelection = () => {
    this.canvas.discardActiveObject();
  };
  /**
   * Design method that aligns the Car at the bottom of the reserved space
   */
  public showDivBehindCanvas = (): boolean => {
    const fahrzeugSeite = this.dataService.getSelectedSide();
    if (
      fahrzeugSeite === Fahrzeugseite.BEIFAHRER ||
      fahrzeugSeite === Fahrzeugseite.FAHRER
    ) {
      return false;
    }
    return true;
  };

  private getTextKeyFromCustomText(textObj: CustomText) {
    return textObj.textKey;
  }

  private updatePositionBasedOnCursorKeys(
    object: fabric.Object,
    keyCode?: string
  ) {
    const movementDelta = 1;
    if (!object) {
      return;
    }
    if (keyCode) {
      switch (keyCode) {
        case 'ArrowUp':
          if (
            object.getBoundingRect().top - movementDelta > 0 &&
            !(
              this.dataService.isTopSystemActive &&
              this.activeObject === this.getTopSystemCandidate()
            )
          ) {
            object.top = Math.max(0, object.top - movementDelta);
          }
          break;
        case 'ArrowDown':
          if (
            object.getBoundingRect().top +
              object.getBoundingRect().height +
              movementDelta <
              this.getCanvasHeight() &&
            !(
              this.dataService.isTopSystemActive &&
              this.activeObject === this.getTopSystemCandidate()
            )
          ) {
            // on the bottom there are only tyres, so leave a bit more room
            object.top = Math.min(
              this.getCanvasHeight() - 15,
              object.top + movementDelta
            );
          }
          break;
        case 'ArrowRight':
          if (
            object.getBoundingRect().left +
              object.getBoundingRect().width +
              movementDelta <
            this.getCanvasWidth()
          ) {
            object.left = Math.min(
              this.getCanvasWidth() - 5,
              object.left + movementDelta
            );
          }
          break;
        case 'ArrowLeft':
          if (object.getBoundingRect().left - movementDelta > 0) {
            object.left = Math.max(0, object.left - movementDelta);
          }
          break;
      }
      object.setCoords();
    } else {
      this.allCurrentlyPressedArrowKeys.forEach(value => {
        this.updatePositionBasedOnCursorKeys(object, value);
      });
    }
  }

  /**
   * Get text Color Data from Assets.
   * @returns Color Value and Name
   */
  getTextColorData = (): Color[] => {
    return TEXT_COLOR_DATA;
  };

  /**
   * Get text Color Data from Assets.
   * @returns Color Value and Name
   */
  getCarColorData = (): string[] => {
    return this.dataService.vehicleColors;
  };

  public getClassForTextButton() {
    if (!this.textColorPicker.isVisible) {
      return 'iconbutton text-colorbutton';
    } else {
      return 'iconbutton text-colorbutton activeColorPickerButton';
    }
  }

  public alignLeft(): void {
    this.alignObjects('left');
  }

  public alignRight() {
    this.alignObjects('right');
  }

  public alignHorizontalCenter() {
    this.alignObjects(this.ALIGNMENT_HC);
  }

  public alignTop(): void {
    this.alignObjects('top');
  }

  public alignBottom(): void {
    this.alignObjects('bottom');
  }

  public alignVerticalCenter(): void {
    this.alignObjects('vertical center');
  }

  public alignHorizontalSpread(): void {
    this.alignObjects(this.ALIGNMENT_HS);
  }

  public alignVerticalSpread(): void {
    this.alignObjects(this.ALIGNMENT_VS);
  }

  /**
   * Provide alignment functionality
   * for a single object or a group
   * of objects. Single objects are
   * aligned horizontally relative
   * to the canvas and a group of objects
   * is grouped together.
   *
   * @param {string} direction
   * @memberof EditorComponent
   */
  public alignObjects(direction: string): void {
    const activeSelection = this.canvas.getActiveObject();

    // discard selections
    this.selectionService.discardAndStoreSelection(this.canvas);
    if (activeSelection.type === 'activeSelection') {
      this.alignObjectGroup(activeSelection, direction);
    } else {
      this.alignSingleObject(activeSelection, direction);
    }
    this.canvas.renderAll();
    // save State
    this.updatePriceAndSaveState();
    this.selectionService.restoreSelection();
  }

  /**
   * Centers a single object relative to
   * the largest edit area and updates
   * it controls.
   *
   * @private
   * @param {fabric.Object} object
   * @param {string} direction
   * @memberof EditorComponent
   */
  private alignSingleObject(object: fabric.Object, direction: string): void {
    if (direction === this.ALIGNMENT_HC) {
      const seitenAnsicht: Seitenansicht =
        this.dataService.getSeitenansichtForSelectedSide();
      const useEditareaForAlignment = false;
      let centerPosition: Point;
      if (useEditareaForAlignment) {
        centerPosition =
          this.canvasHelperService.getCenterPositionLargestEditArea(
            this.canvas,
            seitenAnsicht
          );
      } else {
        // use viewBox as derived from the background-svg for alignment
        const carSideManager = this.carDataManagerService.getCarSideManager(
          this.dataService.getSelectedSide()
        );
        const viewBoxWidth = carSideManager.getViewboxWidth();
        const viewBoxHeight = carSideManager.getViewboxHeight();
        centerPosition = new Point(viewBoxWidth / 2, viewBoxHeight / 2);
      }

      const newLeft = this.calculateXPositionforRotatedPointOnPositionX(
        object.left,
        object.width * object.scaleX,
        object.height * object.scaleY,
        object.angle,
        centerPosition.x
      );
      object.left = newLeft;
      object.setCoords();
    }
  }

  /**
   *calculates new X Postition for Point so that the Center of the Descibed Object is at newXPosition
   */
  private calculateXPositionforRotatedPointOnPositionX(
    objX: number,
    objWidth: number,
    objHeight: number,
    angleInDegrees: number,
    newXPosition: number
  ): number {
    // degree to randians:
    // ang * Math.PI/180
    //       const radians = object.getAngle() * (Math.PI / 180);
    const radians = this.fabric.util.degreesToRadians(angleInDegrees);

    const objCenterRelativeLeft = (Math.cos(radians) * objWidth) / 2;
    // const xPartOfHeight = (object.getAngle()>180)?(( 1-Math.cos(radians)) * object.getHeight() / 2):0;
    const xPartOfHeight = (Math.sin(radians) * objHeight) / 2;

    const newDifference =
      newXPosition - (objX + objCenterRelativeLeft - xPartOfHeight);
    this.logger.debug(`AlignmentDebugging:
      angle: ${angleInDegrees}
      radians: ${radians}
      cos: ${Math.cos(radians)}
      sin: ${Math.sin(radians)}
      sinAng: ${Math.sin(angleInDegrees)}
      cosAng: ${Math.cos(angleInDegrees)}
      width:${objWidth}
      height:${objHeight}
      leftCenterRelative=${objCenterRelativeLeft},
       xPart of Height ${xPartOfHeight}
       newDifference ${newDifference}
       new pos: ${objX + newDifference}
       newX-Param: ${newXPosition}`);
    return objX + newDifference;
  }

  getWidth(obj: fabric.Object): number {
    return (obj.width + obj.strokeWidth) * obj.scaleX;
  }

  getHeight(obj: fabric.Object): number {
    return (obj.height + obj.strokeWidth) * obj.scaleY;
  }

  /**
   * Align an object group according to the
   * passed direction.
   *
   * @private
   * @param {fabric.Object[]} allObject
   * @param {fabric.Group} group
   * @param {string} direction
   * @memberof EditorComponent
   */
  private alignObjectGroup(activeSelection: fabric.Object, direction: string) {
    const groupLeft = activeSelection.left;
    const groupWidth = activeSelection.width * activeSelection.scaleX;
    const groupHeight = activeSelection.height * activeSelection.scaleY;
    const groupTop = activeSelection.top;

    const allObject = (activeSelection as any).getObjects();
    if (direction !== this.ALIGNMENT_VS && direction !== this.ALIGNMENT_HS) {
      allObject.forEach(object => {
        if (direction === 'left') {
          object.left = groupLeft;
        } else if (direction === 'right') {
          object.left = groupLeft + groupWidth - this.getWidth(object);
        } else if (direction === this.ALIGNMENT_HC) {
          object.left = groupLeft + (groupWidth - this.getWidth(object)) / 2;
        } else if (direction === 'top') {
          object.top = groupTop;
        } else if (direction === 'bottom') {
          object.top = groupTop + groupHeight - this.getHeight(object);
        } else if (direction === 'vertical center') {
          object.top = groupTop + (groupHeight - this.getHeight(object)) / 2;
        } else {
          console.error('undefined direction in align');
        }
      });
    } else {
      if (direction === this.ALIGNMENT_HS) {
        const allSortedObject = allObject.sort(
          (a: fabric.Object, b: fabric.Object) => {
            if (a.left < b.left) {
              return -1;
            } else if (a.left > b.left) {
              return 1;
            } else {
              return 0;
            }
          }
        );
        const lastObj = allSortedObject[allSortedObject.length - 1];
        const averageDistance =
          (lastObj.left - allSortedObject[0].left) /
          (allSortedObject.length - 1);
        allSortedObject.forEach((sortedObject, index) => {
          if (index !== 0 && index !== allSortedObject.length - 1) {
            sortedObject.left =
              allSortedObject[0].left + index * averageDistance;
          }
        });
      } else if (direction === this.ALIGNMENT_VS) {
        const allSortedObject = allObject.sort(
          (a: fabric.Object, b: fabric.Object) => {
            if (a.top < b.top) {
              return -1;
            } else if (a.top > b.top) {
              return 1;
            } else {
              return 0;
            }
          }
        );
        const lastObj = allSortedObject[allSortedObject.length - 1];
        const averageDistance =
          (lastObj.top +
            lastObj.height * lastObj.scaleY -
            allSortedObject[0].top) /
          (allSortedObject.length - 1);
        allSortedObject.forEach((sortedObject, index) => {
          if (index !== 0 && index !== allSortedObject.length - 1) {
            sortedObject.top = allSortedObject[0].top + index * averageDistance;
          }
        });
      }
    }
    this.canvas.renderAll();
  }

  public isAlignmentEnabled(): boolean {
    if (!this.canvas) {
      return false;
    }
    if (this.isTopSystemActive) {
      return false;
    }

    return (
      (<any>this.canvas).getActiveObjects().length > 0 || this.isSingleObject()
    );
  }

  public isSingleObject(): boolean {
    if (!this.canvas) {
      return false;
    }
    return (
      this.canvas.getActiveObject() &&
      this.canvas.getActiveObject().type !==
        CONSTANTS.ACTIVE_SELECTION_TYPE_STRING
    );
  }

  private onCarSideDataFullyInitialized() {
    this.carEditorOverlayRef.nativeElement.style.display = 'none';
  }

  public zoomIn(): void {
    if (this.activateZoom || this.isInZoomMode) {
      return;
    }
    this.setEditMode(true);

    // set zoom
    this.activateZoom = true;
    this.isInZoomMode = true;
    // deactivate groupSelection for Zoom
    this.canvas.selection = false;

    this.canvas.hoverCursor = 'zoom-in';
    this.canvas.defaultCursor = 'zoom-in';
    this.adaptOnResize();
  }

  public zoomOut(): void {
    if (!this.activateZoom || !this.isInZoomMode) {
      return;
    }
    this.resetZoomMode();
    this.adaptCarSvgViewportToCanvas();
    this.renderEditMode();
  }

  private getCanvasWidth(): number {
    return this.canvasRef.nativeElement.offsetWidth;
  }

  private getCanvasHeight(): number {
    return this.canvasRef.nativeElement.offsetHeight;
  }

  public getCarWidth(): number {
    return (<any>(
      window.document.getElementsByTagName('app-car-side-handler')[0]
    )).offsetWidth;
  }

  public getCarHeight(): number {
    return (<any>(
      window.document.getElementsByTagName('app-car-side-handler')[0]
    )).offsetHeight;
  }

  public isShowOverlay(): boolean {
    return (
      this.isShowAlignMenu ||
      this.isShowTextAlignMenu ||
      this.isShowFormAddMenu ||
      this.isShowCopyMenu
    );
  }

  public onOverlayClick() {
    this.setShowAlignMenu(false);
    this.setShowTextAlignMenu(false);
    this.setShowFormAddMenu(false);
    this.setShowCopyMenu(false);
  }

  public getAlignElementMenuClass(): string {
    return this.isSingleObject() ? 'single-object' : 'multi-object';
  }

  public get zoomLevel(): number {
    return this.canvas
      ? Math.round(
          (this.canvas.getZoom() / this.carSvg.getScaleToCanvasFactor()) * 100
        )
      : 100;
  }

  public warningCloseEvent() {
    this.showWarningIsInActiveMode = false;
  }

  public errorCloseEvent() {
    this.showWarningIsInActiveMode = false;
  }

  public get showWarning(): boolean {
    if (!this.canvas || !this.canvas.getActiveObject() || this.isInZoomMode) {
      return false;
    }

    const activeObject = this.canvas.getActiveObject();
    const isGroup = activeObject.type === 'activeSelection';
    const fahrzeugSeite =
      this.dataService.getSeitenansichtForSelectedSide().fahrzeugseite;
    return (
      !isGroup &&
      this.warningManagerService.isPartial(fahrzeugSeite, activeObject) &&
      !(
        this.isTopSystemActive &&
        isCustomText(activeObject) &&
        !this.warningManagerService.isTopsysIntersectLR(
          fahrzeugSeite,
          activeObject
        )
      ) &&
      this.showWarningIsInActiveMode
    );
  }

  public get showError(): boolean {
    if (!this.canvas || !this.canvas.getActiveObject() || this.isInZoomMode) {
      return false;
    }
    const activeObject = this.canvas.getActiveObject();
    let imageHasBadQuality = false;
    if (isCustomImage(activeObject)) {
      imageHasBadQuality = activeObject.badQuality;
    }
    const isGroup = activeObject.type === 'activeSelection';
    const isNotIncluded = this.warningManagerService.isNotIncluded(
      this.dataService.getSeitenansichtForSelectedSide().fahrzeugseite,
      activeObject
    );
    const isDesignUser = this.dataService.userType === UserType.DESIGNUSER;

    return (
      !isDesignUser &&
      !isGroup &&
      (imageHasBadQuality || isNotIncluded) &&
      this.showWarningIsInActiveMode
    );
  }

  public isJpegImageSelected(): boolean {
    const selection = this.canvas.getActiveObject();
    return (
      this.isSingleObject() &&
      isCustomImage(selection) &&
      this.isJpgFilename(selection.filename)
    );
  }

  private isJpgFilename(filename: string): boolean {
    return filename !== undefined
      ? filename.toLowerCase().endsWith('jpg') ||
          filename.toLowerCase().endsWith('jpeg')
      : false;
  }

  public clickCropImage() {
    if (this.isCroppingPossible && !this.isCroppingActive) {
      this.isCroppingActive = true;
      this.setLoadingCycle(true);
    } else if (this.isCroppingActive) {
      this.finishCropper();
    }
  }
  public cropperInitialized() {
    this.setLoadingCycle(false);
  }

  public get cropActiveClass(): string {
    if (this.isCroppingActive) {
      return 'iconbuttonsmall crop-active';
    }
    return 'iconbuttonsmall';
  }
  private get isCroppingPossible(): boolean {
    return this.canvas && this.isJpegImageSelected();
  }

  public get selectedImage(): CustomImage {
    if (!this.isCroppingPossible) {
      return;
    }
    return this.canvas.getActiveObject() as CustomImage;
  }

  public get cropimageLeft(): string {
    if (!this.isCroppingPossible) {
      return;
    }
    return (
      this.getNewHorizontalPositionPostCrop(
        this.deltaX,
        this.deltaY,
        true,
        this.selectedImage
      ) + 'px'
    );
  }

  public get cropimageTop(): string {
    if (!this.isCroppingPossible) {
      return;
    }

    return (
      this.getNewVerticalPostionPostCrop(
        this.deltaX,
        this.deltaY,
        true,
        this.selectedImage
      ) + 'px'
    );
  }
  private get deltaY() {
    let deltaY = 0;
    if (
      this.selectedImage.cropperPositionPercent &&
      !isNaN(this.selectedImage.cropperPositionPercent.y1) &&
      isFinite(this.selectedImage.cropperPositionPercent.y1)
    ) {
      deltaY =
        -this.selectedImage.cropperPositionPercent.y1 * this.cropimageHeight;
    }
    return deltaY;
  }
  private get deltaX() {
    let deltaX = 0;
    if (
      this.selectedImage.cropperPositionPercent &&
      !isNaN(this.selectedImage.cropperPositionPercent.x1) &&
      isFinite(this.selectedImage.cropperPositionPercent.x1)
    ) {
      deltaX = -(
        this.selectedImage.cropperPositionPercent.x1 * this.cropimageWidth
      );
    }
    return deltaX;
  }

  public get cropimageWidth(): number {
    if (!this.isCroppingPossible) {
      return;
    }
    return this.calculateImageWidth(this.selectedImage);
  }

  private calculateImageWidth(customImage: CustomImage): number {
    return (
      (customImage.initialDisplayImageWidth || customImage.width) *
      customImage.scaleX *
      this.carSvg.getScaleToCanvasFactor()
    );
  }

  public get cropimageHeight(): number {
    if (!this.isCroppingPossible) {
      return;
    }

    return this.calculateImageHeight(this.selectedImage);
  }
  private calculateImageHeight(customImage: CustomImage): number {
    return (
      (customImage.initialDisplayImageHeight || customImage.height) *
      customImage.scaleY *
      this.carSvg.getScaleToCanvasFactor()
    );
  }
  public get selectedImageAngle(): string {
    if (this.selectedImage !== undefined) {
      return `rotate(${this.selectedImage.angle}deg)`;
    } else {
      return '';
    }
  }

  /**
   * Take the cropped image and convert its base64 representation
   * to a regular file. The later artifact is then uploaded to
   * server for conversion.
   *
   */
  public insertImageIfCropped(customimage?: CustomImage) {
    if (this.imageWasCropped && !this.isCroppingActive) {
      const event = this.cropper.imageCroppedSubject.getValue();

      const targetedImage = customimage || this.selectedImage;

      const cropImageWidth = this.calculateImageWidth(targetedImage);
      const cropImageHeight = this.calculateImageHeight(targetedImage);
      const percentageCropper = this.getCropPositionInPercent(
        event.cropperPosition,
        cropImageWidth,
        cropImageHeight
      );

      let deltaX = percentageCropper.x1 * cropImageWidth;
      let deltaY = percentageCropper.y1 * cropImageHeight;
      if (targetedImage.cropperPositionPercent) {
        deltaX =
          (percentageCropper.x1 - targetedImage.cropperPositionPercent.x1) *
          cropImageWidth;
        deltaY =
          (percentageCropper.y1 - targetedImage.cropperPositionPercent.y1) *
          cropImageHeight;
      }

      targetedImage.top = this.getNewVerticalPostionPostCrop(
        deltaX,
        deltaY,
        false,
        targetedImage
      );

      // move to the right
      targetedImage.left = this.getNewHorizontalPositionPostCrop(
        deltaX,
        deltaY,
        false,
        targetedImage
      );

      targetedImage.setCoords();
      targetedImage.cropperPositionPercent = percentageCropper;

      this.substituteImage(event.base64, targetedImage);
    }
  }
  private getCropPositionInPercent(
    cropperPosition: CropperPosition,
    width: number,
    height: number
  ): CropperPosition {
    return {
      x1: cropperPosition.x1 / Math.ceil(width),
      y1: cropperPosition.y1 / Math.ceil(height),
      x2: cropperPosition.x2 / Math.ceil(width),
      y2: cropperPosition.y2 / Math.ceil(height),
    };
  }
  public isObjectSelected(): boolean {
    if (!this.canvas) {
      // still initializing
      return false;
    }
    return (
      this.canvas.getActiveObject() !== undefined &&
      this.canvas.getActiveObject() !== null
    );
  }
}
