import * as fabric from 'fabric';
import { FabricHelper } from 'models/helpers/fabric.helper';
import { CropperPosition } from 'ngx-image-cropper/lib/interfaces/cropper-position.interface';

const fabricObj = <any>fabric.fabric;

// -- fabric.custom.js --
fabricObj.CustomImage = fabricObj.util.createClass(fabricObj.Image, {
  type: 'customImage',
  minScaleLimit: 0,

  initialize: function (element, options) {
    if (!element || element === '') {
      // in case element is missing, construct
      // it with the very basic information
      // before calling super.init (`Image` is native in Browser)
      element = new Image(options.width, options.height);
      element.src = options.imageData || options.src;
      this.setElement(element, options);
    }

    this.callSuper('initialize', element, options);

    if (options) {
      this.isTemplateData = options.isTemplateData;
      this.filename = options.filename;
      this.size = options.size;
      this.kbSize = options.kbSize;
      this.badQuality = options.badQuality;
      this.id = options.id;
      this.nativeSource = options.nativeSource;
      this.originalDisplayImage = options.originalDisplayImage;
      this.initialDisplayImageWidth =
        options.initialDisplayImageWidth || options.width;
      this.initialDisplayImageHeight =
        options.initialDisplayImageHeight || options.height;
      this.cropperPositionPercent = options.cropperPositionPercent;
      this.originalHeight = options.originalHeight;
      this.originalWidth = options.originalWidth;
      this.additionalPrice = options.additionalPrice;
    }

    // the width / height

    // set some custom fixed default options for the object controls
    this.rotatingPointOffset = 25;
    this.borderColor = 'rgb(0,93,166)';
    this.cornerShape = 'rect';
    this.cornerSize = 20;
    this.setControlsVisibility({
      tl: true,
      tr: true,
      br: true,
      bl: true,
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: true,
    });
  },
  toObject: function () {
    const obj = fabricObj.util.object.extend(this.callSuper('toObject'), {
      isTemplateData: this.isTemplateData,
      filename: this.filename,
      size: this.size,
      kbSize: this.kbSize,
      badQuality: this.badQuality,
      id: this.id,
      imageData: this._element.src,
      nativeSource: this.nativeSource,
      cropperPositionPercent: this.cropperPositionPercent,
      originalDisplayImage: this.originalDisplayImage,
      initialDisplayImageWidth: this.initialDisplayImageWidth || this.width,
      initialDisplayImageHeight: this.initialDisplayImageHeight || this.height,
      originalHeight: this.originalHeight,
      originalWidth: this.originalWidth,
      additionalPrice: this.additionalPrice,
    });
    return FabricHelper.copyProperties(this, obj);
  },
});

fabricObj.CustomImage.fromObject = function (object, callback) {
  fabricObj.util.loadImage(object.src, function (img) {
    if (callback) {
      callback(new fabricObj.CustomImage(img, object));
    }
    return {};
  });
};
fabricObj.CustomImage.fromElement = function (element, callback, options) {
  const parsedAttributes = fabricObj.parseAttributes(
    element,
    fabricObj.Image.ATTRIBUTE_NAMES
  );
  fabricObj.CustomImage.fromURL(
    parsedAttributes['xlink:href'],
    callback,
    fabricObj.util.object.extend(
      options ? fabricObj.util.object.clone(options) : {},
      parsedAttributes
    )
  );
};

fabricObj.CustomImage.fromURL = function (url, callback, imageOptions) {
  fabricObj.util.loadImage(url, function (img) {
    if (callback) {
      callback(new fabricObj.CustomImage(img, imageOptions));
    }
    return {};
  });
};

fabricObj.CustomImage.async = true;

export interface ICustomImageAttributes {
  id: string;
  isTemplateData: boolean;
  filename: string;
  size: number;
  kbSize: number;
  badQuality: boolean;
  nativeSource: string;
  cropperPositionPercent: CropperPosition;
  originalDisplayImage: string;
  initialDisplayImageWidth: number;
  initialDisplayImageHeight: number;
  originalWidth: number;
  originalHeight: number;
  additionalPrice: number;
}

export const isCustomImage = (obj: any): obj is CustomImage => {
  return obj !== null && obj !== undefined && obj.type === 'customImage';
};
export type CustomImage = fabric.fabric.Image & ICustomImageAttributes;
export const CustomImage = fabricObj.CustomImage;
