import { CustomTriangle } from './../../models/graphicelements/customtriangle';
import { Fahrzeugseite } from './../../models/fahrzeugeseite';
import { CustomImage } from './../../models/graphicelements/customimage';
import { DimensionsHelperService } from './dimensionshelperservice';
import { isCustomImage } from 'models/graphicelements/customimage';
import { CustomText, isCustomText } from 'models/graphicelements/customtext';
import { Injectable } from '@angular/core';
import { DataService } from 'app/services/dataservice';
import { CONSTANTS } from 'models/helpers/constants';
import { fabric } from 'fabric';
import { Druckauftrag } from 'models/druckauftrag';
import { Seitenansicht } from 'models/seitenansicht';
import * as color from 'color';
import * as _ from 'lodash';
import { SvgEditAreaParserService } from './svgeditareaparserservice';
import { Subscriber, Observable } from 'rxjs';
import { Rectangle } from '../../models/graphicelements/rectangle';
import { Polygon } from './../../models/graphicelements/polygon';
import { Point } from '../../models/graphicelements/point';

import { GraphicElement } from '../../models/graphicelements/graphicelement';
import { CentoidHelper } from 'app/helpers/polygon/centoid.helper';
import { NGXLogger } from "ngx-logger";import { CarSideManager } from 'app/services/car-side-manager';

@Injectable()
/**
 * This service contains helpermethods for the canvas that are/canbe used in multiple Components
 */
export class CanvasHelperService {
  private domParser: DOMParser;
  private prevScaleX = 0;
  private prevScaleY = 0;
  private copiedData: { obj: fabric.Object; carSide: any };
  private readonly allContrastColor = ['#ffffff', '#000000'];
  constructor(
    private dimensionsHelperService: DimensionsHelperService,
    private svgEditAreaParserService: SvgEditAreaParserService,
    private logger: NGXLogger
  ) {
    this.domParser = new DOMParser();
  }
  //  private stateStorageService: StateStorageService

  /**
   * Draws data on the canvas and sets the controls
   * in case controls are not set they are enabled (borders selectable... =true)
   */
  public drawAllDataOnCanvas = (
    canvas: fabric.Canvas,
    allData: fabric.Object[],
    controls?: boolean
  ) => {
    if (!canvas) {
      console.log('canvas not ready');
      return;
    }
    if (controls === undefined) {
      controls = true;
    }
    this.fixViewPort(canvas);
    if (allData) {
      const restoreValue = canvas.renderOnAddRemove;
      canvas.renderOnAddRemove = false;
      for (let i = 0; i < allData.length; i++) {
        const currentObj = allData[i];
        if (!currentObj) {
          // skip undefined and null values
          continue;
        }
        this.configureFabricObjectForDisplay(currentObj, controls);
        if (canvas.getObjects().indexOf(currentObj) < 0) {
          canvas.add(currentObj);
          currentObj.moveTo(i);
        }

        currentObj.setCoords();
      }

      this.logger.debug(
        `rawAllDataOnCanvas: renderAll ${allData.length} canvas object count: ${
          canvas.getObjects().length
        }`
      );
      canvas.renderOnAddRemove = restoreValue;
      canvas.calcOffset();
      canvas.renderAll();
    }
  };

  public fixViewPort(canvas: fabric.Canvas) {
    canvas.viewportTransform.forEach((value, index) => {
      if (!value || isNaN(value)) {
        canvas.viewportTransform[index] = 0;
      }
    });
  }

  public configureFabricObjectForDisplay(
    currentObj: fabric.Object,
    allowEditing: boolean
  ) {
    currentObj.hasBorders = allowEditing;
    currentObj.visible = true;
    (currentObj as any).cornerStrokeColor = '#ffffff';
    currentObj.hasRotatingPoint = allowEditing;
    currentObj.cornerColor = '#0669b2';
    (currentObj as any).cornerStrokeColor = '#ffffff';
    currentObj.transparentCorners = false;
    currentObj.cornerSize = 8;
    currentObj.selectable = allowEditing;
    currentObj.lockMovementX = currentObj.lockMovementY = !allowEditing;
    if (!allowEditing) {
      // pointer for the selected shape
      currentObj.hoverCursor = 'default';
    }
    // set rotation angles:
    // https://stackoverflow.com/questions/34991635/fabric-js-limit-rotation-to-x-degrees-with-rotation-handle
    currentObj.snapAngle = 1;
    (currentObj as any).caching = allowEditing;
    // handle different fonts
    if (isCustomText(currentObj)) {
      const textObj = currentObj;
      textObj.editable = allowEditing;
      if ((textObj as any)._updateTextarea) {
        (textObj as any)._updateTextarea();
      }
      let objectFontFamily = textObj.fontFamily;
      if (objectFontFamily === 'Times New Roman') {
        if (typeof textObj.styles[0] === 'string') {
          objectFontFamily = textObj.styles[0];
        } else if (typeof textObj.styles[0][0] === 'string') {
          // replace Times new Roman!
          objectFontFamily = (textObj.styles[0][0] as any).fontFamily;
        } else {
          objectFontFamily = 'OpenSans-Regular';
        }
      }
      textObj.fontFamily = objectFontFamily;
    }
    if (isCustomImage(currentObj)) {
      // disable badQuality rendering for nonedit views
      if (!allowEditing) {
        (currentObj as any).badQuality = false;
      } else {
        this.dimensionsHelperService.performQualityCheck(currentObj);
      }
    }

    // set the rotation icon
    (currentObj as any).customiseCornerIcons({
      mtr: {
        icon: 'assets/icons/V2/icon-rotieren.svg',
        settings: {
          cornerSize: 20,
          borderColor: 'transparent',
          cornerStrokeColor: 'transparent',
        },
      },
    });
  }

  /**
   * returns the color form a predefined set of colors with the maximal contrast value to the inputColor
   * @param colorStrIn
   */
  public getContrastColor(colorStrIn: string): string {
    const colorIn = color(colorStrIn);
    return this.allContrastColor.reduce((a, b) => {
      const colorA = color(a);
      const colorB = color(b);
      const contrastA = colorA.contrast(colorIn);
      const contrastB = colorB.contrast(colorIn);
      if (contrastA > contrastB) {
        return colorA.rgb();
      }
      return colorB.rgb();
    });
  }

  /**
   * changes fontsize of CustomTextelement on scaling
   * adaption
   * @param event
   */
  public adaptCustomTextSizeOnScaling(
    customText: CustomText,
    event: fabric.IEvent,
    factorIn?: number
  ) {
    if (event) {
      if (
        (customText.fontSize <= CONSTANTS.MINIMAL_FONTSIZE &&
          (event as any).transform.newScaleX < 1) ||
        (event as any).transform.newScaleY < 1
      ) {
        return;
      }
    }
    // adapt fontsize of customText
    if (!isCustomText(customText)) {
      throw new Error('Invalid Parameter, object is no CustomText');
    } else {
      // enable caching to prevent scale-display errors
      (customText as any).noScaleCache = false;
      customText.lockScalingFlip = true;

      // use Xscale as factor if Yscale does not change
      // e.g. if the size is dragged to the left/right
      const factor = factorIn
        ? factorIn
        : customText.scaleY !== 1
        ? customText.scaleY
        : customText.scaleX;

      // result for textfield:
      let newFieldFontSize = customText.fontSize * factor;

      // set minimal font size
      newFieldFontSize = Math.max(CONSTANTS.MINIMAL_FONTSIZE, newFieldFontSize);
      customText.fontSize = newFieldFontSize;
      let lineIndex = 0;
      // the .styles attribute has structure:
      // 0: 0: {fontSize=x}
      //    1: ....
      // 1: ....
      // the first index is the line the second index is the style for a character

      // handle all lines
      while (customText.styles.hasOwnProperty('' + lineIndex)) {
        const linestyle = customText.styles[lineIndex];
        let charIndex = 0;
        // handle all characters in line
        while (linestyle.hasOwnProperty('' + charIndex)) {
          if (linestyle[charIndex] && linestyle[charIndex].fontSize) {
            let newFontSize = linestyle[charIndex].fontSize * factor;
            // check for minimal size
            newFontSize = Math.max(CONSTANTS.MINIMAL_FONTSIZE, newFontSize);
            linestyle[charIndex].fontSize = newFontSize;
          }
          charIndex++;
        }
        lineIndex++;
      }
      // reset scale
      customText.scaleX = 1;
      customText.scaleY = 1;
    }
  }

  /**
   * adapt image on scale
   * @param image
   */
  public adaptImageOnScale(image: CustomImage, isTopSystemActive: boolean) {
    const widthInMeter =
      image.width * image.scaleX * CONSTANTS.VIEWBOX_TO_METER_FACTOR;
    const heightInMeter =
      image.height * image.scaleY * CONSTANTS.VIEWBOX_TO_METER_FACTOR;
    console.log(`
    ImageWidth: ${widthInMeter}
    ImageHeight: ${heightInMeter}
    scaleX: ${image.scaleX}
    scaleY: ${image.scaleY}
    `);

    if (
      !isTopSystemActive &&
      (widthInMeter < CONSTANTS.MIN_IMAGE_SIZE ||
        heightInMeter < CONSTANTS.MIN_IMAGE_SIZE)
    ) {
      const scaleFactor = Math.max(this.prevScaleX, this.prevScaleY);
      if (scaleFactor > 0) {
        image.scaleX = scaleFactor;
        image.scaleY = scaleFactor;
      }
    } else {
      this.prevScaleX = image.scaleX;
      this.prevScaleY = image.scaleY;
    }
  }

  /**
   * set the previous Scaling for Objects
   */
  public setPreviousScaling(scaleX: number, scaleY: number): void {
    this.prevScaleX = scaleX;
    this.prevScaleY = scaleY;
  }

  public calculateWidthScalingForObject(
    activeObject: fabric.Object,
    value: number
  ): boolean {
    const scaleXNew =
      value /
      ((activeObject.width + activeObject.strokeWidth) *
        CONSTANTS.VIEWBOX_TO_METER_FACTOR *
        1000);
    if (activeObject.scaleX !== scaleXNew) {
      if (isCustomImage(activeObject)) {
        activeObject.scaleY =
          activeObject.scaleY * (scaleXNew / activeObject.scaleX);
      }
      activeObject.scaleX = scaleXNew;
      return true;
    }
    return false;
  }

  public calculateHeightScalingForObject(
    activeObject: fabric.Object,
    value: number
  ): boolean {
    const scaleYNew =
      value /
      ((activeObject.height + activeObject.strokeWidth) *
        CONSTANTS.VIEWBOX_TO_METER_FACTOR *
        1000);
    if (activeObject.scaleY !== scaleYNew) {
      if (isCustomImage(activeObject)) {
        activeObject.scaleX =
          activeObject.scaleX * (scaleYNew / activeObject.scaleY);
      }
      activeObject.scaleY = scaleYNew;
      return true;
    }
    return false;
  }

  /**
   * rounds fontSize of CustomText-object and all characters
   * if the floor parameter is set the floor mehtod is used instead of the round method
   * @param event
   */
  public roundFontSizesOfCustomText(obj: CustomText, floor?: boolean) {
    if (!isCustomText(obj)) {
      throw new Error('Invalid Parameter, object is no CustomText');
    } else {
      const customText = obj;
      customText.fontSize = !floor
        ? Math.round(customText.fontSize)
        : Math.floor(customText.fontSize);
      let lineIndex = 0;

      // handle all lines
      while (customText.styles.hasOwnProperty('' + lineIndex)) {
        const linestyle = customText.styles[lineIndex];
        let charIndex = 0;
        // handle all characters in line
        while (linestyle.hasOwnProperty('' + charIndex)) {
          if (linestyle[charIndex] && linestyle[charIndex].fontSize) {
            linestyle[charIndex].fontSize = !floor
              ? Math.round(linestyle[charIndex].fontSize)
              : Math.floor(linestyle[charIndex].fontSize);
          }
          charIndex++;
        }
        lineIndex++;
      }
    }
  }

  public refreshSideData(druckauftrag: Druckauftrag): Promise<Druckauftrag> {
    return Promise.all([
      this.refreshSide(druckauftrag.rechts),
      this.refreshSide(druckauftrag.links),
      this.refreshSide(druckauftrag.front),
      this.refreshSide(druckauftrag.heck),
    ]).then((allside: Seitenansicht[]) => {
      druckauftrag.rechts = allside[0];
      druckauftrag.links = allside[1];
      druckauftrag.front = allside[2];
      druckauftrag.heck = allside[3];
      return druckauftrag;
    });
  }
  public refreshSide(seitenansicht: Seitenansicht): Promise<Seitenansicht> {
    // handle all (0 or many) objects on a side
    const sidePromise = new Promise<fabric.Object[]>(resolve => {
      // handle empty side
      if (
        !seitenansicht.allDataElement ||
        seitenansicht.allDataElement.length === 0
      ) {
        return resolve([]);
      }
      const newAllData = [];
      let clonedObjectCounter = 0;
      // clone counter?
      seitenansicht.allDataElement.forEach(
        (value: fabric.Object, index: number) => {
          value.clone(object => {
            if (isCustomText(object)) {
              // adapt font size to scale factor
              this.adaptCustomTextSizeOnScaling(object, undefined);
              // get natural number for font size
              this.roundFontSizesOfCustomText(object);
              object.isEditing = false;
              // set control nodes
              object.setCoords();
            }
            object.dirty = true;
            // Cloning an objects applies a rounding
            // method to the scaleX and scaleY values.
            // As a result the objects are moved to
            // the round coordinates - as a workaround
            // we reuse the old values.

            object.scaleX = value.scaleX;
            object.scaleY = value.scaleY;
            newAllData[index] = object;

            clonedObjectCounter++;
            // use counter since the obects are set by index (therefore .length is not useful)
            if (clonedObjectCounter === seitenansicht.allDataElement.length) {
              return resolve(newAllData);
            }
          });
        }
      );
    });
    return sidePromise.then(newAllData => {
      seitenansicht.allDataElement = newAllData;
      return seitenansicht;
    });
  }
  outlineObjects(
    ctx: CanvasRenderingContext2D,
    allObject: fabric.Object[],
    carColor: string
  ) {
    const oldStroke = ctx.strokeStyle;
    const oldlineDash = ctx.getLineDash();
    const oldlineWidth = ctx.lineWidth;
    allObject.forEach(o => {
      o.setCoords();
      ctx.strokeStyle = this.getContrastColor(carColor);

      ctx.setLineDash([3, 2]);
      ctx.lineWidth = 1;
      const coords = o.oCoords;
      ctx.beginPath();
      ctx.moveTo(coords.tl.x, coords.tl.y);
      ctx.lineTo(coords.tr.x, coords.tr.y);
      ctx.lineTo(coords.br.x, coords.br.y);
      ctx.lineTo(coords.bl.x, coords.bl.y);
      ctx.closePath();
      ctx.stroke();
    });
    ctx.strokeStyle = oldStroke;
    ctx.setLineDash(oldlineDash);
    ctx.lineWidth = oldlineWidth;
  }

  private hasDocumentElement = (document: Document): boolean => {
    if (document && document.documentElement) {
      return true;
    }
    return false;
  };

  private hasAllFirstChild = (document: Document): boolean => {
    if (
      document.documentElement.firstChild &&
      (document.documentElement.firstChild.nodeName === '#comment' ||
        document.documentElement.firstChild.nodeName === '#text' ||
        document.documentElement.firstChild.nodeName === 'svg')
    ) {
      return true;
    }
    return false;
  };

  private parseEditAreaSide = (
    document: Document,
    druckauftrag: Druckauftrag,
    sideView: Seitenansicht
  ) => {
    if (
      this.hasDocumentElement(document) &&
      this.hasAllFirstChild(document) &&
      !sideView.allEditierbereich // do not load twice
    ) {
      this.svgEditAreaParserService.loadEditArea(
        document.documentElement,
        druckauftrag,
        sideView
      );
    }
  };

  /**
   * changes the fill property of fabic objects and chars in text objects
   */
  public changeTextColorOfObject(
    obj: fabric.Object,
    textcolor: string
  ): boolean {
    if (obj) {
      if (isCustomText(obj)) {
        if (!obj.isEditing) {
          console.log('COLOR_CHANGE TXT IS !editing ');
          obj.fill = textcolor;
          return this.adaptTextStyleofCharacters(obj, 'fill', textcolor);
        } else {
          return this.adaptTextStyleofCharacters(obj, 'fill', textcolor);
        }
      } else if (obj.type === CONSTANTS.RECT_TYPE_STRING) {
        const rectObj = <fabric.Rect>obj;
        rectObj.fill = textcolor;
        return true;
      } else if (
        obj.type === CONSTANTS.TRIANGLE_TYPE_STRING ||
        obj.type === CONSTANTS.CUSTOM_TRIANGLE_TYPE_STRING
      ) {
        const triangleObj = obj as CustomTriangle;
        triangleObj.fill = textcolor;

        return true;
      } else if (obj.type === CONSTANTS.CIRCLE_TYPE_STRING) {
        const circleObj = <fabric.Circle>obj;
        circleObj.fill = textcolor;
        return true;
      }
      return false;
    }
  }

  /**
   * Adapts the Text style of a the param object (CostomText/IText)
   */
  public adaptTextStyleofCharacters = (
    object: fabric.IText,
    styleName,
    value
  ): boolean => {
    // inner text selected
    if (object.setSelectionStyles && object.isEditing) {
      // if we are not at the beginning and if only one
      // character was selectded the chagens will get applied
      // to the character before the cursor ( selectionStart - 1)
      // due to design decisions.

      if (
        object.selectionStart > 0 &&
        object.selectionEnd === object.selectionStart
      ) {
        object.selectionStart -= 1;
      }
      // handle the deletion of the underline property

      if (value === undefined) {
        delete (object.getSelectionStyles(
          object.selectionStart,
          object.selectionEnd
        ) as any).styleName;
      } else {
        const style =
          object.getSelectionStyles(
            object.selectionStart,
            object.selectionEnd
          ) || {};
        for (let i = 0; i < object.selectionEnd - object.selectionStart; i++) {
          style[i][styleName] = value;
          (object as any).setSelectionStyles(
            style[i],
            object.selectionStart + i,
            object.selectionStart + i + 1
          );
        }
      }
    } else {
      // outer selection -> map it to inner selection, to prevent unexpected behaviour
      const style = {};
      style[styleName] = value;
      object.selectionStart = 0;
      object.selectionEnd = object.text.length + 1;
      object.setSelectionStyles(style);
    }
    return true;
  };

  /**
   * Loads the EditAreas and returns whether it was fully loaded into the druckauftrag.
   *
   * @param path: string
   * @param Side: Seitenansicht
   * @returns hasAllEditierbereich: Observable<boolean>
   */

  public hasAllEditierbereich = (druckauftrag: Druckauftrag): boolean => {
    if (
      druckauftrag &&
      druckauftrag.links.allEditierbereich &&
      druckauftrag.rechts.allEditierbereich &&
      druckauftrag.front.allEditierbereich &&
      druckauftrag.heck.allEditierbereich
    ) {
      return true;
    }
    return false;
  };

  /**
   * returns a Dictionary containing object id and svgstring
   */
  public getTextSvgDictionary = (canvas: fabric.Canvas): string[] => {
    const dict = [];

    canvas.getObjects().forEach((o, index) => {
      if (isCustomText(o)) {
        dict.push({ key: o.id, value: o.toSVG() });
      }
    });
    return dict;
  };

  private getResolvablePromise(): {
    resolveFunction: any;
    promise: Promise<boolean>;
  } {
    let resolvePromiseFunction;
    const promiseToReturn = new Promise((r: (val: boolean) => void) => {
      resolvePromiseFunction = r;
    });
    return {
      resolveFunction: resolvePromiseFunction,
      promise: promiseToReturn,
    };
  }

  /**
   * copies /saves the active selection on canvas
   */
  public copy(canvas: fabric.Canvas, carSide: Fahrzeugseite): Promise<any> {
    // clone what are you copying since you
    // may want copy and paste on different moment.
    // and you do not want the changes happened
    // later to reflect on the copy.
    const { resolveFunction, promise } = this.getResolvablePromise();
    canvas.getActiveObject().clone(cloned => {
      this.copiedData = { obj: cloned, carSide };
      resolveFunction();
    });
    return promise;
  }

  /**
   * copied objects to the canvas
   * discards active selection and adds, no price calculation
   * selects added object
   * @return boolean true if three was something copied to insert
   */
  public async paste(
    canvas: fabric.Canvas,
    seitenansicht: Seitenansicht
  ): Promise<boolean> {
    const { resolveFunction, promise } = this.getResolvablePromise();
    if (!this.copiedData || !this.copiedData.obj) {
      resolveFunction(false);
      return promise;
    }
    // clone again, so you can do multiple copies.

    this.copiedData.obj.clone(clonedObj => {
      let x = clonedObj.left + 5;
      let y = clonedObj.top + 5;
      if (this.copiedData.carSide !== seitenansicht.fahrzeugseite) {
        this.positionInCenterOfLargestEditArea(
          canvas,
          clonedObj,
          seitenansicht
        );
        x = clonedObj.left;
        y = clonedObj.top;
      }
      canvas.discardActiveObject();
      clonedObj.set({
        left: x,
        top: y,
        evented: true,
        canvas,
      });
      if (clonedObj.type === 'activeSelection') {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas;
        clonedObj.forEachObject(obj => {
          this.configureFabricObjectForDisplay(obj, true);
          canvas.add(obj);
          seitenansicht.allDataElement.push(obj);
        });
        // this should solve the unselectability

        this.configureFabricObjectForDisplay(clonedObj, true);
        clonedObj.setCoords();
      } else {
        this.configureFabricObjectForDisplay(clonedObj, true);
        canvas.add(clonedObj);
        seitenansicht.allDataElement.push(clonedObj);
      }
      canvas.setActiveObject(clonedObj);
      resolveFunction(true);
    });
    return promise;
  }

  /** old method used in editor  */
  public polygonArea(coords: number[][]): number {
    let area = 0; // Accumulates area in the loop
    const numPoints = coords.length;
    let j = numPoints - 1; // The last vertex is the 'previous' one to the first
    let i = 0;

    for (i = 0; i < numPoints; i++) {
      area =
        area + (coords[j][0] + coords[i][0]) * (coords[j][1] - coords[i][1]);
      j = i; // j is previous vertex to i
    }
    return Math.abs(area / 2);
  }
  public getCenterPositionLargestEditArea(
    canvas: fabric.Canvas,
    currentSide: Seitenansicht
  ): Point {
    const elemWithMaxArea = this.findLargestEditArea(canvas, currentSide);
    let centerPosX = 0;
    let centerPosY = 0;
    if (elemWithMaxArea) {
      if (elemWithMaxArea instanceof Rectangle) {
        const rect: Rectangle = elemWithMaxArea;

        centerPosX = rect.x + rect.width / 2;
        centerPosY = rect.y + rect.height / 2;
      } else {
        const polygon: Polygon = elemWithMaxArea as Polygon;

        const centerPos = CentoidHelper.calculate(polygon.allPoint);
        centerPosX = centerPos.x;
        centerPosY = centerPos.y;
      }
      return new Point(centerPosX, centerPosY);
    } else {
      console.error('no element with max area found');
      return null;
    }
  }

  /**
   * Finds the largest edit area of the given Seitenansicht.
   * @param {Seitenansicht} currentSide the side to check all edit areas for.
   * @returns {GraphicElement} the element of the edit area with the largest size.
   */
  public findLargestEditArea(
    canvas: fabric.Canvas,
    currentSide: Seitenansicht
  ): GraphicElement {
    this.fixViewportTransform(canvas);

    let maxArea = 0;
    let elemWithMaxArea: GraphicElement = undefined;
    // Find Biggest Area
    for (let i = 0; i < currentSide.allEditierbereich.length; i++) {
      const elem = currentSide.allEditierbereich[i].graphicElement;
      const area = this.calculateArea(elem);
      if (area > maxArea) {
        maxArea = area;
        elemWithMaxArea = elem;
      }
    }
    return elemWithMaxArea;
  }

  private calculateArea = (elem: GraphicElement): number => {
    if (elem instanceof Rectangle) {
      const rect: Rectangle = elem;
      return rect.width * rect.height;
    } else {
      const polygon: Polygon = elem as Polygon;

      if (polygon.allPoint.length > 2) {
        const area = this.polygonArea(
          polygon.allPoint.map(point => [point.x, point.y])
        );
        return area;
      }
    }
    return 0;
  };
  private fixViewportTransform(canvas: fabric.Canvas) {
    // bugfix missing viewport transforms on canvas
    canvas.viewportTransform.forEach((value, index) => {
      if (!value) {
        canvas.viewportTransform[index] = 0;
      }
    });
  }

  /**
   * Positions the given object with its center point around the given center. So the center of the object always lies
   * on the given target X/Y coordinates.
   * @param {Object} image the object to position
   * @param {number} centerPosX the target center point
   * @param {number} centerPosY the target center point
   */
  private positionWithCenterPoint(
    image: fabric.Object,
    centerPosX: number,
    centerPosY: number
  ) {
    image.left = Math.max(0, centerPosX - (image.width * image.scaleX) / 2);
    image.top = Math.max(0, centerPosY - (image.height * image.scaleY) / 2);
  }

  /**
   * Adds the new fabric object to the canvas and positions it properly. It will also make sure that the object is
   * added to the data structure for saving to the server.
   * @param {Object} obj the object to add
   * @param {Seitenansicht} seitenansicht the seitenansicht to which the element will be added (server storage)
   * @param {boolean} centerOfViewPort if {@code true} then the element will be positioned in the center of the canvas
   *      if {@code false} then it will be positioned in the center of the largest edit area.
   */
  public addElementToCanvas(
    carSideManager: CarSideManager,
    canvas: fabric.Canvas,
    obj: fabric.Object,
    seitenansicht: Seitenansicht,
    centerOfViewPort: boolean = false,
    postionElement: boolean = true
  ) {
    if (postionElement) {
      if (centerOfViewPort) {
        this.positionInCenterOfViewPort(obj, carSideManager);
      } else {
        this.positionInCenterOfLargestEditArea(canvas, obj, seitenansicht);
      }
    }
    this.configureFabricObjectForDisplay(obj, true);
    canvas.add(obj);
    canvas.setActiveObject(obj);
    obj.setCoords();
    canvas.calcOffset();
    seitenansicht.allDataElement.push(obj);
  }

  private positionInCenterOfViewPort(
    obj: fabric.Object,
    carSideManager: CarSideManager
  ) {
    const viewBoxWidth = carSideManager.getViewboxWidth();
    const viewBoxHeight = carSideManager.getViewboxHeight();
    const centerPosX = viewBoxWidth / 2;
    const centerPosY = viewBoxHeight / 2;
    this.positionWithCenterPoint(obj, centerPosX, centerPosY);
  }

  private positionInCenterOfLargestEditArea(
    canvas: fabric.Canvas,
    obj: fabric.Object,
    currentSide: Seitenansicht
  ): void {
    const centerPos: Point = this.getCenterPositionLargestEditArea(
      canvas,
      currentSide
    );
    if (centerPos !== null && centerPos !== undefined) {
      this.positionWithCenterPoint(obj, centerPos.x, centerPos.y);
    } else {
      this.logger.warn(
        'Could not be positioned in center of largest edit area'
      );
    }
  }
  /**
   * adjustOnOversize
   * checks whether objectImage fits into a provided viewBox (and is max half as large).
   * A non-fitting objectImage is scaled by a factor depending
   * on its side ratio and a fixed scalar. Also it is ensured that the max size of the image is half the size of the
   * viewbox.
   * @param objectImage: fabric.Image
   * @private

   * @memberof KonfiguratorComponent
   */
  public adjustImageOnOversize(
    objectImage: fabric.Image,
    carSideManager: CarSideManager
  ): void {
    const viewBoxWidth = carSideManager.getViewboxWidth();
    const viewBoxHeight = carSideManager.getViewboxHeight();

    // FIXME: move to e.g. "ObjectHelperSevice"
    const tooWide = objectImage.width * objectImage.scaleX > viewBoxWidth / 2;
    const tooHigh = objectImage.height * objectImage.scaleY > viewBoxHeight / 2;
    if (tooWide || tooHigh) {
      const heightscale =
        (viewBoxHeight - objectImage.top) /
        (objectImage.height * objectImage.scaleY);
      const widthscale =
        (viewBoxWidth - objectImage.left) /
        (objectImage.width * objectImage.scaleX);
      const scale = heightscale > widthscale ? widthscale : heightscale;
      objectImage.scale(scale / 2);
    }
  }
}
