import { NGXLogger } from "ngx-logger";import { Injectable } from '@angular/core';

import { CONSTANTS } from '../../models/helpers/constants';
import { CustomImage } from '../../models/graphicelements/customimage';

@Injectable()
export class DimensionsHelperService {
  constructor(private logger: NGXLogger) {}

  public performQualityCheck(customImage: CustomImage): void {
    if (
      !customImage ||
      customImage.filename === undefined ||
      customImage.filename === null ||
      customImage.filename === ''
    ) {
      return;
    }
    if (
      customImage.filename.endsWith('.svg') ||
      customImage.filename.endsWith('.SVG')
    ) {
      return; // vector is always good quality
    }
    if (
      customImage.filename.endsWith('.eps') ||
      customImage.filename.endsWith('.EPS')
    ) {
      return; // vector is always good quality
    }
    if (
      customImage.filename.endsWith('.ai') ||
      customImage.filename.endsWith('.AI')
    ) {
      return; // vector is always good quality
    } else if (
      customImage.filename.endsWith('.pdf') ||
      customImage.filename.endsWith('.PDF')
    ) {
      return; // no quality checks for pdf
    }

    const {
      widthInMeter,
      heightInMeter,
      widthInInch,
      heightInInch,
      widthDPI,
      heightDPI,
    } = this.getFactorsAndParameters(customImage);

    this.logger.debug(`
    Checking Quality of: "${customImage.filename}
    Width: ${customImage.width}
    Height: ${customImage.height}
    Width-original: ${customImage.originalWidth}
    Height-original: ${customImage.originalHeight}
    Width (scaled): ${this.getScaledWidth(customImage)}
    Height (scaled): ${this.getScaledHeight(customImage)}
    Width in Meter: ${widthInMeter} m
    Height in Meter: ${heightInMeter} m
    Width in Inch: ${widthInInch}"
    Height in Inch: ${heightInInch}"
    Width DPI: ${widthDPI}
    Height DPI: ${heightDPI}
    IMAGE_MAXDPI allowed: ${CONSTANTS.IMAGE_MAXDPI}`);
    if (
      widthDPI < CONSTANTS.IMAGE_MAXDPI ||
      heightDPI < CONSTANTS.IMAGE_MAXDPI
    ) {
      customImage.badQuality = true;
    } else {
      customImage.badQuality = false;
    }
  }
  public scaleForQuality(customImage: CustomImage) {
    const { widthDPI, heightDPI } = this.getFactorsAndParameters(customImage);

    const minDPI = Math.min(widthDPI, heightDPI);
    customImage.badQuality = false;
    customImage.scaleX *=
      minDPI / (CONSTANTS.IMAGE_MAXDPI + CONSTANTS.SCALING_SAFETY_DPI);
    customImage.scaleY *=
      minDPI / (CONSTANTS.IMAGE_MAXDPI + CONSTANTS.SCALING_SAFETY_DPI);
  }

  public getScaledWidth(fObject: fabric.Object): number {
    return (
      (fObject.width + (fObject.strokeWidth ? fObject.strokeWidth : 0)) *
      fObject.scaleX
    );
  }
  public getScaledHeight(fObject: fabric.Object): number {
    return (
      (fObject.height + (fObject.strokeWidth ? fObject.strokeWidth : 0)) *
      fObject.scaleY
    );
  }
  /**
   * helper method to retrieve needed parameters
   * to reduce code duplication
   */
  private getFactorsAndParameters(customImage: CustomImage) {
    const widthInMeter =
      this.getScaledWidth(customImage) * CONSTANTS.VIEWBOX_TO_METER_FACTOR;
    const heightInMeter =
      this.getScaledHeight(customImage) * CONSTANTS.VIEWBOX_TO_METER_FACTOR;
    const widthInInch = widthInMeter * CONSTANTS.METER_TO_INCH_FACTOR;
    const heightInInch = heightInMeter * CONSTANTS.METER_TO_INCH_FACTOR;

    const cropPos = customImage.cropperPositionPercent;
    const cropscaleFactorX = cropPos ? cropPos.x2 - cropPos.x1 : 1;
    const widthOfImageOnCar =
      cropscaleFactorX * (customImage.originalWidth || customImage.width);
    const cropscaleFactorY = cropPos ? cropPos.y2 - cropPos.y1 : 1;
    const heightfImageOnCar =
      cropscaleFactorY * (customImage.originalWidth || customImage.width);

    const widthDPI = widthOfImageOnCar / widthInInch;
    const heightDPI = heightfImageOnCar / heightInInch;

    return {
      widthInMeter,
      heightInMeter,
      widthInInch,
      heightInInch,
      cropPos,
      cropscaleFactorX,
      widthOfImageOnCar,
      cropscaleFactorY,
      heightfImageOnCar,
      widthDPI,
      heightDPI,
    };
  }
}
