import { druckauftrag } from './../../../test/seitenansicht';
import { NGXLogger } from "ngx-logger";import { isCustomText } from 'models/graphicelements/customtext';
import { isCustomTriangle } from './../../models/graphicelements/customtriangle';
import { isCustomImage } from 'models/graphicelements/customimage';
import { DataService } from './dataservice';
import { EditorState } from 'models/statemodels/editorstate';
import { Injectable } from '@angular/core';
import { CONSTANTS } from 'models/helpers/constants';
import { CanvasHelperService } from './../services/canvashelperservice';
import * as fabric from 'fabric';
import * as _ from 'lodash';

/**
 * The StateStorageService is responsible for the undo/redo operations in the Konfigurator.
 * It therefore provides operations to clone the current State from the DataService
 * and saves / loads them to /from a stack (or an array is used as a stack)
 */
@Injectable()
export class StateStorageService {
  /**
   * key in sessionstorage for druckauftrag
   */
  private static readonly DRUCKAUFTRAG_KEY = 'druckauftrag';
  /**
   * key in sessionstorage for selected side
   */
  private static readonly SELECTED_SIDE_KEY = 'selectedside';
  /**
   * URL from components to the location of car images
   */
  private static readonly CAR_PATH_PREFIX = '../../assets/car-images/';

  // StateStorage Attributes

  private stateStorage: Array<EditorState> = new Array<EditorState>();
  private currentStorageIndex = -1;

  constructor(
    private dataService: DataService,
    private canvasHelperService: CanvasHelperService,
    private logger: NGXLogger
  ) {
    this.dataService.druckauftragLoadedEmitter.subscribe(() => {
      console.log('constructor State Storage');
      this.saveCurrentDataServiceStateAsInitialState();
    });
    this.dataService.saveStateEmitter.subscribe(this.saveState);
  }

  // getter
  public getSelectedSide = () => {
    return this.getSelectedSide;
  };

  // -------------------------------------------------------------------------------------------------------------------------------------
  // State storage Oparations
  // -------------------------------------------------------------------------------------------------------------------------------------
  /**
   * UI:is  REDO Button Disabled
   */
  public isRedoPossible = (): boolean => {
    return this.stateStorage.length - 1 > this.currentStorageIndex;
  };
  /**
   * UI:is  UNDO Button Disabled
   */
  public isUndoPossible = (): boolean => {
    return 0 < this.currentStorageIndex;
  };

  /**
   * saves the EditorState (adds it to the state array)
   */
  public saveState = (): Promise<EditorState> => {
    if (this.dataService.druckauftrag) {
      this.logger.debug('Save State');
      return this.canvasHelperService
        .refreshSideData(_.cloneDeep(this.dataService.druckauftrag))
        .then(druckauftrag => {
          const editorState: EditorState = new EditorState();
          editorState.selectedSide = this.dataService.getSelectedSide();
          editorState.druckauftrag = druckauftrag;
          this.currentStorageIndex = this.currentStorageIndex + 1;

          // limit max undo history, to avoid RAM from getting out of hand
          if (this.currentStorageIndex > CONSTANTS.MAX_UNDO_HISTORY_COUNT) {
            const delta =
              this.currentStorageIndex - CONSTANTS.MAX_UNDO_HISTORY_COUNT;
            this.stateStorage = this.stateStorage.slice(delta);
            this.currentStorageIndex -= delta;
          }

          // override "redo" in case a new change is made
          if (this.stateStorage[this.currentStorageIndex]) {
            this.stateStorage = this.stateStorage.slice(
              0,
              this.currentStorageIndex
            );
          }
          this.stateStorage.push(editorState);
          return editorState;
        });
    }
  };
  /**
   * retrieves old/previous State form Statestorage
   */
  public undo = () => {
    // reload data the canvas
    console.log(this.currentStorageIndex);
    if (this.currentStorageIndex < 0) {
      console.log('no Undo possible');
      return;
    }
    this.currentStorageIndex = this.currentStorageIndex - 1;
    console.log('State loaded from index: ');
    console.log(this.currentStorageIndex);
    this.loadState();
  };
  /**
   * Applies previously created State
   */
  public redo = () => {
    if (
      !this.stateStorage[this.currentStorageIndex] ||
      !this.stateStorage[this.currentStorageIndex + 1]
    ) {
      return;
    }
    this.currentStorageIndex = this.currentStorageIndex + 1;
    console.log('State reset to index: ');
    console.log(this.currentStorageIndex);
    this.loadState();
  };

  private cleanStateCache = () => {
    this.stateStorage = new Array<EditorState>();
    this.currentStorageIndex = -1;
  };
  /**
   * Loadsthe currently selected State to the stateStorage
   */
  private loadState = () => {
    const editorState: EditorState = this.stateStorage[
      this.currentStorageIndex
    ];
    if (editorState) {
      this.canvasHelperService
        .refreshSideData(_.cloneDeep(editorState.druckauftrag))
        .then(druckauftrag => {
          this.dataService.druckauftrag = druckauftrag;
          this.dataService.setSelectedSide(editorState.selectedSide);
          this.dataService.emitSelectedStateChanged();
        });
    }
  };
  /**
   * sets the druckauftrag of the inital state
   * this method is called after the druckauftrag was initialy loaded
   */
  public saveCurrentDataServiceStateAsInitialState = () => {
    console.log('State Storage, Save initial state:');
    this.saveState().then((editorState: EditorState) => {
      this.cleanStateCache();
      this.stateStorage.push(editorState);
      this.currentStorageIndex = 0;
    });
  };
}
