export class Template {
  public id: string;
  public name: string;
  public type: string;
  public previewImagePathStr: string;

  public staticPrice: number;
  public dynamicPrice: number;
  public carId: string;

  constructor(object?: any) {
    this.type = 'basic';
    if (object != null) {
      this.id = object.id;
      this.name = object.name;
      this.type = object.type; // minimal / basic

      this.previewImagePathStr = object.previewImagePathStr;
      this.staticPrice = object.staticPrice;
      this.dynamicPrice = object.dynamicPrice;
      this.carId = object.carId;
    }
  }
}
