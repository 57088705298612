import { DataService } from 'app/services/dataservice';
import { druckauftrag } from './../../../test/seitenansicht';
import { Fahrzeugseite } from 'models/fahrzeugeseite';
import { TranslateService } from '@ngx-translate/core';
import { Subscriber, Subscription } from 'rxjs';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { InitializationService } from '../services/initialization.service';
import { MatIconRegistry } from '@angular/material/icon';
import { disableDebugTools, DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-track-selection',
  templateUrl: './track-selection.component.html',
  styleUrls: ['./track-selection.component.scss'],
})
export class TrackSelectionComponent implements OnInit {
  selectedOption = 0;
  routeSubscription: Subscription;

  public imagePathFastTrack: string;
  public imagePathEditor: string;
  public showLoadingCycle = true;
  // TODO: implement
  public languageChangedEmitter = new EventEmitter<void>();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initializationService: InitializationService,
    private translateService: TranslateService,
    private dataService: DataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.dataService.druckauftrag = undefined;
    console.log('TrackSelectionComponent: constructor');
    if (!this.routeSubscription) {
      this.initializationService.hideLoadingCicleEmitter.subscribe(() => {
        this.showLoadingCycle = false;
      });
      this.initializationService.setLangageSetEmitter(
        this.languageChangedEmitter
      );
      this.languageChangedEmitter.subscribe(() => {
        this.setImageContent();
      });
      this.setImageContent();
      this.routeSubscription = this.activatedRoute.queryParams.subscribe(
        this.initializationService.handleParameters
      );
    }
    this.matIconRegistry.addSvgIcon(
      'preview-next-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/V2/icon-preview-next.svg'
      )
    );
  }
  private setImageContent = (): void => {
    if (this.translateService.currentLang) {
      const lang = this.translateService.currentLang.toUpperCase();
      this.imagePathFastTrack =
        '../assets/fasttrack/trackselect_fasttrack-' + lang + '.jpg';
      this.imagePathEditor =
        '../assets/fasttrack/trackselect_editor-' + lang + '.jpg';
    }
  };

  ngOnInit() {
    // console.log('TrackSelectionComponent: on Init');
  }

  public get isFastTrackDisabled() {
    return (
      this.initializationService && this.initializationService.isTopsystemActive
    );
  }

  public getClass(value: number) {
    if (value === 1 && this.isFastTrackDisabled) {
      return 'disabled-fasttrack';
    }
    if (value === this.selectedOption) {
      return 'selected';
    }
    return '';
  }
  public selectEditor(): void {
    this.selectedOption = 2;
  }

  public selectFastTrack(): void {
    this.selectedOption = 1;
  }
  public hasSelection(): boolean {
    if (this.selectedOption !== 0) {
      return true;
    }
    return false;
  }

  public nextView(): void {
    if (this.selectedOption === 1) {
      this.router.navigate(['/fast-track']);
    } else if (this.selectedOption === 2) {
      // initialize Druckauftrag
      this.router.navigate(['/konfigurator']);
    }
  }
}
