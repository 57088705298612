import { Injectable } from '@angular/core';
import { DataService } from './dataservice';

interface ConversionResults {
  content: string; width: number; height: number
}

@Injectable()
export class FileReaderService {
  constructor(private dataService: DataService) {}

  public fileReadPromise(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reader.abort();
        reject('could not read file');
      };
      reader.readAsDataURL(file);
    });
  }

  public emitConvertedImages(
    file: File,
    fileReadPromise: Promise<string>
  ): Promise<void> {
    const promiseArray: [Promise<ConversionResults>, Promise<string>] = [
      this.dataService.convertImage(file), // 2. request conversion from server
      fileReadPromise,
    ];
    return Promise.all(promiseArray).then(results => {
      // get both images as data-uri
      const convertedImagInfo = results[0];
      const originalImageContent = results[1];

      if (
        convertedImagInfo &&
        convertedImagInfo.content &&
        convertedImagInfo.content !== ''
      ) {
        return this.dataService.imageConvertedEmitter.emit({
          convertedImage: convertedImagInfo.content,
          originalImage: {
            content: originalImageContent,
            originalWidth: convertedImagInfo.width,
            originalHeight: convertedImagInfo.height,
          },
          file,
        });
      }
      return Promise.resolve();
    });
  }

  /**
   * Function returning promise checks whether file is PDF File.
   *   if no, it resolves to true
   *   if yes, it reads in file and checks whether it contains vector data only. Resolves to true if yes and false if no.
   * @param file: the file to be checked
   * @returns
   */
  checkFileIsVectorPDF(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isPDFFile(file)) {
        const reader = new FileReader();

        // WReding successful
        reader.onload = () => {
          //resolve(reader.result);
          if (this.isPDFContainsVectorData(reader.result as ArrayBuffer)) {
            resolve(true);
          }
          resolve(false);
        };

        // Error on reading
        reader.onerror = () => {
          reject("could not read file: " + reader.error);
        };

        // Read file as Text
        reader.readAsArrayBuffer(file);
      }
      else {
        resolve(true);
      }

    });
  }

  /**
  * evaluates if the file is a PDF file
  * @param file: the file to be checked
  * @returns true if file is a PDF file
  */
  private isPDFFile = (file: File): boolean => {
    const acceptedPDFFileEnding = [
      'pdf',
      'PDF'
    ];
    const result = acceptedPDFFileEnding.filter(fileEnding =>
      file.name.endsWith('.' + fileEnding)
    );
    return result.length >= 1;
  };

  /**
   * Function checks whether file contains vector data only. If not, return false, else true
   * @param content: the file content read in by fileService
   * @returns false if PDF file contains no vector data
   */
  private isPDFContainsVectorData = (content: ArrayBuffer): boolean => {
    const textDecoder = new TextDecoder();
    const textContent = textDecoder.decode(content)

    // If textContent contains "/Subtype/Image" or "/Subtype /Image" then a image is embedded -> no vector
    // If textContent contains "/Subtype/Form" or "/Subtype /Form" or a number followed by space and a PostScript operator, then a vector is embedded
    // Hint: if RegEx value should be delivered with ", every \ has to be escaped, so "\" -> "\\""
    const imagePattern = new RegExp(/\/Subtype\s{0,1}\/Image/);
    const vectorPattern = new RegExp(/\/Subtype\s{0,1}\/Form|(\d+\s+[mlhvcreWn])/);

    if (imagePattern.test(textContent)) {
      return false;
    }
    else if (vectorPattern.test(textContent)) {
      return true;
    }
    return false;
  };
}
