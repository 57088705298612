import { Injectable } from '@angular/core'
import { fabric } from 'fabric'

@Injectable({
  providedIn: 'root',
})
export class CanvasSelectionService {
  private activeObject: fabric.Object
  private selectedObjects: fabric.Object[]
  private canvas: fabric.Canvas
  constructor() {}

  get allSelectedObject() {
    return this.selectedObjects
  }

  public discardAndStoreSelection(canvas: fabric.Canvas) {
    this.activeObject = canvas.getActiveObject()
    this.selectedObjects = canvas.getActiveObjects()
    this.canvas = canvas
    this.canvas.discardActiveObject()
  }

  public restoreSelection() {
    if (this.activeObject && this.activeObject.type === 'activeSelection') {
      const selection = new fabric.ActiveSelection(this.selectedObjects, {
        canvas: this.canvas,
      })
      this.canvas.setActiveObject(selection)
    } else if (this.activeObject) {
      this.canvas.setActiveObject(this.activeObject)
    }
  }
}
