import { CropperPosition } from 'ngx-image-cropper';
import { AzureImage } from './../../models/communcation/AzureImage';
import { CarDataManagerService } from 'app/services/car-data-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { FastTrackData } from 'models/fasttrackdata';
import { PriceCalculatorService } from 'app/services/price-calculator.service';
import { DruckauftragCreatorService } from './druckauftragcreator.service';
import { Observable, Subscriber, from, forkJoin, of, lastValueFrom } from 'rxjs'; // FIXME: forkjoin, from and of are deprecated
import { CONSTANTS } from 'models/helpers/constants';
import { Car } from 'models/car';
import { RestDataService } from './restdataservice';
import { Template } from 'models/template';

import { Seitenansicht } from 'models/seitenansicht';

import { Druckauftrag } from 'models/druckauftrag';
import { Fahrzeugseite } from 'models/fahrzeugeseite';
import { EventEmitter, Injectable, OnInit } from '@angular/core';

import * as fabric from 'fabric';

import { UserType } from '../../models/communcation/UserType';
import { Router, Params } from '@angular/router';
import { CustomImage } from 'models/graphicelements/customimage';
/**
 * This service stores the data of the application.
 * As most central data there is the druckauftrag
 */
@Injectable()
export class DataService implements OnInit {
  /**
   * URL from components to the location of car images
   */
  private static readonly CAR_PATH_PREFIX = '../../assets/car-images/';

  public sideUpdatedEmitter: EventEmitter<any> = new EventEmitter();
  public stateUpdateEmitter: EventEmitter<any> = new EventEmitter();
  public druckauftragLoadedEmitter: EventEmitter<
    Druckauftrag
  > = new EventEmitter();
  public saveStateEmitter: EventEmitter<void> = new EventEmitter();
  public fastTrackDataLoadedEmitter: EventEmitter<void> = new EventEmitter();

  public imageConvertedEmitter: EventEmitter<{
    convertedImage: string;
    originalImage: {
      originalWidth: number;
      originalHeight: number;
      content: string;
    };
    file: File;
    azureImage?: AzureImage;
  }> = new EventEmitter();

  public loadingCycleEmitter: EventEmitter<boolean> = new EventEmitter();
  public editModeFalseEmitter: EventEmitter<void> = new EventEmitter();

  // allEditAreaLoadedPromise
  private resolveAllEditareaLoaded: (value?: void | PromiseLike<void>) => void;
  public allEditAreaLoadedPromise: Promise<void> = new Promise(resolve => {
    this.resolveAllEditareaLoaded = resolve;
  });
  public druckauftrag: Druckauftrag;
  private selectedSide: Fahrzeugseite;
  public canvas: fabric.fabric.Canvas;

  public allSortimoImage: AzureImage[] = [];

  public vehicleColors: string[] = [];
  public isLoadingDruckauftrag = false;
  public isFastTrackDataReplacedAndInitialized = true;
  public fastTrackData: FastTrackData;

  public druckauftragAndTemplateInitialized = false;
  public isDruckauftragCreationRunning = false;

  public currencyFactor: number;
  public currency: string;
  public tax;
  public countryHourRate;
  public country: string;
  public configMode: 'quote' | 'normal';
  public readOnly: boolean;
  public userType: UserType;
  public hybrisConfigId: string;

  public isTopSystemActive = false;

  constructor(
    private restDataService: RestDataService,
    private router: Router,
    private druckauftragCreatorService: DruckauftragCreatorService,
    private priceCalculatorService: PriceCalculatorService,
    private carManager: CarDataManagerService,
    private translateService: TranslateService
  ) {}

  public getSelectedSide = (): Fahrzeugseite => {
    return this.selectedSide;
  };
  public setSelectedSide = (side: Fahrzeugseite) => {
    this.selectedSide = side;
  };

  ngOnInit() {}

  public emitSelectedSideChanged = () => {
    this.sideUpdatedEmitter.emit({});
  };
  public emitSelectedStateChanged = () => {
    this.stateUpdateEmitter.emit({});
  };

  public async setDruckauftrag(
    druckauftrag: Druckauftrag,
    country: string,
    language: string,
    mergeTemplate: boolean = true
  ): Promise<any> {
    this.druckauftrag = druckauftrag;
    this.priceCalculatorService.updateDruckauftragPrice(
      druckauftrag,
      this.countryHourRate,
      this.currencyFactor,
      this.userType,
      this.country
    );
    await this.carManager.setCarId(
      druckauftrag.car.id,
      language,
      country,
      this.currencyFactor,
      this.countryHourRate
    );
    this.druckauftragAndTemplateInitialized = true;
    this.selectedSide = Fahrzeugseite.BEIFAHRER;

    if (mergeTemplate) {
      return Promise.all([
        this.selectDefaultTempalte(),
        this.initializeEditareas(),
      ]);
    }
    if (!this.druckauftrag.selectedTemplate) {
      this.druckauftrag.selectedTemplate = this.carManager.allBasicTemplate[0];
    }
    await Promise.all([
      lastValueFrom(this.selectTemplate(this.druckauftrag.selectedTemplate, false, false)),
      lastValueFrom(from(this.initializeEditareas())),
    ]);
    /* return Promise.all([
      this.selectTemplate(
        this.druckauftrag.selectedTemplate,
        false,
        false
      ).toPromise(),
      this.initializeEditareas(),
    ]); */
  }

  public getSupportUrl(): string {
    if (!document.referrer) {
      return '';
    }
    const supportLinkMap = new Map<string, string>();
    supportLinkMap.set(
      'de',
      'https://www.mysortimo.de/de/support/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'en',
      'https://www.mysortimo.de/en/support/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'de_at',
      'https://www.mysortimo.at/de_AT/support/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'en_uk',
      'https://www.mysortimo.co.uk/en_UK/support/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'en_ie',
      'https://www.mysortimo.co.uk/en_UK/support/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'fr',
      'https://www.mysortimo.fr/fr/assistance/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'nl',
      'https://www.mysortimo.nl/nl/service/veelgestelde-vragen#comp_00001ANM'
    );
    supportLinkMap.set(
      'nl_be',
      'https://www.mysortimo.be/nl_BE/hulp/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'fr_be',
      'https://www.mysortimo.be/fr_BE/assistance/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'da',
      'https://www.mysortimo.dk/da/support/oss#comp_00001ANM'
    );
    supportLinkMap.set(
      'sv_se',
      'https://www.mysortimo.se/sv_SE/support/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'no',
      'https://www.mysortimo.no/no/support/faq#comp_00001ANM'
    );
    supportLinkMap.set(
      'es',
      'https://www.mysortimo.es/es/asistencia/faq#comp_00001ANM'
    );
    const currentLang = this.translateService.currentLang.toLowerCase();
    const linkForLang = supportLinkMap.get(currentLang);
    return linkForLang;
  }
  public openSupportPage() {
    const url = this.getSupportUrl();
    window.open(url, '_blank');
  }

  /*public initializeDruckauftrag = (
    car: Car,
    carColor: string,
    country: string,
    language: string,
    currency: string,
    defaultTemplate?: string
  ): Promise<any> => {
    this.isLoadingDruckauftrag = true;

    if (!car || !car.id) {
      console.error('car or id not defined');
      return;
    }
    if (this.isInitializationNotNecessary(car, country)) {
      this.selectedSide = Fahrzeugseite.BEIFAHRER;
      this.druckauftragAndTemplateInitialized = true;
      return Promise.all([
        this.selectDefaultTempalte(defaultTemplate),
        this.initializeEditareas(),
      ]);
    }

    this.druckauftrag = this.createDruckauftrag(
      car,
      carColor,
      country,
      language,
      currency
    );

    return this.carManager
      .setCarId(
        car.id,
        this.druckauftrag.language,
        this.druckauftrag.country,
        this.currencyFactor,
        this.countryHourRate
      )
      .then(() => {
        this.druckauftragAndTemplateInitialized = true;
        this.selectedSide = Fahrzeugseite.BEIFAHRER;
        return Promise.all([
          this.selectDefaultTempalte(defaultTemplate),
          this.initializeEditareas(),
        ]);
      });
  };*/


  private async selectDefaultTempalte(defaultTemplate?: string): Promise<void> {
    let tmplt = undefined;
    return this.carManager.templateDataLoadedPromise.then(async () => {
      if (defaultTemplate && defaultTemplate.indexOf('minimal') >= 0) {
        tmplt = this.carManager.allMinimalTemplate[0];
      } else {
        tmplt = this.isTopSystemActive
          ? this.carManager.allMinimalTemplate[0]
          : this.carManager.allBasicTemplate[0] ||
            this.carManager.allMinimalTemplate[0];
      }
      return await lastValueFrom(this.selectTemplate(tmplt, true));
    });
  }

  private isInitializationNotNecessary(car: Car, country: string) {
    return (
      this.druckauftrag &&
      car !== undefined &&
      car.id !== undefined &&
      this.druckauftrag &&
      this.druckauftrag.car &&
      car.id === this.druckauftrag.car.id
    );
  }

  private async initializeEditareas(): Promise<void> {
    await lastValueFrom(this.druckauftragCreatorService.initEditareas(this.druckauftrag));
    console.log('Editareas are initialized');
    this.resolveAllEditareaLoaded();
    return Promise.resolve(); // Return a resolved Promise<void>

/*     return this.druckauftragCreatorService
      .initEditareas(this.druckauftrag)
      .toPromise()
      .then(() => {
        console.log('Editareas are initialized');
        this.resolveAllEditareaLoaded();
      });
 */  }

  public selectTemplate(
    template: Template,
    updateCarSide?: boolean,
    resetTemplates?: boolean
  ): Observable<void> {
    console.log('selectTemplate');
    if (!template) {
      console.log('no Template passed');
    }

    const promise = this.carManager
      .selectTemplate(template, this.druckauftrag, resetTemplates)
      .then(() => {
        // do not update only when indicated via function call
        this.druckauftrag.selectedTemplate = template;
        if (
          updateCarSide !== null &&
          updateCarSide !== undefined &&
          updateCarSide
        ) {
          this.sideUpdatedEmitter.emit({});
        }
      });
    // update TemplateData
    return from(
      Promise.all([promise, this.allEditAreaLoadedPromise]).then(
        this.calculatePriceAndSaveState
      )
    );
  }
  public calculatePriceAndSaveState = () => {
    this.priceCalculatorService.updateDruckauftragPrice(
      this.druckauftrag,
      this.countryHourRate,
      this.currencyFactor,
      this.userType,
      this.country
    );
    this.saveStateEmitter.emit();
  };
  public getParams(): Params {
    const params = [];
    params[CONSTANTS.HYBRIS_CONFIG_ID_KEY] = this.hybrisConfigId;
    params[CONSTANTS.HYBRIS_READ_ONLY_KEY] = this.readOnly;
    params[CONSTANTS.HYBRIS_CONFIG_MODE_KEY] = this.configMode || 'normal';
    params[CONSTANTS.HYBRIS_USERTYPE_KEY] = this.userType;

    return params;
  }

  /**
   * will convert the given image to png
   * @param img file to convert
   * @returns the converted image as data-uri
   */
  public convertImage(
    img: File
  ): Promise<{ content: string; width: number; height: number }> {
    return this.restDataService.remoteConvertImage(img, this.getParams());
  }

  public mirrorImage(
    img: CustomImage
  ): Promise<{
    src: string;
    nativeSrc: string;
    croppedDisplayImage: string;
    cropInfo: CropperPosition;
  }> {
    return this.restDataService.mirrorImage(img, this.getParams());
  }

  public convertSorimoDbImage(
    id: string
  ): Promise<{ original: string; preview: string }> {
    return this.restDataService.remoteConvertSortimoImage(id, this.getParams());
  }
  public getSeitenansichtForSelectedSide = (): Seitenansicht => {
    if (!this.druckauftrag) {
      return undefined;
    }
    if (this.selectedSide !== null && this.selectedSide !== undefined) {
      switch (this.selectedSide) {
        case Fahrzeugseite.FAHRER:
          return this.druckauftrag.links;
        case Fahrzeugseite.BEIFAHRER:
          return this.druckauftrag.rechts;
        case Fahrzeugseite.FRONT:
          return this.druckauftrag.front;
        case Fahrzeugseite.HECK:
          return this.druckauftrag.heck;
        default:
          console.log('selected side does not exist');
          this.selectedSide = Fahrzeugseite.BEIFAHRER;
          if (this.druckauftrag) {
            return this.druckauftrag.rechts;
          } else {
            return undefined;
          }
      }
    }
  };
  public navigateToPreview() {
    this.router.navigate(['/preview']);
  }
  public navigateToKonfigurator() {
    this.router.navigate(['/konfigurator']);
  }

  public async createLiveDruckauftrag(obj: any): Promise<Druckauftrag> {
    const newDruckauftrag = new Druckauftrag(obj);
    if (!obj.front) {
      throw new Error('keine front seitenansicht vorhanden!');
    }
    if (!obj.heck) {
      throw new Error('keine heck seitenansicht vorhanden!');
    }
    if (!obj.links) {
      throw new Error('keine links seitenansicht vorhanden!');
    }
    if (!obj.rechts) {
      throw new Error('keine rechts seitenansicht vorhanden!');
    }
    const promiseArr: Promise<Seitenansicht>[] = [
      this.createLiveSeitenansicht(obj.front),
      this.createLiveSeitenansicht(obj.heck),
      this.createLiveSeitenansicht(obj.links),
      this.createLiveSeitenansicht(obj.rechts),
    ];

    return Promise.all(promiseArr).then(ansichten => {
      newDruckauftrag.setSeitenansichten(
        ansichten[0],
        ansichten[1],
        ansichten[2],
        ansichten[3]
      );
      return newDruckauftrag;
    });
  }

  private createLiveSeitenansicht(obj: any): Promise<Seitenansicht> {
    const newSeitenAnsicht: Seitenansicht = new Seitenansicht(obj);

    return new Promise(resolve => {
      (<any>fabric.fabric).util.enlivenObjects(
        obj.allDataElement,
        (allObject: fabric.fabric.Object[]) => {
          this.cloneAllDataElementArrayForSeitenansicht(
            newSeitenAnsicht,
            allObject
          );
          console.log('seitenansicht now alive');
          resolve(newSeitenAnsicht);
        }
      );
    });
  }

  /**
   * Takes a copy of the given source objects and sets it in the seitenansicht object. It will specifically create a
   * new array and not take the original array (which might be modified by the canvas, e.g.).
   * @param {Seitenansicht} seitenAnsicht the seitenansicht to update
   * @param {Object[]} objectsToCopyOver the original objects which will be used as is, but the source object array
   * will not be referenced
   */
  public async cloneAllDataElementArrayForSeitenansicht(
    seitenAnsicht: Seitenansicht,
    objectsToCopyOver: Object[]
  ): Promise<void> {
    seitenAnsicht.allDataElement = [];

    objectsToCopyOver.forEach(function (
      o: fabric.fabric.Object,
      index: number
    ) {
      if (o.backgroundColor === ' ') {
        o.backgroundColor = '';
      }
      o.setCoords();
      seitenAnsicht.allDataElement[index] = o;

      if (seitenAnsicht.allDataElement.length === objectsToCopyOver.length) {
        return Promise.resolve();
      }
    });
  }

  /**
   * create new Druckauftragfor a specific car and a specific side
   */
  private createSeitenansicht = (
    car: Car,
    side: Fahrzeugseite,
    languageCountryCombination: string
  ): Seitenansicht => {
    const seitenansicht: Seitenansicht = new Seitenansicht();
    seitenansicht.fahrzeugseite = side;
    seitenansicht.allEditierbereich = undefined;
    seitenansicht.allDataElement = [];
    // reads front and heck
    const sidename = Fahrzeugseite[side].toLowerCase();
    if (car && car.id) {
      seitenansicht.fahrzeugPath =
        DataService.CAR_PATH_PREFIX +
        car.id +
        '/' +
        languageCountryCombination.toUpperCase() +
        '/' +
        car.id +
        CONSTANTS.ID_SEPARATOR +
        sidename +
        '-' +
        languageCountryCombination.toUpperCase() +
        '.svg';
    }
    return seitenansicht;
  };
}
