import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { XMLSerializer } from '@xmldom/xmldom';
import { CarSideManager } from 'app/services/car-side-manager';
import { Component, OnInit } from '@angular/core';
import { fabric } from 'fabric';
@Component({
  selector: 'app-car-side-handler',
  templateUrl: './car-side-handler.component.html',
  styleUrls: ['./car-side-handler.component.css'],
})
export class CarSideHandlerComponent implements OnInit {
  private carSideManager: CarSideManager;
  public htmlContent: SafeHtml;

  constructor(private readonly domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.updateHtml();
  }

  public updateHtml(): void {
    let htmlContent;
    if (this.carSideManager) {
      const serializer = new XMLSerializer();
      const serializedHtml = serializer.serializeToString(
        this.carSideManager.getDocument()
      );
      if (this.htmlContent !== serializedHtml) {
        htmlContent = serializedHtml;
      }
    } else {
      htmlContent = '<div> nothing here</div>';
    }
    this.htmlContent = this.domSanitizer.bypassSecurityTrustHtml(htmlContent);
  }

  public setCarSideManager(carSideManager: CarSideManager) {
    this.carSideManager = carSideManager;
    if (this.carSideManager) {
      this.carSideManager.datachangeEmitter.subscribe(() => {
        this.updateHtml();
      });
    }
    this.updateHtml();
  }
  public getScaledWidth(): number {
    const factor = this.getScaleToCanvasFactor();
    if (this.carSideManager) {
      return this.carSideManager.getViewboxWidth() * factor;
    }
    return 0;
  }
  public getScaledHeight(): number {
    if (this.carSideManager) {
      return (
        this.carSideManager.getViewboxHeight() * this.getScaleToCanvasFactor()
      );
    }
    return 0;
  }

  public getScaleToCanvasFactor(): number {
    const maxWidth = 1200;
    const maxHeight = 520;
    if (this.carSideManager) {
      const factorwidth = maxWidth / this.carSideManager.getViewboxWidth();
      const factorheight = maxHeight / this.carSideManager.getViewboxHeight();
      const factor = Math.min(factorwidth, factorheight);

      return factor;
    }
    return 1;
  }
}
