import { Druckauftrag } from './../druckauftrag';
import { UserType } from './UserType';

/**
 * Start information Contains Details of configuration(vehicle information, preisinformation etc..)
 */
export class StartInformation {
  public static createStartInformation(obj: any) {
    return new StartInformation(
      obj.language,
      obj.country,
      obj.currency,
      obj.configMode,
      obj.carName,
      obj.readOnly,
      obj.userType,
      obj.currencyFactor,
      obj.countryHourRate,
      obj.vehicleId,
      obj.configId,
      obj.vehicleColor,
      obj.vehicleColors,
      obj.branch,
      obj.tax,
      obj.druckauftrag
    );
  }

  constructor(
    public language: string,
    public country: string,
    public currency: string,
    public configMode: 'quote' | 'normal',
    public carName: string,
    public readOnly: boolean,
    public userType: UserType,
    public currencyFactor: number,
    public countryHourRate: number,
    public vehicleId: string,
    public configId: string,
    public vehicleColor: string,
    public vehcleColors: string[],
    public branch: string,
    public tax: number,
    public druckauftrag?: Druckauftrag
  ) {}
}
