import {
  CustomImage,
  isCustomImage,
} from '../../models/graphicelements/customimage';
import { Fahrzeugseite } from '../../models/fahrzeugeseite';
import { IntersectionHelperService } from './intersection-helper.service';
import { Seitenansicht } from '../../models/seitenansicht';
import { Injectable } from '@angular/core';
import { Point } from 'fabric/fabric-impl';
import { isCustomText } from 'models/graphicelements/customtext';
import { UserType } from 'models/usertype';

@Injectable({
  providedIn: 'root',
})
export class WarningManagerService {
  private readonly warningOverlayImage = new Image();
  private readonly errorOverlayImage = new Image();
  private readonly allIntersection: Map<Fahrzeugseite, Map<any, any[]>>;
  constructor(
    private readonly intersectionHelperService: IntersectionHelperService
  ) {
    this.warningOverlayImage.src = '../../assets/icons/V2/icon-warnung.svg';
    this.errorOverlayImage.src = '../../assets/icons/V2/icon-stop.svg';
    this.allIntersection = new Map();
  }

  public get isShowWarningOverlay(): boolean {
    return true;
  }
  public get isShowErrorOverlay(): boolean {
    return true;
  }
  public getCarSideContainsErrors(seitenansicht: Seitenansicht) {
    return seitenansicht.hasError;
  }

  public updateIntersections(seitenansicht: Seitenansicht) {
    this.allIntersection.set(
      seitenansicht.fahrzeugseite,
      this.intersectionHelperService.getAllIntersection(seitenansicht)
    );
  }

  private isAdaptionNeeded(fahrzeugseite: Fahrzeugseite, o: fabric.Object) {
    return (
      this.isNotIncluded(fahrzeugseite, o) ||
      (o as CustomImage).badQuality ||
      this.isPartial(fahrzeugseite, o)
    );
  }

  private drawObjectWarningErrorForObject(
    context: CanvasRenderingContext2D,
    factor: number,
    allObject: fabric.Object[],
    allUngroupedCoordsArray: {
      bl: Point;
      br: Point;
      tl: Point;
      tr: Point;
    }[],
    fahrzeugseite: Fahrzeugseite,
    o: fabric.Object,
    isInEditMode: boolean,
    isTopSystemActive: boolean,
    userType: UserType
  ) {
    context.lineWidth = 3;

    const bl = allUngroupedCoordsArray[allObject.indexOf(o)].bl;
    const br = allUngroupedCoordsArray[allObject.indexOf(o)].br;
    const tr = allUngroupedCoordsArray[allObject.indexOf(o)].tr;
    const tl = allUngroupedCoordsArray[allObject.indexOf(o)].tl;
    this.drawObjectRect(context, bl, br, tr, tl, factor);
    let image: HTMLImageElement;
    if (
      userType !== UserType.DESIGNUSER &&
      (this.isNotIncluded(fahrzeugseite, o) || (o as CustomImage).badQuality)
    ) {
      context.strokeStyle = 'red';
      image = this.errorOverlayImage;
      context.stroke();
    } else if (this.isPartial(fahrzeugseite, o) && isInEditMode) {
      if (
        !(
          isTopSystemActive &&
          isCustomText(o) &&
          !this.isTopsysIntersectLR(fahrzeugseite, o)
        )
      ) {
        context.strokeStyle = '#FFDE00';
        image = this.warningOverlayImage;
        context.stroke();
      }
    }
    context.strokeStyle = 'transparent';
    if (image) {
      context.save();

      context.translate(tl.x * factor, tl.y * factor);
      context.rotate(o.angle * (Math.PI / 180));

      const scaleFactor = this.calculateImageScaleFactor(o, image, factor);

      const objectStroke = !o.strokeWidth ? 0 : o.strokeWidth;

      let x =
        ((o.width + objectStroke) * o.scaleX * factor -
          image.width * scaleFactor) /
        2;
      let y =
        ((o.height + objectStroke) * o.scaleY * factor -
          image.height * scaleFactor) /
        2;
      x = x > 0 ? x : 0;
      y = y > 0 ? y : 0;
      context.scale(scaleFactor, scaleFactor);
      context.drawImage(image, x / scaleFactor, y / scaleFactor);
      context.restore();
    }
  }
  public drawAllCanvasWarning(
    context: CanvasRenderingContext2D,
    factor: number,
    allObject: fabric.Object[],
    allUngroupedCoordsArray: {
      bl: Point;
      br: Point;
      tl: Point;
      tr: Point;
    }[],
    fahrzeugseite: Fahrzeugseite,
    isInEditMode: boolean,
    isTopSystemActive: boolean,
    userType: UserType
  ) {
    context.lineWidth = 3;
    context.strokeStyle = 'transparent';
    allObject.forEach(o => {
      if (this.isAdaptionNeeded(fahrzeugseite, o)) {
        this.drawObjectWarningErrorForObject(
          context,
          factor,
          allObject,
          allUngroupedCoordsArray,
          fahrzeugseite,
          o,
          isInEditMode,
          isTopSystemActive,
          userType
        );
      }
    });
  }

  /**
   * calculates the scaleFactor for correct with of the warning/error image on an element
   * @param o
   * @param image
   * @param factor
   */
  private calculateImageScaleFactor(
    o: fabric.Object,
    image: HTMLImageElement,
    factor: number
  ): number {
    let xScale = 1;
    let yScale = 1;
    // scale width /height
    if (image.width > o.width * o.scaleX * factor) {
      xScale = (o.width * o.scaleX * factor) / image.width;
    }

    if (image.height > o.height * o.scaleY * factor) {
      yScale = (o.height * o.scaleY * factor) / image.height;
    }
    return Math.min(xScale, yScale);
  }
  /**
   * adds rectangle to the CanvasRenderingContext2D
   */
  private drawObjectRect(
    context: CanvasRenderingContext2D,
    bl: Point,
    br: Point,
    tr: Point,
    tl: Point,
    factor: number
  ) {
    context.beginPath();
    context.moveTo(bl.x * factor, bl.y * factor);
    context.lineTo(br.x * factor, br.y * factor);
    context.lineTo(tr.x * factor, tr.y * factor);
    context.lineTo(tl.x * factor, tl.y * factor);
    context.closePath();
  }

  public isNotIncluded(
    fahrzeugseite: Fahrzeugseite,
    o: fabric.Object
  ): boolean {
    const intersection = this.allIntersection.get(fahrzeugseite).get(o);
    return !intersection || intersection.length === 0;
  }
  public isPartial(fahrzeugseite: Fahrzeugseite, o: fabric.Object): boolean {
    const intersection = this.allIntersection.get(fahrzeugseite).get(o);
    return intersection && intersection.length > 0 && intersection[0].partialy;
  }
  public isTopsysIntersectLR(
    fahrzeugseite: Fahrzeugseite,
    o: fabric.Object
  ): boolean {
    const intersection: {
      partial: boolean;
      included: boolean;
      intersectLR: boolean;
    }[] = this.allIntersection.get(fahrzeugseite).get(o);
    return (
      intersection && intersection.length > 0 && intersection[0].intersectLR
    );
  }
}
