import { Point } from '../../../models/graphicelements/point';

export class CentoidHelper {
  /**
   * Calculate the centoid (arithmetic mean position of all the points)
   * for a given polgon (array of points). It is assumed that the points are
   * in the order of their occurence along the polygon's perimiter.
   * @param polygon the polygon as array of points
   * @returns the centoid
   */
  public static calculate(polygon: Array<Point>): Point {
    const poly = polygon.slice(); // don't modify the original array
    poly.push(poly[0]); // make the polygons wrap

    // polygons area with shoelance formula (en.wikipedia.org/wiki/Shoelace_formula)
    let surface = 0;
    for (let i = 0; i < poly.length - 1; i++) {
      surface += poly[i].x * poly[i + 1].y - poly[i + 1].x * poly[i].y;
    }
    surface = surface / 2;

    // calculate centoid point as described here: en.wikipedia.org/wiki/Centroid#Of_a_polygon
    // x coordinate of centoid
    let c_x = 0;
    // y coordinate of centoid
    let c_y = 0;
    for (let i = 0; i < poly.length - 1; i++) {
      const f = poly[i].x * poly[i + 1].y - poly[i + 1].x * poly[i].y;
      c_x += (poly[i].x + poly[i + 1].x) * f;
      c_y += (poly[i].y + poly[i + 1].y) * f;
    }
    c_x = (1 / (6 * surface)) * c_x;
    c_y = (1 / (6 * surface)) * c_y;

    return new Point(c_x, c_y);
  }
}
