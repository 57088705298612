import { NGXLogger } from "ngx-logger";import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DataService } from '../../services/dataservice';
import { CONSTANTS } from '../../../models/helpers/constants';
import { ConfirmDialogComponent } from '../../dialogs/confirmdialog.component';
import { FileReaderService } from '../../services/file-reader-service';

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { UserType } from 'models/communcation/UserType';

@Component({
  selector: 'app-upload-dialog-own-image',
  templateUrl: './upload-dialog-own-image.component.html',
  styleUrls: ['./upload-dialog-own-image.component.scss'],
  providers: [FileReaderService],
})
export class UploadDialogOwnImageComponent implements OnInit, OnDestroy {
  @ViewChild('imageInput', { static: true })
  private readonly imageInputRef: ElementRef;
  @ViewChild('dragContainer', { static: true })
  private readonly dragContainer: ElementRef;

  public accepted = false;
  public uploadActive = false;

  public readonly dragOverClassName = 'is-dragover';

  constructor(
    private readonly dialogRef: MatDialogRef<UploadDialogOwnImageComponent>,
    private readonly dataService: DataService,
    private readonly dialog: MatDialog,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private logger: NGXLogger,
    private readonly fileReaderService: FileReaderService
  ) {}

  ngOnInit() {
    this.matIconRegistry
      .addSvgIcon(
        'close-button',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-meldung-close.svg'
        )
      )
      .addSvgIcon(
        'upload-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/upload/icon-bildupload-50x73.svg'
        )
      );
    this.uploadActive = false;
    this.addDragListeners();
  }

  ngOnDestroy() {
    this.uploadActive = false;
    this.removeDragListeners();
  }

  private readonly addDragListeners = () => {
    const dragElement: HTMLElement = this.dragContainer.nativeElement;
    dragElement.addEventListener('drag', this.drag);
    dragElement.addEventListener('dragstart', this.dragstart);
    dragElement.addEventListener('dragend', this.dragend);
    dragElement.addEventListener('dragover', this.dragover);
    dragElement.addEventListener('dragenter', this.dragenter);
    dragElement.addEventListener('drop', this.drop);
    dragElement.addEventListener('dragleave', this.dragleave);
  };

  private removeDragListeners() {
    const dragElement: HTMLElement = this.dragContainer.nativeElement;
    dragElement.removeEventListener('drag', this.drag);
    dragElement.removeEventListener('dragstart', this.dragstart);
    dragElement.removeEventListener('dragend', this.dragend);
    dragElement.removeEventListener('dragover', this.dragover);
    dragElement.removeEventListener('dragenter', this.dragenter);
    dragElement.removeEventListener('drop', this.drop);
    dragElement.removeEventListener('dragleave', this.dragleave);
  }
  public get acceptFilesString(): string {
    return this.dataService.userType === UserType.DESIGNUSER
      ? '.jpg, .jpeg, .pdf, .ai, .eps, .svg'
      : '.pdf, .ai, .eps, .svg';
  }

  public readonly dragstart = event => {
    this.preventDefaults(event);
  };
  public readonly dragend = event => {
    this.preventDefaults(event);
    this.removeDragable();
  };

  public readonly dragover = event => {
    this.preventDefaults(event);
    this.setDragable();
  };
  public readonly dragenter = event => {
    this.preventDefaults(event);
    this.setDragable();
  };
  public readonly dragleave = event => {
    this.preventDefaults(event);
    this.removeDragable();
  };

  public readonly drag = event => {
    if (!this.accepted || this.uploadActive) {
      return;
    }
    this.preventDefaults(event);
  };

  public readonly drop = event => {
    if (!this.accepted || this.uploadActive) {
      return;
    }
    this.preventDefaults(event);
    const droppedFiles = event.dataTransfer.files;
    this.logger.debug('AllFiles: ');
    this.logger.debug(droppedFiles);
    this.removeDragable();
    this.handleMultipleFiles(droppedFiles).then(() => {
      this.close();
    });
  };

  private preventDefaults(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private setDragable() {
    if (!this.accepted || this.uploadActive) {
      return;
    }
    const nativeElem: HTMLDivElement = this.dragContainer.nativeElement;
    let classAttribute = nativeElem.getAttribute('class');
    classAttribute = classAttribute ? classAttribute : '';
    if (classAttribute.indexOf(this.dragOverClassName) < 0) {
      classAttribute += ` ${this.dragOverClassName}`;
    }
    nativeElem.setAttribute('class', classAttribute);
  }
  private removeDragable() {
    const nativeElem: HTMLDivElement = this.dragContainer.nativeElement;
    let classAttribute = nativeElem.getAttribute('class');
    classAttribute = classAttribute ? classAttribute : '';
    if (classAttribute.indexOf(this.dragOverClassName) >= 0) {
      classAttribute = classAttribute.replace(this.dragOverClassName, '');
    }
    nativeElem.setAttribute('class', classAttribute);
  }

  private close(): void {
    this.dialogRef.close();
  }
  public uploadImage() {
    if (this.uploadActive) {
      return;
    }
    const event = new MouseEvent('click', { bubbles: true });
    this.imageInputRef.nativeElement.dispatchEvent(event);
  }

  /**
   * this function is triggered on content change of the input-element.
   * The input image is added to the canvas.
   *
   * @param {Event} event
   * @memberof EditorComponent
   */
  public onChangeFile = (event: Event) => {
    this.dataService.loadingCycleEmitter.emit(true);
    this.handleMultipleFiles((event.target as any).files).then(() => {
      this.close();
      // const fileList: FileList = this.imageInputRef.nativeElement.files;
      this.imageInputRef.nativeElement.value = '';
    });
  };

  private handleMultipleFiles(allFile: FileList): Promise<void> {
    this.uploadActive = true;
    // TODO: disable drag  / Upload button
    const allFilePromise = Array.from(allFile).map(file => {
      return this.handleFile(file);
    });
    return Promise.all(allFilePromise).then(allDone => {
      this.uploadActive = false;
      return;
    });
  }

  private handleFile(file: File): Promise<void> {
    if (this.isUnSupportedFile(file)) {
      this.showUnsuporrtedFormatError(file);
      this.dataService.loadingCycleEmitter.emit(false);
    } else {
      this.dataService.editModeFalseEmitter.emit();
      this.fileReaderService.checkFileIsVectorPDF(file).then(
        (fileIsNoPdfOrIsVectorPDF) => {
          if (fileIsNoPdfOrIsVectorPDF) {
            // File is no PDF file or is PDF file containing vector data only

            // Read the actual file content (to save later)
            const fileReadPromise = this.fileReaderService.fileReadPromise(file);

            return this.fileReaderService
              .emitConvertedImages(file, fileReadPromise)
              .catch(() => {
                this.showErrorMessage();
              });
          }
          else {
            // File is PDF but contains images instead of vector data only
            this.showUnsuporrtedPDFFileError(file);
            this.dataService.loadingCycleEmitter.emit(false);
            return null;
          }
        },
        (error) => {
          console.error('Error checking file:', error);
        }
      );
      return null;
    }
  }

  private showErrorMessage() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        textKey: 'EDITOR.MESSAGE.IMAGE-CONVERSION-ERROR-TEXT',
        showNoButton: false,
        positiveText: 'EDITOR.MESSAGE.IMAGE-CONVERSION-ERROR-CONFIRM-TEXT',
        titleKey: 'EDITOR.MESSAGE.IMAGE-CONVERSION-ERROR-TITLE',
      },
    });
    this.dataService.loadingCycleEmitter.emit(false);
  }
  public get uploadImagePopupText() {
    return this.dataService.userType == UserType.DESIGNUSER
      ? 'EDITOR.UI.POPUP.IMAGE-UPLOAD-OWN-IMAGE.TEXT3-DESIGNUSER'
      : 'EDITOR.UI.POPUP.IMAGE-UPLOAD-OWN-IMAGE.TEXT3';
  }

  private showUnsuporrtedFormatError(file: File) {
    const textKeyForSupportedFormats =
      this.dataService.userType == UserType.DESIGNUSER
        ? 'EDITOR.MESSAGE.IMAGE-FORMAT-TEXT-DESIGNUSER'
        : 'EDITOR.MESSAGE.IMAGE-FORMAT-TEXT';

    this.dialog.open(ConfirmDialogComponent, {
      data: {
        textKey: textKeyForSupportedFormats,
        showNoButton: false,
        positiveText: 'EDITOR.MESSAGE.IMAGE-FORMAT-CONFIRM-TEXT',
        titleKey: 'EDITOR.MESSAGE.IMAGE-FORMAT-TITLE',
        paramsForTranslation: {
          name: file.name,
          size: Math.round(file.size / 1024) / 1024,
          maxSize: CONSTANTS.IMAGE_MAX_MB_SIZE,
        },
      },
    });
  }

  private showUnsuporrtedPDFFileError(file: File) {
    const textKeyForSupportedPDFFiles = 'EDITOR.MESSAGE.PDF-FORMAT-TEXT';

    this.dialog.open(ConfirmDialogComponent, {
      data: {
        textKey: textKeyForSupportedPDFFiles,
        showNoButton: false,
        positiveText: 'EDITOR.MESSAGE.PDF-FORMAT-CONFIRM-TEXT',
        titleKey: 'EDITOR.MESSAGE.PDF-FORMAT-TITLE',
        paramsForTranslation: {
          name: file.name
        },
      },
    });
  }

  /**
   * evaluates if the file has supported properties
   */
  private isUnSupportedFile = (file: File): boolean => {
    const allAcceptedFileEnding = [
      'svg',
      'SVG',
      'pdf',
      'PDF',
      'eps',
      'ai',
      'EPS',
      'AI',
    ];
    const additionalDesignUserEnding = ['jpg', 'jpeg', 'JPG'];
    const acceptedFileEnding =
      this.dataService.userType === UserType.DESIGNUSER
        ? allAcceptedFileEnding.concat(additionalDesignUserEnding)
        : allAcceptedFileEnding;
    const allMatchedFileEnding = acceptedFileEnding.filter(fileEnding =>
      file.name.endsWith('.' + fileEnding)
    );
    return (
      allMatchedFileEnding.length < 1 ||
      Math.round(file.size / 1024) / 1024 > CONSTANTS.IMAGE_MAX_MB_SIZE
    );
  };

  public toggleAccepted() {
    this.accepted = !this.accepted;
  }

  public isAccepted(): boolean {
    return this.accepted;
  }
}
