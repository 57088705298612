import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-preview-step',
  templateUrl: './preview-step.component.html',
  styleUrls: ['./preview-step.component.scss'],
})
export class PreviewStepComponent implements OnInit {
  @Input() step: number = 1

  constructor() {}

  ngOnInit() {}

  public getStep() {
    return this.step
  }
}
