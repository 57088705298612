import { TranslateService } from '@ngx-translate/core';
import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-icon-dialog',
  templateUrl: './icon-dialog.component.html',
  styleUrls: ['./icon-dialog.component.scss'],
})
export class IconDialogComponent {
  textKey: string;
  checked = false;
  constructor(
    public dialogRef: MatDialogRef<IconDialogComponent>,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.textKey = data;
  }

  forewardIsDisabled() {
    return !this.checked;
  }

  // TODO: button nach rechts

  onNoClick(): void {
    this.dialogRef.close(this.checked);
  }

  onUserClose(): void {
    this.dialogRef.close(this.checked);
  }
  onUserAbort(): void {
    this.dialogRef.close(false);
  }
}
