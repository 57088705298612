import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-dialog-choose',
  templateUrl: './upload-dialog-choose.component.html',
  styleUrls: ['./upload-dialog-choose.component.scss'],
})
export class UploadDialogChooseComponent {
  private uploadCallback: () => void;
  private imageDbCallback: () => void;

  constructor(
    private readonly dialogRef: MatDialogRef<UploadDialogChooseComponent>,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry
      .addSvgIcon(
        'close-button',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-meldung-close.svg'
        )
      )
      .addSvgIcon(
        'upload-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/upload/icon-bildupload-66x98.svg'
        )
      )
      .addSvgIcon(
        'imagedb-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/upload/icon-bilddatenbank.svg'
        )
      );
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
  public clickSelectUpload(): void {
    if (this.uploadCallback) {
      this.uploadCallback();
    }
  }
  public clickSelectDB(): void {
    if (this.imageDbCallback) {
      this.imageDbCallback();
    }
  }

  public setUploadCallback(fkt: () => void) {
    this.uploadCallback = fkt;
  }
  public setSortimoCallback(fkt: () => void) {
    this.imageDbCallback = fkt;
  }
}
