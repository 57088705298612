import { Directive, ViewContainerRef } from '@angular/core';

/**
 * UploadDialogDirective Represent Container
 * That holds Upload dialog Views.
 */
@Directive({
  selector: '[appUploadDialog]',
})
export class UploadDialogDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
