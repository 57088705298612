import { Druckauftrag } from './../druckauftrag';
import { Seitenansicht } from './../seitenansicht';
import { Template } from './../template';
import { Fahrzeugseite } from '../fahrzeugeseite';

export class EditorState {

    public selectedSide: Fahrzeugseite;
    public druckauftrag: Druckauftrag;
}
