import { Rectangle } from './graphicelements/rectangle';
import { Point } from './graphicelements/point';
import { Polygon } from './graphicelements/polygon';
import { EditArea } from './graphicmodels/editarea';
import { Fahrzeugseite } from './fahrzeugeseite';
import { fabric } from 'fabric';

export class Seitenansicht {
  allEditierbereich: EditArea[];
  fahrzeugPath: string;
  fahrzeugseite: Fahrzeugseite; // Enum
  allDataElement: fabric.Object[];
  hasError = false;
  constructor(obj?: any) {
    if (obj) {
      if (obj.allEditierbereich) {
        this.allEditierbereich = [];
        for (let i = 0; i < obj.allEditierbereich.length; i++) {
          const element: EditArea = obj.allEditierbereich[i];
          const graphicElem = element.graphicElement;
          if (graphicElem instanceof Polygon || (<any>graphicElem).allPoint) {
            const poly = new Polygon();
            poly.allPoint = (<any>graphicElem).allPoint;
            const editarea = new EditArea();
            editarea.graphicElement = poly;
            this.allEditierbereich.push(editarea);
          }
          if (
            graphicElem instanceof Rectangle ||
            ((<any>graphicElem).x && (<any>graphicElem).y && (<any>graphicElem).width && (<any>graphicElem).height)
          ) {
            const rect = new Rectangle();
            rect.x = (<any>graphicElem).x;
            rect.y = (<any>graphicElem).y;
            rect.width = (<any>graphicElem).width;
            rect.height = (<any>graphicElem).height;
            const editarea = new EditArea();
            editarea.graphicElement = rect;
            this.allEditierbereich.push(editarea);
          }
        }
      }

      this.fahrzeugPath = obj.fahrzeugPath;
      this.fahrzeugseite = obj.fahrzeugseite;
      this.hasError = obj.hasError;
    }
  }

  /**
   * set all elements not to be a template (targets the custom fabric objects)
   * essentially sets `isTemplateData` false for all objects
   */
  public disableTemplate(): void {
    this.allDataElement.map((el) => {
      if (el.type === "customImage" || el.type === "customText") {
        (el as any).isTemplateData = false;
      }
    })
  }
}
