import { Seitenansicht } from 'models/seitenansicht';
import { Injectable } from '@angular/core';
import { DataService } from 'app/services/dataservice';
import { Rectangle } from 'models/graphicelements/rectangle';
import { EditArea } from 'models/graphicmodels/editarea';
import { Polygon } from 'models/graphicelements/polygon';
import { Point } from 'models/graphicelements/point';
import { fabric } from 'fabric';

@Injectable()
/**
 * This service provides allows to define ClippingAreas for a given Seitenansicht in a Editierbereich.
 */
export class RenderEditModeService {
  constructor(private dataService: DataService) {}

  public defineClippingAreas = function (
    seitenansicht: Seitenansicht,
    zoomFactor: number,
    context: CanvasRenderingContext2D,
    tl: fabric.Point
  ) {
    if (!seitenansicht.allEditierbereich) {
      return;
    }

    context.beginPath();
    seitenansicht.allEditierbereich.forEach((editArea: EditArea) => {
      if (editArea.graphicElement instanceof Rectangle) {
        const rectangle: Rectangle = editArea.graphicElement;
        context.rect(
          rectangle.x * zoomFactor - tl.x,
          rectangle.y * zoomFactor - tl.y,
          rectangle.width * zoomFactor,
          rectangle.height * zoomFactor
        );
      } else if (editArea.graphicElement instanceof Polygon) {
        const polygon: Polygon = editArea.graphicElement;

        let pathx = polygon.allPoint[0].x * zoomFactor - tl.x;
        let pathy = polygon.allPoint[0].y * zoomFactor - tl.y;

        // start at frist point
        context.moveTo(pathx, pathy);

        polygon.allPoint.forEach((point: Point) => {
          // continue if there is a invalid point
          if (point) {
            pathx = point.x * zoomFactor - tl.x;
            pathy = point.y * zoomFactor - tl.y;
            context.lineTo(pathx, pathy);
          }
        });
      } else {
        console.log('Unknown Editarea Type');
      }
    });
    context.closePath();
  };

  public getClipToPath(seitenansicht: Seitenansicht): fabric.Object {
    const group: fabric.Group = new fabric.Group();
    if (!seitenansicht.allEditierbereich) {
      return group;
    }

    seitenansicht.allEditierbereich.forEach((editArea: EditArea) => {
      if (editArea.graphicElement instanceof Rectangle) {
        const rectangle: Rectangle = editArea.graphicElement;
        const obj = new fabric.Rect({
          left: rectangle.x,
          top: rectangle.y,
          width: rectangle.width,
          height: rectangle.height,
        });
        group.addWithUpdate(obj);
      } else if (editArea.graphicElement instanceof Polygon) {
        const polygon: Polygon = editArea.graphicElement;

        group.addWithUpdate(
          new fabric.Polyline(
            polygon.allPoint.map(p => {
              return new Point(p.x, p.y);
            })
          )
        );
      } else {
        console.log('Unknown Editarea Type');
      }
    });
    group.setCoords();
    console.log(group.getObjects().length);
    return group;
  }
}
