import { forkJoin as observableForkJoin, Observable, lastValueFrom } from 'rxjs';
import { TU_COUNTRY_FACTORS } from './../../assets/pricing/pricing-country-constants';
import { PriceCalculatorService } from 'app/services/price-calculator.service';
import { FontDataService } from './fontdataservice';
import { DruckauftragHelperService } from 'app/services/druckauftrag-helper.service';
import { Druckauftrag } from 'models/druckauftrag';
import { TemplateInteractionService } from './../services/templateInteraction.service';
import { TemplateDataParser } from 'app/services/templatedataparser';
import { Template } from 'models/template';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CarSideManager } from './car-side-manager';
import { Injectable, EventEmitter } from '@angular/core';
import { DOMParser } from '@xmldom/xmldom'
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class CarDataManagerService {
  private allCarSideManager: CarSideManager[];

  private domParser: DOMParser;
  private carId: string;
  private allTemplateSideDataMap: Map<string, Map<number, fabric.Object[]>>;

  public allMinimalTemplate: Template[];
  public allBasicTemplate: Template[];

  public templateDataUpdatedEmitter: EventEmitter<void> = new EventEmitter();

  private resolveAllTemplateDataLoaded: (
    value?: void | PromiseLike<void>
  ) => void;
  public readonly templateDataLoadedPromise: Promise<void> = new Promise(
    resolve => {
      this.resolveAllTemplateDataLoaded = resolve;
    }
  );

  constructor(
    private http: HttpClient,
    private templateDataParser: TemplateDataParser,
    private templateInteractionService: TemplateInteractionService,
    private druckauftragHelperService: DruckauftragHelperService,
    private fontDataService: FontDataService,
    private priceCalculatorService: PriceCalculatorService
  ) {
    this.domParser = new DOMParser();
  }

  public async setCarId(
    carId: string,
    languageCountryCombination: string,
    country: string,
    currencyFactor,
    hourRate
  ): Promise<any> {
    if (this.carId) {
      console.log('Car-DataLoader: Templates already Loaded ');
      return Promise.resolve('Templates Already Loaded ');
    }
    languageCountryCombination = languageCountryCombination.toUpperCase();
    if (languageCountryCombination === 'EN') {
      languageCountryCombination = 'DE';
    }

    this.allMinimalTemplate = [];
    this.allBasicTemplate = [];

    this.carId = carId;
    this.allTemplateSideDataMap = new Map();
    console.log(`CarDataManager -country: ${languageCountryCombination}`);
    const fahrer =
      'assets/car-images/' +
      carId +
      '/' +
      languageCountryCombination +
      '/' +
      carId +
      '-fahrer-' +
      languageCountryCombination +
      '.svg';
    const beifahrer =
      'assets/car-images/' +
      carId +
      '/' +
      languageCountryCombination +
      '/' +
      carId +
      '-beifahrer-' +
      languageCountryCombination +
      '.svg';
    const front =
      'assets/car-images/' +
      carId +
      '/' +
      languageCountryCombination +
      '/' +
      carId +
      '-front-' +
      languageCountryCombination +
      '.svg';
    const heck =
      'assets/car-images/' +
      carId +
      '/' +
      languageCountryCombination +
      '/' +
      carId +
      '-heck-' +
      languageCountryCombination +
      '.svg';

    const allInitializationPromise = [];
    // generate Documents

    const allCarSideObservable = this.getCarSides([
      beifahrer,
      fahrer,
      front,
      heck,
    ]);
    const allCarSideManager = [];
    allCarSideObservable.subscribe(allDocument => {
      // generate side managers
      allDocument.forEach(value => {
        const carSideManager = new CarSideManager(
          value,
          this.templateDataParser
        );
        allCarSideManager.push(carSideManager);
      });
      this.allCarSideManager = allCarSideManager;
      const allIdString = this.getAllTemplateId();
      allIdString.forEach(idstr => {
        // FIXME: adapt naming
        this.handleBasicOrMinimalTemplate(
          carId,
          idstr,
          languageCountryCombination,
          'basic',
          this.allBasicTemplate
        );
        this.handleBasicOrMinimalTemplate(
          carId,
          idstr,
          languageCountryCombination,
          'minimal',
          this.allMinimalTemplate
        );
      });
      console.log(`
      TEMPLATES:
      minimal:${this.allMinimalTemplate.length}
      basic:${this.allBasicTemplate.length}
      `);
      this.allBasicTemplate.sort(this.templateSortFunction);
      this.allMinimalTemplate.sort(this.templateSortFunction);

      allInitializationPromise.push(
        this.loadAllTemplateData(
          this.allTemplateSideDataMap,
          country,
          currencyFactor,
          hourRate
        )
      );
    });
    /**
     * We await the allCarSideObservable using lastValueFrom to get the last emitted value as a promise.
     * 
     * We push the promise (resolved with the last emitted value) into the allInitializationPromise array.
     * 
     * Note that we use Promise.resolve(result) to create a resolved promise with the value.
     */
    const result = await lastValueFrom(allCarSideObservable);
    allInitializationPromise.push(Promise.resolve(result));
    return Promise.all(allInitializationPromise);
  }

  private templateSortFunction(a: Template, b: Template) {
    return a.name.localeCompare(b.name);
  }

  private handleBasicOrMinimalTemplate(
    carId: string,
    idstring: string,
    languageCountryCombination: string,
    templateType: string,
    templateArray: Template[]
  ) {
    const index = idstring.toLowerCase().indexOf(templateType);
    if (index >= 0) {
      const name =
        templateType +
        '-' +
        idstring.replace('Template', '').substr(templateType.length);
      const previewImagePathStr =
        environment.assets_url + 'assets/car-images/' +
        carId +
        '/' +
        languageCountryCombination +
        '/' +
        carId +
        '-template-' +
        name +
        '-' +
        languageCountryCombination +
        '.png';
      const template = new Template({
        name,
        previewImagePathStr,
        type: templateType,
      });
      templateArray.push(template);
    }
  }
  private getCarSides(allPath: string[]): Observable<Document[]> {
    const allDocument = allPath.map(path => {
      const headers = new HttpHeaders().set(
        'Content-Type',
        'text/plain; charset=utf-8'
      );
      const res = this.http
        .get(environment.assets_url + path, { headers: headers, responseType: 'text' })
        .pipe(
          map((response: any) => {
            return this.domParser.parseFromString(response);
          })
        );
      return res;
    }); // FIXME: insert proper type for "any"
    return observableForkJoin(allDocument);
  }

  private getAllTemplateId(): string[] {
    const completeSet = this.allCarSideManager
      .map(e => e.getAllId())
      .reduce((s1, s2) => {
        s2.forEach(e => s1.add(e));
        return s1;
      });
    return Array.from(completeSet).filter(
      value => value && value !== '' && value.startsWith('Template')
    );
  }
  private loadAllTemplateData(
    allTemplateSideDataMap,
    country: string,
    currencyFactor,
    hourRate
  ): Promise<void> {
    return this.fontDataService.getAllFontLoadedPromise().then(() => {
      return this._loadAllTemplateData(
        allTemplateSideDataMap,
        country,
        currencyFactor,
        hourRate
      );
    });
  }
  /**
   * Load the TemplateData
   * @param allTemplateSideDataMap
   */
  private _loadAllTemplateData(
    allTemplateSideDataMap,
    country: string,
    currencyFactor: number,
    hourRate: number
  ): Promise<void> {
    // get allTemplates
    const allTemplate = this.allBasicTemplate.concat(this.allMinimalTemplate);

    const allTemplatePromise = [];
    allTemplate.forEach(t => {
      const allSidePromise: Promise<fabric.Object[]>[] = [];
      this.allCarSideManager.forEach((side, index) => {
        allSidePromise.push(side.getAllTemplateData(t.name));
      });
      const templatePromise = Promise.all(allSidePromise);
      allTemplatePromise.push(
        templatePromise.then(dataArr => {
          const sideMap = new Map();
          dataArr.forEach((value, index) => {
            sideMap.set(index, value);
          });
          allTemplateSideDataMap.set(t.name, sideMap);
          t.dynamicPrice = this.priceCalculatorService.calculatePriceForAllElements(
            Array.from(sideMap.values()).reduce((a, b) => a.concat(b || [])),
            TU_COUNTRY_FACTORS[country],
            hourRate,
            currencyFactor,
            country,
            false
          ).price;
        })
      );
    });

    return Promise.all(allTemplatePromise).then(() => {
      console.log(`AllPromise Data Parsed:`);
      this.allTemplateSideDataMap = allTemplateSideDataMap;
      console.log(this.allTemplateSideDataMap.size);
      this.resolveAllTemplateDataLoaded();
    });
  }
  public getCarSideManager(index: number): CarSideManager {
    if (this.allCarSideManager && index !== undefined && index >= 0) {
      return this.allCarSideManager[index];
    }
    return undefined;
  }
  public getAllCarSideManager(): CarSideManager[] {
    return this.allCarSideManager;
  }
  /**
   * select the Templated provided as parameter
   * @param template
   * @param druckauftrag
   */
  public selectTemplate(
    template: Template,
    druckauftrag: Druckauftrag,
    resetTemplate?: boolean
  ): Promise<void> {
    const promiseArray = [];
    return this.templateDataLoadedPromise.then(() => {
      if (
        !druckauftrag.selectedTemplate ||
        druckauftrag.selectedTemplate.name !== template.name ||
        resetTemplate
      ) {
        // Fix me
        this.allTemplateSideDataMap.get(template.name).forEach((value, key) => {
          const side = this.druckauftragHelperService.getSide(
            druckauftrag,
            key
          );
          promiseArray.push(
            this.templateInteractionService
              .mergeTemplateData(
                _.cloneDeep(side.allDataElement),
                _.cloneDeep(value),
                druckauftrag
              )
              .then(allTemplateData => {
                side.allDataElement = allTemplateData;
              })
          );
        });
      }
      return Promise.all(promiseArray).then(() => {
        this.templateDataUpdatedEmitter.emit();
      });
    });
  }
  /**
   * sets the background color for all carsides
   */
  public setBackgroundColor(color: string) {
    this.allCarSideManager.forEach(carSideManager => {
      carSideManager.setBackgroundColor(color);
    });
  }

  /**
   * shows only the relevant views for displaying the car
   */
  public createPresentationView(carColor: string) {
    this.allCarSideManager.forEach(carSideManager => {
      carSideManager.createPresentationView(carColor);
    });
  }
}
