import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { DataService } from './../services/dataservice';
import { InitializationService } from './../services/initialization.service';

/**
 * EditorHeaderComponent provides a Header Of Editor
 * With External Help and Support link as well as Selection of Templates.
 */
@Component({
  selector: 'app-editor-header',
  templateUrl: './editor-header.component.html',
  styleUrls: ['./editor-header.component.scss'],
})
export class EditorHeaderComponent implements OnInit {
  @Input()
  isTemplateNavHidden = false;
  @Input()
  public isTemplateNavButtonDisabled = false;

  @Output()
  showAllTemplateClicked: EventEmitter<any> = new EventEmitter();

  constructor(
    private readonly translateService: TranslateService,
    private readonly initializationService: InitializationService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly dataService: DataService
  ) {
    this.matIconRegistry.addSvgIcon(
      'support-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/V2/icon-hilfe-support.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/V2/pfeil-vorlagen-dropdown.svg'
      )
    );
  }

  ngOnInit() {}

  /**
   * Template Button event handler
   */
  public templateButtonClicked = () => {
    this.showAllTemplateClicked.emit();
  };

  /**
   * Opens Support Page.
   */
  public openSupportPage() {
    this.dataService.openSupportPage();
  }

  /**
   * Returns the text content for the show/hide Templates button
   * by extracting it from the ngx-translate files
   */
  public get textForTemplateButton(): string {
    let templateControl = '';

    this.isTemplateNavHidden
      ? this.translateService
          .get('EDITOR.TEMPLATE-CONTROL.SHOW')
          .subscribe(result => {
            templateControl = result;
          })
      : this.translateService
          .get('EDITOR.TEMPLATE-CONTROL.HIDE')
          .subscribe(result => {
            templateControl = result;
          });

    return templateControl;
  }

  /**
   * Dispaly current Car Name.
   */
  public get displayText() {
    return this.initializationService.getCarName();
  }

  /**
   * Class for VorlageTemplate Button.
   */
  public get vorlagenButtonClass() {
    return this.isTemplateNavHidden
      ? 'vorlagenButtonActive font-bold font-16px'
      : 'vorlagenButtonHidden font-bold font-16px';
  }
}
