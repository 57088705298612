import { fabric } from 'fabric';

/**
 * Contains all Pricing Property for Price Calculator Service.
 */
export class PricingObject {
  public additionalPrice: number;
  public width: number;
  public height: number;
  public charCount: number;
  public colorChanges: number;
  public colorCount: number;
  public type: 'text' | 'shape';
  public referencedObject: fabric.Object;
}
