export class AzureImage {
  public filename: string;
  public id: string;
  public priceValue: number;
  public priceCurrency: string;
  public category: string;
  public tags: string | string[];
  public language: string;
  public url: string;

  constructor(json: any) {
    this.filename = json.filename;
    this.id = json.id;
    this.priceValue = json.priceValue;
    this.priceCurrency = json.priceCurrency;
    this.category = json.category;
    this.tags = json.tags;
    this.language = json.language;
    this.url = json.url;
  }
}
