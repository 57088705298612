import { CustomImage } from 'models/graphicelements/customimage';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent implements OnInit {
  public static readonly PADDING = 5;

  @ViewChild(ImageCropperComponent, { static: true })
  imageCropper: ImageCropperComponent;

  @Input()
  public image: CustomImage;
  @Input()
  public width: number;
  @Input()
  public height: number;
  public imageCroppedSubject: BehaviorSubject<
    ImageCroppedEvent
  > = new BehaviorSubject<ImageCroppedEvent>(null);

  @Output()
  public cropperInitialized = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}

  get imageSource() {
    if (!this.image) {
      return null;
    }
    return this.image.originalDisplayImage || this.image.getSrc();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageCroppedSubject.next(event);
  }
  imageLoaded() {
    // show cropper
    // TODO: takes very long
  }
  cropperReady() {
    if (this.image.cropperPositionPercent) {
      this.imageCropper.cropper.x1 =
        this.image.cropperPositionPercent.x1 * this.width;
      this.imageCropper.cropper.x2 =
        this.image.cropperPositionPercent.x2 * this.width;
      this.imageCropper.cropper.y1 =
        this.image.cropperPositionPercent.y1 * this.height;
      this.imageCropper.cropper.y2 =
        this.image.cropperPositionPercent.y2 * this.height;
      this.imageCropper.crop();
    }

    this.cropperInitialized.emit();
    // TODO takes very long
    // cropper ready
  }
  loadImageFailed() {
    // show message
    // TODO takes very long
  }
}
