export const DELIVERY_CONSTANTS: { [code: string]: number } = {
  AT: 10,
  BE: 10,
  DE: 10,
  DK: 10,
  ES: 20,
  FR: 12,
  GB: 15,
  IE: 15,
  NL: 10,
  NO: 47,
  SE: 18,
  UK: 15,
};
export const TU_COUNTRY_FACTORS: { [code: string]: number } = {
  AT: 1.8,
  BE: 1.66,
  DE: 1.8,
  DK: 1.8,
  ES: 1.6,
  FR: 1.8,
  GB: 1.2,
  IE: 1.2,
  NL: 1.8,
  NO: 1.8,
  SE: 1.8,
  UK: 1.2,
};

export const DESIGN_USER_VK_FACTOR = 1.8;
