import { UserType } from './../../models/usertype';
import {
  DELIVERY_CONSTANTS,
  TU_COUNTRY_FACTORS,
} from './../../assets/pricing/pricing-country-constants';
import { CONSTANTS } from 'models/helpers/constants';
import { PriceCalculationResult } from 'models/pricecalcuationresult';
import { PricingObject } from 'models/helpers/pricingObject';
import { isCustomImage } from 'models/graphicelements/customimage';
import { isCustomText } from 'models/graphicelements/customtext';
import { Injectable } from '@angular/core';
import { Druckauftrag } from 'models/druckauftrag';
import { Seitenansicht } from 'models/seitenansicht';
import { CustomText } from 'models/graphicelements/customtext';
import { CustomImage } from 'models/graphicelements/customimage';
import { NGXLogger } from 'ngx-logger';
@Injectable()
export class PriceCalculatorService {
  // Hour rates
  private static HOUR_RATE_PLOT_CUT = 30.0;
  private static HOUR_RATE_PLOT_PLOT = 55.0;

  // price per element
  private static ELEMENT_PRICE = 2.5;

  // postion / glue constants
  private static POSITONTIME_PER_ELEM_IN_MIN = 6;
  private static CLEAN_TIME_PER_M2_IN_MIN = 25;
  private static RUEST_TIME = 0.0;
  // Static costs or hourly rates
  private static BASE_PRICE_PLOT = 15;
  private static BASE_PRICE_DIGI = 25;

  private static REDUCTION_FACTOR_MATERIAL_PRODUCTION = 0.6;

  // Image parameters
  private static PLOT_PRINT_TIME_PER_LETTER_SECONDS = 5;
  private static PLOT_PROD_TIME_PER_LETTER_SECONDS = 10;

  // Plot Parameters
  private static PLOT_COST_PER_M2 = 9.5;
  private static LOGO_PRINT_PLOT_TIME_ONCE_SECONDS = 300;
  private static LOGO_PROD_TIME_ONCE_SECONDS = 300;
  private static DIGI_COST_PER_M2 = 30.0;
  private logPriceSummary = false;

  // general price increase
  private static readonly PRODUCTION_PRICE_INCREASE = 1.15 * 1.25;

  private static isShapeWithStroke(e: fabric.Object) {
    return (
      e.strokeWidth &&
      (e.type === 'rect' ||
        e.type === 'circle' ||
        e.type === 'triangle' ||
        e.type === 'customTriangle')
    );
  }

  private static getScaledHeight(e: fabric.Object) {
    return PriceCalculatorService.isShapeWithStroke(e)
      ? (e.height + e.strokeWidth) * e.scaleY
      : e.height * e.scaleY;
  }
  private static getScaledWidth(e: fabric.Object) {
    return PriceCalculatorService.isShapeWithStroke(e)
      ? (e.width + e.strokeWidth) * e.scaleX
      : e.width * e.scaleX;
  }

  constructor(private logger: NGXLogger) {}

  public calculatePrice(
    druckauftrag: Druckauftrag,
    hourRate: number,
    currencyFactor: number,
    userType: UserType,
    country: string
  ): PriceCalculationResult {
    /* this.logger.trace(
      `${new Date().getHours() % 100}:${new Date().getMinutes() %
        100}:${new Date().getSeconds() % 100} :${new Date().getMilliseconds() %
        1000}: Price Calcuation Start`
    );*/

    const arr: Seitenansicht[] = [
      druckauftrag.front,
      druckauftrag.links,
      druckauftrag.rechts,
      druckauftrag.heck,
    ];
    const allElement = arr
      .map(seitenansichtObj => {
        if (!seitenansichtObj) {
          return;
        }
        if (
          seitenansichtObj.allDataElement &&
          seitenansichtObj.allDataElement.length > 0
        ) {
          return seitenansichtObj.allDataElement;
        }
        return [];
      })
      .reduce((acc, val) => acc.concat(val));
    // materialOnly is only enabled for PROUsers
    const priceCalculationResult = this.calculatePriceForAllElements(
      allElement,
      TU_COUNTRY_FACTORS[country],
      hourRate,
      currencyFactor,
      country,
      userType === UserType.PROUSER && druckauftrag.calculateMaterialOnly
    );

    priceCalculationResult.hourRate = hourRate;
    priceCalculationResult.budget_extern =
      hourRate *
      ((PriceCalculatorService.RUEST_TIME * 60 +
        priceCalculationResult.glueTime) /
        60);
    priceCalculationResult.materialCosts =
      priceCalculationResult.productionCosts * TU_COUNTRY_FACTORS[country];
    /*this.logger.trace(
      `${new Date().getHours() % 100}:${new Date().getMinutes() %
        100}:${new Date().getSeconds() % 100} :${new Date().getMilliseconds() %
        1000}: Price Calcuation Done`
    );*/
    // add price for DB elements
    priceCalculationResult.price += this.getAdditionalPriceForDbImages(
      allElement.filter(a => isCustomImage(a)).map(a => a as CustomImage),
      currencyFactor
    );
    return priceCalculationResult;
  }

  calcMaterialPlotCostsForText(
    width_in_CM: number,
    height_in_CM: number,
    letterInColorCount: number,
    colorCount: number,
    colorChanges: number
  ) {
    const costsForCutting =
      ((width_in_CM * 2 + (1 + colorChanges + colorCount) * height_in_CM) /
        3600) *
      PriceCalculatorService.HOUR_RATE_PLOT_CUT;

    const totalLetterProdTime =
      PriceCalculatorService.PLOT_PROD_TIME_PER_LETTER_SECONDS *
      letterInColorCount;
    const costsForAbgittern =
      (totalLetterProdTime / 3600) * PriceCalculatorService.HOUR_RATE_PLOT_CUT;

    const costsForPlot =
      ((PriceCalculatorService.PLOT_PRINT_TIME_PER_LETTER_SECONDS *
        letterInColorCount) /
        3600) *
      PriceCalculatorService.HOUR_RATE_PLOT_PLOT;

    const elementPrice = costsForPlot + costsForAbgittern + costsForCutting;

    if (this.logPriceSummary) {
      /* this.logger.trace(`TEXT-ELEMENT Material PRICE:
      width (cm): ${width_in_CM}
      height (cm): ${height_in_CM}
      costsForPlot: ${costsForPlot}
      costsForAbgittern: ${costsForAbgittern}
      totalLetterProdTime: ${totalLetterProdTime}
      costsForCutting: ${costsForCutting}
      elementPrice: ${elementPrice}
      `);
      */
    }

    return elementPrice;
  }

  calcMaterialForPlotOrDigiElem(width_in_CM: number, height_in_CM: number) {
    const costsForCutting =
      (((width_in_CM + height_in_CM) * 2) / 3600) *
      PriceCalculatorService.HOUR_RATE_PLOT_CUT;

    const costsForAbgittern =
      (PriceCalculatorService.LOGO_PROD_TIME_ONCE_SECONDS / 3600) *
      PriceCalculatorService.HOUR_RATE_PLOT_CUT;

    const costsForPlot =
      (PriceCalculatorService.LOGO_PRINT_PLOT_TIME_ONCE_SECONDS / 3600) *
      PriceCalculatorService.HOUR_RATE_PLOT_PLOT;
    const elementPrice = costsForPlot + costsForAbgittern + costsForCutting;
    if (this.logPriceSummary) {
      /* this.logger.trace(`PLOT / DIGI -ELEMENT PRICE:
    costsForPlot: ${costsForPlot}
    costsForAbgittern: ${costsForAbgittern}
    costsForCutting: ${costsForCutting}
    elementPrice: ${elementPrice}
    `);*/
    }
    return elementPrice;
  }

  /**
   * calculatesPrice for allElements
   * @param allElem
   */
  public calculatePriceForAllElements(
    allElem: fabric.Object[],
    tu_factor: number,
    countryDependentHourRate: number,
    currency_Factor: number,
    country: string,
    onlyCalculateMaterial: boolean
  ): PriceCalculationResult {
    if (allElem.length === 0) {
      return new PriceCalculationResult({ price: 0 });
    }
    // let areaSumInM2 = 0;
    const digitalArr = [];
    const allPlotColorSet: Set<string> = new Set();
    const allPricingObject: PricingObject[] = [];

    const deliveryRate = DELIVERY_CONSTANTS[country];

    let elemSumPlot = 0;
    let elemSumDig = 0;
    let totalArea = 0;
    for (let index = 0; index < allElem.length; index++) {
      const e = allElem[index];

      if (isCustomImage(e) || PriceCalculatorService.isShapeWithStroke(e)) {
        digitalArr.push(e);

        elemSumDig += this.calcMaterialForPlotOrDigiElem(
          PriceCalculatorService.getScaledWidth(e) *
            CONSTANTS.VIEWBOX_TO_METER_FACTOR *
            100,
          PriceCalculatorService.getScaledHeight(e) *
            CONSTANTS.VIEWBOX_TO_METER_FACTOR *
            100
        );
      } else if (isCustomText(e)) {
        this.createPricingObjectForTextElement(
          e,
          allPricingObject,
          allPlotColorSet
        );
      } else {
        const el = <any>e;
        // rect circle or triangle
        const po = new PricingObject();
        po.type = 'shape';
        po.width =
          PriceCalculatorService.getScaledWidth(e) *
          CONSTANTS.VIEWBOX_TO_METER_FACTOR *
          100;
        po.height =
          PriceCalculatorService.getScaledHeight(e) *
          CONSTANTS.VIEWBOX_TO_METER_FACTOR *
          100;

        po.colorCount = 1;
        po.referencedObject = e;
        allPricingObject.push(po);
        allPlotColorSet.add(el.fill);
      }
      totalArea +=
        PriceCalculatorService.getScaledWidth(e) *
        PriceCalculatorService.getScaledHeight(e) *
        CONSTANTS.VIEWBOX_TO_METER_FACTOR *
        CONSTANTS.VIEWBOX_TO_METER_FACTOR;
    }

    let plotPriceAdditional = 0;
    allPricingObject.forEach((po, index) => {
      if (isCustomText(po.referencedObject)) {
        elemSumPlot += this.calcMaterialPlotCostsForText(
          po.width,
          po.height,
          po.charCount,
          po.colorCount,
          po.colorChanges
        );
        //     this.logger.trace(`po: { color:${po.color}, width: ${po.width} cm ,height:${po.height} cm }`);
      } else {
        elemSumPlot += this.calcMaterialForPlotOrDigiElem(po.width, po.height);
      }
    });

    plotPriceAdditional +=
      this.calculatePriceForAllPlotElements(allPricingObject);

    const basePriceSumPlot =
      PriceCalculatorService.BASE_PRICE_PLOT * allPlotColorSet.size;
    if (this.logPriceSummary) {
      /*   this.logger.trace(
        `BasePrice * PlotColorCount= ${PriceCalculatorService.BASE_PRICE_PLOT} * ${allPlotColorSet.size} =${basePriceSumPlot}`
      );*/
    }
    const digiPriceAdditional =
      this.calculateMaterialPriceForAllDigitalElements(digitalArr);

    const totalPricePlot = plotPriceAdditional + elemSumPlot;
    const totalPriceDigi = digiPriceAdditional + elemSumDig;

    const allElementPrice =
      allElem.length * PriceCalculatorService.ELEMENT_PRICE;
    const totalPriceMaterialProduction =
      (totalPricePlot + totalPriceDigi + basePriceSumPlot + allElementPrice) *
      PriceCalculatorService.REDUCTION_FACTOR_MATERIAL_PRODUCTION *
      PriceCalculatorService.PRODUCTION_PRICE_INCREASE;

    const glueCostsProductionPrice = this.calculateGlueCosts(
      allElem.length,
      totalArea,
      countryDependentHourRate
    );

    // glueCostsProductionPrice.productionCosts = totalPriceMaterialProduction;
    const priceResult = new PriceCalculationResult(glueCostsProductionPrice);
    priceResult.deliveryConstant = deliveryRate;
    priceResult.productionCosts = totalPriceMaterialProduction;
    const priceMaterialProduction =
      currency_Factor *
      (totalPriceMaterialProduction * tu_factor + deliveryRate);
    if (this.logPriceSummary) {
      console.log(
        `AllPriceObjects :`,
        allPricingObject.map(obj => {
          return `${obj.width}/${obj.height} (${obj.type})`;
        })
      );
      console.log(
        `AllObjects :`,
        allElem.map(obj => {
          return `${obj.width}/${obj.height} (${obj.type})`;
        })
      );
    }

    priceResult.price =
      (onlyCalculateMaterial ? 0 : glueCostsProductionPrice.price) +
      priceMaterialProduction;

    if (this.logPriceSummary) {
      /*  this.logger.trace(`AllElement PRICE:
         plotPriceAdditional: ${plotPriceAdditional},
         elemSumDig: ${elemSumDig},
         elemSumPlot: ${elemSumPlot}
         digiPriceAdditional: ${digiPriceAdditional},
         totalPricePlot: ${totalPricePlot},
         allElementPrice: ${allElementPrice}
         totalPriceDigi: ${totalPriceDigi},
         basePriceSumPlot: ${basePriceSumPlot},
         Beklebungskosten: ${glueCostsProductionPrice.price},
         Material vor Faktoren (und Dselivery): ${totalPriceMaterialProduction},
         priceMaterialProduction:  ${priceMaterialProduction},
         TotalGlueTime: ${priceResult.glueTime},
         Arbeitseinheiten(graphGraphicsLeValue): ${priceResult.glueTime / 6},
         Price-Gesamt: ${priceResult.price}!

         `);*/
    }
    return priceResult;
  }

  /**
   *
   */
  private createPricingObjectForTextElement(
    e: CustomText,
    allPricingObject: PricingObject[],
    allPlotColorSet: Set<string>
  ) {
    // This should happen per Color sepately
    let colorChanges = 0;
    const allObjectColorSet = new Set<string>();
    const styleArray = this.getArrayOfStyles(e);

    for (let i = 0; i < styleArray.length; i++) {
      let currentFill = styleArray[i].fill;
      // add fills to array
      if (typeof currentFill === 'string') {
        allObjectColorSet.add(currentFill);
      } else {
        currentFill = e.fill;
      }
      // incase the fill was used add it to set
      if (typeof currentFill === 'string' && e.fill === currentFill) {
        allObjectColorSet.add(e.fill);
      }
      if (i + 1 < styleArray.length) {
        const nextFill = styleArray[i + 1].fill;
        if (
          typeof currentFill === 'string' &&
          typeof styleArray[i + 1].fill === 'string' &&
          currentFill !== nextFill
        ) {
          colorChanges++;
        }
      }
    }

    const po = new PricingObject();
    po.type = 'text';
    if (this.logPriceSummary) {
      /*  this.logger.trace(`
        text      ${e.text}
        width     ${e.width}
        +strokeWidth    ${e.width + e.strokeWidth}
        height    ${e.height}
        +strokeWidth   ${e.height + e.strokeWidth}
        scalex   ${e.scaleX}
        scaley   ${e.scaleY}
        strokewidth   ${e.strokeWidth}
        `);*/
    }
    po.width =
      PriceCalculatorService.getScaledWidth(e) *
      CONSTANTS.VIEWBOX_TO_METER_FACTOR *
      100;
    po.height =
      PriceCalculatorService.getScaledHeight(e) *
      CONSTANTS.VIEWBOX_TO_METER_FACTOR *
      100;
    po.charCount = e.text
      .replace(/(\f|\n|\r|\t|\v)/gm, '')
      .replace(/ /gm, '').length;
    po.colorChanges = colorChanges;
    po.colorCount = allObjectColorSet.size;
    po.referencedObject = e;

    allPricingObject.push(po);
    allObjectColorSet.forEach(colorValue => {
      if (this.logPriceSummary) {
        /*  this.logger.trace(
          e.text +
            '  colorChanges: ' +
            colorChanges +
            ' len: ' +
            e.text.length +
            ' len:-ohne Leerzeichen und Linebreak:' +
            po.charCount +
            ' colorCount:' +
            allObjectColorSet.size
        );*/
      }
      allPlotColorSet.add(colorValue);
    });
  }

  /**
   * returns the styles of an element as array for each charater unequal '\n' or  ' '
   */
  getArrayOfStyles(e: CustomText): any[] {
    const arr = [];
    let linectr = 0;
    let charctr = 0;
    const baseFill = e.fill;
    for (let ctr = 0; ctr < e.text.length; ctr++) {
      const currentChar = e.text.charAt(ctr);
      if (currentChar === '\n') {
        linectr++;
        charctr = 0;
        continue;
      }
      if (currentChar === '\u0020') {
        // Leerzeichen
        charctr++;
        continue;
      }
      try {
        const currentStyle = e.styles[linectr.toString()][charctr.toString()];
        // char debugging
        //   this.logger.trace(`${currentChar} : ${e.styles[linectr.toString()][charctr.toString()].fill}`);

        if (
          currentStyle &&
          currentStyle.fill &&
          typeof currentStyle.fill === 'string'
        ) {
          arr.push({ fill: currentStyle.fill });
        } else {
          arr.push({ fill: baseFill });
        }
      } catch (error) {
        // add Text fill
        arr.push({ fill: baseFill });
      }
      charctr++;
    }
    return arr;
  }
  /**
   * calculates the glue costs for all Elements
   *
   * elemCount is the number of all Elements
   * totalArea is the area filled by all Elements
   * @param elemCount
   * @param totalArea
   */
  calculateGlueCosts(
    elemCount: number,
    totalArea: number,
    countryDependentHourRate: number
  ): PriceCalculationResult {
    if (elemCount <= 0 && totalArea <= 0) {
      return new PriceCalculationResult({ price: 0 });
    }
    const totalPositionTimeInMin =
      elemCount * PriceCalculatorService.POSITONTIME_PER_ELEM_IN_MIN;
    const totalCleanTimeInMin =
      totalArea * PriceCalculatorService.CLEAN_TIME_PER_M2_IN_MIN;
    let totalGlueTimeInMin = totalCleanTimeInMin + totalPositionTimeInMin;

    // adaption for only full 6 minutes
    const originalValue = totalGlueTimeInMin;
    if (totalGlueTimeInMin % 6 > 0) {
      totalGlueTimeInMin = 6 * Math.ceil(totalGlueTimeInMin / 6);
    }
    /*
    this.logger.trace(
      `totalGlueTimeInMin: Adaption: ${originalValue}->${totalGlueTimeInMin}`
    );*/

    const costForGlueing = (totalGlueTimeInMin / 60) * countryDependentHourRate;
    const ruestPrice =
      PriceCalculatorService.RUEST_TIME * countryDependentHourRate;
    const totalCostGlueing = costForGlueing + ruestPrice;
    /*
    this.logger.trace(
      `AllElement totalCostGlueing:
       totalCostGlueing: ${totalCostGlueing},
       totalGlueTimeInMin: ${totalGlueTimeInMin},
       ruestTime: ${PriceCalculatorService.RUEST_TIME},
       cleanTime: ${totalCleanTimeInMin},
       ruestPrice: ${ruestPrice}`
    );
    */

    return new PriceCalculationResult({
      price: totalCostGlueing,
      glueTime: totalGlueTimeInMin,
      glueCosts: totalCostGlueing,
      ruestTime: PriceCalculatorService.RUEST_TIME,
    });
  }
  /**
   * calculates the matrial price for all digital
   * @param allElem
   */
  calculateMaterialPriceForAllDigitalElements(allElem: CustomImage[]): number {
    if (allElem.length <= 0) {
      return 0;
    }
    let areaSumInM2 = 0;
    allElem.forEach(e => {
      const areaInM2 =
        PriceCalculatorService.getScaledWidth(e) *
        PriceCalculatorService.getScaledHeight(e) *
        CONSTANTS.VIEWBOX_TO_METER_FACTOR *
        CONSTANTS.VIEWBOX_TO_METER_FACTOR;
      areaSumInM2 += areaInM2;
    });
    areaSumInM2 *= 1.3; // apply factor for  "Zugabe"

    const materialCosts = PriceCalculatorService.DIGI_COST_PER_M2 * areaSumInM2;
    const priceApplizieren = areaSumInM2 * 7 + 5;
    const priceDelivery = allElem.length * 0.6;
    const costsForAllDigitalElements =
      PriceCalculatorService.BASE_PRICE_DIGI +
      materialCosts +
      priceDelivery +
      priceApplizieren;
    /*this.logger.trace(`AllDIGi_ElementPrice:
       areaSumInM2+zugabe: ${areaSumInM2},
       materialCosts: ${materialCosts},
       priceDelivery: ${priceDelivery},
       priceApplizieren: ${priceApplizieren},
       BASE_PRICE_DIGI : ${PriceCalculatorService.BASE_PRICE_DIGI}
       ElementCosts: ${costsForAllDigitalElements}`);*/
    return costsForAllDigitalElements;
  }
  /**
   * calculates the material price for all plot elements (triangles, rectangles, circle)
   * @param allElem
   *
   */
  calculatePriceForAllPlotElements(allElem: PricingObject[]): number {
    if (allElem.length <= 0) {
      return 0;
    }
    let areaSumInM2 = 0;
    allElem.forEach(e => {
      const areaInM2 = (e.width * e.height) / 10000; // convert from cm to meter
      areaSumInM2 += areaInM2;
    });
    areaSumInM2 *= 1.3; // apply factor for  "Zugabe"
    const materialCosts = PriceCalculatorService.PLOT_COST_PER_M2 * areaSumInM2;
    const priceApplizieren = areaSumInM2 * 7 + 5;
    const priceDelivery = allElem.length * 0.6;

    // const price for elements
    // const priceForElements = allElem.length * PriceCalculatorService.ELEMENT_PRICE;
    /*this.logger.trace(
      `AllPlotElementPrice:
       areaSumInM2+zugabe: ${areaSumInM2},
       materialCosts: ${materialCosts},
       priceDelivery: ${priceDelivery},
       priceApplizieren: ${priceApplizieren}
       ElementCosts: ${materialCosts + priceDelivery + priceApplizieren}`
    );*/
    return materialCosts + priceDelivery + priceApplizieren;
  }

  private extractProfessionalPriceKey(carId, templateName) {
    const idElements = carId.split('-');
    return (
      idElements[0] +
      '-' +
      idElements[1] +
      '-' +
      idElements[2] +
      '-' +
      idElements[3] +
      '-' +
      idElements[4] +
      '-' +
      templateName
    );
  }

  /**
   * delegates the price calculation to priceCalculatorService
   * and sets the new prices according to the result
   *
   * @private
   * @memberof EditorComponent
   */
  public updateDruckauftragPrice(
    druckauftrag: Druckauftrag,
    hourRate,
    currencyFactor,
    userType: UserType,
    country: string
  ) {
    const priceCalculationResult = this.calculatePrice(
      druckauftrag,
      hourRate,
      currencyFactor,
      userType,
      country
    );
    druckauftrag.calculatedPrice = priceCalculationResult.price;
    druckauftrag.ruestTime = priceCalculationResult.ruestTime;
    druckauftrag.productionCosts = priceCalculationResult.productionCosts;
    druckauftrag.glueTime = priceCalculationResult.glueTime;
    druckauftrag.glueCosts = priceCalculationResult.glueCosts;
    druckauftrag.hourRate = priceCalculationResult.hourRate;

    druckauftrag.deliveryConstant = priceCalculationResult.deliveryConstant;

    druckauftrag.materialCosts = priceCalculationResult.materialCosts;
    druckauftrag.budget_extern = priceCalculationResult.budget_extern;
    /*  this.logger.info(`new Price ${priceCalculationResult.price}`);*/
  }

  private roundValue(num: number): number {
    return Math.round(num * 100) / 100;
  }

  private getAdditionalPriceForDbImages(
    allElem: CustomImage[],
    currencyFactor: number
  ): number {
    if (allElem.length <= 0) {
      return 0;
    }
    let additionalPriceSum = 0;
    allElem.forEach(e => {
      if (e.additionalPrice !== undefined) {
        additionalPriceSum += e.additionalPrice * currencyFactor;
      }
    });
    return additionalPriceSum;
  }
}
