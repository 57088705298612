import { Car } from './car';
import { Template } from './template';
import { Seitenansicht } from './seitenansicht';

export class Druckauftrag {
  front: Seitenansicht;
  heck: Seitenansicht;
  links: Seitenansicht;
  rechts: Seitenansicht;
  dachPath: string;
  car: Car;
  submittedForPrint: boolean;
  selectedTemplate: Template;
  templateColor: string;
  calculatedPrice: number;
  carColor: string;
  calculateMaterialOnly: boolean;
  configID: string;
  textLayout: string[][];
  branch: string;
  /**
   * Map from the text key to the user entered value.
   * @type {Map<any, any>}
   */
  textReplacementMap: any = {};

  // price stuff:
  productionCosts: number;
  glueTime: number;
  ruestTime: number;
  priceProfessionalNetto: number;
  deliveryConstant;
  glueCosts: number;
  hourRate: number;
  materialCosts: number;
  budget_extern: number;

  // design_user can defined a designUserPredefinedMinutes (SOBEKO-722 / SOBEKO-734)
  designUserPredefinedMinutes: number;
  // design_user can defined a designUserPredefineHqPrice (SOBEKO-722 / SOBEKO-734)
  designUserPredefinedHqPrice: number;
  // design_user can defined a designUserPredefinedVkPrice (SOBEKO-722 / SOBEKO-734)
  designUserPredefinedVkPrice: number;

  constructor(obj?: any) {
    if (obj) {
      if (obj.car) {
        this.car = new Car(obj.car);
      }
      this.submittedForPrint = obj.submittedForPrint || false;
      if (obj.selectedTemplate) {
        this.selectedTemplate = new Template(obj.selectedTemplate);
      }
      this.templateColor = obj.templateColor;
      this.carColor = obj.carColor;
      this.branch = obj.branch;

      if (obj.textReplacementMap) {
        this.textReplacementMap = obj.textReplacementMap;
      }
      this.configID = obj.configID;
      this.textLayout = obj.textLayout;
      this.calculateMaterialOnly = obj.calculateMaterialOnly;
      if (this.calculateMaterialOnly === undefined) {
        this.calculateMaterialOnly = false;
      }
      // price calucation info:
      this.calculatedPrice = obj.calculatedPrice;
      this.productionCosts = obj.productionCosts;
      this.glueTime = obj.glueTime;
      this.ruestTime = obj.ruestTime;
      this.priceProfessionalNetto = obj.priceProfessionalNetto;
      this.deliveryConstant = obj.deliveryConstant;
      this.glueCosts = obj.glueCosts;
      this.hourRate = obj.hourRate;
      this.materialCosts = obj.materialCosts;
      this.budget_extern = obj.budget_extern;

      this.designUserPredefinedHqPrice = obj.designUserPredefinedHqPrice;
      this.designUserPredefinedMinutes = obj.designUserPredefinedMinutes;
      this.designUserPredefinedVkPrice = obj.designUserPredefinedVkPrice;
    }
  }

  setSeitenansichten(
    newFront: Seitenansicht,
    newHeck: Seitenansicht,
    newLinks: Seitenansicht,
    newRechts: Seitenansicht
  ) {
    this.front = newFront;
    this.heck = newHeck;
    this.links = newLinks;
    this.rechts = newRechts;
  }

  /**
   * set this order not to be a template (targets the custom fabric objects)
   * essentially sets `isTemplateData` false for all objects
   */
  public disableTemplate(): void {
    this.links.disableTemplate();
    this.rechts.disableTemplate();
    this.front.disableTemplate();
    this.heck.disableTemplate();
  }
}
