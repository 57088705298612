import { isCustomText } from 'models/graphicelements/customtext';
import { TranslateService } from '@ngx-translate/core';

import { TEXT_COLOR_DATA } from 'assets/color-data/textcolor-data';
import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { NGXLogger } from "ngx-logger";import { Observable, fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActiveSelection } from 'fabric/fabric-impl';
@Component({
  selector: 'app-shape-color-selector-button',
  templateUrl: './shape-color-selector-button.component.html',
  styleUrls: ['./shape-color-selector-button.component.scss'],
})
export class ShapeColorSelectorButtonComponent
  implements OnInit, AfterViewInit {
  @Input()
  public activeObject;

  @Output() strokeClicked = new EventEmitter<void>();
  @Output() fillClicked = new EventEmitter<void>();
  @Output() strokeWidthUpdated = new EventEmitter<number>();

  @Output() isWidthUpdateActive = new EventEmitter<boolean>();
  private _strokeWidth;

  @ViewChild('widthInput', { static: true })
  private widthInput: ElementRef;
  constructor(
    private readonly domSanitizer: DomSanitizer,
    private logger: NGXLogger,
    private readonly translate: TranslateService,
    private matIconRegistry: MatIconRegistry
  ) {
    this.addIconsToRegistry();
  }

  ngOnInit() {}
  ngAfterViewInit(): void {
    fromEvent(this.widthInput.nativeElement, 'focus').subscribe(
      (event: FocusEvent) => {
        this.isWidthUpdateActive.emit(true);
      }
    );
    fromEvent(this.widthInput.nativeElement, 'blur').subscribe(
      (event: FocusEvent) => {
        this.isWidthUpdateActive.emit(false);
      }
    );
    fromEvent(this.widthInput.nativeElement, 'input')
      .pipe(
        map((a: Event) => {
          return (a.target as any).valueAsNumber;
        }),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((newValue: number) => {
        if (newValue !== undefined && newValue !== null && !isNaN(newValue)) {
          this.strokeWidthUpdated.emit(newValue);
        }
      });
  }
  private addIconsToRegistry = () => {
    this.matIconRegistry.addSvgIcon(
      'no-border',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/V2/icon-noborder.svg'
      )
    );
  };

  public get fillColor(): string {
    return this.activeObject && !this.isFillDisabled
      ? this.activeObject.fill
      : 'white';
  }

  public get borderColor(): string {
    return this.activeObject ? this.activeObject.stroke : 'black';
  }
  public get strokeWidth(): number {
    if (this.isStrokeWidthDisabled) {
      return 0;
    }
    this._strokeWidth = this.activeObject
      ? this.activeObject.strokeWidth
      : this._strokeWidth;
    return this._strokeWidth;
  }
  public set strokeWidth(value: number) {
    this._strokeWidth = Math.round(value);
  }

  public get isFillDisabled() {
    if (!this.activeObject) {
      return true;
    }
    return (
      this.activeObject.type !== 'customText' &&
      this.isStrokeWidthDisabled &&
      !this.allElementsInGroupAreText()
    );
  }

  /**
   * returns if current selection is a group and all elements are texts
   */
  private allElementsInGroupAreText(): boolean {
    return (
      this.activeObject.type === 'activeSelection' &&
      (this.activeObject as ActiveSelection).getObjects().filter(o => {
        return !isCustomText(o);
      }).length === 0
    );
  }

  public get isStrokeWidthDisabled() {
    if (!this.activeObject) {
      return true;
    }
    return (
      this.activeObject.type !== 'rect' &&
      this.activeObject.type !== 'triangle' &&
      this.activeObject.type !== 'customTriangle' &&
      this.activeObject.type !== 'circle'
    );
  }

  public get showOutlineColor(): boolean {
    return (
      !this.isStrokeWidthDisabled &&
      this.strokeWidth > 0 &&
      this.activeObject.stroke !== '' &&
      this.activeObject.stroke !== undefined
    );
  }

  public hasFocus(): boolean {
    return this.widthInput.nativeElement === document.activeElement;
  }

  public fillClick(): void {
    this.fillClicked.emit();
  }
  public strokeClick(): void {
    this.strokeClicked.emit();
  }

  public get fillColorName(): string {
    const match = TEXT_COLOR_DATA.filter(a => {
      return a.value === this.fillColor;
    });
    if (match.length > 0) {
      return match[0].name;
    }
    return '';
  }
  public getOpacityForColor(): number {
    if (this.activeObject && !this.isFillDisabled) {
      return 1;
    }
    return 0.2;
  }
  public getOpacityForStroke(): number {
    if (this.activeObject && !this.isStrokeWidthDisabled) {
      return 1;
    }
    return 0.3;
  }
  public getOpacityForStrokeWidth(): number {
    if (this.activeObject && !this.isStrokeWidthDisabled) {
      return 1;
    }
    return 0.2;
  }
}
