import {
  Component,
  OnInit,
  ViewChild,
//  ComponentFactoryResolver,
} from '@angular/core';

import { UploadDialogSortimoImageComponent } from './../upload-dialog-sortimo-image/upload-dialog-sortimo-image.component';
import { UploadDialogOwnImageComponent } from './../upload-dialog-own-image/upload-dialog-own-image.component';
import { UploadDialogChooseComponent } from './../upload-dialog-choose/upload-dialog-choose.component';
import { UploadDialogDirective } from './../../directives/upload-dialog.directive';

@Component({
  selector: 'app-upload-dialog-wrapper',
  templateUrl: './upload-dialog-wrapper.component.html',
})
export class UploadDialogWrapperComponent implements OnInit {
  @ViewChild(UploadDialogDirective, { static: true })
  dialog: UploadDialogDirective;

  /**
   *  since v13, dynamic component creation via ViewContainerRef.createComponent does not require resolving component factory: component class can be used directly.
   * 'ComponentFactoryResolver' is deprecated.
   */
  constructor(
   // private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.loadInitialComponent();
  }

  loadInitialComponent() {
    const component = UploadDialogChooseComponent;

    /* const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component
    ); */
    const viewContainerRef = this.dialog.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(component);
    componentRef.instance.setUploadCallback(this.loadUpload);
    componentRef.instance.setSortimoCallback(this.loadSortimoDb);
  }
  loadUpload = (): void => {
    const component = UploadDialogOwnImageComponent;

    /* const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component
    ); */

    const viewContainerRef = this.dialog.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.createComponent(component);
  };
  loadSortimoDb = (): void => {
    const component = UploadDialogSortimoImageComponent;

    /* const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component
    ); */

    const viewContainerRef = this.dialog.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.createComponent(component);
  };
}
