import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
/**
 * The confirmation dialog is a customized "popup"-dialog that displays
 * infromation according to the input data
 * this view returns a boolean ("yes/  no"- answer of the user)
 */
@Component({
  template: `
    <h2 mat-dialog-title>{{ titleKey | translate: paramsForTranslation }}</h2>
    <mat-dialog-content>
      {{ textKey | translate: paramsForTranslation }}
    </mat-dialog-content>
    <mat-dialog-actions>
      <button *ngIf="showNoButton" mat-button mat-dialog-close>
        {{ negativeText || 'NOTIFICATIONS.DIALOG-NEGATIVE-TEXT' | translate }}
      </button>
      <button mat-button [mat-dialog-close]="true">
        {{ positiveText || 'NOTIFICATIONS.DIALOG-POSTIVE-TEXT' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./confirmdialog.component.scss'],
})

/**
 * The confirmation dialog is a customized "popup"-dialog that displays
 * infromation according to the input data
 * this view returns a boolean ("yes/  no"- answer of the user)
 *
 * Customizable Dialog with the following input Parameters
 *
 * data.textKey // For Translation Keys
 * data.titleKey
 *
 * data.showNoButton default false
 * data.negativeText default 'Nein'
 * data.positiveText default 'Ja'
 *
 * @export
 * @class ConfirmDialogComponent
 */
export class ConfirmDialogComponent {
  public showNoButton = false;
  public positiveText;
  public negativeText;
  public titleKey;
  public paramsForTranslation;
  public textKey;

  constructor(
    private readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.titleKey) {
      this.titleKey = data.titleKey;
    }
    if (data.paramsForTranslation) {
      this.paramsForTranslation = data.paramsForTranslation;
    }
    if (data.textKey) {
      this.textKey = data.textKey;
    }
    if (this.data.showNoButton) {
      this.showNoButton = this.data.showNoButton;
    }
    if (this.data.positiveText) {
      this.positiveText = this.data.positiveText;
    }
    if (this.data.negativeText) {
      this.negativeText = this.data.negativeText;
    }
  }
}
