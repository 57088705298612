export const FASTTRACKREPLACEMENTS = {
  DE: {
    website: 'www.webseite-mustermann.de',
    phone: 'Tel. 0170 / 23 45 678',
    'info@webseite-mustermann.de': 'info@webseite-mustermann.de',
    'Ihr kompetenter Partner vor Ort!': 'Ihr kompetenter Partner vor Ort!',
    'Mustermann GmbH & Co.KG': 'Mustermann GmbH & Co.KG',
    streetAndNumber: 'Mustermannstraße 1',
    postalTown: '12345 Musterstadt',
    'Platzhalter für eigenen Upload': 'Platzhalter für eigenen Upload',
    company: 'Firma Max Mustermann',
    Heizungstechnik: 'Heizungstechnik',
    Holzverarbeitung: 'Holzverarbeitung',
    Elektroinstallation: 'Elektroinstallation',
    Meisterbetrieb: 'Meisterbetrieb',
  },
  EN: {
    website: 'www.webseite-mustermann.de',
    phone: 'Tel. 0170 / 23 45 678',
    'info@webseite-mustermann.de': 'info@webseite-mustermann.de',
    'Ihr kompetenter Partner vor Ort!': 'Ihr kompetenter Partner vor Ort!',
    'Mustermann GmbH & Co.KG': 'Mustermann GmbH & Co.KG',
    streetAndNumber: 'Mustermannstraße 1',
    postalTown: '12345 Musterstadt',
    'Platzhalter für eigenen Upload': 'Platzhalter für eigenen Upload',
    company: 'Firma Max Mustermann',
    Heizungstechnik: 'Heizungstechnik',
    Holzverarbeitung: 'Holzverarbeitung',
    Elektroinstallation: 'Elektroinstallation',
    Meisterbetrieb: 'Meisterbetrieb',
  },
  DE_AT: {
    website: 'www.webseite-mustermann.at',
    phone: 'Tel. 0170 / 23 45 678',
    'info@webseite-mustermann.de': 'info@webseite-mustermann.at',
    'Ihr kompetenter Partner vor Ort!': 'Ihr kompetenter Partner vor Ort!',
    'Mustermann GmbH & Co.KG': 'Mustermann GmbH & Co.KG',
    streetAndNumber: 'Mustermannstraße 1',
    postalTown: '12345 Musterstadt',
    'Platzhalter für eigenen Upload': 'Platzhalter für eigenen Upload',
    company: 'Firma Max Mustermann',
    Heizungstechnik: 'Heizungstechnik',
    Holzverarbeitung: 'Holzverarbeitung',
    Elektroinstallation: 'Elektroinstallation',
    Meisterbetrieb: 'Meisterbetrieb',
  },
  EN_UK: {
    website: 'www.johnsample.co.uk',
    phone: 'Call +44 10 2030 4050',
    'info@webseite-mustermann.de': 'mail@johnsample-company.co.uk',
    'Ihr kompetenter Partner vor Ort!': 'Your experts for any problem',
    'Mustermann GmbH & Co.KG': 'John Sample Company',
    streetAndNumber: 'Test Street 1',
    postalTown: 'XX1 2XX Test Town',
    'Platzhalter für eigenen Upload': 'Placeholder for your upload',
    company: 'John Sample Ltd.',
    Heizungstechnik: 'Heating',
    Holzverarbeitung: 'Ventilation',
    Elektroinstallation: 'Air-Conditioning',
    Meisterbetrieb: 'Certified tradesman',
  },
  EN_IE: {
    website: 'www.johnsample.co.uk',
    phone: 'Call +44 10 2030 4050',
    'info@webseite-mustermann.de': 'mail@johnsample-company.co.uk',
    'Ihr kompetenter Partner vor Ort!': 'Your experts for any problem',
    'Mustermann GmbH & Co.KG': 'John Sample Company',
    streetAndNumber: 'Test Street 1',
    postalTown: 'XX1 2XX Test Town',
    'Platzhalter für eigenen Upload': 'Placeholder for your upload',
    company: 'John Sample Ltd.',
    Heizungstechnik: 'Heating',
    Holzverarbeitung: 'Ventilation',
    Elektroinstallation: 'Air-Conditioning',
    Meisterbetrieb: 'Certified tradesman',
  },
  FR: {
    website: 'www.gruau.com',
    phone: 'tel.+332 96 50 12 75',
    'info@webseite-mustermann.de': 'Christine.MORIN@gruau.com',
    'Ihr kompetenter Partner vor Ort!': 'un expert du logotage à votre disposition',
    'Mustermann GmbH & Co.KG': "TEX' PRESS",
    streetAndNumber: "14 r d'Armor",
    postalTown: '22403 LAMBALLE CEDEX',
    'Platzhalter für eigenen Upload': 'Espace réservé pour téléchargement',
    company: 'Société GRUAU',
    Heizungstechnik: 'Carrossier ',
    Holzverarbeitung: 'Constructeur',
    Elektroinstallation: 'Aménageur',
    Meisterbetrieb: 'certifié ',
  },
  NL: {
    website: 'www.sjaaktest.nl',
    phone: 'tel. 010 - 12 34 567',
    'info@webseite-mustermann.de': 'info@sjaaktest.nl',
    'Ihr kompetenter Partner vor Ort!': 'uw vakkundige partner',
    'Mustermann GmbH & Co.KG': 'Sjaak Test B.V.',
    streetAndNumber: 'Teststraat 1',
    postalTown: '1234 AB Testplaats',
    'Platzhalter für eigenen Upload': 'plek voor eigen upload',
    company: 'Firma Sjaak Test',
    Heizungstechnik: 'Verwarming',
    Holzverarbeitung: 'Ventilatie',
    Elektroinstallation: 'Airconditioning',
    Meisterbetrieb: 'Erkende vakman',
  },
  FR_BE: {
    website: 'www.gruau.com',
    phone: 'tel.+332 96 50 12 75',
    'info@webseite-mustermann.de': 'Christine.MORIN@gruau.com',
    'Ihr kompetenter Partner vor Ort!': 'un expert du logotage à votre disposition',
    'Mustermann GmbH & Co.KG': "TEX' PRESS",
    streetAndNumber: "14 r d'Armor",
    postalTown: '22403 LAMBALLE CEDEX',
    'Platzhalter für eigenen Upload': 'Espace réservé pour téléchargement',
    company: 'Société GRUAU',
    Heizungstechnik: 'Carrossier ',
    Holzverarbeitung: 'Constructeur',
    Elektroinstallation: 'Aménageur',
    Meisterbetrieb: 'certifié ',
  },
  NL_BE: {
    website: 'www.mijnsite.be',
    phone: 'Tel.: 02 355 03 70',
    'info@webseite-mustermann.de': 'info@mijnsite.be',
    'Ihr kompetenter Partner vor Ort!': 'Uw expert voor alle karweien',
    'Mustermann GmbH & Co.KG': 'Mijn bedrijf',
    streetAndNumber: 'Test straat 1',
    postalTown: '1234 Test',
    'Platzhalter für eigenen Upload': 'Plaats voor eigen upload',
    company: 'Mijn bedrijf',
    Heizungstechnik: 'Verwarming',
    Holzverarbeitung: 'Ventilatie',
    Elektroinstallation: 'Koeling',
    Meisterbetrieb: 'Gecerticifeerd verkoper',
  },
  DA: {
    website: 'www.adresse.dk',
    phone: 'Telefon',
    'info@webseite-mustermann.de': 'mail@dresse.dk',
    'Ihr kompetenter Partner vor Ort!': 'Firma slogan her',
    'Mustermann GmbH & Co.KG': 'Firmaadresse',
    streetAndNumber: 'Firma vejnavn',
    postalTown: 'Firma post og by',
    'Platzhalter für eigenen Upload': 'Logo',
    company: 'Navn',
    Heizungstechnik: 'emne1',
    Holzverarbeitung: 'emne2',
    Elektroinstallation: 'emne3',
    Meisterbetrieb: 'emne 4',
  },
  SV_SE: {
    website: 'www.hemsida.se',
    phone: 'Telefonnummer',
    'info@webseite-mustermann.de': 'adress@e-post.se',
    'Ihr kompetenter Partner vor Ort!': 'Företagets slogan',
    'Mustermann GmbH & Co.KG': 'Företagets namn',
    streetAndNumber: 'Företagets adress',
    postalTown: 'Företagets postnummer',
    'Platzhalter für eigenen Upload': 'Logo',
    company: 'Namn',
    Heizungstechnik: 'Bransch 1',
    Holzverarbeitung: 'Bransch 2',
    Elektroinstallation: 'Bransch 3',
    Meisterbetrieb: 'Yrke',
  },
  NO: {
    website: 'www.adresse.no',
    phone: 'Telefon',
    'info@webseite-mustermann.de': 'mail@dresse.no',
    'Ihr kompetenter Partner vor Ort!': 'Firma slagord her',
    'Mustermann GmbH & Co.KG': 'Firmaadresse',
    streetAndNumber: 'Firma veinavn',
    postalTown: 'Firma postnr. og sted',
    'Platzhalter für eigenen Upload': 'Logo',
    company: 'Navn',
    Heizungstechnik: 'emne1',
    Holzverarbeitung: 'emne2',
    Elektroinstallation: 'emne3',
    Meisterbetrieb: 'emne4',
  },
  ES: {
    website: 'www.web-electricistas.com',
    phone: 'Tel. +34 91 628 94 27',
    'info@webseite-mustermann.de': 'mail@webprueba.com',
    'Ihr kompetenter Partner vor Ort!': 'Tus expertos para cualquier problema',
    'Mustermann GmbH & Co.KG': 'Electricitas',
    streetAndNumber: 'Calle 1',
    postalTown: '255 Ciudad',
    'Platzhalter für eigenen Upload': 'Marcador de posición para tu descarga',
    company: 'Electricistas, S.A.',
    Heizungstechnik: 'Calefacción',
    Holzverarbeitung: 'Ventilación',
    Elektroinstallation: 'Aire acondicionado',
    Meisterbetrieb: 'Comercial certificado',
  },
};
