import { FontDataService } from '../services/fontdataservice';
import { CONSTANTS } from 'models/helpers/constants';
import { WarningManagerService } from './warning-manager.service';
import { Druckauftrag } from './../../models/druckauftrag';
import { Location } from '@angular/common';
import { UserType } from '../../models/usertype';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { DruckauftragCreatorService } from './druckauftragcreator.service';
import { DataService } from './dataservice';
import { TranslateService } from '@ngx-translate/core';
import { Params } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { StartInformation } from '../../models/communcation/StartInformation';
import { RestDataService } from './restdataservice';
import { NGXLogger } from "ngx-logger";
import { lastValueFrom } from 'rxjs';
@Injectable()
export class InitializationService {
  public hideLoadingCicleEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private startInformation: StartInformation;
  private languageChangeEmitter: EventEmitter<void>;
  constructor(
    private readonly translateService: TranslateService,
    private readonly dataService: DataService,
    private readonly druckauftragCreatorService: DruckauftragCreatorService,
    private readonly location: Location,
    private readonly angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private readonly restDataService: RestDataService,
    private logger: NGXLogger,
    private readonly warningManagerService: WarningManagerService,
    private readonly fontDataService: FontDataService
  ) {
    this.handleParameters.bind(this);
  }

  public get isTopsystemActive() {
    this.dataService.isTopSystemActive =
      this.startInformation !== undefined &&
      this.startInformation !== null &&
      this.startInformation.vehicleId !== null &&
      this.startInformation.vehicleId !== undefined &&
      (this.startInformation.vehicleId.indexOf('topsystem') >= 0 ||
        this.startInformation.vehicleId.indexOf('wange') >= 0);
    return this.dataService.isTopSystemActive;
  }

  public setLangageSetEmitter(emitter: EventEmitter<void>) {
    this.languageChangeEmitter = emitter;
  }

  handleParameters = async (params: Params) => {
    this.logger.debug(`InitParamters: ${JSON.stringify(params)}`);
    if (!params) {
      this.hideLoadingCicleEmitter.emit(true);
      return;
    }
    // change type to Startparameters
    const observable = this.restDataService.getStartInformation(params);
    this.startInformation = await lastValueFrom(observable);
   /*  this.startInformation = await this.restDataService.getStartInformation(
      params
    ) */
    if (!this.startInformation) {
      throw Error('Already Initialized and StartParameters are not defined!');
    }
    this.dataService.hybrisConfigId = params[CONSTANTS.HYBRIS_CONFIG_ID_KEY];

    this.logger.debug(JSON.stringify(this.startInformation));

    const language = this.startInformation.language;
    this.dataService.country = this.startInformation.country;
    this.dataService.countryHourRate = this.startInformation.countryHourRate;
    this.dataService.currencyFactor = this.startInformation.currencyFactor;
    this.dataService.currency = this.startInformation.currency;
    this.dataService.tax = this.startInformation.tax;

    this.dataService.readOnly = this.startInformation.readOnly;
    this.dataService.userType = this.startInformation.userType;
    this.dataService.configMode = this.startInformation.configMode;
    this.dataService.vehicleColors = this.startInformation.vehcleColors;

    if (language) {
      this.translateService.use(language.toLowerCase());
      this.languageChangeEmitter.emit();
    }
    this.initGoogleAnalytics(this.startInformation.country);
    await this.fontDataService.getAllFontLoadedPromise();
    const druckauftrag =
      this.dataService.druckauftrag ||
      (await this.dataService.createLiveDruckauftrag(
        this.startInformation.druckauftrag
      ));

    if (!this.startInformation) {
      throw Error('Already Initialized and StartParameters are not defined!');
    }
    // design user: set readonly in case "predefinedPrice" is set
    if (
      !this.dataService.readOnly &&
      druckauftrag.designUserPredefinedHqPrice !== null &&
      druckauftrag.designUserPredefinedHqPrice !== undefined &&
      this.dataService.userType !== UserType.DESIGNUSER
    ) {
      this.dataService.readOnly = true;
    }

    await this.initializeDruckauftrag(druckauftrag);

    console.log('Emit DruckauftragLoaded');
    this.dataService.druckauftragLoadedEmitter.emit();
  };

  private initGoogleAnalytics(country) {
    if (environment.production) {
      const keyMapping = {
        DE: 'GTM-TRSQ3TZ',
        AT: 'GTM-N7SGL6H',
        BE: 'GTM-WB8JHNQ',
        UK: 'GTM-TW88DBV',
        DK: 'GTM-KNJ92MT',
        ES: 'GTM-WZHCNCV',
        FR: 'GTM-N5BS4H6',
        IE: 'GTM-PPXWN6G',
        NL: 'GTM-5LH2PBD',
        NO: 'GTM-MLZGZXS',
        SE: 'GTM-WWRJM52',
        US: 'GTM-KWQHN9S',
      };
      (window as any).gtm(
        window,
        document,
        'script',
        'dataLayer',
        keyMapping[country]
      );
      const url = this.location.path();
      this.angulartics2GoogleTagManager.pageTrack(url);
    }
  }

  private async initializeDruckauftrag(
    druckauftrag: Druckauftrag
  ): Promise<void> {
    this.dataService.isDruckauftragCreationRunning = true;

    if (this.startInformation.configId) {
      druckauftrag.disableTemplate();
    }
    // initialize data
    await this.dataService.setDruckauftrag(
      druckauftrag,
      this.startInformation.country,
      this.startInformation.language,
      !this.dataService.readOnly &&
        (druckauftrag.configID === null || druckauftrag.configID === undefined)
    );
    this.dataService.druckauftrag.configID = this.startInformation.configId;
    this.dataService.druckauftrag.branch = this.startInformation.branch;

    // initialize intersections
    this.initializeIntersections();

    this.hideLoadingCicleEmitter.emit(true);

    this.dataService.isDruckauftragCreationRunning = false;

    if (this.dataService.readOnly) {
      this.dataService.navigateToPreview();
    }
    if (this.startInformation.configId && this.startInformation.druckauftrag) {
      this.dataService.navigateToKonfigurator();
    }
  }

  public getStartInformation() {
    return this.startInformation;
  }

  public getCarName(): string {
    return this.startInformation ? this.startInformation.carName : '';
  }

  private initializeIntersections(): void {
    this.warningManagerService.updateIntersections(
      this.dataService.druckauftrag.rechts
    );
    this.warningManagerService.updateIntersections(
      this.dataService.druckauftrag.links
    );
    this.warningManagerService.updateIntersections(
      this.dataService.druckauftrag.front
    );
    this.warningManagerService.updateIntersections(
      this.dataService.druckauftrag.heck
    );
  }
}
