import { TemplateInteractionService } from './../services/templateInteraction.service';
import { TemplateDataParser } from 'app/services/templatedataparser';
import { Injectable, EventEmitter } from '@angular/core';

import { fabric } from 'fabric';

export class CarSideManager {
  private document: Document;
  private svgElement: SVGElement;
  private background: HTMLElement;
  private editInvert: HTMLElement;
  private carSkizze: HTMLElement;
  private editArea: HTMLElement;
  private viewBoxHeight: number;
  private viewBoxWidth: number;

  /**
   * Eventemitter for changes of the representation
   */
  public datachangeEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    carsvg: Document,
    private templateDataParser: TemplateDataParser
  ) {
    this.document = carsvg;
    const obj = this.document.lastChild;
    this.svgElement =
      obj.nodeName === 'svg'
        ? <SVGElement>obj
        : <SVGElement>this.document.firstElementChild;

    this.background = undefined;

    if (!carsvg) {
      return;
    }
    this.background = carsvg.getElementById('Color');
    if (this.background.getAttribute('display')) {
      this.background.removeAttribute('display');
    }
    this.carSkizze = carsvg.getElementById('Skizze');
    this.editArea = carsvg.getElementById('Edit');
    this.editInvert = carsvg.getElementById('EditInvert');
    if (this.editInvert.getAttribute('display')) {
      this.editInvert.removeAttribute('display');
    }
    this.hideAllLayers();

    this.initEditInvert();

    this.initializeDisplayedLayers();
    this.emitDataChange();
  }

  private initializeDisplayedLayers() {
    this.showElement(this.background);
    this.showElement(this.carSkizze);
    this.showElement(this.editInvert);
  }

  initEditInvert(): void {
    const defs = this.document.createElement('defs');
    const pattern = this.document.createElement('pattern');
    pattern.setAttribute('id', 'diagonalHatch');
    pattern.setAttribute('patternUnits', 'userSpaceOnUse');
    pattern.setAttribute('width', '4');
    pattern.setAttribute('height', '4');
    const lg = this.document.createElement('linearGradient');
    lg.setAttribute('id', 'grad1');
    lg.setAttribute('x1', '0%');
    lg.setAttribute('y1', '0%');
    lg.setAttribute('x2', '100%');
    lg.setAttribute('y2', '0%');
    const path = this.document.createElement('path');
    path.setAttribute('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2');
    path.setAttribute('style', 'stroke:url(#grad1); stroke-width:1;');
    const stop1 = this.document.createElement('stop');
    stop1.setAttribute('offset', '0%');
    stop1.setAttribute('style', 'stop-color:rgb(0,0,0); stop-opacity:1');
    const stop2 = this.document.createElement('stop');
    stop2.setAttribute('offset', '100%');
    stop2.setAttribute('style', 'stop-color:rgb(255,255,255); stop-opacity:1');
    lg.appendChild(stop1);
    lg.appendChild(stop2);
    pattern.appendChild(lg);
    pattern.appendChild(path);
    defs.appendChild(pattern);
    this.svgElement.appendChild(defs);
    this.setElementFillandOpacity(
      this.editInvert,
      'url(#diagonalHatch)',
      '' + 0
    );
  }

  /**
   * hide all child elements of the svg element
   */
  private hideAllLayers() {
    for (let i = 0; i < this.svgElement.childNodes.length; i++) {
      const htmlElem = <HTMLElement>this.svgElement.childNodes.item(i);
      if (htmlElem.setAttribute && htmlElem.tagName !== 'defs') {
        htmlElem.setAttribute('style', 'display: none;');
      }
    }
  }
  /**
   * removes the style attribute of a passed HTMLElement
   */
  private showElement(htmlElem: HTMLElement) {
    htmlElem.removeAttribute('style');
  }

  /**
   * createPresentationView
   * show only carImage and Background
   */
  public createPresentationView(carColor: string) {
    this.hideAllLayers();
    this.initializeDisplayedLayers();
    this.hideEditInvert();
    this.setBackgroundColor(carColor);
    this.emitDataChange();
  }
  public setElementFillandOpacity(
    element: HTMLElement,
    color = '#000fff',
    opacity?: string
  ) {
    this.setElementStyle(element, 'fill', color);
    if (opacity) {
      this.setElementStyle(element, 'opacity', opacity);
    }
    for (let i = 0; i < element.childNodes.length; i++) {
      const child = <Element>element.childNodes.item(i);
      // check whether this is a text
      this.setElementStyle(child, 'fill', color);
    }
  }

  public setElementStyle(
    elem: HTMLElement | Node,
    stylename: string,
    value: string
  ) {
    const element = <Element>elem;

    if (element.nodeName !== undefined && element.nodeName !== '#text') {
      const attrChildNode = element.getAttributeNode(stylename);
      if (attrChildNode) {
        attrChildNode.value = value;
      } else {
        element.setAttribute(stylename, value);
      }
    }
  }

  /**
   *  set the background color of the car
   */
  public setBackgroundColor(fill: string) {
    this.setElementFillandOpacity(this.background, fill);
    this.emitDataChange();
  }
  /**
   * show the EditInvert layer
   */
  public showEditInvert() {
    this.setElementStyle(this.editInvert, 'opacity', '1');
    this.emitDataChange();
  }
  /**
   * hide the EditInvert layer
   */
  public hideEditInvert() {
    this.setElementStyle(this.editInvert, 'opacity', '0');
    this.emitDataChange();
  }

  private getTemplateContentData(templatename: string): HTMLElement {
    const elem = this.document.getElementById(
      this.getTemplateElementName(templatename)
    );
    if (elem) {
      return elem;
    }
    return undefined;
  }
  /**
   * returns the document of the managed svg
   */
  public getDocument(): Document {
    return this.document;
  }

  public setViewPort(tl: fabric.Point, br: fabric.Point) {
    this.svgElement.setAttribute(
      'viewBox',
      `${tl.x} ${tl.y} ${br.x - tl.x} ${br.y - tl.y}`
    );
    this.emitDataChange();
  }

  public getViewboxWidth(): number {
    if (!this.viewBoxWidth) {
      this.viewBoxWidth = parseFloat(
        this.svgElement.attributes.getNamedItem('viewBox').value.split(' ')[2]
      );
      console.log(`CarSideManager: read VB-Width: ${this.viewBoxWidth}`);
    }
    return this.viewBoxWidth;
  }
  public getViewboxHeight(): number {
    if (!this.viewBoxHeight) {
      this.viewBoxHeight = parseFloat(
        this.svgElement.attributes.getNamedItem('viewBox').value.split(' ')[3]
      );

      console.log(`CarSideManager: read VB-Height: ${this.viewBoxHeight}`);
    }
    return this.viewBoxHeight;
  }

  public getAllId(): Set<string> {
    const set = new Set<string>();
    for (let i = 0; i < this.svgElement.childNodes.length; i++) {
      const htmlElem = <HTMLElement>this.svgElement.childNodes.item(i);
      if (htmlElem.setAttribute) {
        set.add(htmlElem.getAttribute('id'));
      }
    }
    return set;
  }
  public getAllTemplateData(templatename: string): Promise<fabric.Object[]> {
    const contentElem = this.getTemplateContentData(templatename);
    if (contentElem) {
      return this.templateDataParser.parseTemplateDataSVG(contentElem);
    }
    console.error(`No Template Data found for ${templatename}`);
    return Promise.resolve([]);
  }

  private emitDataChange() {
    this.datachangeEmitter.emit();
  }

  private getTemplateElementName(templatename: string) {
    const splittedNameArr = templatename.split('-');
    const typeName =
      splittedNameArr[0].charAt(0).toUpperCase() + splittedNameArr[0].substr(1);
    return 'Template' + typeName + splittedNameArr[1];
  }
}
