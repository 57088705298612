import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AzureImage } from './../../../models/communcation/AzureImage';
import { DataService } from './../../services/dataservice';

@Component({
  selector: 'app-sortimo-image-display',
  templateUrl: './sortimo-image-display.component.html',
  styleUrls: ['./sortimo-image-display.component.scss'],
})
export class SortimoImageDisplayComponent implements OnInit {
  @Input()
  public image: AzureImage;
  @Input()
  public selected: boolean;

  constructor(
    private readonly dataService: DataService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {}

  public get price(): string {
    return (
      this.image.priceValue * this.dataService.currencyFactor
    ).toLocaleString(this.translateService.currentLang.substring(0, 2), {
      style: 'currency',
      currency: this.dataService.currency,
    });
  }
  private getDefaultOrSelectedValue<T>(selected: T, defaultValue: T): T {
    return this.selected ? selected : defaultValue;
  }

  public get background(): string {
    return this.getDefaultOrSelectedValue('#dee7f0ff', 'transparent');
  }

  public get borderWidth(): string {
    return this.getDefaultOrSelectedValue('2px', '0px');
  }
  public get borderColor(): string {
    return this.getDefaultOrSelectedValue('#0068b3', 'grey');
  }
}
