import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { StateStorageService } from './../services/statestorage';
import { PriceCalculatorService } from './../services/price-calculator.service';
import { UserType } from '../../models/usertype';
import { DataService } from './../services/dataservice';
import { DESIGN_USER_VK_FACTOR } from '../../assets/pricing/pricing-country-constants';
@Component({
  selector: 'app-aktueller-preis',
  templateUrl: './aktueller-preis.component.html',
  styleUrls: ['./aktueller-preis.component.scss'],
})
export class AktuellerPreisComponent {
  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private priceCalculatorService: PriceCalculatorService,
    private stateStorageService: StateStorageService
  ) {}
  @Input()
  allowEdit: boolean = false;

  public hasCalculatedPrice() {
    if (this.dataService.druckauftrag) {
      return this.dataService.druckauftrag.calculatedPrice >= 0 ? true : false;
    }
    return false;
  }

  public getPrice(weight = 1): string {
    if (!this.hasCalculatedPrice()) {
      return '?';
    }
    let price = this.dataService.druckauftrag.calculatedPrice;
    if (
      this.dataService.druckauftrag.designUserPredefinedHqPrice !== null &&
      this.dataService.druckauftrag.designUserPredefinedHqPrice !== undefined &&
      this.dataService.druckauftrag.designUserPredefinedVkPrice !== null &&
      this.dataService.druckauftrag.designUserPredefinedVkPrice !== undefined &&
      this.dataService.druckauftrag.designUserPredefinedMinutes !== null &&
      this.dataService.druckauftrag.designUserPredefinedMinutes !== undefined
    ) {
      // Preis inkl. Beklebung
      price = ( this.dataService.druckauftrag.designUserPredefinedVkPrice +
        ( this.dataService.druckauftrag.designUserPredefinedMinutes / 60) *
        this.dataService.countryHourRate ) * this.dataService.currencyFactor

    }

    const roundedValue = Math.round(weight * price * 100) / 100;

    const localeString = `${this.translateService.currentLang.substr(0, 2)}-${
      this.dataService.country
    }`;

    return roundedValue.toLocaleString(localeString, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: this.dataService.currency,
      style: 'currency',
    });
  }

  public getPriceString(): string {
    let priceString = '';
    const tax =
      this.dataService.tax > 1
        ? this.dataService.tax / 100
        : this.dataService.tax;
    this.translateService
      .get('PREIS.TAX', { vat: this.getPrice(tax) })
      .subscribe(result => {
        priceString = result;
      });

    return priceString;
  }
  public isProUser(): boolean {
    return this.dataService.userType === UserType.PROUSER;
  }
  public isDesignUser(): boolean {
    return this.dataService.userType === UserType.DESIGNUSER;
  }
  public getCurrency(): string {
    return this.dataService.currency;
  }
  public toggleMaterial(event) {
    if (event.target.id === 'material-only-toggle') {
      this.dataService.druckauftrag.calculateMaterialOnly =
        !this.dataService.druckauftrag.calculateMaterialOnly;

      this.priceCalculatorService.updateDruckauftragPrice(
        this.dataService.druckauftrag,
        this.dataService.countryHourRate,
        this.dataService.currencyFactor,
        this.dataService.userType,
        this.dataService.country
      );

      this.stateStorageService.saveState();
    }
  }

  public onUpdateHqPrice() {
    this.dataService.druckauftrag.designUserPredefinedVkPrice =
      this.dataService.druckauftrag.designUserPredefinedHqPrice *
      DESIGN_USER_VK_FACTOR;
  }
  public get materialOnly(): boolean {
    return this.dataService.druckauftrag.calculateMaterialOnly;
  }
  public set materialOnly(b: boolean) {
    this.dataService.druckauftrag.calculateMaterialOnly = b;
  }
  public get predefinedHqPrice() {
    return this.dataService.druckauftrag.designUserPredefinedHqPrice;
  }
  public set predefinedHqPrice(value) {
    this.dataService.druckauftrag.designUserPredefinedHqPrice = value;
  }
  public get predefinedMinutes() {
    return this.dataService.druckauftrag.designUserPredefinedMinutes;
  }
  public set predefinedMinutes(value) {
    this.dataService.druckauftrag.designUserPredefinedMinutes = value;
  }
  public get predefinedVkPrice() {
    return this.dataService.druckauftrag.designUserPredefinedVkPrice;
  }
  public set predefinedVkPrice(value) {
    this.dataService.druckauftrag.designUserPredefinedVkPrice = value;
  }
}
