import { Injectable } from '@angular/core';
const uuidv4 = require('uuid/v4');

import { environment } from 'environments/environment';
import { TEXT_COLOR_DATA } from 'assets/color-data/textcolor-data';
import { fabric } from 'fabric';
import * as fabricSrc from 'fabric';
import { CustomText } from 'models/graphicelements/customtext';
import { CustomImage } from 'models/graphicelements/customimage';

/**
 * This service reads the data of the Template-svg and creates fabric.objects,
 * which can be added to a canvas.
 */
@Injectable()
export class TemplateDataParser {
  private fabric: any;
  constructor() {
    this.fabric = <any>fabricSrc.fabric;
  }

  /**
   * returns promise of all Fabric objects
   * @param svg string
   */
  public parseTemplateDataSVG = (
    svgContent: HTMLElement
  ): Promise<fabric.Object[]> => {
    if (svgContent != null) {
      const promiseArray: Promise<fabric.Object>[] = this.handleChildNodes(
        svgContent
      );

      return Promise.all(promiseArray);
    }
    return new Promise(resolve => resolve(undefined));
  };
  /**
   * parse child nodes of svg Document
   */
  private handleChildNodes(
    element: HTMLElement,
    widthOfRectangle?: number,
    rectLeftIn?: number
  ): Promise<fabric.Object>[] {
    let promiseArray: Promise<fabric.Object>[] = [];
    let rectangleWidth: number = widthOfRectangle;
    let rectLeft: number = rectLeftIn;
    for (let i = 0; i < element.childNodes.length; i++) {
      const elem = element.childNodes.item(i);
      if (elem.nodeName === 'rect') {
        rectangleWidth = parseFloat(
          (<HTMLElement>elem).attributes.getNamedItem('width').nodeValue
        );
        rectLeft = parseFloat(
          (<HTMLElement>elem).attributes.getNamedItem('x').nodeValue
        );
      }
      if (elem.nodeName === 'text') {
        promiseArray.push(
          this.parseTextElement(elem, rectangleWidth, rectLeft)
        );
      } else if (elem.nodeName === 'image') {
        promiseArray.push(this.parseImageElement(<HTMLElement>elem));
      } else if (elem.nodeName === 'g') {
        promiseArray = promiseArray.concat(
          this.handleChildNodes(<HTMLElement>elem, rectangleWidth, rectLeft)
        );
      }
    }
    return promiseArray;
  }

  /**
   * parses ImageElements from Text to fabricObject
   * The Promise is Retrned because creating Image Objects might contain asynchronous parts
   */
  private parseImageElement = (
    element: HTMLElement
  ): Promise<fabric.Object> => {
    if (element.tagName !== 'image') {
      console.log('wrong tag type!');
      return undefined;
    }

    return new Promise((resolve, reject) => {
      const params = this.parseObjectScaleAndPosition(element);
      element.removeAttribute('transform');
      CustomImage.fromElement(element, oImg => {
        oImg.isTemplateData = true;
        oImg.width = parseFloat(element.getAttribute('width')); // * params.scaleX;
        oImg.height = parseFloat(element.getAttribute('height')); // * params.scaleY;
        oImg.scaleX = params.scaleX;
        oImg.scaleY = params.scaleY;
        oImg.left = params.left;
        oImg.top = params.top;
        oImg.setCoords();
        oImg.id = uuidv4();
        return resolve(oImg);
      });
    });
  };

  private parseObjectScaleAndPosition(
    element: HTMLElement
  ): { scaleX; left; top; scaleY } {
    const matrixStr = this.parseAttributesAsString(
      element,
      'transform',
      'matrix'
    );
    element.setAttribute('transform', '');
    let a = 1,
      b = 0,
      c = 0,
      d = 1,
      e = 0,
      f = 0;

    if (matrixStr) {
      const strArr = matrixStr.split(' ');
      if (strArr.length === 6) {
        a = parseFloat(strArr[0]);
        b = parseFloat(strArr[1]);
        c = parseFloat(strArr[2]);
        d = parseFloat(strArr[3]);
        e = parseFloat(strArr[4]);
        f = parseFloat(strArr[5]);
      }
    }
    const obj = { scaleX: 1, left: 0, top: 0, scaleY: 0 };
    if (a) {
      obj.scaleX = a;
    }
    if (d) {
      obj.scaleY = d;
    }
    if (e) {
      obj.left = e;
    }
    if (f) {
      obj.top = f;
    }
    return obj;
  }

  /**
   * parses <Text elements> Promise is returned for consistency between childobjects
   */
  private parseTextElement = (
    element,
    rectangleWidth?: number,
    rectLeft?: number
  ): Promise<fabric.Object> => {
    return new Promise((resolve, reject) => {
      if (element.tagName !== 'text') {
        console.log('wrong tag type!');
        return reject();
      }
      const textObj: CustomText = new CustomText('');
      const linenumber = 1;
      const currentStyle = this.insertStyledText(element, textObj, 0, '', {});

      textObj.isTemplateData = true;
      let arr = this.parseTwoParamAttribute(
        element,
        'transform',
        'translate',
        0,
        0
      );
      let e = arr[0];
      let f = arr[1];
      arr = this.parseTwoParamAttribute(element, 'transform', 'scale', 1, 1);
      let a = arr[0];
      let d = arr[1];

      let b = undefined,
        c = undefined;

      const matrixStr = this.parseAttributesAsString(
        element,
        'transform',
        'matrix'
      );
      if (matrixStr) {
        const strArr = matrixStr.split(' ');
        if (strArr.length === 6) {
          a = parseFloat(strArr[0]);
          b = parseFloat(strArr[1]);
          c = parseFloat(strArr[2]);
          d = parseFloat(strArr[3]);
          e = parseFloat(strArr[4]);
          f = parseFloat(strArr[5]);
        }
      }
      textObj.left = e;
      textObj.initialLeft = rectLeft || textObj.left;

      if (currentStyle.fontSize) {
        textObj.top = f - currentStyle.fontSize;
        textObj.initialTop = textObj.top;
      } else {
        // try to remove fontsize of first child
        let fontsizeChild = 0;
        if (element.childNodes.length > 1) {
          let fontsizeItem = undefined;
          if (element.childNodes[0].nodeName === 'tspan') {
            fontsizeItem = element.childNodes[0].attributes.getNamedItem(
              'font-size'
            );
          } else if (element.childNodes[1].nodeName === 'tspan') {
            fontsizeItem = element.childNodes[1].attributes.getNamedItem(
              'font-size'
            );
          }
          if (fontsizeItem) {
            fontsizeChild = parseInt(fontsizeItem.value, 10);
          }
          if (fontsizeChild) {
            textObj.top = f - fontsizeChild;
          } else {
            textObj.top = f;
          }
        } else {
          textObj.top = f;
        }
      }

      // in case of right alignment the left attribute needs to be adapted
      let xDifference = 0;
      // needed to identify right alignment when biggest object is in first row
      let initialTspanWidth = 0;
      let isElementBiggerThanInitialwidth = false;
      for (let i = 0; i < element.childNodes.length; i++) {
        let y = parseInt(element.getAttribute('y'), 10);
        const childnode = element.childNodes.item(i);
        if (childnode && childnode.nodeName === 'tspan') {
          y = this.parseTSpanElement(
            childnode,
            i,
            textObj,
            y || 0,
            currentStyle,
            linenumber
          );
          // once save intital_width
          if (initialTspanWidth === 0) {
            initialTspanWidth = childnode.innerHTML
              ? childnode.innerHTML.length
              : childnode.firstChild.length;
          } else {
            const nextelemWidth = childnode.innerHTML
              ? childnode.innerHTML.length
              : childnode.firstChild.length;
            if (nextelemWidth > initialTspanWidth) {
              isElementBiggerThanInitialwidth = true;
            }
          }
          // evaluate alignment
          if (childnode.attributes.getNamedItem('x')) {
            const x =
              parseInt(childnode.attributes.getNamedItem('x').value, 10) || 0;
            if (x < xDifference) {
              xDifference = x;
            }
          }
        } else {
          this.insertStyledText(
            element,
            textObj,
            textObj.text.length,
            element.childNodes.item(i).textContent.trim(),
            currentStyle
          );
        }
      }
      if (xDifference !== 0 || isElementBiggerThanInitialwidth) {
        textObj.left = textObj.left + xDifference;
        textObj.textAlign = 'right';
      }

      // TODO: BEKO-307 figure out how we extract the text key, for now we simply use the objects own text, usually
      // we would use something like an SVG annotation of the text element.

      textObj.textKey = textObj.text.replace(/\n/g, '');
      textObj.initialFontSize = textObj.fontSize;
      textObj.initialFontFamily = textObj.fontFamily;
      textObj.initialWidth = rectangleWidth;

      textObj.hasBorders = true;
      textObj.hasControls = true;
      textObj.hasRotatingPoint = true;
      textObj.selectable = true;
      textObj.id = uuidv4();
      textObj.setCoords();
      return resolve(textObj);
      /*return (<any>fabric).util.enlivenObjects([textObj], (allObject: fabric.Object[]) => {
        return resolve(allObject[0]);
      })*/
    });
  };
  /**
   * parses and handles TSpan Elements of the svg
   */
  private parseTSpanElement = (
    node,
    index,
    textObj,
    currentYoffset,
    currentStyle,
    linenumber
  ) => {
    if (node.nodeName !== 'tspan') {
      console.log('wrong tag type!');
      return currentYoffset;
    }
    const startLength = textObj.text.length;
    let y = currentYoffset;
    // Evaluate wheter there should be a newline
    if (node.attributes.getNamedItem('y')) {
      y = parseInt(node.attributes.getNamedItem('y').value, 10) || 0;
    }
    let newLine = false;
    if (y > currentYoffset) {
      linenumber++;
      currentYoffset = y;
      //  // this.insertStyledText(node, textObj, index, '\n', currentStyle)
      //   textObj.insertNewlineStyleObject(linenumber, index, true);
      console.log(
        `newline line:${linenumber} at:index ${index},currentYoffset ${currentYoffset} linenumber ${linenumber}`
      );
      newLine = true;
    }

    const substyle = this.insertStyledText(
      node,
      undefined,
      0,
      '',
      currentStyle
    );
    for (let i = 0; i < node.childNodes.length; i++) {
      if (node.childNodes.item(i).nodeName === 'tspan') {
        y = this.parseTSpanElement(
          node.childNodes.item(i),
          i,
          textObj,
          y || 0,
          substyle,
          linenumber
        );
      } else {
        let prefix = '';
        if (newLine) {
          prefix = '\n';
        }
        this.insertStyledText(
          node,
          textObj,
          textObj.text.length,
          prefix + node.childNodes.item(i).textContent.trim(),
          currentStyle
        );
      }
    }

    let fill = undefined;
    if (textObj.styles[0] && textObj.styles[0][0]) {
      // console.log(textObj.styles);
      fill = this.replaceFillColorOfTemplates(textObj.styles[0][0].fill);
    }
    const tmpStyles = textObj.styles;
    const newFill = fill || this.replaceFillColorOfTemplates(textObj.fill);
    this.checkFillValidity(newFill);
    textObj.fill = newFill;
    textObj.styles = tmpStyles;
    return y;
  };

  /**
   * replaces wrongly set fill Colors
   * @param colorIn
   */
  private replaceFillColorOfTemplates(colorIn: string): string {
    let color = this.normalizedColorName(colorIn);
    //  console.log(typeof colorIn);
    // console.log(colorIn);
    if (color === '#1A66AF') {
      // 869 Intense Blue rgb(26,102,75)
      color = '#0054A9';
    } else if (color === '#1D66AF') {
      color = '#0054A9';
    } else if (color === '#010202') {
      // 801 Black rgb(1,2,2)
      color = '#000000';
    } else if (color === '#0069B4') {
      // 1080 G47 GlossIntenseBlue rgb(0,105,180)
      color = '#0054A9';
    } else if (color === '#005DA6') {
      // 869 Intense Blue rgb(26,102,75)
      color = '#0054A9';
    } else if (color === '#000001') {
      color = '#000000';
    } else if (color === '#1665AF') {
      color = '#0054A9';
    } else if (color === '#0068B4') {
      color = '#0054A9';
    } else if (color === '#0066BD') {
      // replace old blue color
      color = '#0054A9';
    }
    return color;
  }

  /** validates whether the color is known  */
  private checkFillValidity = function (fillToCheck: string) {
    if (environment.production) {
      return;
    }

    const allmatchedColor = TEXT_COLOR_DATA.filter(color => {
      const c1 = color.value.toUpperCase().trim();
      const c2 = fillToCheck.toUpperCase().trim();
      const result = 0 === c1.localeCompare(c2);

      return result;
    });
    if (allmatchedColor.length !== 1) {
      alert(`TemplateDataContains unknownColor: ${fillToCheck}`);
    }
  };
  /**
   *
   * Enforces an uniform format for color data
   *
   * @private
   * @param {string} color
   * @returns {string}
   * @memberof TemplateDataParser
   */
  public normalizedColorName(color: string): string {
    let colorStringNormalized: string;
    if (color !== undefined && color !== null) {
      colorStringNormalized = color.toUpperCase();

      if (colorStringNormalized.substring(0, 3) === 'RGB') {
        colorStringNormalized = colorStringNormalized
          .replace('RGB(', '')
          .replace(')', '')
          .split(',')
          .reduce((colorStringNormalized2: string, value: string) => {
            // definitely have 2 digits! for each color:
            return (
              colorStringNormalized2 +
              ('0' + parseInt(value, 10).toString(16)).slice(-2)
            );
          }, '#');
        // ensure length and hat first char is #
        colorStringNormalized = ('#' + colorStringNormalized).slice(-7);
      }
    }

    return colorStringNormalized;
  }
  /**
   * reads Attributes of a node as string
   */
  private parseAttributesAsString = (node, attrname, stylename) => {
    const attr = node.attributes.getNamedItem(attrname);
    if (attr) {
      const strval = attr.value;
      const arr = strval.split(stylename + '(', 2);
      if (arr.length >= 2) {
        return arr[1].split(')', 2)[0];
      }
    }
    return undefined;
  };
  /**
   * applies the parsed Styles to textObjects
   */
  private insertStyledText = (
    node,
    textObj,
    selectionStart,
    text: string,
    previousstyles
  ) => {
    if (!node) {
      console.log('node undefined!!');
      return;
    }
    const styles = (<any>fabricSrc.fabric).util.object.clone(previousstyles);
    let fill = '#000000';
    if (node.attributes.getNamedItem('fill')) {
      fill = node.attributes.getNamedItem('fill').value;
      this.checkFillValidity(this.replaceFillColorOfTemplates(fill));
      styles['fill'] = this.replaceFillColorOfTemplates(fill);
      if (selectionStart === 0 && textObj) {
        textObj.fill = this.replaceFillColorOfTemplates(fill);
      }
    } else {
      // handle missing Color information
      styles['fill'] = '#000000';
      if (selectionStart === 0 && textObj) {
        textObj.fill = '#000000';
      }
    }
    let fontSize = 40;
    if (node.attributes.getNamedItem('font-size')) {
      fontSize = parseInt(node.attributes.getNamedItem('font-size').value, 10);
      styles['fontSize'] = fontSize;
    }
    let fontFamily = 'OpenSans-Regular';
    if (node.attributes.getNamedItem('font-family')) {
      fontFamily = node.attributes
        .getNamedItem('font-family')
        // tslint:disable-next-line: quotemark
        .value.replace("'", '')
        // tslint:disable-next-line: quotemark
        .replace("'", '');
      if (fontFamily.indexOf('-') < 0) {
        fontFamily += '-Regular';
      }

      styles['fontFamily'] = fontFamily;
      // console.log(styles['fontFamily']);
    }
    if (node.attributes.getNamedItem('font-weight')) {
      styles['fontWeight'] = parseInt(
        node.attributes.getNamedItem('font-weight').value,
        10
      );
    }
    if (textObj) {
      textObj.setSelectionStart(selectionStart);
      if (fontSize) {
        textObj.fontSize = fontSize;
      }

      const newStyle = Object.assign({}, styles);
      try {
        // insertCharStyleObject(lineIndex, charIndex, quantity, copiedStyle);
        //  let linectr = 0;
        const arr = Object.keys(newStyle).map(function (key, index) {
          return { key: newStyle[key] };
        });

        for (let i = 0; i < text.length; i++) {
          // console.log(text.charAt(i));
          // console.log(JSON.stringify(newStyle));
          // console.log(text.length);
          textObj.insertChars(text.charAt(i), arr, selectionStart + i);
        }
      } catch (e) {
        console.error(e);
      }
      textObj.fontFamily = fontFamily;
    }
    return styles;
  };
  /**
   * parses an Attribute with two Parameters
   */
  private parseTwoParamAttribute = (
    node,
    attrname,
    stylename,
    default1,
    default2
  ): number[] => {
    const attr = node.attributes.getNamedItem(attrname);
    if (attr) {
      const str = attr.value;
      const arr = str.split(stylename + '(', 2);
      if (arr.length >= 2) {
        const xy = arr[1].split(')', 2)[0].split(' ');
        let tmp1 = parseInt(xy[0], 10);
        if (!tmp1) {
          tmp1 = default1;
        }
        let tmp2 = parseInt(xy[1], 10);
        if (!tmp2) {
          tmp2 = default2;
        }
        return [tmp1, tmp2];
      }
    }
    return [default1, default2];
  };

  /**
   * Replaces the text of the text element (in case the element is a text element) with the corresponding text in the
   * text replacement map.
   * @param element the element to inspect/change
   * @param {Map<string, string>} replacementMap containing a map of <TextKey -> replacementText>.
   */
  public replaceTextForElementIfPossible(element, replacementMap: any) {
    if (element instanceof CustomText) {
      const textObj = element as CustomText;
      if (textObj.textKey) {
        const replacement = replacementMap.get
          ? replacementMap.get(
              this.removeSpecialCharactersforTextKey(
                textObj.textKey
              ).toLowerCase()
            )
          : replacementMap[
              this.removeSpecialCharactersforTextKey(
                textObj.textKey
              ).toLowerCase()
            ];
        if (replacement) {
          textObj.text = replacement;
        }
      }
    }
  }

  /**
   *  Returns Textkey string with removed special charactres
   * @param key
   * @param seite
   */
  public removeSpecialCharactersforTextKey(key: string): string {
    return key
      ? key.replace(new RegExp(/\n/g), '').replace(new RegExp(/ /g), '')
      : '';
  }
}
