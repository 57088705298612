/**
 * Relevant code reference
 * NOT WORKING ON nodejs /unit tests see reference
 * https://stackoverflow.com/questions/33596408/need-example-of-using-fabricjs-d-ts-with-typescript
 */
import * as fabric from 'fabric';
import { FabricHelper } from 'models/helpers/fabric.helper';
const uuidv4 = require('uuid/v4');

const fabricOb = <any>fabric.fabric;

interface ICustomText {
  fromObject: any;
  async: boolean;
}

// -- fabric.custom.js --
fabricOb.CustomText = fabricOb.util.createClass(fabricOb.IText, {
  type: 'customText',

  initialize: function(element, options) {
    options = options || {};
    options.id = options.id || uuidv4();

    this.callSuper('initialize', element, options);
    if (options) {
      this.isTemplateData = options.isTemplateData;
      this.textKey = options.textKey;
      this.id = options.id;
      this.initialLeft = options.initialLeft;
      this.initialTop = options.initialTop;
      this.initialFontSize = options.initialFontSize;
      this.initialFontFamily = options.initialFontFamily;
      this.initialWidth = options.initialWidth;
    }

    // set some custom fixed default options for the object controls
    this.rotatingPointOffset = 25;
    this.borderColor = 'rgb(0,93,166)';
    this.cornerShape = 'rect';
    this.setControlsVisibility({
      tl: true,
      tr: true,
      br: true,
      bl: true,
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: true,
    });
  },
  toObject: function() {
    const obj = fabricOb.util.object.extend(this.callSuper('toObject'), {
      isTemplateData: this.isTemplateData,
      textKey: this.textKey,
      id: this.id,
      initialWidth: this.initialWidth,
      initialFontSize: this.initialFontSize,
      initialFontFamily: this.initialFontFamily,
      initialLeft: this.initialLeft,
      initialTop: this.initialTop,
    });
    return FabricHelper.copyProperties(this, obj);
  },
});

fabricOb.CustomText.fromObject = function(object, callback) {
  const obj = new fabricOb.CustomText(object.text, object);
  if (callback) {
    callback(obj);
  }
  return obj;
};
fabricOb.CustomText.async = false;

/*
 *
    Customize the controllers for CustomText elements.
    Every node within the controller substituted by a
    svg modelling a small rectangle within a bigger one.
    Any visual changes to the should be applied there.
*/

export const isCustomText = (obj: any): obj is CustomText => {
  return obj !== null && obj !== undefined && obj.type === 'customText';
};

export interface ICustomAttributes {
  isTemplateData: boolean;
  textKey: string;
  id: string;
  initialFontSize: number;
  initialFontFamily: string;
  initialWidth: number;
  initialLeft: number;
  initialTop: number;
}

export type CustomText = fabric.fabric.IText & ICustomAttributes;
export const CustomText = fabricOb.CustomText;
