export class CONSTANTS {
  public static readonly MINIMAL_FONTSIZE = 4;
  public static readonly MIN_IMAGE_SIZE = 0.05; // m
  public static readonly MAX_UNDO_HISTORY_COUNT = 20;
  // meter to inch 1m =  39,3701
  public static readonly METER_TO_INCH_FACTOR = 1 / 0.0254;
  public static readonly IMAGE_MAX_MB_SIZE = 20;
  public static readonly IMAGE_MAXDPI = 72;
  public static readonly VIEWBOX_TO_METER_FACTOR = 127.0 / 18000;
  public static readonly MOVING_INTERVAL_MSECONDS = 100;

  // when the MAXDPI is set to X, the actual DPI value for scaling will be X+1
  // this prevents rounding errors during the scaling (where the quality would not match afterwards)
  public static readonly SCALING_SAFETY_DPI = 1;

  // s3 Constants
  public static readonly S3ENDPOINTPATH = 's3-eu-central-1.amazonaws.com';
  public static readonly S3ENDPOINTVERSION = 'v4';
  public static readonly ID_SEPARATOR = '-';
  public static readonly TEMPLATE_PATH_PREFIX = '../../assets/templates/';
  // Code Constants
  public static readonly ACTIVE_SELECTION_TYPE_STRING = 'activeSelection';
  public static readonly RECT_TYPE_STRING = 'rect';
  public static readonly CIRCLE_TYPE_STRING = 'circle';
  public static readonly TRIANGLE_TYPE_STRING = 'triangle';
  public static readonly CUSTOM_TRIANGLE_TYPE_STRING = 'customTriangle';

  public static readonly DRUCKAUFTRAG_SAVE_NAME = 'druckauftrag';
  public static readonly HYBRIS_CONFIG_ID_KEY = 'confi';
  public static readonly HYBRIS_READ_ONLY_KEY = 'readOnly';
  public static readonly HYBRIS_CONFIG_MODE_KEY = 'configMode';
  public static readonly HYBRIS_USERTYPE_KEY = 'userType';
  public static readonly SORTIMO_IMAGE_ID = 'sortimo-image-id';
}
