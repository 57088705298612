import { CanvasHelperService } from './canvashelperservice';
import { HttpClient } from '@angular/common/http';
import { isCustomText } from 'models/graphicelements/customtext';
import { Seitenansicht } from 'models/seitenansicht';
import { Injectable } from '@angular/core';
import { Druckauftrag } from 'models/druckauftrag';
import * as fabricNS from 'fabric';
import { CustomText } from 'models/graphicelements/customtext';
import { TemplateDataParser } from './templatedataparser';

@Injectable()
/**
 * This service includes helper methods to:
 *  load template data from the backend
 *  process such data
 *  manipulate existing template data and
 *  to perform linked actions suchs as setting a default template.
 *
 */
export class TemplateInteractionService {
  readonly TEMPLATE_PATH_PREFIX = '../../assets/templates/';
  private fabric: any;

  constructor(
    private templateDataParser: TemplateDataParser,
    private http: HttpClient,
    private canvasHelperService: CanvasHelperService
  ) {
    this.fabric = <any>fabricNS.fabric;
  }

  /**
   * Sets the Default template for the Druckauftrag
   */
  public mergeTemplateData = (
    currentSideData: fabric.Object[],
    newTemplateData: fabric.Object[],
    druckauftrag: Druckauftrag
  ): Promise<Array<fabric.Object>> => {
    const userDataArr = new Array<fabric.Object>();
    const canvas = new this.fabric.Canvas();
    let prevElemCounter = 0;

    if (currentSideData) {
      currentSideData.forEach(element => {
        // if (element instanceof a.TextElement || element instanceof ImageElement) {
        if ((<any>element).isTemplateData) {
          // do not use Templatedata
          // console.log((<any>element).isTemplateData);
        } else {
          // TODO: BEKO-46 Merge Data with new template
          userDataArr.push(element);
          canvas.add(element);
          prevElemCounter++;
          //  console.log('Data contains illegal classes')
        }
        //  }
      });
    } else {
      console.log('MERGE: NO currentsideData');
    }

    if (newTemplateData && newTemplateData.length > 0) {
      const arr = new Array<fabric.Object>();
      return new Promise(resolve => {
        const replacementMap = druckauftrag.textReplacementMap;
        newTemplateData.forEach(element => {
          // adding data to canvas and renderering is needed to ensure the correct positioning for the price calcualtion on the other sides
          arr.push(element);
          canvas.add(element);
          this.templateDataParser.replaceTextForElementIfPossible(
            element,
            replacementMap
          );
          canvas.renderAll();
          if (
            canvas.getObjects().length ===
            newTemplateData.length + prevElemCounter
          ) {
            canvas.getObjects().forEach(canvasElem => {
              canvasElem.setCoords();
            });
            return resolve(arr.concat(userDataArr));
          }
        });
      });
    }
    return new Promise(resolve => {
      return resolve(userDataArr);
    });
  };

  /**
   * Replaces the text of the text element (in case the element is a text element) with the corresponding text in the
   * text replacement map.
   * @param element the element to inspect/change
   * @param {Map<string, string>} replacementMap containing a map of <TextKey -> replacementText>.
   */

  private replaceTextForElementIfPossible(element, replacementMap: any) {
    if (element instanceof CustomText) {
      const textObj = element as CustomText;
      if (textObj.textKey) {
        const replacement = replacementMap[textObj.textKey];
        if (replacement) {
          textObj.text = replacement;
        }
      }
    }
  }

  public onTextOfTemplateTextObjectChanged(
    druckauftrag: Druckauftrag,
    textObj: CustomText,
    newText: string
  ) {
    druckauftrag.textReplacementMap[
      this.removeSpecialCharactersforTextKeyToLowerCase(textObj.textKey)
    ] = newText;
    // now also update the same text key in all other sides
    this.replaceTextkeyText(druckauftrag.links, textObj.textKey, newText);
    this.replaceTextkeyText(druckauftrag.rechts, textObj.textKey, newText);
    this.replaceTextkeyText(druckauftrag.heck, textObj.textKey, newText);
    this.replaceTextkeyText(druckauftrag.front, textObj.textKey, newText);
  }

  private replaceTextkeyText(
    seitenansicht: Seitenansicht,
    textKey: string,
    newText: string
  ) {
    seitenansicht.allDataElement.forEach(element => {
      if (element instanceof CustomText) {
        const textObj = element as CustomText;
        if (
          this.removeSpecialCharactersforTextKeyToLowerCase(textObj.textKey) ===
          this.removeSpecialCharactersforTextKeyToLowerCase(textKey)
        ) {
          textObj.text = newText;
          textObj.setCoords();
        }
      }
    });
  }
  public removeAllObjectByKey(key: string, druckauftrag: Druckauftrag) {
    this.removeAllObjectByKeyFromSide(key, druckauftrag.rechts);
    this.removeAllObjectByKeyFromSide(key, druckauftrag.links);
    this.removeAllObjectByKeyFromSide(key, druckauftrag.front);
    this.removeAllObjectByKeyFromSide(key, druckauftrag.heck);
  }
  private removeAllObjectByKeyFromSide(key: string, seite: Seitenansicht) {
    seite.allDataElement = seite.allDataElement.filter(value => {
      if (
        isCustomText(value) &&
        this.removeSpecialCharactersforTextKeyToLowerCase(value.textKey) ===
          this.removeSpecialCharactersforTextKeyToLowerCase(key)
      ) {
        return false;
      }
      return true;
    });
  }
  private removeSpecialCharactersforTextKeyToLowerCase(text: string): string {
    return this.templateDataParser
      .removeSpecialCharactersforTextKey(text)
      .toLowerCase();
  }
}
