import { CustomTriangle } from './../../models/graphicelements/customtriangle';
import { Injectable } from '@angular/core';
import { fabric } from 'fabric';

@Injectable({
  providedIn: 'root',
})
export class BasicShapeEditorService {
  private static readonly DEFAULT_FILL = '#000000';

  constructor() {}

  public getTriangle(
    fillColor?: string,
    width = 50,
    height = 50 * Math.cos(Math.PI / 6),
    strokeWidth = 0,
    strokeFill = ''
  ): fabric.Triangle {
    return new CustomTriangle({
      fill: fillColor || BasicShapeEditorService.DEFAULT_FILL,
      stroke: strokeFill,
      strokeWidth: strokeWidth,
      width: width - strokeWidth,
      height: height - strokeWidth,
    });
  }

  public getRectangle(
    fillColor?: string,
    width = 50,
    strokeWidth = 0,
    strokeFill = ''
  ): fabric.Rect {
    return new fabric.Rect({
      fill: fillColor || BasicShapeEditorService.DEFAULT_FILL,
      stroke: strokeFill,
      strokeWidth: strokeWidth,
      width: width - strokeWidth,
      height: width - strokeWidth,
    });
  }

  public getCircle(
    fillColor?: string,
    width = 50,
    strokeWidth = 0,
    strokeFill = ''
  ): fabric.Circle {
    return new fabric.Circle({
      fill: fillColor || BasicShapeEditorService.DEFAULT_FILL,
      stroke: strokeFill,
      strokeWidth: strokeWidth,
      radius: width / 2 - strokeWidth / 2,
    });
  }

  public adaptElementStrokeWidth(activeObject, newStrokeWidth): void {
    const previousStrokeWidth = activeObject.strokeWidth;
    const width = activeObject.width;
    const height = activeObject.height;

    const halfHeight = (previousStrokeWidth + height) / 2;
    const halfWidth = (previousStrokeWidth + width) / 2;

    // keep strokeWidth > 0
    newStrokeWidth = newStrokeWidth < 0 ? 0 : newStrokeWidth;

    if (activeObject.type === 'rect') {
      // keep strokeWidth valid: strokeWidth < Element-Width / -Height
      newStrokeWidth =
        newStrokeWidth > Math.min(halfHeight, halfWidth)
          ? Math.min(halfHeight, halfWidth)
          : newStrokeWidth;
      activeObject.height = previousStrokeWidth + height - newStrokeWidth;
      activeObject.width = previousStrokeWidth + width - newStrokeWidth;
    } else if (
      activeObject.type === 'triangle' ||
      activeObject.type === 'customTriangle'
    ) {
      const radian = (30 * Math.PI) / 180;
      const c = Math.floor(halfWidth / Math.cos(radian));
      newStrokeWidth = newStrokeWidth > c ? c : newStrokeWidth;
      activeObject.height = previousStrokeWidth + height - newStrokeWidth;
      activeObject.width = previousStrokeWidth + width - newStrokeWidth;
    } else if (activeObject.type === 'circle') {
      // keep strokeWidth valid: strokeWidth < Element-Width / -Height
      newStrokeWidth =
        newStrokeWidth > Math.min(halfHeight, halfWidth)
          ? Math.min(halfHeight, halfWidth)
          : newStrokeWidth;
      activeObject.radius =
        previousStrokeWidth / 2 + activeObject.radius - newStrokeWidth / 2;
      activeObject.height = previousStrokeWidth + height - newStrokeWidth;
      activeObject.width = previousStrokeWidth + width - newStrokeWidth;
    }
    activeObject.strokeWidth = newStrokeWidth;
    activeObject.dirty = true;
    activeObject.setCoords();
  }
}
