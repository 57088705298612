import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToggleSwitchComponent implements OnInit {
  @Input()
  inputId: string;

  @Input()
  isImageUploadParent: boolean;

  @Input()
  isChecked: boolean;

  @Input()
  isDisabled: boolean;

  @ViewChild('labelElement', { static: true })
  labelElement: ElementRef;

  @ViewChild('container', { static: true })
  containerElement: ElementRef;

  private _labelWidth: string;
  private _labelHeight: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (null === this.inputId || this.inputId === '') {
      throw new Error('Attribute "inputId" is required');
    }
  }

  @Input()
  set labelWidth(value: string) {
    if (value && this._labelWidth !== value) {
      this.labelElement.nativeElement.style.setProperty('width', value);
      this._labelWidth = value;
    }
  }

  get labelWidth(): string {
    return this._labelWidth;
  }

  @Input()
  set labelHeight(value: string) {
    if (value && this._labelHeight !== value) {
      this.labelElement.nativeElement.style.setProperty('height', value);
      this.containerElement.nativeElement.style.setProperty('height', value);
      this._labelHeight = value;
    }
  }

  get labelHeight(): string {
    return this._labelHeight;
  }
}
