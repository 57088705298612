import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { RestDataService } from 'app/services/restdataservice'
import { ConfirmDialogComponent } from 'app/dialogs/confirmdialog.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { DataService } from 'app/services/dataservice'
import { Fahrzeugseite } from 'models/fahrzeugeseite'
import { NGXLogger } from "ngx-logger";@Injectable()
export class FinishGenerationProcessService {
  constructor(
    private readonly restDataService: RestDataService,
    private readonly router: Router,
    private readonly dataService: DataService,
    private readonly dialog: MatDialog,
    private logger: NGXLogger
  ) {}

  public finish(): Observable<any> {
    console.log('druckauftrag')
    console.log(this.dataService.druckauftrag)
    return new Observable(subscriber => {
      return this.restDataService
        .saveDruckauftrag(
          this.dataService.druckauftrag,
          this.dataService.getParams()
        )
        .subscribe(
          (response: any) => {
            response =
              typeof response == 'string' ? JSON.parse(response) : response;
            console.log(`finishOrder-RESPONSE: ${response}`);
            if (!response || response.errorMessage) {
              console.error('Error while finishing order', response)
              this.handleFinishOrderError(response)
              subscriber.error(response)
            } else {
              this.logger.debug(response.hybrisConfigId);
              this.logger.debug(
                `Hybris ConfigIds Match: ? ${response.hybrisConfigId ===
                  this.dataService.hybrisConfigId}`
              );
              subscriber.next(response.configId);
              subscriber.complete();
            }
          },
          err => {
            subscriber.error(err)
            this.handleFinishOrderError(err)
          }
        )
    })
  }

  private handleFinishOrderError(err) {
    console.warn('Fehler beim Senden/Abschließen des Auftrags', err)
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: {
          textKey: 'PREVIEW.ERROR.FINISH-ORDER.TEXT',
          titleKey: 'PREVIEW.ERROR.FINISH-ORDER.TITLE',
          positiveText: 'PREVIEW.ERROR.FINISH-ORDER.POSITIVE-TEXT',
          showNoButton: false,
        },
      }
    )
    dialogRef.afterClosed().subscribe(value => {
        this.router.navigate(['/konfigurator'])
      })
  }
}
