import { Druckauftrag } from 'models/druckauftrag'
import { Fahrzeugseite } from 'models/fahrzeugeseite'
import { Injectable } from '@angular/core'
import { Seitenansicht } from 'models/seitenansicht'

@Injectable()
export class DruckauftragHelperService {
  constructor() {}

  public getSide(
    druckauftrag: Druckauftrag,
    key: Fahrzeugseite
  ): Seitenansicht {
    switch (key) {
      case Fahrzeugseite.FAHRER:
        return druckauftrag.links
      case Fahrzeugseite.BEIFAHRER:
        return druckauftrag.rechts
      case Fahrzeugseite.FRONT:
        return druckauftrag.front
      case Fahrzeugseite.HECK:
        return druckauftrag.heck
      default:
        return undefined
    }
  }
}
