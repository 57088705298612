import { Seitenansicht } from 'models/seitenansicht';
import { Fahrzeugseite } from 'models/fahrzeugeseite';
import { Druckauftrag } from 'models/druckauftrag';
import { Point } from 'models/graphicelements/point';
import { Injectable } from '@angular/core';
import { GraphicElement } from 'models/graphicelements/graphicelement';
import { Rectangle } from 'models/graphicelements/rectangle';
import { Polygon } from 'models/graphicelements/polygon';
import { EditArea } from 'models/graphicmodels/editarea';
/**
 * This service parses the Editareas from Svgs included in the DOM
 *
 */
@Injectable()
export class SvgEditAreaParserService {
  constructor() {}

  /**
   * read all EditArea from a SVG-HtmlElement
   * @param svgSrc
   */
  public parseAllEditArea(svgSrc: Element): Array<EditArea> {
    if (!svgSrc) {
      console.log('EditAreas not loaded yet!!');
      return;
    }
    const collection: NodeList = svgSrc.childNodes;
    let result: EditArea[] = [];
    for (let i = 0; i < collection.length; i++) {
      const node: Node = collection.item(i);
      if (node.nodeName !== 'rect' && node.nodeName !== 'polygon') {
        result = result.concat(this.parseAllEditAreaNode(node));
      } else if (node.nodeName === 'rect') {
        const newRect: Rectangle = new Rectangle();

        const xAttr = (node as Element).attributes.getNamedItem('x');
        const yAttr = (node as Element).attributes.getNamedItem('y');

        newRect.x = parseFloat(xAttr ? xAttr.value : '0');
        newRect.y = parseFloat(yAttr ? yAttr.value : '0');
        newRect.width = parseFloat(
          (node as Element).attributes.getNamedItem('width').value
        );
        newRect.height = parseFloat(
          (node as Element).attributes.getNamedItem('height').value
        );

        const editArea: EditArea = new EditArea();
        editArea.graphicElement = newRect;
        result.push(editArea);
      } else {
        // polygon
        const newPoly: Polygon = new Polygon();
        const pointAttrStr: string = (node as Element).attributes.getNamedItem(
          'points'
        ).value;
        const allPointStr: string[] = pointAttrStr.split(' ');
        allPointStr.forEach((value, index, array) => {
          // separte x and y
          const xyArray: string[] = value.split(',');
          if (xyArray.length === 2) {
            const xValue: number = parseFloat(xyArray[0]);
            const yValue: number = parseFloat(xyArray[1]);
            newPoly.allPoint.push(new Point(xValue, yValue));
          }
        });
        const editArea: EditArea = new EditArea();
        editArea.graphicElement = newPoly;
        result.push(editArea);
      }
    }
    return result;
  }
  /**
   * read all EditArea from a SVG-HtmlElement
   * @param svgSrc
   */
  public parseAllEditAreaNode(inNode: Node): Array<EditArea> {
    const collection: NodeList = inNode.childNodes;
    let result: EditArea[] = [];
    for (let i = 0; collection && i < collection.length; i++) {
      const node: Node = collection.item(i);
      if (node.nodeName !== 'rect' && node.nodeName !== 'polygon') {
        result = result.concat(this.parseAllEditAreaNode(node));
      } else if (node.nodeName === 'rect') {
        const newRect: Rectangle = new Rectangle();
        const xAttr = (node as Element).attributes.getNamedItem('x');
        const yAttr = (node as Element).attributes.getNamedItem('y');
        newRect.x = parseFloat(xAttr ? xAttr.value : '0');
        newRect.y = parseFloat(yAttr ? yAttr.value : '0');
        newRect.width = parseFloat(
          (node as Element).attributes.getNamedItem('width').value
        );
        newRect.height = parseFloat(
          (node as Element).attributes.getNamedItem('height').value
        );

        const editArea: EditArea = new EditArea();
        editArea.graphicElement = newRect;
        result.push(editArea);
      } else {
        // polygon
        const newPoly: Polygon = new Polygon();
        const pointAttrStr: string = (node as Element).attributes.getNamedItem(
          'points'
        ).value;
        const allPointStr: string[] = pointAttrStr.split(' ');
        allPointStr.forEach((value, index, array) => {
          // separte x and y
          const xyArray: string[] = value.split(',');
          if (xyArray.length === 2) {
            const xValue: number = parseFloat(xyArray[0]);
            const yValue: number = parseFloat(xyArray[1]);
            newPoly.allPoint.push(new Point(xValue, yValue));
          }
        });
        const editArea: EditArea = new EditArea();
        editArea.graphicElement = newPoly;
        result.push(editArea);
      }
    }
    return result;
  }
  /**
   *  TODO: BEKO-98 remove & parse editareas on server
   */
  public loadEditArea = (
    htmlElement: Element,
    druckauftrag: Druckauftrag,
    carsideObject: Seitenansicht
  ): boolean => {
    if (!druckauftrag) {
      return false;
    }
    switch (carsideObject.fahrzeugseite) {
      case Fahrzeugseite.FAHRER:
        druckauftrag.links.allEditierbereich = this.parseAllEditArea(
          htmlElement
        );
        return true;

      case Fahrzeugseite.BEIFAHRER:
        druckauftrag.rechts.allEditierbereich = this.parseAllEditArea(
          htmlElement
        );
        return true;

      case Fahrzeugseite.FRONT:
        druckauftrag.front.allEditierbereich = this.parseAllEditArea(
          htmlElement
        );
        return true;

      case Fahrzeugseite.HECK:
        druckauftrag.heck.allEditierbereich = this.parseAllEditArea(
          htmlElement
        );
        return true;

      default:
        console.log('fahrzeugseite not supported');
        return false;
    }
  };
}
