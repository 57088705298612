import { CarDataManagerService } from './../services/car-data-manager.service';
import { CONSTANTS } from 'models/helpers/constants';
import { TemplateDataParser } from 'app/services/templatedataparser';
import { StateStorageService } from 'app/services/statestorage';
import { CanvasHelperService } from 'app/services/canvashelperservice';
import { CustomText, isCustomText } from 'models/graphicelements/customtext';
import { TemplateInteractionService } from 'app/services/templateInteraction.service';
import { Color } from 'models/helpers/color';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { FastTrackData } from './../../models/fasttrackdata';
import { DataService } from 'app/services/dataservice';
import { NavigationService } from 'app/services/navigation.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TEXT_COLOR_DATA } from 'assets/color-data/textcolor-data';
import { ColorPickerComponent } from '../editor/colorpicker/colorpicker.component';

import { TranslateService } from '@ngx-translate/core';
import * as fabricVar from 'fabric';

import { FASTTRACKREPLACEMENTS } from '../../assets/fasttrack/replacements';

import { NGXLogger } from "ngx-logger";
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-fast-track',
  templateUrl: './fast-track.component.html',
  styleUrls: ['./fast-track.component.scss'],
})
export class FastTrackComponent implements OnInit {
  @ViewChild('textColorPicker', { static: true })
  private textColorPicker: ColorPickerComponent;
  @ViewChild('textcolorelement', { static: true })
  private textColorElementRef: ElementRef;

  data: FastTrackData;
  dataForm: UntypedFormGroup;
  firmaField: AbstractControl;
  strasseField: AbstractControl;
  nrField: AbstractControl;
  plzField: AbstractControl;
  ortField: AbstractControl;
  telefonField: AbstractControl;
  urlField: AbstractControl;
  textColor: string;
  previewImagePath: string;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly dataService: DataService,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly templateInteractionService: TemplateInteractionService,
    private readonly canvasHelperService: CanvasHelperService,
    private readonly stateStorageService: StateStorageService,
    private readonly templateDataParser: TemplateDataParser,
    private readonly translateService: TranslateService,
    private readonly carDataManagerService: CarDataManagerService,
    private logger: NGXLogger
  ) {
    if (!this.dataService.druckauftrag || !this.dataService.druckauftrag.car) {
      this.logger.warn('FastTrackComponent: Car  undefined ');
      this.navigationService.navigateTrackSelect(this.dataService.getParams());
      return;
    }
    this.data = this.dataService.fastTrackData
      ? this.dataService.fastTrackData
      : new FastTrackData();
    this.dataForm = this.formBuilder.group({
      firma: this.data.firma,
      strasse: this.data.strasse,
      nr: this.data.hausNr,
      url: this.data.url,
      plz: this.data.plz,
      ort: this.data.ort,
      telefon: this.data.telefon,
    });
    this.firmaField = this.dataForm.controls['firma'];
    this.strasseField = this.dataForm.controls['strasse'];
    this.nrField = this.dataForm.controls['nr'];
    this.plzField = this.dataForm.controls['plz'];
    this.ortField = this.dataForm.controls['ort'];
    this.telefonField = this.dataForm.controls['telefon'];
    this.urlField = this.dataForm.controls['url'];
    this.previewImagePath =
      '../assets/fasttrack/fasttrack_screen-' +
      this.translateService.currentLang.toUpperCase() +
      '.png';
    this.initIcons();
  }

  ngOnInit() {}

  private initIcons() {
    this.matIconRegistry
      .addSvgIcon(
        'text-color-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/Icons-36px/colorpicker-36px.svg'
        )
      )
      .addSvgIcon(
        'person-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/content/Icon_Anmelden.svg'
        )
      )
      .addSvgIcon(
        'preview-next-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-preview-next.svg'
        )
      );
  }

  /**
   * Builds the use model with the supplied data
   *
   * @private
   * @memberof FastTrackComponent
   */
  private bindFieldsToModel() {
    this.data.ort = this.ortField.value || '';
    this.data.plz = this.plzField.value || '';

    this.data.strasse = this.strasseField.value || '';
    this.data.hausNr = this.nrField.value || '';
    this.data.firma = this.firmaField.value || '';
    this.data.telefon = this.telefonField.value || '';
    this.data.url = this.urlField.value || '';
  }
  /**
   * boolean that checks whether any FormData is set or not.
   */
  private isAnyValueSet(): boolean {
    this.bindFieldsToModel();

    return (
      this.isSet(this.data.ort) ||
      this.isSet(this.data.plz) ||
      this.isSet(this.data.strasse) ||
      this.isSet(this.data.hausNr) ||
      this.isSet(this.data.firma) ||
      this.isSet(this.data.telefon) ||
      this.isSet(this.data.url)
    );
  }

  /**
   * Returns True When FormData is set.
   * @param value 
   * @returns 
   */
  private isSet(value: string) {
    return value && value.replace(new RegExp(' ', 'g'), '') !== '';
  }
  public submitForm() {}

  /**
   * boolean that specifies whether the ColorPicker Button is Disabled.
   * Returns False When No Value is set.
   */
  public isColorPickerDisabled(): boolean {
    if (!this.textColor) {
      return !this.isAnyValueSet();
    }
    return false;
  }

  /**
   * Opens ColorPicker Component View
   */
  public triggerTextColorChange(event: any) {
    this.textColorPicker.openView(this.textColor);
    this.textColorPicker.setVisible(true);
  }

  /**
   * changes the textcolor to the this component
   * @param color
   */
  public changeTextColor() {
    const color = this.textColorPicker.getRgbColor();

    this.textColor = color;
    this.textColorPicker.setRgbColor(this.textColor);

    // after we chose the color
    this.onChangeTextColor();
    this.textColorPicker.setVisible(false);
  }

  /**
   * Changes the ColorElement (RectangleColorBox in view) Color when Text color is changed.
   */
  public onChangeTextColor() {
    this.data.farbe = this.textColor;
    this.logger.debug(`${this.data.farbe}\n
    ${this.data.firma}\n
    ${this.data.ort}\n
    ${this.data.plz}\n
    ${this.data.strasse}\n
    ${this.data.hausNr}\n
    ${this.data.telefon}\n
    ${this.data.url}\n
    ${this.textColorElementRef.nativeElement.getAttribute('value')}`);
  }

  /**
   * Add Class to Color Selection Button.
   * @returns Class String
   */
  public getClassForTextButton(): string {
    if (!this.textColorPicker.isVisible) {
      return 'colorSelectButton';
    } else {
      return 'colorSelectButton colorSelectButton-Active ';
    }
  }
  public getTextColorData(): Color[] {
    return TEXT_COLOR_DATA;
  }
  public getTextRgbColor() {
    return this.textColor;
  }

  /**
   * Get Selected Color name and display Beside Colorpicker Button. 
   * @returns Color name
   */
  public getSelectedColorName(): string {
    if (this.textColor) {
      const filtered = TEXT_COLOR_DATA.filter((value: Color) => {
        return value.value === this.textColor;
      });
      if (filtered.length === 1) {
        return filtered[0].name;
      }
      return 'unknown Color';
    }
    return 'unknown';
  }
  /**
   * boolean That specifies wheather NextView Button is Enabled or not.
   */
  public isNextViewEnabled(): boolean {
    return (
      this.textColor !== undefined &&
      this.textColor !== null &&
      this.isAnyValueSet()
    );
  }

  /**
   * Go back to Track-Selection View.
   */
  public previousView() {
    this.navigationService.navigateTrackSelect(this.dataService.getParams());
  }

  /**
   * Navigate to Next View(Preview).
   * Where Vehicle will be Appended to Temporary canvas.
   */
  public async nextView() {
    this.dataService.fastTrackData = this.data;
    this.dataService.isFastTrackDataReplacedAndInitialized = false;
    /**
     * we import the appropriate operator lastValueFrom from rxjs, and then we await the Observable returned by selectTemplate using the respective operator.
     */
    await lastValueFrom(
      this.dataService.selectTemplate(
        this.carDataManagerService.allMinimalTemplate[0],
        true,
        true
      ));
    /* await this.dataService
      .selectTemplate(
        this.carDataManagerService.allMinimalTemplate[0],
        true,
        true
      )
      .toPromise(); */
    this.carDataManagerService.setBackgroundColor(
      this.dataService.druckauftrag.carColor
    );
    this.router.navigate(['/preview']);
    // FIXME:
    const allReplacement: Map<string, string> = this.getReplacementMap();
    const data = this.dataService.druckauftrag.rechts.allDataElement
      .concat(this.dataService.druckauftrag.links.allDataElement)
      .concat(this.dataService.druckauftrag.front.allDataElement)
      .concat(this.dataService.druckauftrag.heck.allDataElement);

    const el = document.createElement('canvas');
    el.setAttribute('id', 'temporary-canvas');
    const canvas = new fabricVar.fabric.StaticCanvas(el);
    document.body.appendChild(el);
    const context = (<any>(
      document.getElementById('temporary-canvas')
    )).getContext('2d');
    this.replaceTextColorAndScaleElements(context, allReplacement, data);
    let ctr = 0;
    const promise = new Promise<void>((resolve, reject) => {
      canvas.on('object:added', e => {
        ctr++;
        if (ctr === data.length) {
          canvas.renderAll();
          canvas.getObjects().forEach(o => {
            o.setCoords();
          });
          resolve();
        }
      });
      data.forEach(fabObj => {
        if (isCustomText(fabObj)) {
          canvas.add(fabObj);
          context.font = fabObj.fontSize + 'px ' + fabObj.fontFamily;
          fabObj.width = context.measureText(fabObj.text).width;
          (<any>fabObj).dirty = true;
          fabObj.setCoords();
        }
      });
    });
    promise.then(() => {
      // remove element again
      const element = document.getElementById('temporary-canvas');
      if (element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
      // set state of druckauftrag new the frist state is sofar the content of the minimal template
      this.dataService.calculatePriceAndSaveState();
      this.stateStorageService.saveCurrentDataServiceStateAsInitialState();
      this.dataService.isFastTrackDataReplacedAndInitialized = true;
      this.dataService.druckauftragLoadedEmitter.emit();
      this.dataService.fastTrackDataLoadedEmitter.emit();
    });
  }

  private getReplacementMap() {
    const allReplacement: Map<string, string> = new Map();
    const minimalTextKeys = this.getTextKeys();
    this.setTextOrRemoveObjectFromDruckauftrag(
      minimalTextKeys.postalTown,
      this.data.plz + ' ' + this.data.ort,
      allReplacement
    );
    this.setTextOrRemoveObjectFromDruckauftrag(
      minimalTextKeys.streetAndNumber,
      this.data.strasse + ' ' + this.data.hausNr,
      allReplacement
    );
    this.setTextOrRemoveObjectFromDruckauftrag(
      minimalTextKeys.phone,
      this.data.telefon,
      allReplacement
    );
    this.setTextOrRemoveObjectFromDruckauftrag(
      minimalTextKeys.website,
      this.data.url,
      allReplacement
    );
    this.setTextOrRemoveObjectFromDruckauftrag(
      minimalTextKeys.company,
      this.data.firma,
      allReplacement
    );
    return allReplacement;
  }

  private setTextOrRemoveObjectFromDruckauftrag(
    key: string,
    value: string,
    allReplacement: Map<string, string>
  ) {
    if (this.isSet(value)) {
      allReplacement.set(key, value);
    } else {
      this.templateInteractionService.removeAllObjectByKey(
        key,
        this.dataService.druckauftrag
      );
    }
  }

  private replaceTextColorAndScaleElements(
    context,
    allReplacement: Map<string, string>,
    allObject
  ) {
    // get allObjects
    const druckauftrag = this.dataService.druckauftrag;

    const allRechtsLinksObj: fabric.Object[] = druckauftrag.rechts.allDataElement.concat(
      druckauftrag.links.allDataElement
    );

    allReplacement.forEach((value, key) => {
      // replace Text
      const obj = allObject.find(fabObj => {
        if (isCustomText(fabObj)) {
          return (
            this.templateDataParser
              .removeSpecialCharactersforTextKey(fabObj.textKey)
              .toLowerCase() ===
            this.templateDataParser
              .removeSpecialCharactersforTextKey(key)
              .toLowerCase()
          );
        }
      });
      if (obj && isCustomText(obj)) {
        this.templateInteractionService.onTextOfTemplateTextObjectChanged(
          druckauftrag,
          obj,
          value
        );
      }
    });
    // change TextColor for allObject
    allObject.forEach(o => {
      (<any>o).styles = {};
      this.canvasHelperService.changeTextColorOfObject(o, this.textColor);
    });

    this.rescaleandCenterAllObject(
      context,
      allObject,
      allRechtsLinksObj,
      druckauftrag.heck.allDataElement
    );
  }

  private getTextKeys() {
    return FASTTRACKREPLACEMENTS[
      this.translateService.currentLang.toUpperCase()
    ];
  }

  /**
   *  rescales allObject to maxSize of Textkey text width
   * @param allObject
   */
  private rescaleandCenterAllObject(
    context,
    allObject: fabric.Object[],
    allFahrerBeifahrerObj: fabric.Object[],
    allHeckObject: fabric.Object[]
  ) {
    allObject.forEach(fabObj => {
      if (isCustomText(fabObj)) {
        const factor = this.getScaleFactor(fabObj, context);
        this.canvasHelperService.adaptCustomTextSizeOnScaling(
          fabObj,
          undefined,
          factor
        );
        this.canvasHelperService.roundFontSizesOfCustomText(fabObj, true);
        if (factor !== 1) {
          fabObj.height = Math.round(fabObj.fontSize * 1.13 * 100) / 100;
        }
      }
    });
    allFahrerBeifahrerObj.forEach(fabObj => {
      if (isCustomText(fabObj)) {
        this.centerObjectsCenteredLikeTextKey(fabObj, context);
        if (fabObj.left < fabObj.initialLeft) {
          fabObj.left = fabObj.initialLeft;
        }
      }
    });

    // filter Heckobjects
    const allHeckText = allHeckObject
      .filter(o => o instanceof CustomText)
      .map(o => <CustomText>o);
    // scale Ort, Strasse and PLZ
    this.syncFontSizeOfSpecialHeckObject(allHeckText);
  }

  private getScaleFactor(
    object: CustomText,
    ctx: CanvasRenderingContext2D
  ): number {
    ctx.font = object.fontSize + 'px ' + object.fontFamily;
    const widthKey =
      object.initialWidth || ctx.measureText(object.textKey).width;
    const widthText = ctx.measureText(object.text).width;
    const factor = widthText <= widthKey ? 1 : widthKey / widthText;
    return factor;
  }

  private centerObjectsCenteredLikeTextKey(
    object: CustomText,
    ctx: CanvasRenderingContext2D
  ): void {
    ctx.font = object.initialFontSize + 'px ' + object.initialFontFamily;
    const widthKey = object.initialWidth;
    ctx.font = object.fontSize + 'px ' + object.fontFamily;
    const widthText = ctx.measureText(object.text).width;
    object.left = object.initialLeft + widthKey / 2 - widthText / 2;
  }

  /**
   * Scales Street and City+ postalcode,telefon to minimal fontsize
   * @param allHeckObj
   */
  private syncFontSizeOfSpecialHeckObject(allHeckObj: CustomText[]): void {
    const objectsToSynchFontSize = allHeckObj.filter(
      o =>
        o.textKey === this.getTextKeys().postalTown ||
        o.textKey === this.getTextKeys().streetAndNumber ||
        o.textKey === this.getTextKeys().phone
    );
    if (objectsToSynchFontSize.length > 0) {
      let minFont = objectsToSynchFontSize
        .map(o => o.fontSize)
        .reduce((a, b) => Math.min(a, b));
      minFont =
        minFont >= CONSTANTS.MINIMAL_FONTSIZE
          ? minFont
          : CONSTANTS.MINIMAL_FONTSIZE;
      objectsToSynchFontSize.forEach(customText => {
        customText.fontSize = minFont;

        // adapt fontsize of Styleobject
        let lineIndex = 0;
        while (customText.styles.hasOwnProperty('' + lineIndex)) {
          const linestyle = customText.styles[lineIndex];
          let charIndex = 0;
          // handle all characters in line
          while (linestyle.hasOwnProperty('' + charIndex)) {
            if (linestyle[charIndex] && linestyle[charIndex].fontSize) {
              linestyle[charIndex].fontSize = minFont;
            }
            charIndex++;
          }
          lineIndex++;
        }
      });
    }
  }
}
