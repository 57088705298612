import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import * as fabric from 'fabric';

import { Template } from './../../models/template';
import { Seitenansicht } from './../../models/seitenansicht';
import { CarDataManagerService } from './../services/car-data-manager.service';
import { CanvasHelperService } from './../services/canvashelperservice';
import { StateStorageService } from './../services/statestorage';
import { DataService } from './../services/dataservice';
import { InitializationService } from './../services/initialization.service';
import { EditorComponent } from '../editor/editor.component';

import { Fahrzeugseite } from '../../models/fahrzeugeseite';
import { NavigationService } from './../services/navigation.service';

import { DimensionsHelperService } from './../services/dimensionshelperservice';
import { UserType } from 'models/communcation/UserType';

/**
 *  This Component is the central component of the design of a car
 *  It handles operations on the whole car (every side)
 */
@Component({
  selector: 'app-konfigurator',
  templateUrl: './konfigurator.component.html',
  styleUrls: ['./konfigurator.component.scss'],
})
export class KonfiguratorComponent implements OnInit, OnDestroy {
  private carIdSubscription: Subscription;

  // layout test
  public isHiddenSidenav = false;

  @ViewChild(EditorComponent, { static: true })
  private editorComponent: EditorComponent;

  @ViewChild('sidenav', { static: true })
  sideNavElementRef: ElementRef;
  @ViewChild('editorContainer', { static: true })
  editorElementRef: ElementRef;
  @ViewChild('sidenavopen', { static: true })
  sidenavOpenButtonRef: ElementRef;
  @ViewChild('overlay', { static: true })
  overlayElementRef: ElementRef;

  currentPrice = 0;

  public mirrorSideString;
  public get carHeckCssClasses() {
    const css = 'car-side-button car-heck';
    if (this.dataService.isTopSystemActive) {
      return css + ' transparent-car-side';
    }
    return css;
  }

  public get carFrontCssClasses() {
    const css = 'car-side-button car-front';
    if (this.dataService.isTopSystemActive) {
      return css + ' transparent-car-side';
    }
    return css;
  }

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dataService: DataService,
    private stateStorageService: StateStorageService,
    private router: Router,
    private navigationService: NavigationService,
    private readonly canvasHelperService: CanvasHelperService,
    private readonly translateService: TranslateService,
    private readonly carDataManagerService: CarDataManagerService,
    private readonly dimensionsHelperService: DimensionsHelperService,
    private readonly initializationService: InitializationService
  ) {
    // initialize Icons
    this.registerIcons();

    this.isHiddenSidenav = this.dataService.isTopSystemActive ? true : false;
  }

  private registerIcons = () => {
    this.matIconRegistry
      .addSvgIcon(
        'image-car-heck',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-heck.svg'
        )
      )
      .addSvgIcon(
        'image-car-front',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-front.svg'
        )
      )
      .addSvgIcon(
        'image-car-beifahrer',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-beifahrerseite.svg'
        )
      )
      .addSvgIcon(
        'image-car-fahrer',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-fahrerseite.svg'
        )
      )
      .addSvgIcon(
        'template-checked',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/template.checked.svg'
        )
      )
      .addSvgIcon(
        'car-side-error-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-stop-small.svg'
        )
      )
      .addSvgIcon(
        'mirror-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-layout-uebertragen.svg'
        )
      )
      .addSvgIcon(
        'preview-next-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-preview-next.svg'
        )
      );
  };

  ngOnInit() {
    if (this.dataService.readOnly) {
      this.router.navigate(['/preview']);
      return;
    }
    if (
      !this.dataService.isDruckauftragCreationRunning &&
      !this.dataService.druckauftrag
    ) {
      console.log('Konfigurator: Car or druckauftrag undefined ');
      this.navigationService.navigateTrackSelect(this.dataService.getParams());
      return;
    }
    this.isHiddenSidenav = this.dataService.isTopSystemActive
      ? true
      : this.isInZoomMode();

    this.updateLanguageContentOnSideChange();
  }

  ngOnDestroy() {
    if (!this.dataService.druckauftrag || !this.dataService.druckauftrag.car) {
      return;
    }
    if (this.carIdSubscription) {
      this.carIdSubscription.unsubscribe();
    }
  }

  public get isTemplateNavButtonDisabled(): boolean {
    return this.isInZoomMode() || this.dataService.isTopSystemActive;
  }

  public toogleTempNavOpen = () => {
    if (this.isHiddenSidenav !== undefined && !this.isInZoomMode()) {
      this.isHiddenSidenav = !this.isHiddenSidenav;
    }
  };

  public carSideContainsError(side: Fahrzeugseite): boolean {
    if (
      !this.dataService.druckauftrag ||
      !this.dataService.druckauftrag.links ||
      !this.dataService.druckauftrag.rechts ||
      !this.dataService.druckauftrag.front ||
      !this.dataService.druckauftrag.heck
    ) {
      return false;
    }
    // exception for designuser
    if (this.dataService.userType === UserType.DESIGNUSER) {
      return false;
    }
    if (side !== null && side !== undefined) {
      switch (side) {
        case Fahrzeugseite.FAHRER: {
          return this.dataService.druckauftrag.links.hasError;
        }
        case Fahrzeugseite.BEIFAHRER: {
          return this.dataService.druckauftrag.rechts.hasError;
        }
        case Fahrzeugseite.FRONT: {
          return this.dataService.druckauftrag.front.hasError;
        }
        case Fahrzeugseite.HECK: {
          return this.dataService.druckauftrag.heck.hasError;
        }
      }
    }
    return true;
  }

  public anyCarSideContainsError(): boolean {
    if (!this.dataService.druckauftrag) {
      return false;
    }
    return (
      this.dataService.userType !== UserType.DESIGNUSER &&
      (this.dataService.druckauftrag.links.hasError ||
        this.dataService.druckauftrag.rechts.hasError ||
        this.dataService.druckauftrag.front.hasError ||
        this.dataService.druckauftrag.heck.hasError)
    );
  }
  public onChangeSide = (side: Fahrzeugseite) => {
    console.log('SideSelection changed to: ', side);
    if (
      this.editorComponent.activateZoom ||
      this.editorComponent.isInZoomMode
    ) {
      this.editorComponent.resetZoomMode();
      this.editorComponent.adaptCarSvgViewportToCanvas();
    }
    if (side !== this.dataService.getSelectedSide()) {
      this.dataService.setSelectedSide(side);
      this.editorComponent.onSideUpdatedEvent();
      this.stateStorageService.saveState();
    }

    this.updateLanguageContentOnSideChange();
  };

  public isTemplateSelected = (item: Template): boolean => {
    return (
      item === this.dataService.druckauftrag.selectedTemplate ||
      (this.dataService.druckauftrag.selectedTemplate &&
        item.previewImagePathStr ===
          this.dataService.druckauftrag.selectedTemplate.previewImagePathStr)
    );
  };

  public mirrorButtonClicked = () => {
    if (
      this.editorComponent.activateZoom ||
      this.editorComponent.isInZoomMode
    ) {
      console.error('Spiegeln not possible in ZoomMode ');
      return;
    }
    let fahrzeugseiteFrom: Seitenansicht = undefined;
    let fahrzeugseiteTo: Seitenansicht = undefined;

    // discard groups to not cause false postioning in mirrored view
    this.editorComponent.discardSelection();

    fahrzeugseiteFrom = this.dataService.getSeitenansichtForSelectedSide();
    if (this.dataService.getSelectedSide() === Fahrzeugseite.BEIFAHRER) {
      fahrzeugseiteTo = this.dataService.druckauftrag.links;
    }
    if (this.dataService.getSelectedSide() === Fahrzeugseite.FAHRER) {
      fahrzeugseiteTo = this.dataService.druckauftrag.rechts;
    }

    if (fahrzeugseiteTo && fahrzeugseiteFrom) {
      this.dataService.getSeitenansichtForSelectedSide();

      // copy objects an conve via   this.canvasHelperService.refreshSide
      if (
        fahrzeugseiteFrom.allDataElement &&
        fahrzeugseiteFrom.allDataElement.length > 0
      ) {
        fahrzeugseiteTo.allDataElement = fahrzeugseiteFrom.allDataElement;
      } else {
        fahrzeugseiteTo.allDataElement = [];
      }
      let clonedObject_counter = 0;

      if (
        fahrzeugseiteTo.allDataElement &&
        fahrzeugseiteTo.allDataElement.length > 0
      ) {
        this.canvasHelperService
          .refreshSide(fahrzeugseiteTo)
          .then(fahrzeugeseite => {
            fahrzeugeseite.allDataElement.forEach(
              (
                element: fabric.fabric.Object,
                index: number,
                array: fabric.fabric.Object[]
              ) => {
                if (!element) {
                  // ignore null or undefined elements
                  return;
                }

                const elementWidth = this.dimensionsHelperService.getScaledWidth(
                  element
                );

                if (element.angle !== 0) {
                  const radianAngle = (element.angle * Math.PI) / 180;
                  element.left =
                    this.editorComponent.getViewBoxWidth() -
                    (element.left + elementWidth * Math.cos(radianAngle));

                  element.top =
                    element.top + elementWidth * Math.sin(radianAngle);
                  element.angle = 360 - element.angle;
                } else {
                  element.left =
                    this.editorComponent.getViewBoxWidth() -
                    (elementWidth + element.left);
                }
                element.setCoords();
                fahrzeugseiteTo.allDataElement[index] = element;

                // since the image is added via callback
                // the state can only be saved after all objects have been transferred
                clonedObject_counter++;
                if (
                  fahrzeugseiteTo.allDataElement.length === clonedObject_counter
                ) {
                  this.dataService.setSelectedSide(
                    fahrzeugseiteTo.fahrzeugseite
                  );
                  this.editorComponent.onSideUpdatedEvent();
                  this.editorComponent.updatePriceAndSaveState();
                }
              }
            );
          });
      } else {
        this.dataService.setSelectedSide(fahrzeugseiteTo.fahrzeugseite);
        this.editorComponent.onSideUpdatedEvent();
        this.editorComponent.updatePriceAndSaveState();
      }
    }
  };

  public getPreviewImagePath = (template: Template): string => {
    return template.previewImagePathStr;
  };

  public selectTemplate = (template: Template): void => {
    if (template) {
      if (
        this.editorComponent.activateZoom ||
        this.editorComponent.isInZoomMode
      ) {
        console.error('do not select Template: editorComponent.isInZoomMode');
        return;
      }
      console.log('template selected', template);
      if (this.dataService.druckauftrag.selectedTemplate !== template) {
        this.dataService.selectTemplate(template, true).subscribe(() => {
          console.log('template Selected');
          console.log(this.dataService.druckauftrag);
        });
      }
    }
  };

  public getAllDataElement = () => {
    if (this.dataService.getSelectedSide() != null) {
      return this.dataService.getSeitenansichtForSelectedSide().allDataElement;
    }
    return undefined;
  };
  /**
   * evaluates whether the button for a side view should be disabled
   */
  public isDisabled = (side: Fahrzeugseite) => {
    if (this.dataService.getSelectedSide() != null) {
      return this.dataService.getSelectedSide() === side;
    }
    return false;
  };

  /*
   * returns the Seitenasicht object that is currently selected
   */
  public getSelectedSide = () => {
    return this.dataService.getSeitenansichtForSelectedSide();
  };

  /**
   * returns whether the templates should be shown
   */
  public showTemplateNav = () => {
    return !this.isHiddenSidenav;
  };

  /**
   * calculates the price for a template
   */
  public getPriceForTemplate = (template: Template): string => {
    let price = 0;
    if (template) {
      if (template.staticPrice) {
        price += template.staticPrice;
      }
      if (template.dynamicPrice) {
        price += template.dynamicPrice;
      }
      price = Math.round(price / 10) * 10;
    }
    const localeString =
      this.translateService.currentLang.substr(0, 2) +
      '-' +
      this.dataService.country;

    return price.toLocaleString(localeString, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      currency: this.dataService.currency,
      style: 'currency',
    });
  };

  /**
   * logic for hiding the mirror button
   */
  public hideMirrorButton = () => {
    return (
      this.dataService.getSelectedSide() === Fahrzeugseite.FRONT ||
      this.dataService.getSelectedSide() === Fahrzeugseite.HECK
    );
  };

  public isMirrorButtonDisabled(): boolean {
    return this.editorComponent.isObjectSelected();
  }

  public get previousViewDisabled() {
    if (!this.initializationService.getStartInformation()) {
      return false;
    }
    return (
      this.initializationService.getStartInformation().configId !== undefined &&
      this.initializationService.getStartInformation().configId !== null
    );
  }
  public previousView() {
    this.navigationService
      .showTrackSelect(this.dataService.getParams())
      .then(confirmed => {
        if (confirmed) {
          this.editorComponent.zoomOut();
          // TODO: potentially reset the druckauftrag
          // this.dataService.druckauftrag = null;
          if (this.dataService.druckauftrag) {
            this.dataService.druckauftrag.selectedTemplate = undefined;
            //
            this.dataService.druckauftrag.rechts.allDataElement = [];
            this.dataService.druckauftrag.links.allDataElement = [];
            this.dataService.druckauftrag.front.allDataElement = [];
            this.dataService.druckauftrag.heck.allDataElement = [];
          }
        }
      });
  }
  public forewardButtonClicked = () => {
    // reset  Cursor bug (/selection)
    this.editorComponent.discardSelection();

    this.router.navigate(['/preview']);
  };
  public getAllMinimalTemplate = () => {
    return this.carDataManagerService.allMinimalTemplate;
  };

  public getAllBasicTemplate = () => {
    return this.carDataManagerService.allBasicTemplate;
  };

  /**
   * called when editor is fully Initialized
   */
  onEditorFullyInitialized() {
    this.overlayElementRef.nativeElement.style.display = 'none';
  }

  /**
   * returns whether the editor is in Zoommode
   */
  public isInZoomMode(): boolean {
    return this.editorComponent.isInZoomMode;
  }

  private updateLanguageContentOnSideChange() {
    const selectedSide: Seitenansicht = this.getSelectedSide();
    if (!selectedSide) {
      return '';
    }

    // update mirror text
    const selectedSideString =
      selectedSide.fahrzeugseite === Fahrzeugseite.FAHRER
        ? 'MIRROR-BEIFAHRER-VALUE'
        : 'MIRROR-FAHRER-VALUE';
    this.translateService
      .get('EDITOR.TOOLTIP.' + selectedSideString)
      .subscribe(result => {
        const paramsObject = { value: result };
        this.translateService
          .get('EDITOR.TOOLTIP.MIRROR-TOOLTIP', paramsObject)
          .subscribe(result2 => {
            this.mirrorSideString = result2;
          });
      });
  }
}
