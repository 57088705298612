import { DataService } from './services/dataservice';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Globals } from './globals';
import { Location } from '@angular/common';
import { FontDataService } from './services/fontdataservice';
import { MatIconRegistry } from '@angular/material/icon';
import { RestDataService } from './services/restdataservice';

import { Component, AfterViewInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  Params,
} from '@angular/router';

import { Angulartics2GoogleAnalytics } from 'angulartics2';

import { TranslateService } from '@ngx-translate/core';

import { NGXLogger } from "ngx-logger";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Beklebungskonfigurator ';
  // save the NavigationStart url at any router event
  // that triggers a navigation (change)
  public latestUrl = '';
  public returnPath = '/track-select';
  constructor(
    private dataService: DataService,
    private restDataService: RestDataService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private fontDataService: FontDataService,
    public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    public location: Location,
    private translateService: TranslateService,
    private globals: Globals,
    private logger: NGXLogger
  ) {
    translateService.setDefaultLang(this.globals.defaultLocale);

    router.events.subscribe((event: any) => {
      this.latestUrl = event.url;
      if (event instanceof NavigationEnd) {
        const url = this.location.path();
        this.angulartics2GoogleAnalytics.pageTrack(url);
        this.angulartics2GoogleTagManager.pageTrack(url);
      }
      // set the current url to provide backtracking option
      if (event instanceof NavigationStart) {
        this.logger.debug(`Return-Url: ${event.url}`);
        this.returnPath = event.url;
      }
    });
    this.logger.debug(
      `Fonts Loaded : ${this.fontDataService.allFont.length} in Total!`
    );

    // disable backspace default behaviour
    // fix for firefox history redirect
    document.addEventListener('keydown', function (event: KeyboardEvent) {
      if (event.keyCode === 8) {
        const d: Element =
          (event.srcElement as Element) || ((<Element>event.target) as Element);
        const disabled =
          d.getAttribute('readonly') ||
          d.getAttribute('disabled') ||
          d.nodeName === 'INPUT' ||
          d.nodeName === 'TEXTAREA';
        if (!disabled) {
          event.preventDefault();
          return false;
        }
      }
      return true;
    });
  }

  public getReturnPath = (): string => {
    return this.returnPath;
  };
}
