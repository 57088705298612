import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmDialogComponent } from './../dialogs/confirmdialog.component';

@Injectable()
export class NavigationService {
  private readonly trackSelectPath = '/track-select';
  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService
  ) {}

  public showTrackSelect(queryParams: any): Promise<boolean> {
    return this.confirmDatalossAndNavigateTo(this.trackSelectPath, {
      queryParams,
    });
  }

  public navigateTrackSelect(queryParams: any) {
    this.router.navigate([this.trackSelectPath], { queryParams });
  }

  private confirmDatalossAndNavigateTo(
    targetUrl: string,
    queryParams: any
  ): Promise<boolean> {
    return new Promise(resolve => {
      const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
      const currentUrl = snapshot.url;
      const isExternalUrl =
        targetUrl.startsWith('https://') || targetUrl.startsWith('http://');
      if (currentUrl === this.trackSelectPath) {
        this.navigateToTargetOrExternalUrl(
          isExternalUrl,
          targetUrl,
          queryParams
        );
        resolve(true);
      } else {
        this.showConfirmationDialog().subscribe((confirmation: boolean) => {
            if (!confirmation) {
              return resolve(false);
            }
            this.navigateToTargetOrExternalUrl(
              isExternalUrl,
              targetUrl,
              queryParams
            );
            resolve(true);
          })
      }
    });
  }

  /**
   * handles navigation / either via window.location.href or via router
   */
  private navigateToTargetOrExternalUrl(
    isExternalUrl: boolean,
    targetUrl: string,
    queryParams: any
  ) {
    if (isExternalUrl) {
      window.location.href = targetUrl;
    } else {
      this.router.navigate([targetUrl], queryParams);
    }
  }

  private showConfirmationDialog() {
    const dialogRef: MatDialogRef<
      ConfirmDialogComponent,
      any
    > = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleKey: 'NOTIFICATIONS.LOSE-DATA-TITLE',
        textKey: 'NOTIFICATIONS.LOSE-DATA-TEXT',
        showNoButton: true,
      },
    });
    return dialogRef.afterClosed();
  }
}
