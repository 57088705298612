import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { CONSTANTS } from '../../models/helpers/constants';
import { isCustomText } from '../../models/graphicelements/customtext';

@Component({
  selector: 'app-element-measurement',
  templateUrl: './element-measurement.component.html',
  styleUrls: ['./element-measurement.component.scss'],
})
export class ElementMeasurementComponent implements OnInit, AfterViewInit {
  @Output() widthUpdated = new EventEmitter<number>();
  @Output() heightUpdated = new EventEmitter<number>();
  @Output() rotationUpdated = new EventEmitter<number>();
  @Input()
  private activeObject: fabric.Object;
  @Input()
  public rotationOnly: boolean;
  @Input()
  public isTopSystemActive: boolean = false;

  public get minElementSize(): number {
    return this.isTopSystemActive ? 0 : 50;
  }

  private _currentWidth = 0;
  private _currentHeight = 0;
  private _currentRotation = 0;
  @ViewChild('widthInput', { static: false })
  private widthInput: ElementRef;
  @ViewChild('heightInput', { static: false })
  private heightInput: ElementRef;
  @ViewChild('rotationInput', { static: true })
  private rotationInput: ElementRef;

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.matIconRegistry
      .addSvgIcon(
        'icon-rotation',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-rotate.svg'
        )
      )
      .addSvgIcon(
        'icon-element-width',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-breite.svg'
        )
      )
      .addSvgIcon(
        'icon-element-height',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../../assets/icons/V2/icon-hoehe.svg'
        )
      );
  }
  ngAfterViewInit() {
    if (!this.rotationOnly) {
      this.registerInputHtmlEventHandlers(
        this.widthInput.nativeElement,
        this.widthUpdated
      );

      this.registerInputHtmlEventHandlers(
        this.heightInput.nativeElement,
        this.heightUpdated
      );
    }
    this.registerInputHtmlEventHandlers(
      this.rotationInput.nativeElement,
      this.rotationUpdated
    );
  }

  public get isRotationDisabled() {
    return this.isTopSystemActive && isCustomText(this.activeObject);
  }

  private registerInputHtmlEventHandlers = (
    nativeElement: any,
    updateValueEventEmitter: EventEmitter<number>
  ) => {
    fromEvent(nativeElement, 'input')
      .pipe(
        map((a: Event) => {
          return (a.target as any).valueAsNumber;
        }),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((newValue: number) => {
        if (newValue !== undefined && newValue !== null && !isNaN(newValue)) {
          updateValueEventEmitter.emit(newValue);
        }
      });
  };

  /**
   * checks if inputElement of Component is active (hasFocus)
   */
  public hasFocus(): boolean {
    const widthActive = this.widthInput
      ? this.widthInput.nativeElement === document.activeElement
      : false;
    const heightActive = this.heightInput
      ? this.heightInput.nativeElement === document.activeElement
      : false;
    return (
      widthActive ||
      heightActive ||
      this.rotationInput.nativeElement === document.activeElement
    );
  }

  public get currentWidth(): number {
    this._currentWidth = this.activeObject
      ? Math.round(
          (this.activeObject.width + this.activeObject.strokeWidth) *
            this.activeObject.scaleX *
            CONSTANTS.VIEWBOX_TO_METER_FACTOR *
            1000
        )
      : this._currentWidth;
    return this._currentWidth;
  }
  public set currentWidth(value: number) {
    this._currentWidth = Math.round(value);
  }

  public get currentHeight(): number {
    this._currentHeight = this.activeObject
      ? Math.round(
          (this.activeObject.height + this.activeObject.strokeWidth) *
            this.activeObject.scaleY *
            CONSTANTS.VIEWBOX_TO_METER_FACTOR *
            1000
        )
      : this._currentHeight;
    return this._currentHeight;
  }
  public set currentHeight(value: number) {
    this._currentHeight = Math.round(value);
  }

  public get currentRotation(): number {
    this._currentRotation = this.activeObject
      ? this.activeObject.angle
      : this._currentRotation;
    return this._currentRotation;
  }
  public set currentRotation(value: number) {
    this._currentRotation = Math.round(value);
  }
}
